import { ClaimSubmitState, ClaimSubmitActionTypes, CLAIM_SUBMIT } from './types';
export const initialState: ClaimSubmitState = {
    pending: false,
    error: null
};

export const claimSubmitReducer = (state = initialState, action: ClaimSubmitActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case CLAIM_SUBMIT.CLAIM_SUBMIT_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case CLAIM_SUBMIT.CLAIM_SUBMIT_PENDING:
            return {
                ...state,
                pending: true,
                claimSubmitRequest: action.payload
            }
        case CLAIM_SUBMIT.CLAIM_SUBMIT_SUCCESS:
            return {
                ...state,
                pending: false,
                claimSubmitResponse: action.claimSubmit
            }
        default:
            return NewState;

    }
};