import React from 'react';
import { Modal } from 'react-bootstrap';
export const InvalidSessionModal = ({ isShow }: any) => {
    return (
        <Modal centered className="newcommon model_covid modal-background-blur" backdrop={false} show={isShow} >
            <Modal.Header>
                <div className="modal-title">Prescreening appointment</div>
            </Modal.Header>
            <Modal.Body>
                <>
                    <p>Invalid session, please request clinic admin to send you new pre-screening request.</p>
                    <p className='text-danger'>Note: Please close this tab from the browser. </p>
                </>
            </Modal.Body>
        </Modal>
    )
}