import Axios from "axios";
import configs from "../configs/apiConfigs";

export const getHygieneFeatures = () => {
    return Axios.get(configs.API_URL + 'hygienes/').then((success) => {
        if (success.status !== 200) {
            throw success;
        } else if (success && success.data && success.data.status && success.data.status.error === true) {
            throw success;
        }
        return success.data;
    }).then(json => {
        return json;
    }).catch(error => {
        throw JSON.parse(JSON.stringify(error));
    });
}

export const getProviderIdByName = (clinicName: string) => {
    return Axios.get(configs.API_URL + 'clinic/search/byname/?name=' + clinicName).then((success) => {
        if (success.status !== 200) {
            throw success;
        } else if (success && success.data && success.data.status && success.data.status.error === true) {
            throw success;
        }
        return success.data;
    }).then(json => {
        return json;
    }).catch(error => {
        throw JSON.parse(JSON.stringify(error));
    });
}