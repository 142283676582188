import React, { useState, useEffect } from "react";
import { emailValidation } from "../../../../utils/validators";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { referInvitationSent } from "../../../services/refer";
import { toast } from "react-toastify";
export const ReferByEmailSms = ({ handleModalClose, handleSuccess }: any) => {
  const [show, setShow] = useState(false);
  const [referList, setReferList]: any = useState([]);
  const [emailOrMobile, setEmailOrMobile]: any = useState("");
  const [emValidation, setEmValidation] = useState({
    isInValid: false,
    msg: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailOrMobile = () => {
    if (isNaN(emailOrMobile)) {
      if (!emailValidation(emailOrMobile)) {
        setEmValidation({ isInValid: true, msg: "Invalid email" });
        return;
      }
    } else {
      if (emailOrMobile.length > 10 || emailOrMobile.length < 10) {
        setEmValidation({ isInValid: true, msg: "Invalid mobile" });
        return;
      }
    }
    let alreadyAdded = referList.find((refer: any) => {
      return refer === emailOrMobile;
    });
    if (alreadyAdded) {
      setEmValidation({ isInValid: true, msg: "Already added." });
      return;
    }
    setReferList([...referList, emailOrMobile]);
    setEmailOrMobile("");
  };

  const handleRemove = (removeEM: any) => {
    // console.log("remove", removeEM);
    // let findIndex = referList.findIndex((refer: any) => {
    //   return refer === removeEM;
    // });
    // console.log("findIndex", findIndex);
    setReferList(referList.filter((fItem: any) => fItem !== removeEM));
  };

  const onClickReferNow = () => {
    let request = {
      inviteOn: referList.toString(),
    };
    setIsLoading(true);
    referInvitationSent(request)
      .then((success) => {
        setIsLoading(false);
        if (success && success.status && success.status.error === false) {
          toast.success("Invitation sent successfully");
          setEmailOrMobile("");
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    console.log("referList", referList);
  }, [referList]);

  return (
    <>
      <div className="offcanvas-action d-flex">
        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7 ms-auto"
          // data-bs-dismiss="offcanvas" aria-label="Close"
          onClick={() => {
            handleModalClose();
          }}
        >
          Close
        </button>
      </div>

      <div className="offcanvas-header">
        <h5 className="offcanvas-title">
          Refer a Friend{" "}
          <div className="d-inline-block" style={{ marginLeft: "10px" }}>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip>
                  "Earn points for referring unlimited friends. Points can only
                  be earned once per referral."
                </Tooltip>
              }
              delay={{ show: 0, hide: 0 }}
            >
              <i
                className="bi bi-info-circle info-icon"
                style={{ cursor: "pointer" }}
              ></i>
            </OverlayTrigger>
          </div>
        </h5>
      </div>
      <div className="offcanvas-body">
        <div className="addedsmsemailbadge mb-3">
          {referList &&
            referList.length > 0 &&
            referList.map((refer: any) => {
              return (
                <span className="badge bg-secondary me-2">
                  {refer}
                  <button
                    type="button"
                    className="btn text-white p-0 ms-2"
                    onClick={() => {
                      handleRemove(refer);
                    }}
                  >
                    <i className="bi bi-dash-circle"></i>
                  </button>
                </span>
              );
            })}
        </div>
        <div className="adduseremailandsms">
          <label className="form-label">
            Enter email address or 10 digit mobile numbers
          </label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              value={emailOrMobile}
              placeholder="Email address or mobile number"
              onChange={(e) => {
                setEmailOrMobile(e.target.value);
              }}
            />
            <button
              className="btn btn-primary"
              type="button"
              id="button-addon2"
              onClick={() => {
                handleEmailOrMobile();
              }}
            >
              <i className="bi bi-plus-circle"></i>
            </button>
            {emValidation.isInValid && (
              <div className="form-error">{emValidation.msg}</div>
            )}
          </div>
          <div className="mb-3">
            <button
              type="button"
              className="btn btn-dark w-100 fw-normal"
              onClick={() => {
                onClickReferNow();
              }}
              disabled={referList.length == 0}
            >
              Refer Now{" "}
              {isLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
