import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Stack from "react-bootstrap/Stack";
import { addCoverage } from "../../../services/insuranceCalculator";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  allowAlphabetsAndSpace,
  allowOnlyDigit,
} from "../../../../utils/validators";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../../store/loadingIndicator/actions";

export const AddCoverage = ({
  handleModalClose,
  handleSuccess,
  dependant,
}: any) => {
  const dispatch = useDispatch();
  const [dependants, setDependants] = useState([]);
  const [isTypeError, setIsTypeError] = useState(false);
  const [isAmountError, setIsAmountError] = useState(false);
  const [coverageRows, setCoverageRows]: any = useState([]);
  const userData = useSelector(
    (state: any) => state.patientData.patientDataResponse,
    shallowEqual
  );
  const {
    register,
    setError,
    handleSubmit,
    setValue,
    getValues,
    errors,
    trigger,
    control,
    watch,
  } = useForm({
    defaultValues: {
      dependantid: "",
      coveragetype: "",
      type: "",
      amount: "",
    },
  });

  useEffect(() => {
    if (userData && userData.dependant && userData.dependant.length > 0) {
      setDependants(userData.dependant);
    }
  }, [userData]);

  const onAddRow = () => {
    if (!allowAlphabetsAndSpace(watch("type"))) {
      setIsTypeError(true);
      return;
    }
    let amount: any = watch("amount");
    if (!allowOnlyDigit(amount)) {
      setIsAmountError(true);
      return;
    }
    setCoverageRows([...coverageRows, { type: watch("type"), amount: amount }]);
    setIsAmountError(false);
    setIsTypeError(false);
    setValue("type", "");
    setValue("amount", "");
  };

  const onRemoveRow = (selectedIndex: any) => {
    setCoverageRows(
      coverageRows.filter((item: any, index: any) => index !== selectedIndex)
    );
  };

  const onSubmit = (data: any) => {
    const request = {
      name: data.coveragetype,
      // for: data.dependantid,
      for: dependant.id,
      coverageDetails: coverageRows,
    };
    dispatch(fetchLoadingPending());
    addCoverage(request)
      .then((success: any) => {
        dispatch(fetchLoadingSuccess(false));
        console.log("success", success);
        if (
          success &&
          success.response &&
          success.response.data &&
          success.response.data.status &&
          success.response.data.status.error === false
        ) {
          toast.success(success.response.data.status.msg);
          handleSuccess();
        }
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
        toast.error("Something went wrong.");
        console.log(err);
      });
  };
  return (
    <>
      <div className="offcanvas-action d-flex">
        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7 ms-auto"
          onClick={() => {
            handleModalClose();
          }}
        >
          Close
        </button>
      </div>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Add Coverage</h5>
      </div>
      <div className="offcanvas-body">
        <div className="add_covform">
          <div className="mb-3 d-none">
            <label className="form-label">Dependent</label>
            <select className="form-select" name="dependantid" ref={register()}>
              {dependants &&
                dependants.length > 0 &&
                dependants.map((dependant: any) => {
                  return (
                    <option key={dependant.id} value={dependant.id}>
                      {dependant.firstName + " " + dependant.lastName}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label">Coverage type</label>
            <input
              type="text"
              className="form-control"
              id=""
              name="coveragetype"
              placeholder="ex: Dental/Vision/Ortho"
              ref={register({ required: true })}
            />
            {errors &&
              errors.coveragetype &&
              errors.coveragetype.type === "required" && (
                <div className="form-error">Coverage Type is required</div>
              )}
          </div>
          <div className="mb-3">
            <label className="form-label">Coverage details</label>
            {coverageRows &&
              coverageRows.length > 0 &&
              coverageRows.map((row: any, index: any) => {
                return (
                  <Stack
                    key={index}
                    direction="horizontal"
                    gap={3}
                    className="mb-2"
                  >
                    <div>
                      <input
                        disabled
                        type="text"
                        className="form-control"
                        id={index + "type"}
                        value={row.type}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        disabled
                        className="form-control"
                        id={index + "amount"}
                        value={row.amount}
                      />
                    </div>
                    <button
                      className="btn p-0"
                      onClick={() => {
                        onRemoveRow(index);
                      }}
                    >
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    </button>
                  </Stack>
                );
              })}
            <Stack direction="horizontal" gap={3} className="mb-2">
              <div>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Add"
                  name="type"
                  ref={register({
                    pattern: /^[a-zA-Z ]*$/,
                  })}
                />
                {isTypeError && (
                  <div className="text-danger error">Type is invalid.</div>
                )}
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Amount"
                  name="amount"
                  ref={register({
                    pattern: /[^0-9]/g,
                  })}
                />
                {isAmountError && (
                  <div className="text-danger error">Amount is invalid.</div>
                )}
              </div>
              <button
                className="btn p-0"
                disabled={watch("type") === "" || Number(watch("amount")) <= 0}
                onClick={onAddRow}
              >
                <i className="bi bi-plus-circle-fill text-dark"></i>
              </button>
            </Stack>
          </div>
          <div className="mb-3">
            <button
              disabled={
                watch("dependantid") === "" ||
                watch("coveragetype") === "" ||
                coverageRows.length <= 0
              }
              type="button"
              className="btn btn-dark w-100 fw-normal"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
