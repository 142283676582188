import React from 'react';
import { Animated } from "react-animated-css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PatientDataState, PatientDataResponse, PatientDataRequest } from '../../../store/patientData/types';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { allowAlphabetsAndSpace, phoneValidator, emailValidation, allowOnlyDigit } from '../../../utils/validators';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import axios from 'axios';
import CONFIG from '../../configs/apiConfigs';
import { attachHeader, getCookie } from '../../../utils';
import { toast } from 'react-toastify';
import { takeOnlyDigitAndPlus, takeOnlyDigit } from '../../../utils/global';
import { patientDataPending } from '../../../store/patientData/actions';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { CONTACTMETHODS } from '../../../constants';
interface Props {
  PatientDataState: PatientDataState;
  patientData: typeof patientDataPending;

}
interface State {
  patientData?: PatientDataResponse;
  isEdit: boolean;
  firstName: any;
  lastName: any;
  relation: any;
  email: any;
  dob: any;
  phone: any;
  gender: any;
  contactMethod: any;
  reminder: any;
  dependantId: any;
  isAddDependant: boolean;
  isLoading: boolean;
}

class MyFamily extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isEdit: true,
      firstName: '', lastName: '', relation: "Son", email: '', dob: moment().subtract(1, 'days'),
      phone: '', gender: 'Male', contactMethod: CONTACTMETHODS.EMAIL_TEXT, reminder: "", dependantId: '',
      isAddDependant: false, isLoading: false

    }
  }
  componentDidMount() {
    if (this.props.PatientDataState && this.props.PatientDataState.error === null &&
      this.props.PatientDataState.patientDataResponse) {
      console.log('Datass', JSON.parse(JSON.stringify(this.props.PatientDataState.patientDataResponse)))
      this.setState({ patientData: JSON.parse(JSON.stringify(this.props.PatientDataState.patientDataResponse)) })

    }
  }
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (this.props !== nextProps) {
      if (nextProps.PatientDataState && nextProps.PatientDataState.patientDataResponse &&
        nextProps.PatientDataState.patientDataResponse !== this.props.PatientDataState.patientDataResponse) {
        this.setState({ patientData: JSON.parse(JSON.stringify(nextProps.PatientDataState.patientDataResponse)) })
      }
    }

    return true;
  }
  handleIsEdit = (item: any) => {
    this.setState({
      firstName: item.firstName, lastName: item.lastName, relation: item.relation, phone: item.phone,
      dependantId: item.id,
      email: item.email, dob: item.dob, gender: item.gender, contactMethod: item.contactMethod, reminder: item.reminder,
      isEdit: true
    })
  }
  handleChangeDate = (date: any) => {
    this.setState({ dob: date })
  }

  handleUpdate = () => {
    if (this.state.firstName === '') {
      toast.warn('Please enter valid FirstName.');
      return;
    }
    if (this.state.lastName === '') {
      toast.warn('Please enter valid LastName.');
      return;
    }
    if (this.state.firstName === '') {
      toast.warn('Please enter valid FirstName.');
      return;
    }

    if (!emailValidation(this.state.email)) {
      toast.warn('Please enter valid email.');
      return
    }
    if (this.state.phone.length !== 10) {
      toast.warn('Please enter valid mobile.');
      return
    }
    const body = {
      id: this.state.dependantId,
      fname: this.state.firstName,
      lname: this.state.lastName,
      relation: this.state.relation,
      email: this.state.email,
      phone: this.state.phone,
      gender: this.state.gender,
      dob: this.state.dob,
      contactMethod: this.state.contactMethod,
      reminder: this.state.reminder,
    }
    this.setState({ isLoading: true });
    const url = CONFIG.API_URL + 'patient' + '/' + this.props.PatientDataState.patientDataResponse?.uniqueId + '/dependant/edit/';
    axios.post(url, body, { headers: attachHeader(), withCredentials: true }).then((response) => {
      if (response.status !== 200) {
        throw response;
      } else if (response.data && response.data.status && response.data.status.error === true) {
        throw response.data;
      }
      return response.data;
    }).then((success: any) => {
      this.setState({ isLoading: false, isEdit: false });
      toast.success("Updated successfully");
      const login: any = getCookie();
      this.props.patientData({ uniqueId: login.xpr_user_id });
    }).catch((error: any) => {
      this.setState({ isLoading: false });
      if (error.status && error.status.msg) {
        toast.error(error.status.msg);
        return;
      }
      toast.error("Update failed");
    });

  }
  handleAdd = () => {
    if (this.state.firstName === '') {
      toast.warn('Please enter valid FirstName.');
      return;
    }
    if (this.state.lastName === '') {
      toast.warn('Please enter valid LastName.');
      return;
    }
    if (this.state.firstName === '') {
      toast.warn('Please enter valid FirstName.');
      return;
    }

    if (!emailValidation(this.state.email)) {
      toast.warn('Please enter valid email.');
      return
    }
    if (this.state.phone.length !== 10) {
      toast.warn('Please enter valid mobile.');
      return
    }
    const body = {
      fname: this.state.firstName,
      lname: this.state.lastName,
      relation: this.state.relation,
      email: this.state.email,
      phone: this.state.phone,
      gender: this.state.gender,
      dob: this.state.dob,
      contactMethod: this.state.contactMethod,
      reminder: this.state.reminder,
    }
    this.setState({ isLoading: true });
    const url = CONFIG.API_URL + 'patient' + '/' + this.props.PatientDataState.patientDataResponse?.uniqueId + '/dependant/add/';
    axios.post(url, body, { headers: attachHeader(), withCredentials: true }).then((response) => {
      if (response.status !== 200) {
        throw response;
      } else if (response.data && response.data.status && response.data.status.error === true) {
        throw response.data;
      }
      return response.data;
    }).then((success: any) => {
      this.setState({ isLoading: false });
      console.log('Success', success)
      this.setState({ isAddDependant: false })
      const login: any = getCookie();
      this.props.patientData({ uniqueId: login.xpr_user_id });
    }).catch((error: any) => {
      this.setState({ isLoading: false });
      toast.warn(error.status.msg);
      console.log(error)
    });
  }
  handleCreateLogin = (item: any) => {
    const isCreateLogin = window.confirm('Are you sure? you are indicating that this family member will manage their own appointments.')
    if (isCreateLogin) {
      const ID = item.id;
      const url = CONFIG.API_URL + 'patient' + '/' + this.props.PatientDataState.patientDataResponse?.uniqueId + '/dependant/creation/' + ID + '/';
      console.log('IDURL', ID, url)
      axios.get(url, { headers: attachHeader(), withCredentials: true }).then((response) => {
        if (response.status !== 200) {
          throw response;
        } else if (response.data && response.data.status && response.data.status.error === true) {
          throw response.data;
        }
        return response.data;
      }).then((success: any) => {
        console.log('Success', success)
        // this.setState({isAddDependant:false})
        toast.success(success.status.msg);
        const login: any = getCookie();
        this.props.patientData({ uniqueId: login.xpr_user_id });
      }).catch((error: any) => {
        console.log(error)
        toast.warn(error.status.msg)
      });
    }


  }
  handleChangeGuarantor = (item: any) => {
    const isChangeGuarantor = window.confirm('If you change guarantor then you will not able to manage appointment for other family member')
    if (isChangeGuarantor) {
      const ID = item.id;
      const url = CONFIG.API_URL + 'patient' + '/' + this.props.PatientDataState.patientDataResponse?.uniqueId + '/change/guarantor/' + ID + '/';
      console.log('IDURL', ID, url)
      axios.get(url, { headers: attachHeader(), withCredentials: true }).then((response) => {
        if (response.status !== 200) {
          throw response;
        } else if (response.data && response.data.status && response.data.status.error === true) {
          throw response.data;
        }
        return response.data;
      }).then((success: any) => {
        console.log('Success', success)
        // this.setState({isAddDependant:false})
        toast.success(success.status.msg);
        const login: any = getCookie();
        this.props.patientData({ uniqueId: login.xpr_user_id });
      }).catch((error: any) => {
        console.log(error)
      });
    }

  }
  handleIsAddDependant = () => {
    this.setState({
      isAddDependant: true, firstName: '', lastName: '', relation: "Son", email: '', dob: moment().subtract(1, 'days'),
      phone: '', gender: 'Male', contactMethod: CONTACTMETHODS.EMAIL_TEXT, reminder: ""
    })
  }


  handleIsCancel = () => {
    this.setState({ isEdit: false })
  }

  render() {
    return (
      <>
        <div className="main-middle-sec" id="main-middle">
          <section className="middle-navbar px-3">
            <div className="container-fluid">
              <div className="d-flex justify-content-between">
                <div>
                  <div className="nav-title-txt">My family</div>

                  <ul className="nav nav-tabs" id="commonTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className="active nav-link" id="home-tab">My family</a>
                    </li>
                  </ul>

                </div>

                <div>
                  {
                    this.state.patientData && this.state.patientData.isGuarantor &&
                    <div className="memact-btn d-flex justify-content-end">
                      <button type="button" className="btn btn-primary" onClick={() => { this.handleIsAddDependant() }}>Add new member</button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </section>
          <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDuration={1000} isVisible={true}>
            <section className="my-family-page p-3 pt-4">

              <div className="container">

                <div className="row justify-content-center">
                  <div className="col-xl-8">
                    <ul>
                      {this.state.patientData && this.state.patientData.dependant.length > 0 &&
                        this.state.patientData.dependant.map((value: any) => {
                          return (
                            <li className="card mb-3">
                              <div className="card-body">
                                <div key={value.id} className="mlist d-md-flex d-block align-items-center">
                                  <div className="media flex-grow-1">
                                    <div className="media-body align-self-center">
                                      <div>{value.firstName + " " + value.lastName}</div>
                                      <div className="text-muted">{value.email}</div>
                                      {(value.guarantorId === value.loginId) && <span className="badge rounded-pill bg-danger text-white">Guarantor</span>}
                                    </div>
                                  </div>
                                  <div className="d-flex right-btn-box">
                                    {!value.loginId && this.state.patientData && this.state.patientData.isGuarantor && (value.guarantorId !== value.loginId) &&
                                      <div className="crete-lg me-2">
                                        <button type="button" className="btn text-success" onClick={() => { this.handleCreateLogin(value) }}>Create Sign in</button>
                                      </div>
                                    }
                                    <div className="mem-edit">
                                      {this.state.patientData && this.state.patientData.isGuarantor && <button className="btn edit-btn text-primary " onClick={() => { this.handleIsEdit(value) }}>Edit</button>}
                                      {/* {value.loginId && (value.guarantorId !== value.loginId) && this.state.patientData && this.state.patientData.isGuarantor &&
                                  <button type="button" className="btn btn-primary" onClick={() => { this.handleChangeGuarantor(value) }}>Change Guarantor</button>
                                } */}
                                    </div>
                                  </div>
                                </div>

                                {/* edit */}
                                {this.state.isEdit && this.state.dependantId === value.id &&
                                  <div className="editOpen">

                                    <div className="border-bottom mt-4 mb-4 pb-3">
                                      Edit member information
                                    </div>

                                    <div className="memedit">
                                      <div className="row mb-2">
                                        <div className="col-6">
                                          <label className="form-label">First Name</label>
                                          <input type="text" className="form-control" placeholder="First name" value={this.state.firstName} onChange={(e) => {
                                            if (allowAlphabetsAndSpace(e.target.value)) {
                                              this.setState({ firstName: e.target.value });
                                            }
                                          }} />
                                        </div>
                                        <div className="col-6">
                                          <label className="form-label">Last Name</label>
                                          <input type="text" className="form-control" placeholder="Last name" value={this.state.lastName} onChange={(e) => {
                                            if (allowAlphabetsAndSpace(e.target.value)) {
                                              this.setState({ lastName: e.target.value });
                                            }
                                          }} />
                                        </div>
                                      </div>

                                      <div className="row mb-2">
                                        <div className="col-md-6">
                                          <label className="form-label">Email</label>
                                          <input type="text" className="form-control" placeholder="Email address" value={this.state.email} onChange={(e) => {
                                            this.setState({ email: e.target.value });
                                          }} />
                                        </div>
                                        <div className="col-md-6">
                                          <label className="form-label">Mobile number</label>
                                          {/* <input type="text" className="form-control" placeholder="Phone number"/> */}
                                          <InputMask mask="9999999999" value={this.state.phone}
                                            onChange={(e) => {
                                              this.setState({ phone: e.target.value });
                                            }}>
                                            {(inputProps: any) => <input {...inputProps} className="form-control"></input>}
                                          </InputMask>
                                        </div>
                                      </div>

                                      <div className="row mb-2">
                                        <div className="col-6">
                                          <label className="form-label">Relation</label>
                                          <select className="form-select" value={this.state.relation} onChange={(e) => {
                                            this.setState({ relation: e.target.value });
                                          }}>
                                            <option value="Son">Son</option>
                                            <option value="Daughter">Daughter</option>
                                            <option value="Spouse">Spouse</option>
                                          </select>
                                        </div>
                                        <div className="col-6">
                                          <label className="form-label">Gender</label>
                                          <select className="form-select" value={this.state.gender} onChange={(e) => {
                                            this.setState({ gender: e.target.value });
                                          }}>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                          </select>
                                        </div>
                                      </div>

                                      <div className="row mb-2">
                                        <div className="col-md-6 miuif_margin">
                                          <label className="form-label">Date of birth</label>
                                          {/* <input type="text" className="form-control" placeholder="Date of birth"/> */}
                                          <div className="editDate">
                                            <KeyboardDatePicker
                                              margin="none"
                                              id={"date-picker-dialog"}
                                              name={"date-picker-dialog"}
                                              // label="Date of Birth"
                                              format="MM/DD/YYYY"
                                              maxDate={moment().subtract(1, 'days')}
                                              value={moment(this.state.dob)}
                                              onChange={(date: any) => { this.handleChangeDate(date) }}
                                              KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <label className="form-label">Preferred contact method</label>
                                          {/* <select className="form-select" value={this.state.contactMethod} onChange={(e) => {
                                      this.setState({ contactMethod: e.target.value });
                                  }}>
                                  <option value="Call">Call</option>
                                  <option value="Text">Text</option>
                                  <option value="Text & Email">Text & Email</option>
                                </select> */}
                                          <div className="form-group">
                                            <div className="form-check me-2">
                                              <input type="radio" id="call" name="customRadio" className="form-check-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.EMAIL, reminder: '' }) }} checked={this.state.contactMethod === CONTACTMETHODS.EMAIL ? true : false} />
                                              <label className="form-check-label" htmlFor="call">Email Only</label>
                                            </div>
                                            <div className="form-check">
                                              <input type="radio" id="text" name="customRadio" className="form-check-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.TEXT, reminder: '' }) }} checked={this.state.contactMethod === CONTACTMETHODS.TEXT ? true : false} />
                                              <label className="form-check-label" htmlFor="text">Text Only</label>
                                            </div>
                                            <div className="form-check">
                                              <input type="radio" id="Text & Email" name="customRadio" className="form-check-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.EMAIL_TEXT, reminder: "" }) }} checked={this.state.contactMethod === CONTACTMETHODS.EMAIL_TEXT ? true : false} />
                                              <label className="form-check-label" htmlFor="Text & Email">Email & Text</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div className="row">
                              <div className="col-6">
                                <label>Automated Reminder</label>
                                
                                <div className="form-inline">
                                  <div className="custom-control custom-radio me-3">
                                    <input type="radio" id="yes2" name="customRadio1" className="form-check-input" onClick={()=>{this.setState({reminder:"yes"})}} checked={this.state.reminder==="yes"} />
                                    <label className="form-check-label" htmlFor="yes">Yes</label>
                                  </div>

                                  <div className="custom-control custom-radio">
                                    <input type="radio" id="no2" name="customRadio1" className="form-check-input" onClick={()=>{this.setState({reminder:"no"})}} checked={this.state.reminder==="no"} />
                                    <label className="form-check-label" htmlFor="no">No</label>
                                  </div>
                                </div>
                              </div>
                              </div> */}
                                      <div className="row">
                                        <div className="d-flex justify-content-end">
                                          <button type="button" className="btn btn-outline-secondary me-2" onClick={() => { this.handleIsCancel() }}>Cancel</button>
                                          <button disabled={this.state.isLoading} type="button" className="btn btn-primary" onClick={() => { this.handleUpdate() }}>
                                            {
                                              this.state.isLoading &&
                                              <span className="aft_load right">
                                                <Spinner
                                                  as="span"
                                                  animation="border"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            }
                                            Save
                                          </button>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                }
                              </div>
                            </li>
                          )
                        })

                      }
                    </ul>
                  </div>
                </div>

              </div>



            </section>
          </Animated>
          <Modal show={this.state.isAddDependant} className="newcommon addNewMember" centered backdrop={true} onHide={() => {
            this.setState({ isAddDependant: false });
          }}>

            <Modal.Header>
              <div className="modal-title">Add new member</div>
              <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                this.setState({ isAddDependant: false });
              }}>
              </button>
            </Modal.Header>

            <Modal.Body>
              <div>
                <div className="memedit">
                  <div className="row mb-2">
                    <div className="col-6">
                      <label className="form-label">First Name</label>
                      <input type="text" className="form-control" placeholder="First name" value={this.state.firstName} onChange={(e) => {
                        if (allowAlphabetsAndSpace(e.target.value)) {
                          this.setState({ firstName: e.target.value });
                        }
                      }} />
                    </div>
                    <div className="col-6">
                      <label className="form-label">Last Name</label>
                      <input type="text" className="form-control" placeholder="Last name" value={this.state.lastName} onChange={(e) => {
                        if (allowAlphabetsAndSpace(e.target.value)) {
                          this.setState({ lastName: e.target.value });
                        }
                      }} />
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6">
                      <label className="form-label">Email</label>
                      <input type="text" className="form-control" placeholder="Email address" value={this.state.email} onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }} />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Mobile number</label>
                      {/* <input type="text" className="form-control" placeholder="Phone number"/> */}
                      <InputMask mask="9999999999" value={this.state.phone}
                        onChange={(e) => {
                          this.setState({ phone: e.target.value });
                        }}>
                        {(inputProps: any) => <input {...inputProps} className="form-control"></input>}
                      </InputMask>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-6">
                      <label className="form-label">Relation</label>
                      <select className="form-select" value={this.state.relation} onChange={(e) => {
                        this.setState({ relation: e.target.value });
                      }}>
                        <option value="Son">Son</option>
                        <option value="Daughter">Daughter</option>
                        <option value="Spouse">Spouse</option>
                      </select>
                    </div>
                    <div className="col-6">
                      <label className="form-label">Gender</label>
                      <select className="form-select" value={this.state.gender} onChange={(e) => {
                        this.setState({ gender: e.target.value });
                      }}>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-6 miuif_margin">
                      <label className="form-label">Date of birth</label>
                      <KeyboardDatePicker
                        margin="none"
                        id={"date-picker-dialog"}
                        name={"date-picker-dialog"}
                        format="MM/DD/YYYY"
                        maxDate={moment().subtract(1, 'days')}
                        value={moment(this.state.dob)}
                        onChange={(date: any) => { this.handleChangeDate(date) }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="row mb-2">
                      <label className="form-label">Preferred contact method</label>
                      <div className="d-flex align-items-center">
                        <div className="form-check me-3">
                          <input type="radio" id="call" name="customRadio3" className="form-check-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.EMAIL, reminder: "" }) }} checked={this.state.contactMethod === CONTACTMETHODS.EMAIL ? true : false} />
                          <label className="form-check-label" htmlFor="call">Email</label>
                        </div>

                        <div className="form-check me-3">
                          <input type="radio" id="text" name="customRadio3" className="form-check-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.TEXT, reminder: '' }) }} checked={this.state.contactMethod === CONTACTMETHODS.TEXT ? true : false} />
                          <label className="form-check-label" htmlFor="text">Text</label>
                        </div>
                        <div className="form-check me-3">
                          <input type="radio" id="Text & Email" name="customRadio3" className="form-check-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.EMAIL_TEXT, reminder: "" }) }} checked={this.state.contactMethod === CONTACTMETHODS.EMAIL_TEXT ? true : false} />
                          <label className="form-check-label" htmlFor="Text & Email">Text & Email</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </Modal.Body>

            <Modal.Footer>
              <button disabled={this.state.isLoading} type="button" className="btn btn-primary" onClick={() => { this.handleAdd() }}>
                {
                  this.state.isLoading &&
                  <span className="aft_load right">
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                }
                Add
              </button>
            </Modal.Footer>

          </Modal>
        </div>
      </>
    )
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  patientData: (request: PatientDataRequest) => {
    dispatch(patientDataPending(request));
  }
});
const mapStateToProps = (state: IAppState) => ({
  PatientDataState: state.patientData,
});
export default connect(mapStateToProps, mapDispatchToProps)(MyFamily)