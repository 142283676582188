import axios from "axios"
import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandlers";
import { getCookie } from "../utils";

export const joinLoyalty = (id: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "joinloyalty/" + id + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}

export const getLoyaltyActionList = (id: string, queryPrams: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "loyaltyactionlist/" + id + "/" + queryPrams,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}

export const claimLoyalty = (id: string, data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "claimloyalty/" + id + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.FORM_DATA,
            data: data
        },
        method: METHOD_TYPE.POST,
    };
    return commonApi(request);
}

export const getClaimAppointmentList = (id: string, actionId: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "claimloyalty/" + id + "/appointment/" + actionId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}

export const getBrandList = (id: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "brandlist/" + id + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}

export const getBrandItems = (id: string, brand_id: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "brandlist/" + id + "/" + brand_id + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET,
    };
    return commonApi(request);
}

export const checkoutLoyalty = (id: string, data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "redeemed/" + id + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: data,
        },
        method: METHOD_TYPE.POST,
    };
    return commonApi(request);
}

export const sendUpdateMemberShipEmailOTP = (dependantId: any, email: any) => {
    const login = getCookie();
    const request: CommonApiOptions = {
        url: configs.API_URL + "updatemembershipemail/" + login.xpr_user_id + "/" + dependantId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: {
                email
            }
        },
        method: METHOD_TYPE.POST
    };
    return commonApi(request);
};

export const updateMemberShipEmailByDependant = (dependantId: any, email: string, code: string) => {
    const login = getCookie();
    const request: CommonApiOptions = {
        url: configs.API_URL + "updatemembershipemail/" + login.xpr_user_id + "/" + dependantId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        body: {
            type: BODY_TYPE.RAW,
            data: {
                email,
                code
            }
        },
        method: METHOD_TYPE.POST
    };
    return commonApi(request);
}