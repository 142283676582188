import React from "react";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchLoadingSuccess } from "../../../../store/loadingIndicator/actions";
import {
  sendUpdateMemberShipEmailOTP,
  updateMemberShipEmailByDependant,
} from "../../../services/loyalty";
import { toast } from "react-toastify";

export const ManageEmail = (props: any) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [issentOtp, setIsSentOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [selectedDependant, setSelectedDependant]: any = useState("");
  const userData = useSelector(
    (state: any) => state.patientData.patientDataResponse,
    shallowEqual
  );

  useEffect(() => {
    if (userData && userData.dependant && userData.dependant.length > 0) {
      setSelectedDependant(userData.dependant[0]);
    }
  }, [userData]);

  const handleClose = () => {
    setShow(false);
    props.handleClose();
  };

  useEffect(() => {
    if (props.isShow) {
      setShow(true);
    }
  }, [props.isShow]);

  const validateEmail = (email: any) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e: any) => {
    const value = e.target.value;
    setEmail(value);

    // Validate email on change
    if (!validateEmail(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError(""); // Clear error if email is valid
    }
  };

  const sendOTP = () => {
    if (!email) {
      setEmailError("Email is requried");
      return;
    }
    dispatch(fetchLoadingSuccess(true));
    sendUpdateMemberShipEmailOTP(selectedDependant.id, email)
      .then((_success) => {
        toast.success("One-Time password sent");
        setIsSentOtp(true);
        dispatch(fetchLoadingSuccess(false));
      })
      .catch((err) => {
        console.log("err", err);
        setIsSentOtp(false);
        dispatch(fetchLoadingSuccess(false));
        toast.error(err?.msg);
      });
  };

  const submitEmail = () => {
    if (!email) {
      setEmailError("Email is requried");
      return;
    }
    if (!otp) {
      setOtpError("OTP is requried");
      return;
    }
    dispatch(fetchLoadingSuccess(true));
    updateMemberShipEmailByDependant(selectedDependant.id, email, otp)
      .then((_success) => {
        toast.success("Email changed");
        handleClose();
        dispatch(fetchLoadingSuccess(false));
      })
      .catch((err) => {
        console.log("err", err);
        setIsSentOtp(true);
        dispatch(fetchLoadingSuccess(false));
        toast.error(err?.msg);
      });
  };

  return (
    <>
      <Offcanvas
        className="insurance_manage"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <div className="offcanvas-action d-flex">
          <button
            type="button"
            className="btn btn-link p-0 fw-normal fs-7 ms-auto"
            onClick={() => {
              props.handleClose();
              setShow(false);
              setIsSentOtp(false);
              setEmail("");
              setOtp("");
            }}
          >
            cancel
          </button>
        </div>
        <div className="offcanvas-header mt-2">
          <h5 className="offcanvas-title">Add Email</h5>
        </div>
        <div className="offcanvas-body">
          <div className="mt-2 d-flex flex-column">
            <label style={{ fontWeight: "bold" }}>Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              id="email"
              value={email}
              disabled={issentOtp}
              onChange={handleEmailChange}
            />
            {emailError && (
              <div className="text-danger error">{emailError}</div>
            )}
          </div>
          {issentOtp && (
            <div className="d-flex flex-column mt-2">
              <label style={{ fontWeight: "bold" }}> One-Time password</label>
              <input
                type="text"
                className="form-control"
                placeholder="OTP"
                name="otp"
                maxLength={4}
                pattern="\d{4}"
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                  setOtpError("");
                }}
              />
              {otpError && <div className="text-danger error">{otpError}</div>}
            </div>
          )}
          {!issentOtp ? (
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-link"
                disabled={email === ""}
                onClick={() => {
                  sendOTP();
                }}
              >
                Send One-Time Password
              </button>
            </div>
          ) : (
            <div className="d-flex justify-content-end mt-2">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  submitEmail();
                }}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </Offcanvas>
    </>
  );
};
