import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DigitalFormUpload } from './DigitalFormUpload';
import { DigitalFormDownload } from './DigitalFormDownload';
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { fetchClinicList, fetchDigitalForms } from '../../../services/digitalForm';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
export const DigitalForm = (props: any) => {

    const dispatch = useDispatch();
    const [clinicList, setClinicList] = useState([]);
    const [documentList, setDocumentList]: any = useState([]);
    const [selectedClinicId, setSelectedClinicId] = useState('');
    const [selectedDocument, setSelectedDocument] = useState('');
    const [isShowDigitalFormUpload, setIsShowDigitalFormUpload] = useState(false);
    const [isShowDigitalFormDownload, setIsShowDigitalFormDownload] = useState(false);

    useEffect(() => {
        fetchClinics();
    }, []);
    
    const fetchClinics = () => {
        dispatch(fetchLoadingPending());
        fetchClinicList().then((success) => {
            // dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setClinicList(success.clinics);
                if (success.clinics && success.clinics.length > 0) {
                    setSelectedClinicId(success.clinics[0].id)
                }
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    }

    useEffect(() => {
        if (selectedClinicId) {
            fetchDigitalDocList();
        }
    },[selectedClinicId]);

    const fetchDigitalDocList = () => {
        dispatch(fetchLoadingPending());
        fetchDigitalForms(selectedClinicId).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.status && success.status.error === false) {
                setDocumentList(success.alldocument);
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    }

    const handleDigitalForm = (document: any) => {
        setSelectedDocument(document);
        setIsShowDigitalFormDownload(true);
    }

    return (
        <div className="digi_forms d-flex flex-column overflow-hidden">
            <div className="offcanvas-action d-flex">
                <button type="button" className="btn btn-link p-0 fw-normal fs-7"
                    onClick={() => { setIsShowDigitalFormUpload(true) }}>
                    Upload Completed Form
                </button>

                <button type="button" className="btn btn-link p-0 fw-normal fs-7 ms-auto"
                    onClick={() => { props.handleModalClose() }}
                >
                    Cancel
                </button>
            </div>

            {/* <div className="offcanvas-header">
                <h5 className="offcanvas-title">Digital Forms</h5>
            </div> */}

            <div className="offcanvas-body">
                <div className="mb-3">
                    <label className="form-label">Clinic</label>
                    <select className="form-select" value={selectedClinicId} onChange={(e:any)=>{setSelectedClinicId(e.target.value)}}>
                        {
                            (clinicList && clinicList.length > 0) &&
                            clinicList.map((clinic: any) => {
                                return (
                                    <option key={clinic.id} value={clinic.id}>{clinic.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="alert alert-warning mb-4 text-center" role="alert">
                    <div>Step1: Download and fill up the digital form. You do not need any special software to fill up and sign the medical forms.</div>
                    <div>Step 2: Upload your completed form using upload option on self care portal or your Xcare mobile app.</div>
                    <div>Step 3: Clinic admin will review the form and contact you if necessary.</div>
                </div>
                <div className="digiform_list">
                    {
                        (documentList && documentList.length > 0) &&
                        documentList.map((document: any) => {
                            return (
                                <>
                                    {/* <div key={document.id} className="form_title">{document.type}</div> */}
                                    <ul>
                                        {
                                            (document && document.documents && document.documents.length > 0) &&
                                            document.documents.map((item: any) => {
                                                return (
                                                    <li>
                                                        <a target="_blank" href={item && item.path ? item.path : ''}
                                                            // onClick={() => { handleDigitalForm(item) }}
                                                        >{item.documentname}<i className="bi bi-download ms-2 float-end"></i></a>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </>
                            )
                        })
                    }
                </div>
            </div>
                
            <SharedOffCanvas
                isOpen={isShowDigitalFormUpload}
                handleClose={() => { setIsShowDigitalFormUpload(false) }}
                Children={DigitalFormUpload}
                handleSuccess={() => {
                    setIsShowDigitalFormUpload(false)
                }}
                clinicList={clinicList}
            />
            <SharedOffCanvas
                isOpen={isShowDigitalFormDownload}
                handleClose={() => { setIsShowDigitalFormDownload(false) }}
                Children={DigitalFormDownload}
                handleSuccess={() => {
                    setIsShowDigitalFormDownload(false)
                }}
                document={selectedDocument}
                clinicId={selectedClinicId}
                clinicList={clinicList}
                />
        </div>
    )
}
