import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../store/loadingIndicator/actions";
import { getXcareOffers } from "../../v2/services/xcareOffers";
import configs from "../../configs/apiConfigs";
import moment from "moment";
import { toast } from "react-toastify";

export const AvailableOffers = ({ selectedRegion }: any) => {
  const dispatch = useDispatch();
  const [offersList, setOffersList] = useState([]);

  const getXcareoffersData = () => {
    dispatch(fetchLoadingPending());
    getXcareOffers(false, selectedRegion)
      .then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success.status.error === false) {
          setOffersList(success.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.msg);
        dispatch(fetchLoadingSuccess(false));
      });
  };

  useEffect(() => {
    getXcareoffersData();
  }, [selectedRegion]);

  const toggleDescription = (index: any) => {
    setOffersList((prevOffers: any) =>
      prevOffers.map((item: any, i: any) =>
        i === index ? { ...item, isExpanded: !item.isExpanded } : item
      )
    );
  };

  return (
    <div className="d-flex justify-content-center p-2 flex-column">
      {offersList?.length > 0 ? (
        offersList?.map((oItem: any, index: any) => {
          console.log(
            "imageUrl",
            configs.IMAGE_URL +
              "clinic/" +
              oItem.image.name +
              "-100x100." +
              oItem.image.type
          );
          return (
            <div
              className="card shadow-sm my-2"
              style={{ width: "90%",margin: "0px auto" }}
              key={index}
            >
              <div className="d-block d-md-flex align-items-center inner_box p-3 p-md-0">
                {/* Image Section */}
                <div
                  style={{ width: "15%" }}
                  className="box_image px-4 py-3"
                >
                  <img
                    src={oItem.imageUrl} // Use the proper URL here
                    className="card-img-top"
                    alt="Image"
                    style={{ objectFit: "cover" }} // Ensure image is cropped to fit the square container
                  />
                </div>

                {/* Content Section (Title & Description) */}
                <div
                  className="d-flex flex-column pt-2 content_box"
                  style={{ width: "50%" }}
                >
                  <div
                    className="card-title pt-2"
                    style={{ fontWeight: "bold" }}
                  >
                    {oItem.title}
                  </div>
                  <div
                    className="card-text"
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: oItem.isExpanded ? "none" : 2, // Toggle between expanded and collapsed
                      textOverflow: "ellipsis",
                    }}
                  >
                    {oItem.description}
                  </div>
                  {/* Toggle See More / See Less Button */}
                  <button
                    className="btn btn-link p-0 mt-2"
                    onClick={() => toggleDescription(index)}
                    style={{ fontSize: "14px" }}
                  >
                    {oItem.isExpanded ? "See less" : "See more"}
                  </button>
                </div>
                <div
                  className="d-flex flex-column pt-2 justify-content-center right_btn_sec"
                  style={{ width: "35%" }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        window.open(oItem.link, "_blank");
                      }}
                    >
                      {oItem?.buttonTitle}
                    </button>
                  </div>
                  <div
                    className="d-flex justify-content-center pt-2"
                    style={{ fontWeight: "bold" }}
                  >
                    Expiry: {moment(oItem?.expiredAt).format("DD-MMM-YYYY")}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="d-flex justify-content-center">No Offers Available</div>
      )}
    </div>
  );
};
