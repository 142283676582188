import React from 'react';
import { getImageExtension, checkImageExtension, useQuery } from '../../utils/global';
import { toast } from 'react-toastify';
import Helmet from 'react-helmet';
import { TITLES_LABELS, APPOINTMENT_STATUS } from '../../constants';
import { IAppState } from '../../store';
import { claimSubmitPending } from '../../store/claim/actions';
import { ClaimSubmitRequest, ClaimSubmitState } from '../../store/claim/types';
import { connect } from 'react-redux';
import { getCookie } from '../../utils';
import { Redirect } from 'react-router-dom';
import { Animated } from "react-animated-css";
import { PatientDataState, PatientDataRequest } from '../../store/patientData/types';
import { allowOnlyDigit } from '../../utils/validators';
import { patientDataPending } from '../../store/patientData/actions';
interface Props {
    location: any;
    match: any;
    claimState: ClaimSubmitState;
    claimSubmit: typeof claimSubmitPending;
    patientDataState: PatientDataState;
    patientData: typeof patientDataPending;
}
interface State {
    currentActiveCard: number;
    fileName: string;
    appointments: any[];
    formData: {
        appointmentId: string;
        appointmentDate: string;
        score: string;
        comment: string;
        forChild: string;
        receivedPast: string;
        additionalNote: string;
        amount: string;
        receipt: any;
    },
    isSuccess: boolean;
}
const ratings = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
class ClaimReward extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentActiveCard: 0,
            fileName: '',
            appointments: [],
            formData: {
                appointmentId: '',
                appointmentDate: '',
                comment: '',
                additionalNote: '', amount: '', forChild: '', receipt: '', receivedPast: '', score: ''
            },
            isSuccess: false,
        }
    }
    componentDidMount() {
        let appointments: any[] = [];
        if (this.props.patientDataState.patientDataResponse && this.props.patientDataState.patientDataResponse.appointments) {
            appointments = this.props.patientDataState.patientDataResponse.appointments.filter((value: any, index) => {
                return (value.currentStatus === APPOINTMENT_STATUS.COMPLETE[0] && (value.claimStatus === null || value.claimStatus === ""));
            });
        }
        const appointmentId = useQuery(this.props.location).get('appointment');
        if (appointmentId) {
            this.setState({
                appointments: appointments,
                formData: {
                    ...this.state.formData,
                    appointmentId: appointmentId
                }
            });
        } else if (appointments.length > 0) {
            this.setState({
                appointments: appointments,
                formData: {
                    ...this.state.formData,
                    appointmentId: appointments[0].uniqueid
                }
            });
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (this.props !== nextProps) {
            if (nextProps.claimState.error !== null) {
                if (nextProps.claimState.error.status && nextProps.claimState.error.status.error) {
                    toast.error(nextProps.claimState.error.status.msg);
                } else {
                    toast.error('Something went wrong');
                }
            } else {
                if (nextProps.claimState.pending === false) {
                    if (nextProps.claimState.claimSubmitResponse && nextProps.claimState.claimSubmitResponse.status.error === false) {
                        const login: any = getCookie();
                        this.props.patientData({ uniqueId: login.xpr_user_id, doNotShowLoader: true });
                        toast.success(nextProps.claimState.claimSubmitResponse.status.msg);
                        this.setState({ isSuccess: true });
                        this.setState({
                            formData: {
                                appointmentId: '',
                                appointmentDate: '',
                                comment: '',
                                additionalNote: '', amount: '', forChild: '', receipt: '', receivedPast: '', score: ''
                            }
                        });
                    }
                }
            }
        }
        return true;
    }
    handleFormClick(currentCard: number, nextCard: number) {
        if (currentCard === 0) {
            if (this.state.formData.appointmentId === '') {
                toast.warn('Please complete the appointment for claim reward.');
                return;
            } else if (this.state.formData.appointmentDate === "") {
                toast.warn('Please fill appointment date.');
                return;
            } else if (this.state.formData.score === "") {
                toast.warn('Please select score.');
                return;
            } else if (this.state.formData.comment === "") {
                toast.warn('Please fill comment.');
                return;
            } else if (this.state.formData.forChild === "") {
                toast.warn('Please select reward for child or not.');
                return;
            } else if (this.state.formData.receivedPast === "") {
                toast.warn('Please select have you received reward in past.');
                return;
            } else if (this.state.formData.receivedPast === "") {
                toast.warn('Please select have you received reward in past.');
                return;
            }
            this.setState({ currentActiveCard: nextCard });
        } else if (currentCard === 1) {
            if (this.state.formData.amount === "") {
                toast.warn('Please fill amount spent on appointment');
                return;
            } else if (isNaN(Number(this.state.formData.amount))) {
                toast.warn('Please fill valid amount spent on appointment');
                return;
            } else if (this.state.formData.receipt === "") {
                toast.warn('Please select file.');
                return;
            } else if (this.state.formData.additionalNote === "") {
                toast.warn('Please fill additional notes.');
                return;
            }
            const login = getCookie();
            this.props.claimSubmit({
                // userId: login.id,
                // uniqueId: login.uniqueId,
                uniqueId: login.xpr_user_id,
                appointmentId: Number(this.state.formData.appointmentId),
                additionalNote: this.state.formData.additionalNote,
                amount: this.state.formData.amount,
                appointmentDate: this.state.formData.appointmentDate,
                comment: this.state.formData.comment,
                forChild: this.state.formData.forChild,
                receipt: this.state.formData.receipt,
                receivedPast: this.state.formData.receivedPast,
                score: this.state.formData.score
            });
        }
    }
    onChangeFile(event: any) {
        console.log(event.target.files[0]);
        if (event.target.files[0]) {
            const imageExt: any = getImageExtension(event.target.files[0].name);
            if (checkImageExtension(imageExt)) {
                this.setState({
                    fileName: event.target.files[0].name,
                    formData: {
                        ...this.state.formData,
                        receipt: event.target.files[0]
                    }
                });
            } else {
                toast.warn('Files allowed jpg,jpeg,png,pdf');
            }
        }
    }
    componentDidUpdate() {
        this.props.claimState.error = null;
        if (this.props.claimState.claimSubmitResponse) {
            this.props.claimState.claimSubmitResponse.status.error = true;
        }
    }
    render() {
        let today: any = new Date();
        let dd: any = today.getDate();
        let mm: any = today.getMonth() + 1; //January is 0!
        let yyyy: any = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        if (this.state.isSuccess) {
            return (<Redirect to="/claim-reward-status"></Redirect>);
        } else {
            const selectedAppointment = this.state.appointments.find((value: any, index) => {
                return value.uniqueid === this.state.formData.appointmentId;
            });
            console.log(selectedAppointment);
            return (
                <>

                    <div className="main-middle-sec">
                        <div className="container upcoming-box">
                            <div className="row justify-content-center h-100">
                                <div className="col-sm-6 align-self-center">
                                    <div className="text-center">
                                        <div className="pin-letter">
                                            <img src={require('../../assets/images/announce.png')} className="img-responsive letter-icon mb-3" />
                                            <h3>Stay tuned, we will rollout <b>My-rewards</b> soon.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>

                // <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDuration={1000} isVisible={true}>
                //     <section className="claim-reward pantient-view">
                //         <Helmet><title>{TITLES_LABELS.META.CLAIM}</title></Helmet>
                //         <div className="container">
                //             <div className="row justify-content-center">
                //                 <div className="col-12 text-center">
                //                     <h4 className="page-title">Claim your offer</h4>
                //                     <p>Fill out the information below to claim your <span className="cl_gift">
                //                         {
                //                             (this.props.patientDataState.patientDataResponse && this.props.patientDataState.patientDataResponse.defaultClaim) &&
                //                             <>{this.props.patientDataState.patientDataResponse.defaultClaim}</>
                //                         } reward  point</span></p>
                //                 </div>
                //                 <div className="col-lg-7">
                //                     <div className="form-section text-center">
                //                         <div className="row">
                //                             {
                //                                 this.state.currentActiveCard === 0 &&
                //                                 <div className="col-12">

                //                                     <div className="form-group">
                //                                         <label>Selected your appointment</label>
                //                                         <select name="" id="" className="form-control df-select" value={this.state.formData.appointmentId}
                //                                             onChange={(e) => {
                //                                                 this.setState({
                //                                                     formData: {
                //                                                         ...this.state.formData,
                //                                                         appointmentId: e.target.value
                //                                                     }
                //                                                 });
                //                                             }}
                //                                         >
                //                                             {
                //                                                 this.state.appointments.map((value: any, index: number) => {
                //                                                     return (
                //                                                         <option key={index + Math.random()} value={value.uniqueid} data-image={""}>{value.doctor.fullname} - {value.clinic.name}</option>
                //                                                     )
                //                                                 })
                //                                             }
                //                                         </select>
                //                                     </div>

                //                                     <div className="form-group">
                //                                         <label>What was the date of your appointment?</label>
                //                                         <input type="date" className="form-control"
                //                                             max={today}
                //                                             onChange={(e) => {
                //                                                 this.setState({
                //                                                     formData: {
                //                                                         ...this.state.formData,
                //                                                         appointmentDate: e.target.value
                //                                                     }
                //                                                 });
                //                                             }}
                //                                             value={this.state.formData.appointmentDate} />
                //                                     </div>
                //                                     <div className="form-group">
                //                                         <label>
                //                                             How likely are you to visit
                //                                                 {
                //                                                 (selectedAppointment && selectedAppointment.clinic && selectedAppointment.clinic.name) &&
                //                                                 <> {selectedAppointment.clinic.name} </>
                //                                             }
                //                                                 again?
                //                                         </label>
                //                                         <div className="rate-range d-flex justify-content-between">
                //                                             {
                //                                                 ratings.map((value, index: number) => {
                //                                                     return (
                //                                                         <a key={index + Math.random()} href="javascript:void(0);"
                //                                                             onClick={(e) => {
                //                                                                 this.setState({
                //                                                                     formData: {
                //                                                                         ...this.state.formData,
                //                                                                         score: value.toString()
                //                                                                     }
                //                                                                 });
                //                                                             }
                //                                                             }
                //                                                             className={(this.state.formData.score === value.toString() ? 'active' : '')}>{value}</a>
                //                                                     )
                //                                                 })
                //                                             }
                //                                         </div>
                //                                         <div className="ratting-desc d-flex justify-content-between">
                //                                             <span>Not likely</span>
                //                                             <span>Extremely likely</span>
                //                                         </div>
                //                                     </div>
                //                                     {
                //                                         this.state.formData.score !== '' &&
                //                                         <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                //                                             <div className="form-group">
                //                                                 <label>We'd love to learn more. Please tell us why you selected a score of <span>{this.state.formData.score}</span>.</label>
                //                                                 <textarea className="form-control"
                //                                                     rows={4} id="comment" placeholder="" value={this.state.formData.comment}
                //                                                     onInput={(e: any) => {
                //                                                         this.setState({
                //                                                             formData: {
                //                                                                 ...this.state.formData,
                //                                                                 comment: e.target.value
                //                                                             }
                //                                                         });
                //                                                     }}
                //                                                     onChange={(e) => {
                //                                                         this.setState({
                //                                                             formData: {
                //                                                                 ...this.state.formData,
                //                                                                 comment: e.target.value
                //                                                             }
                //                                                         });
                //                                                     }}
                //                                                 ></textarea>
                //                                                 <div className="ratting-desc">
                //                                                     <span> <img src={require('../../assets/images/ic_visibility_off_24px.svg')} className="mr-1" />This won’t be shared with the practice. </span>
                //                                                 </div>
                //                                             </div>
                //                                         </Animated>
                //                                     }
                //                                     <div className="form-group">
                //                                         <label>Are you claiming a reward for your child (under 18 years old)?</label>
                //                                         <div className="form-check-inline yes-no">
                //                                             <input type="radio" name="is_child" id="child_yes" value="1" checked={this.state.formData.forChild === "1"}
                //                                                 onChange={(e) => {
                //                                                     this.setState({
                //                                                         formData: {
                //                                                             ...this.state.formData,
                //                                                             forChild: e.target.value
                //                                                         }
                //                                                     });
                //                                                 }} />
                //                                             <label className="l-yes" htmlFor="child_yes"><img src={require('../../assets/images/yes-icon.svg')} /></label>
                //                                         </div>
                //                                         <div className="form-check-inline yes-no">
                //                                             <input type="radio" name="is_child" id="child_no" value="0" checked={this.state.formData.forChild === "0"}
                //                                                 onChange={(e) => {
                //                                                     this.setState({
                //                                                         formData: {
                //                                                             ...this.state.formData,
                //                                                             forChild: e.target.value
                //                                                         }
                //                                                     });
                //                                                 }} />
                //                                             <label className="l-no" htmlFor="child_no"><img src={require('../../assets/images/no-icon.svg')} /></label>
                //                                         </div>
                //                                     </div>
                //                                     <div className="form-group">
                //                                         <label>Have you received an XCarePro reward in the past?</label>
                //                                         <div className="form-check-inline yes-no">
                //                                             <input type="radio" name="has_received" id="received_yes" value="1" checked={this.state.formData.receivedPast === "1"}
                //                                                 onChange={(e) => {
                //                                                     this.setState({
                //                                                         formData: {
                //                                                             ...this.state.formData,
                //                                                             receivedPast: e.target.value
                //                                                         }
                //                                                     });
                //                                                 }} />
                //                                             <label className="l-yes" htmlFor="received_yes"><img src={require('../../assets/images/yes-icon.svg')} /></label>
                //                                         </div>
                //                                         <div className="form-check-inline yes-no">
                //                                             <input type="radio" name="has_received" id="received_no" value="0" checked={this.state.formData.receivedPast === "0"}
                //                                                 onChange={(e) => {
                //                                                     this.setState({
                //                                                         formData: {
                //                                                             ...this.state.formData,
                //                                                             receivedPast: e.target.value
                //                                                         }
                //                                                     });
                //                                                 }} />
                //                                             <label className="l-no" htmlFor="received_no"><img src={require('../../assets/images/no-icon.svg')} /></label>
                //                                         </div>
                //                                     </div>
                //                                     <button className="btn btn-custom" onClick={(e) => { this.handleFormClick(0, 1) }}>Continue</button>
                //                                 </div>
                //                             }
                //                             {
                //                                 this.state.currentActiveCard === 1 &&
                //                                 <>
                //                                     <div className="col-12">
                //                                         <div className="form-group">
                //                                             <label className="w-100 text-center">How much amount you spent in this appointment.</label>
                //                                             <input className="form-control" id="spentAmount" placeholder="250" value={this.state.formData.amount}
                //                                                 onChange={(e: any) => {
                //                                                     if (allowOnlyDigit(e.target.value) === true) {
                //                                                         this.setState({
                //                                                             formData: {
                //                                                                 ...this.state.formData,
                //                                                                 amount: e.target.value
                //                                                             }
                //                                                         });
                //                                                     } else if (e.target.value === "") {
                //                                                         this.setState({
                //                                                             formData: {
                //                                                                 ...this.state.formData,
                //                                                                 amount: ""
                //                                                             }
                //                                                         });
                //                                                     }
                //                                                 }}
                //                                                 onInput={(e: any) => {
                //                                                     if (allowOnlyDigit(e.target.value) === true) {
                //                                                         this.setState({
                //                                                             formData: {
                //                                                                 ...this.state.formData,
                //                                                                 amount: e.target.value
                //                                                             }
                //                                                         });
                //                                                     } else if (e.target.value === "") {
                //                                                         this.setState({
                //                                                             formData: {
                //                                                                 ...this.state.formData,
                //                                                                 amount: ""
                //                                                             }
                //                                                         });
                //                                                     }
                //                                                 }} />
                //                                         </div>
                //                                         <div className="form-group">
                //                                             <label className="w-100 text-center">Receipt photo.</label>
                //                                             <div className="upload-sec">
                //                                                 {
                //                                                     this.state.fileName !== '' &&
                //                                                     <div className="file-sec">
                //                                                         <a href="javascript:void(0);"
                //                                                             onClick={(e) => {
                //                                                                 this.setState({
                //                                                                     fileName: '',
                //                                                                     formData: {
                //                                                                         ...this.state.formData,
                //                                                                         receipt: ''
                //                                                                     }
                //                                                                 });
                //                                                             }}
                //                                                             className="mr-1"><img src={require('../../assets/images/bin.svg')} /></a>
                //                                                         <span>{this.state.fileName}</span>
                //                                                     </div>
                //                                                 }
                //                                                 <label htmlFor="receipt_file" className="custom-file-upload">
                //                                                     <img src={require('../../assets/images/ic_file_upload_24px.svg')} className="mr-2" />Upload a receipt photo.
                //                                 </label>
                //                                                 <input accept="image/png,image/jpeg,image/jpg,application/pdf" id="receipt_file" type="file"
                //                                                     onChange={(e: any) => { this.onChangeFile(e) }}
                //                                                 />
                //                                             </div>
                //                                         </div>
                //                                         <div className="form-group">
                //                                             <label className="w-100 text-center">Additional Notes</label>
                //                                             <textarea className="form-control" rows={4} id="comment"
                //                                                 value={this.state.formData.additionalNote}
                //                                                 onInput={(e: any) => {
                //                                                     this.setState({
                //                                                         formData: {
                //                                                             ...this.state.formData,
                //                                                             additionalNote: e.target.value
                //                                                         }
                //                                                     });
                //                                                 }}
                //                                                 onChange={(e) => {
                //                                                     this.setState({
                //                                                         formData: {
                //                                                             ...this.state.formData,
                //                                                             additionalNote: e.target.value
                //                                                         }
                //                                                     });
                //                                                 }}
                //                                                 placeholder=""></textarea>
                //                                         </div>
                //                                         <button className="btn btn-custom" onClick={(e) => { this.handleFormClick(1, 1) }}>Submit claim</button>
                //                                     </div>
                //                                 </>
                //                             }
                //                             <div className="col-12">
                //                                 <div className="ratting-desc mt-5">
                //                                     By submitting this claim, you agree to the Reward Terms and Conditions.
                //                                     Any further questions can be sent to info@xcarepro.com
                //                             </div>
                //                             </div>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>
                //         </div>
                //     </section>
                // </Animated>
            )
        }
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    claimSubmit: (request: ClaimSubmitRequest) => {
        dispatch(claimSubmitPending(request));
    },
    patientData: (request: PatientDataRequest) => {
        dispatch(patientDataPending(request));
    }
});
const mapStateToProps = (state: IAppState) => ({
    claimState: state.claim,
    patientDataState: state.patientData,
});
export default connect(mapStateToProps, mapDispatchToProps)(ClaimReward);