import React, { useState, useEffect } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { removeChatPin } from '../../../store/pins/actions';
import { getCookie } from '../../utils/cookies';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { toast } from 'react-toastify';
import { TITLES_LABELS, APPOINTMENT_STATUS, CHAT } from '../../../constants';
import './Pin.css';
import configs from '../../configs/apiConfigs';
import _ from 'lodash';
import $ from "jquery";
import moment from 'moment';
import nl2br from 'react-nl2br';
import { Avatar } from '@material-ui/core';
import { getgroups } from 'process';
export const Pin = ({ appointment, pins }: any) => {
    let db: any, unsubscribe: any;
    let oldMessageList: any = [];
    var clinicName: any
    const dispatch = useDispatch();
    const [clinicAvatar, setClinicAvatar] = useState('');
    const [message, setMessage] = useState('');
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const [messageList, setMessageList]: any = useState([]);

    useEffect(() => {
        db = firebase.firestore();
        if (appointment.clinic && appointment.clinic.name) {
            clinicName = appointment.clinic.name;
            clinicName = clinicName.trim();
            clinicName = clinicName.split(" ");
            setClinicAvatar(clinicName[0] ? clinicName[0].charAt(0) : '' + clinicName[1] ? clinicName[1].charAt(0) : '');
        }
        if (appointment) {
            getChatData();
            getGroups();
        }
    }, []);

    const getGroups = () => {
        firebase.firestore().collection(configs.GROUP_COLLECTION)
            .where('isClosed', '==', false)
            .where('appointmentUniqueId', '==', appointment.appointmentId).get().then((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        const data: any = change.doc.data();
                        Object.assign(data, { groupId: change.doc.id });
                        console.log('dataCheckeed', data);
                        firebase.firestore().collection(configs.MESSAGE_COLLECTION).doc(`${change.doc.id}`).onSnapshot((snapshot) => {
                            const data = snapshot.data();
                            let groupMessagesList = data?.messages;
                            let newMessageList = groupMessagesList.map((message: any) => {
                                return {
                                    ...message,
                                    ...appointment,
                                    createdAt: message.sentAt,
                                    sender: message.senderType,
                                    actionType: 'MESSAGE',
                                    text: message.messageText,
                                    firstName: message.senderType === "clinic" ? clinicName[0] ? clinicName[0] : '' : appointment.dependant.firstName,
                                    lastName: message.senderType === "clinic" ? clinicName[1] ? clinicName[1] : '' : appointment.dependant.lastName

                                }
                            });
                            let singleMessageList = oldMessageList;
                            let mergeMessageList = singleMessageList.concat(newMessageList);
                            let sortMessages = _.sortBy(mergeMessageList, [function (data) {
                                return data.createdAt
                            }])
                            mergeMessageList.sort(function (a: any, b: any) { return moment.unix(a.createdAt.seconds).diff(moment.unix(b.createdAt.seconds)) });
                            setMessageList(mergeMessageList);
                            scrollToBottom();
                        });
                    }
                })
            })
         if (appointment.openPin) {
                handleMinimizePin(appointment.appointmentId);
            }
        scrollToBottom();
    }

    const getChatData = () => {

        scrollToBottom();
        let roundOneFinish = false;
        unsubscribe = firebase.firestore().collection(configs.CHAT_COLLECTION_NAME)
            .where("appointmentId", "==", Number(appointment.appointmentId))
            .orderBy("createdAt")
            .onSnapshot((snapshot) => {
                const docsLength = snapshot.docs.length;
                // console.log("docsLength", docsLength);
                // const messages = snapshot.docs.map((item) => ({...item.data(), id: item.id}));
                // console.log("messages", messages);
                // setMessageList(messages);
                // scrollToBottom();
                snapshot.docChanges().forEach((change: any, index: number) => {
                    if (change.type === "added") {
                        console.log('when Added');
                        const objFound = _.find(oldMessageList, (value: any) => {
                            return value.id === change.doc.id;
                        })
                        if (!objFound) {
                            console.log('when obj not found');

                            const data = change.doc.data();
                            console.log("data", data);
                            oldMessageList = [...oldMessageList, {
                                ...change.doc.data(),
                                id: change.doc.id
                            }];
                            console.log("data", oldMessageList);
                            setMessageList(oldMessageList);

                            scrollToBottom();
                        }
                    }
                    if (change.type === "modified") {
                        console.log('when Modify');
                        const id = change.doc.id;
                        const data = change.doc.data();
                        oldMessageList = oldMessageList.map((value: any, index: any) => {
                            if (value.id === id) {
                                return { ...change.doc.data(), id: id };
                            }
                            return value;
                        });

                        setMessageList(oldMessageList);
                        scrollToBottom();
                    }
                    if (docsLength === index + 1) {
                        roundOneFinish = true;
                    }
                });
            }, (error: any) => { });
        scrollToBottom();

    }
    const handleMinimizePin = (Id: any) => {
        pins.map((item: any) => {
            if (item.appointmentId === Id) {
                // groupMessage();
                $('.prime' + Id).toggleClass('zmdi-comment-outline');
                $('.prime' + Id).toggleClass('zmdi-close');
                $('.prime' + Id).toggleClass('is-active');
                $('.prime' + Id).toggleClass('is-visible');
                $('#prime' + Id).toggleClass('is-float');
                $('.chat' + Id).toggleClass('is-visible');
                $('.fab' + Id).toggleClass('is-visible');
                $('#chatSend' + Id).focus();
            } else {
                $('.prime' + item.appointmentId).addClass('zmdi-comment-outline');
                $('.prime' + item.appointmentId).removeClass('zmdi-close');
                $('.prime' + item.appointmentId).removeClass('is-active');
                $('.prime' + item.appointmentId).removeClass('is-visible');
                $('#prime' + item.appointmentId).removeClass('is-float');
                $('.chat' + item.appointmentId).removeClass('is-visible');
                $('.fab' + item.appointmentId).removeClass('is-visible');
            }
        })
    }
    const handleRemovePin = () => {
        $('.prime' + appointment.appointmentId).toggleClass('zmdi-comment-outline');
        $('.prime' + appointment.appointmentId).toggleClass('zmdi-close');
        $('.prime' + appointment.appointmentId).toggleClass('is-active');
        $('.prime' + appointment.appointmentId).toggleClass('is-visible');
        $('#prime' + appointment.appointmentId).toggleClass('is-float');
        $('.chat' + appointment.appointmentId).toggleClass('is-visible');
        $('.fab' + appointment.appointmentId).toggleClass('is-visible');
        dispatch(removeChatPin(appointment.appointmentId));
    }

    const sendMessage = () => {
        if (message.trim() === '') {
            return;
        }
        const obj = {
            "appointmentId": Number(appointment.appointmentId),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.PATIENT,
            "action": CHAT.ACTION.SMTC,
            "actionType": CHAT.ACTION_TYPE.MESSAGE,
            "text": message,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": appointment.sendToCId,
            "dependantId": Number(appointment.dependant.uniqueid),
            "firstName": patientData.firstName,
            "lastName": patientData.lastName,
            "medium": CHAT.SEND_MEDIUM.CHAT,
            dateTime: {
                date: appointment.date,
                time: appointment.time
            }
        };
        firebase.firestore().collection(configs.CHAT_COLLECTION_NAME).doc().set(obj)
            .then(() => {
                setMessage('');
                // setTimeout(() => {
                //     getChatData();
                // }, 1000);
                scrollToBottom();
            })
            .catch((error: any) => { });
    }
    const scrollToBottom = () => {
        $('#chat_fullscreen' + appointment.appointmentId).animate({ scrollTop: $('#chat_fullscreen' + appointment.appointmentId).prop("scrollHeight") }, 10);
    }
    console.log("messageList", messageList);
    return (
        <div className={"fabs fabs" + appointment.appointmentId} id={'main' + appointment.appointmentId} key={"main" + appointment.appointmentId}>
            <div className={"chat group-chat-box chat" + appointment.appointmentId} id={'Chats' + appointment.appointmentId}>
                <div className="chat_header">
                    <div className="chat_toolbar">
                            <OverlayTrigger
                                placement="auto"
                                overlay={
                                    <Tooltip id="tooltip">
                                        Minimize chat
                                    </Tooltip>
                                }
                        >
                            <>
                                {/* <button className="btn p-1 text-white ms-1" type="button" onClick={() => {
                                    handleMinimizePin(appointment.appointmentId);
                                }}>
                                    <i className="bi bi-dash-circle"></i>
                                </button> */}
                                <span onClick={() => {
                                    handleMinimizePin(appointment.appointmentId);
                                }}>minimize</span>
                            </>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="auto"
                                overlay={
                                    <Tooltip id="tooltip">
                                        Close chat
                                    </Tooltip>
                                }
                        >
                            <>
                                {/* <button className="btn p-1 text-white ms-1" type="button" onClick={() => {
                                    handleRemovePin()
                                }}>
                                    <i className="bi bi-x-circle"></i>
                                </button> */}
                                <span onClick={() => {
                                    handleRemovePin();
                                }}>Close</span>
                            </>
                            </OverlayTrigger>
                    </div>

                    <div className="d-flex align-items-center">
                        {
                            appointment.clinic && appointment.clinic.name &&
                            <div className="avatar avatar-lg rounded-circle flex-shrink-0 me-2">
                                {appointment.clinic.name.charAt(0)}
                            </div>
                        }
                        <div className="av-txt">
                            <div className="av_title">
                                <div id={"chat_head" + appointment.appointmentId} className="d-block fw-medium text-white align-self-center charlimit">
                                    {appointment.clinic && appointment.clinic.name}
                                </div>
                            </div>
                            <div className="av_subtitle opacity-30">{ appointment.doctor && appointment.doctor.fullname && appointment.doctor.fullname}</div>
                            <div className="av_subtitle opacity-30">{ appointment.date && appointment.time && moment(appointment.date +" "+appointment.time,'YYYY-MM-DD LT').format('MMM DD, YYYY - LT')}</div>
                        </div>
                    </div>
                </div>
                <div id={'chat_fullscreen' + appointment.appointmentId} className="chat_conversion chat_converse">
                    {
                        messageList.map((value: any, index: any) => {
                            if (value.actionType === CHAT.ACTION_TYPE.MESSAGE) {
                                let letterOne = "",
                                    letterTwo = "";
                                if (value.firstName) {
                                    letterOne = value.firstName.charAt(0);
                                }
                                if (value.lastName) {
                                    letterTwo = value.lastName.charAt(0);
                                }
                                return (
                                    <div className={"chat_msg_item " + (value.sender === CHAT.SENDER.PATIENT ? 'chat_msg_item_user' : 'chat_msg_item_admin')} key={index + appointment.appointmentId}>
                                        {(letterOne !== "" || letterTwo !== "") && value.sender !== CHAT.SENDER.PATIENT && (
                                            <div className="avatar avatar-icon avatar-md newAvtr">
                                                <Avatar>
                                                    {letterOne}
                                                    {letterTwo}
                                                </Avatar>
                                            </div>
                                        )}
                                        <div className="chat-bubble">
                                            {nl2br(value.text)}
                                        </div>
                                        <span className="msg_date">
                                            {
                                                value &&
                                                value.createdAt &&
                                                <> {moment(moment.unix(value.createdAt.seconds)).format("MMM DD, YYYY - LT")}</>
                                            }
                                        </span>
                                    </div>
                                )
                            } else if (value.actionType === CHAT.ACTION_TYPE.STATUS) {
                                if (
                                    value.toStatus === CHAT.TO_STATUS.REQUESTED &&
                                    value.fromStatus === CHAT.FROM_STATUS.CANCELLED
                                ) {
                                    return (
                                        <div
                                            className="alert alert-success py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            {appointment &&
                                                appointment.dependant &&
                                                appointment.dependant.fname &&
                                                appointment.dependant.fname && (
                                                    <>
                                                        {appointment.dependant.fname +
                                                            " " +
                                                            appointment.dependant.lname}
                                                    </>
                                                )}{" "}
                                            indicated that they need to reschedule. Reach out
                                            to&nbsp;
                                            {appointment &&
                                                appointment.dependant &&
                                                appointment.dependant.fname &&
                                                appointment.dependant.fname && (
                                                    <>
                                                        {appointment.dependant.fname +
                                                            " " +
                                                            appointment.dependant.lname}
                                                    </>
                                                )}{" "}
                                            to reschedule.
                                        </div>
                                    );
                                } else if (value.toStatus === CHAT.TO_STATUS.COMPLETED) {
                                    return (
                                        <div
                                            className="alert alert-success py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            Appointment {value.toStatus.toLowerCase()}
                                            {value && value.createdAt && value.createdAt.seconds && (
                                                <>
                                                    &nbsp;on&nbsp;
                                                    {moment(
                                                        moment.unix(value.createdAt.seconds)
                                                    ).format("MMM DD, YYYY") +
                                                        " at " +
                                                        moment(
                                                            moment.unix(value.createdAt.seconds)
                                                        ).format("LT")}
                                                </>
                                            )}
                                        </div>
                                    );
                                } else if (value.toStatus === CHAT.TO_STATUS.CANCELLED) {
                                    return (
                                        <div
                                            className="alert alert-danger py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            The Appointment has been{" "}
                                            {value.toStatus.toLowerCase()}
                                            <>
                                                {value &&
                                                    value.createdAt &&
                                                    value.createdAt.seconds && (
                                                        <>
                                                            &nbsp;on&nbsp;
                                                            {moment(
                                                                moment.unix(value.createdAt.seconds)
                                                            ).format("MMM DD, YYYY") +
                                                                " at " +
                                                                moment(
                                                                    moment.unix(value.createdAt.seconds)
                                                                ).format("LT")}
                                                        </>
                                                    )}
                                            </>
                                        </div>
                                    );
                                } else if (
                                    value.fromStatus === CHAT.TO_STATUS.SCHEDULED &&
                                    value.toStatus === CHAT.TO_STATUS.SCHEDULED
                                ) {
                                    return (
                                        <div
                                            className="alert alert-info py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            Appointment rescheduled
                                            {value &&
                                                value.dateTime &&
                                                value.dateTime.date &&
                                                value.dateTime.time && (
                                                    <>
                                                        &nbsp;on&nbsp;
                                                        {moment(value.dateTime.date).format(
                                                            "MMM DD, YYYY"
                                                        ) +
                                                            " at " +
                                                            moment(
                                                                value.dateTime.date +
                                                                " " +
                                                                value.dateTime.time,
                                                                "YYYY-MM-DD LT"
                                                            ).format("LT")}
                                                    </>
                                                )}
                                        </div>
                                    );
                                } else if (value.toStatus === CHAT.TO_STATUS.SCHEDULED) {
                                    return (
                                        <div
                                            className="alert alert-info py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            Appointment {value.toStatus.toLowerCase()}
                                            {value &&
                                                value.dateTime &&
                                                value.dateTime.date &&
                                                value.dateTime.time && (
                                                    <>
                                                        &nbsp;on&nbsp;
                                                        {moment(value.dateTime.date).format(
                                                            "MMM DD, YYYY"
                                                        ) +
                                                            " at " +
                                                            moment(
                                                                value.dateTime.date +
                                                                " " +
                                                                value.dateTime.time,
                                                                "YYYY-MM-DD LT"
                                                            ).format("LT")}
                                                    </>
                                                )}
                                        </div>
                                    );
                                }
                            } else if (
                                value.actionType === CHAT.ACTION_TYPE.PRESCREENING
                            ) {
                                return (
                                    <>
                                        <div
                                            className="alert alert-info py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            <span> Pre-screening status:</span> completed{" "}
                                            <>
                                                {value &&
                                                    value.createdAt &&
                                                    value.createdAt.seconds && (
                                                        <>
                                                            &nbsp;at&nbsp;
                                                            {moment(
                                                                moment.unix(value.createdAt.seconds)
                                                            ).format("MMM DD, YYYY") +
                                                                " - " +
                                                                moment(
                                                                    moment.unix(value.createdAt.seconds)
                                                                ).format("LT")}
                                                        </>
                                                    )}
                                            </>
                                        </div>
                                    </>
                                );
                            } else if (
                                value.actionType === CHAT.ACTION_TYPE.PRESCREENING_STATUS
                            ) {
                                return (
                                    <div
                                        className="alert alert-success py-2 text-center"
                                        key={index + Math.random()}
                                    >
                                        <span> Pre-screening status:</span> verified{" "}
                                        <>
                                            {value && value.createdAt && value.createdAt.seconds && (
                                                <>
                                                    &nbsp;at&nbsp;
                                                    {moment(
                                                        moment.unix(value.createdAt.seconds)
                                                    ).format("MMM DD, YYYY") +
                                                        " - " +
                                                        moment(
                                                            moment.unix(value.createdAt.seconds)
                                                        ).format("LT")}
                                                </>
                                            )}
                                        </>
                                    </div>
                                );
                            } else if (
                                value.actionType === CHAT.ACTION_TYPE.PRESCREENING_REQUEST
                            ) {
                                return (
                                    <div
                                        className="alert alert-warning py-2 text-center"
                                        key={index + Math.random()}
                                    >
                                        <span> Pre-screening status:</span> requested{" "}
                                        <>
                                            {value && value.createdAt && value.createdAt.seconds && (
                                                <>
                                                    &nbsp;at&nbsp;
                                                    {moment(
                                                        moment.unix(value.createdAt.seconds)
                                                    ).format("MMM DD, YYYY") +
                                                        " - " +
                                                        moment(
                                                            moment.unix(value.createdAt.seconds)
                                                        ).format("LT")}
                                                </>
                                            )}
                                        </>
                                    </div>
                                );
                            } else if (
                                value.actionType === CHAT.ACTION_TYPE.PAYMENT_REQUEST
                            ) {
                                if (
                                    appointment.billingdata &&
                                    appointment.billingdata
                                        .clinicToPatientBilling &&
                                    appointment.billingdata.clinicToPatientBilling
                                        .total
                                ) {
                                    return (
                                        <div
                                            className="alert alert-light border py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            <span>
                                                {" "}
                                                You request $
                                                {value.amount
                                                    ? value.amount
                                                    : appointment.billingdata
                                                        .clinicToPatientBilling.total}{" "}
                                                from{" "}
                                                {appointment.dependant.firstName +
                                                    " " +
                                                    appointment.dependant.lastName}{" "}
                                            </span>
                                            <>
                                                {value &&
                                                    value.createdAt &&
                                                    value.createdAt.seconds && (
                                                        <>
                                                            &nbsp;on&nbsp;
                                                            {moment(
                                                                moment.unix(value.createdAt.seconds)
                                                            ).format("MMM DD, YYYY") +
                                                                " - " +
                                                                moment(
                                                                    moment.unix(value.createdAt.seconds)
                                                                ).format("LT")}
                                                        </>
                                                    )}
                                            </>
                                        </div>
                                    );
                                }
                                return null;
                            } else if (
                                value.actionType === CHAT.ACTION_TYPE.PAYMENT_SUCCESSFUL
                            ) {
                                return (
                                    <div
                                        className="alert alert-light border py-2 text-center"
                                        key={index + Math.random()}
                                    >
                                        <span>
                                            {" "}
                                            {appointment.dependant.firstName +
                                                " " +
                                                appointment.dependant.lastName}{" "}
                                            paid on{" "}
                                        </span>
                                        <>
                                            {value && value.createdAt && value.createdAt.seconds && (
                                                <>
                                                    &nbsp;on&nbsp;
                                                    {moment(
                                                        moment.unix(value.createdAt.seconds)
                                                    ).format("MMM DD, YYYY") +
                                                        " - " +
                                                        moment(
                                                            moment.unix(value.createdAt.seconds)
                                                        ).format("LT")}
                                                </>
                                            )}
                                        </>
                                    </div>
                                );
                            } else if (
                                value.actionType === CHAT.ACTION_TYPE.APPONINTMENT_ASSIGN
                            ) {
                                return (
                                    <div className="alertparent" key={index + Math.random()}>
                                        <div
                                            className="alert alert-light border py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            <span>
                                                Appointment is assigned to {value.assignToEmail}
                                            </span>
                                        </div>
                                    </div>
                                );
                            } else if (value.actionType === CHAT.ACTION_TYPE.CHECKIN) {
                                return (
                                    <>
                                        <div
                                            className="alert alert-warning py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            <span> Checked in status:</span> {value.text}{" "}
                                            <>
                                                {value &&
                                                    value.createdAt &&
                                                    value.createdAt.seconds && (
                                                        <>
                                                            &nbsp;at&nbsp;
                                                            {moment(
                                                                moment.unix(value.createdAt.seconds)
                                                            ).format("MMM DD, YYYY") +
                                                                " - " +
                                                                moment(
                                                                    moment.unix(value.createdAt.seconds)
                                                                ).format("LT")}
                                                        </>
                                                    )}
                                            </>
                                        </div>
                                    </>
                                );
                            } else if (
                                value.actionType === CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT
                            ) {
                                return (
                                    <>
                                        <div
                                            className="alert alert-warning py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            <span> Appointment:</span> {value.text}{" "}
                                            <>
                                                {value &&
                                                    value.createdAt &&
                                                    value.createdAt.seconds && (
                                                        <>
                                                            &nbsp;on&nbsp;
                                                            {moment(
                                                                moment.unix(value.createdAt.seconds)
                                                            ).format("MMM DD, YYYY") +
                                                                " at " +
                                                                moment(
                                                                    moment.unix(value.createdAt.seconds)
                                                                ).format("LT")}
                                                        </>
                                                    )}
                                            </>
                                        </div>
                                    </>
                                );
                            } else if (
                                value.actionType === CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT
                            ) {
                                return (
                                    <>
                                        <div
                                            className="alert alert-warning py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            <span> Appointment:</span> {value.text}{" "}
                                            <>
                                                {value &&
                                                    value.createdAt &&
                                                    value.createdAt.seconds && (
                                                        <>
                                                            &nbsp;on&nbsp;
                                                            {moment(
                                                                moment.unix(value.createdAt.seconds)
                                                            ).format("MMM DD, YYYY") +
                                                                " at " +
                                                                moment(
                                                                    moment.unix(value.createdAt.seconds)
                                                                ).format("LT")}
                                                        </>
                                                    )}
                                            </>
                                        </div>
                                    </>
                                );
                            } else if (
                                value.actionType === CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT
                            ) {
                                return (
                                    <>
                                        <div
                                            className="alert alert-warning py-2 text-center"
                                            key={index + Math.random()}
                                        >
                                            <span> Appointment:</span> {value.text}{" "}
                                            <>
                                                {value &&
                                                    value.createdAt &&
                                                    value.createdAt.seconds && (
                                                        <>
                                                            &nbsp;on&nbsp;
                                                            {moment(
                                                                moment.unix(value.createdAt.seconds)
                                                            ).format("MMM DD, YYYY") +
                                                                " at " +
                                                                moment(
                                                                    moment.unix(value.createdAt.seconds)
                                                                ).format("LT")}
                                                        </>
                                                    )}
                                            </>
                                        </div>
                                    </>
                                );
                            }
                        })
                    }
                </div>
                <div className="fab_field">
                    <textarea id={"chatSend" + appointment.appointmentId} disabled={appointment.isClosed} name="chat_message"
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                sendMessage();
                            }
                        }}
                        value={message}
                        onChange={(e) => {
                            if (e.target.value.length > 160) {
                                return;
                            }
                            setMessage(e.target.value);
                        }} placeholder="Send a message" className="chat_field chat_message"></textarea>
                    <a id="fab_send" className={`fab is-visible ${appointment.isClosed === true && 'disabled'}`}
                        onClick={(e) => {
                            sendMessage();
                        }}
                    >
                        <span className="material-icons">
                            send
                        </span>
                    </a>
                </div>
            </div>
            <div id={"prime" + appointment.appointmentId} className={"fab avatar avatar-lg rounded-circle text-uppercase fab" + appointment.appointmentId}
                onClick={() => {
                    handleMinimizePin(appointment.appointmentId);
                }}
            >
                {
                    appointment.clinic && appointment.clinic.name &&
                    <div className="fab-bcn">{clinicAvatar}</div>
                }
                <div className="fab-bic"><i className="bi bi-chevron-down"></i></div>
                <span className="position-absolute pintype"><i className="bi bi-geo-alt-fill"></i></span>
            </div>
        </div>
    )
}
