import React from 'react';
import configs from '../../configs/apiConfigs';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { ProviderSearchRequest, ProviderState, ProviderSearchResponse } from '../../store/provider/types';
import { searchProviderPending } from '../../store/provider/actions';
import { toast } from 'react-toastify';
import { showAddress } from '../renderAddress';
import { Avatar } from '@material-ui/core';
import { getProviderIdByName } from '../../services/provider';
import { getCookie } from '../../utils';
import { SharedOffCanvas } from '../../v2/components/sharedOffCanvas/SharedOffCanvas';
interface Props {
    searchProvider: typeof searchProviderPending;
    providerState: ProviderState;
    history: any;
    location: any;
}
interface State {
    clinicCardShow: boolean;
    showProviderSearch: boolean;
    providerId: string;
    searchResult?: any;
    throughId: boolean;
    srcIframe: string;
}
class SearchProvider extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            clinicCardShow: false,
            showProviderSearch: false,
            providerId: '',
            throughId: false, srcIframe: ''
        }
        this.searchProvider = this.searchProvider.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.type) {
            if (this.props.location.state.type === "existing") {
                const login: any = getCookie();
                this.setState({ srcIframe: configs.FRONT_URL + 'list?iframe=yes&q=' + login.xpr_user_id });
            } else {
                this.setState({ srcIframe: configs.FRONT_URL + '?iframe=yes' });
            }
        }
    }
    searchProvider() {
        if (this.state.providerId !== '') {
            if (isNaN(Number(this.state.providerId))) {
                getProviderIdByName(this.state.providerId).then((success) => {
                    console.log(success);
                    this.setState({ searchResult: success.clinic, clinicCardShow: true, throughId: false });
                }).catch((err) => {
                    console.log(err);
                });
            } else {
                this.props.searchProvider({ uniqueId: this.state.providerId });
            }
        }
    }
    cancel() {
        this.setState({ clinicCardShow: false, providerId: '' });
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (this.props !== nextProps) {
            if (nextProps.providerState.error !== null) {
                toast.error('Something went wrong');
            } else if (nextProps.providerState.pending === false && nextProps.providerState.providerSearchResponse &&
                nextProps.providerState.providerSearchResponse !== this.props.providerState.providerSearchResponse) {
                console.log(nextProps.providerState.providerSearchResponse);
                this.setState({
                    searchResult: nextProps.providerState.providerSearchResponse,
                    throughId: true,
                    clinicCardShow: true
                });
            }
        }
        return true;
    }
    handleExistingClick = () => {
        // this.setState({ showProviderSearch: true });
        const login: any = getCookie();
        // this.props.history.push({pathname:'http://localhost:3000/list?q='+login.xpr_user_id});
        window.open(configs.FRONT_URL + 'list?q=' + login.xpr_user_id)


    }
    handleIFrameOpen = (type: string) => {
        if (type === "existing") {
            const login: any = getCookie();
            this.setState({ srcIframe: configs.FRONT_URL + 'list?iframe=yes&q=' + login.xpr_user_id });
        } else {
            this.setState({ srcIframe: configs.FRONT_URL + '?iframe=yes' });
        }
    }
    render() {
        let letterOne = '';
        let letterTwo = '';
        if (this.state.searchResult && this.state.throughId && this.state.searchResult.name !== "") {
            letterOne = this.state.searchResult.name.split(" ")[0] ? this.state.searchResult.name.split(" ")[0].charAt(0) : '';
            letterTwo = this.state.searchResult.name.split(" ")[1] ? this.state.searchResult.name.split(" ")[1].charAt(1) : '';
        }
        return (
            <>
                <section className="main-middle-sec" id="main-middle">
                    <div className="container-fluid p-0 hide-df-scroll">
                        {
                            this.state.srcIframe !== '' &&
                            <>
                                {/* <button className="btn btn-outline-dark float-end mb-3 me-3" onClick={() => { this.props.history.go(-1) }}><i className="bi bi-arrow-left-short"></i> Back to home</button> */}
                                <iframe className="searchpframe" src={this.state.srcIframe} width="600" title="Appointment book"></iframe>
                            </>

                        }

                        {/* {
                            this.state.srcIframe === '' &&
                            <div className="row justify-content-center align-items-center">
                                <div className="col-md-7 col-xl-5 mt-xl-5">
                                    <div className="card px-4 py-5">
                                        <div className="card-body text-center">
                                            <div className="exptitl mb-3">Are you looking for a new Provider or your Existing? </div>
                                            <button className="btn btn-dark fw-normal me-2" onClick={(e) => {
                                                // this.handleExistingClick()
                                                this.handleIFrameOpen("new");
                                            }}>New Provider</button>
                                            <button className="btn btn-dark fw-normal " onClick={(e) => {
                                                // this.handleExistingClick()
                                                this.handleIFrameOpen("existing");
                                            }}>Existing Provider</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } */}
                        {
                            this.state.showProviderSearch === true &&
                            <div className="row justify-content-center">
                                <div className="col-sm-5">
                                    <div className="sbyid">
                                        <div className="exptitl mb-3">Enter Clinic Name or Provider ID</div>

                                        <div className="form-group mb-4">
                                            <input type="text" className="form-control" placeholder="ex. 5478956898 or Dental Clinic" value={this.state.providerId} onChange={(e) => {
                                                this.setState({ providerId: e.target.value });
                                            }} />
                                        </div>
                                        {
                                            (this.state.clinicCardShow === true && this.state.searchResult && this.state.throughId === false) &&
                                            this.state.searchResult.map((item: any) => {
                                                return (
                                                    <div className="media" key={item.id} onClick={() => {
                                                        this.props.history.push('/clinic/' + item.uniqueId);
                                                    }}>
                                                        {
                                                            (item.logo && item.logo.name && item.logo.type) ?
                                                                <img alt="mr-3 rounded-circle" src={configs.IMAGE_URL + 'clinic/' + item.logo.name + '-200x200.' + item.logo.type} height={50} width={50}></img>
                                                                :
                                                                <img alt="mr-3 rounded-circle" src={configs.DEFAULT_IMAGE_URL_CLINIC} height={50} width={50}></img>
                                                        }
                                                        <div className="media-body ml-2">
                                                            <h5 className="mt-0">{item.name}</h5>
                                                            <p className="mt-0">{showAddress(item.address)}</p>
                                                            <p className="mt-0">{item.uniqueId}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            (this.state.clinicCardShow === true && this.state.searchResult && this.state.throughId) &&
                                            <div className="media" onClick={(e) => {
                                                if (this.state.searchResult)
                                                    this.props.history.push('/clinic/' + this.state.searchResult.uniqueId);
                                            }}>
                                                {
                                                    (this.state.searchResult.logo.name !== "" && this.state.searchResult.logo.type !== "") ?
                                                        <img className="mr-3 rounded-circle"
                                                            src={configs.IMAGE_URL + 'clinic/' + this.state.searchResult.logo.name + '-200x200.' + this.state.searchResult.logo.type} alt="" />
                                                        :
                                                        <Avatar>{letterOne}{letterTwo}</Avatar>
                                                }
                                                <div className="media-body">
                                                    <h5 className="mt-0">{this.state.searchResult.name}</h5>
                                                    <p>{showAddress(this.state.searchResult.address)}</p>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.clinicCardShow === false &&
                                            <button className="btn btn-cancel btn-custom" disabled={this.state.providerId === ""} onClick={(e) => {
                                                this.searchProvider();
                                            }}>Search</button>
                                        }
                                        {
                                            this.state.clinicCardShow === true &&
                                            <button className="btn btn-cancel btn-custom" onClick={(e) => {
                                                this.cancel();
                                            }}>Cancel</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </section>
            </>
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    providerState: state.provider
});
const mapDispatchToProps = (dispatch: any) => ({
    searchProvider: (request: ProviderSearchRequest) => {
        dispatch(searchProviderPending(request));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchProvider);