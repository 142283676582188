import React from 'react';
import './PopUpStyle.css';
import apiConfigs from '../../configs/apiConfigs';
import { Avatar } from '@material-ui/core';
interface Props {
    showNextPopUp: (clinic: any, selectedDoctor: any) => void;
    closePopUp: () => void;
    data: any;
}
interface State {
    selectedDoctor: any;
}
class PopUpOne extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedDoctor: ''
        }
    }
    continueClick = () => {
        this.props.showNextPopUp(this.props.data, this.state.selectedDoctor);
    }
    closePopUp = () => {
        this.props.closePopUp();
    }
    render() {
        return (

            <div className="step-1">
                <div className="modal-header">
                    <button className="btn book-close-btn" onClick={(e) => { this.closePopUp(); }}>
                        <img src={process.env.PUBLIC_URL + "/images/close-icon-white.svg"} className="img-fluid" />
                    </button>

                    <div className="media">
                        <div className="media-img mr-3 img-box">
                            {
                                (this.props.data.logo && this.props.data.logo.name && this.props.data.logo.type) ?
                                    <img src={apiConfigs.IMAGE_URL + '/clinic/' + this.props.data.logo.name + '-200x200.' + this.props.data.logo.type} className="img-fluid" /> :
                                    <img src={apiConfigs.DEFAULT_IMAGE_URL_CLINIC} className="img-fluid" />
                            }
                        </div>
                        <div className="media-body align-self-end">
                            <div>Book your appointment with</div>
                            <div className="p-name">{this.props.data.name}</div>
                        </div>
                    </div>

                </div>

                <div className="modal-body">
                    <div className="publp_title">
                        <h6>Which provider would you like to visit?</h6>
                    </div>
                    <div className="publp_form">
                        <div className="row d-flex justify-content-center">
                            {
                                this.props.data.doctors.map((doctor: any, index: number) => {
                                    let speciality = '';
                                    if (doctor.specialities) {
                                        const elementFound = doctor.specialities.findIndex((spec: any, index: number) => {
                                            return spec.coreSpeciality === "1"
                                        });
                                        if (elementFound === -1 && doctor.specialities.length > 0) {
                                            speciality = doctor.specialities[0].name;
                                        } else {
                                            speciality = doctor.specialities[elementFound].name;
                                        }
                                    }
                                    let imageUrl = ''
                                    if (doctor.photo && doctor.photo.name && doctor.photo.type) {
                                        imageUrl = apiConfigs.IMAGE_URL + '/doctor/' + doctor.photo.name + '-200x200.' + doctor.photo.type;
                                    } else {
                                        imageUrl = apiConfigs.DEFAULT_IMAGE_URL_DOCTOR;
                                    }
                                    let doctorName = doctor.fullname.replace("Dr.", "");
                                    doctorName = doctorName.trim();
                                    doctorName = doctorName.split(" ");
                                    const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
                                    const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
                                    return (
                                        <div className="col-md-6" key={index + Math.random()}>
                                            <input type="radio" name="dr-profile" checked={Number(this.state.selectedDoctor.uniqueid) === Number(doctor.uniqueid)} className="dr-selected"
                                                onChange={(e: any) => {
                                                    this.setState({ selectedDoctor: doctor });
                                                }}
                                            />
                                            <div className="media">
                                                <div className="media-img mr-3">
                                                    {
                                                        (doctor.photo && doctor.photo.name && doctor.photo.type) ?
                                                            <img src={imageUrl} className="img-fluid" /> :
                                                            <Avatar>{letterOne}{letterTwo}</Avatar>
                                                        // <img src={apiConfigs.DEFAULT_IMAGE_URL_DOCTOR} className="img-fluid" />
                                                    }
                                                </div>
                                                <div className="media-body align-self-center">
                                                    <div className="dr-name">{doctor.fullname ? doctor.fullname : ''}</div>
                                                    <div className="dr-desg">{speciality}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="form-group pop_btnsec">
                            <button className="btn btn-custom w-100" disabled={this.state.selectedDoctor === ''} onClick={(e) => { this.continueClick() }}>Continue</button>
                        </div>
                    </div>

                </div>


            </div>
        );
    }
}
export default PopUpOne;