import axios from "axios"
import configs from "../configs/apiConfigs";
import { CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandlers";
import { getCookie } from "../utils/cookies";
export const getPatientClinicList = () => {
    const login = getCookie();
    const request: CommonApiOptions = {
        url: configs.API_URL + "patient/" + login.xpr_user_id + "/cliniclist/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};

export const getChatAppointmentList = (clinicId: any) => {
    const login = getCookie();
    const request: CommonApiOptions = {
        url: configs.API_URL + "patient/" + login.xpr_user_id + "/chatappointmentlist/" + clinicId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};