import {
    PatientDataRequest, PatientDataPendingAction, PATIENT_DATA, PatientDataResponse,
    PatientDataSuccessAction, PatientDataErrorAction, ACCOUNT, AccountUpdatePendingAction, AccountUpdateRequest, AccountUpdateResponse, AccountUpdateSuccessAction, AccountUpdateErrorAction, PatientReferRequest, PatientReferResponse, PatientReferErrorAction, PatientReferSuccessAction, PatientReferPendingAction, EmailVerificationRequest, EmailVerificationPendingAction, EmailVerificationResponse, EmailVerificationSuccessAction, EmailVerificationErrorAction, RemoveDefaultClinicRequest, RemoveDefaultClinicPendingAction, RemoveDefaultClinicErrorAction, RemoveDefaultClinicResponse, RemoveDefaultClinicSuccessAction, AddDefaultClinicRequest, AddDefaultClinicPendingAction, AddDefaultClinicErrorAction, AddDefaultClinicResponse, AddDefaultClinicSuccessAction, ChangeWriteReview, ChangeWriteReviewAction
} from './types';
/* Action Creators */
export const patientDataPending = (request: PatientDataRequest): PatientDataPendingAction => {
    return {
        type: PATIENT_DATA.PATIENT_DATA_LOAD_PENDING,
        payload: request
    };
};

export const patientDataSuccess = (response: PatientDataResponse): PatientDataSuccessAction => {
    return {
        type: PATIENT_DATA.PATIENT_DATA_LOAD_SUCCESS,
        patientData: response
    }
}

export const patientDataError = (err: any): PatientDataErrorAction => {
    return {
        type: PATIENT_DATA.PATIENT_DATA_LOAD_ERROR,
        error: err
    }
}

export const accountUpdatePending = (request: AccountUpdateRequest): AccountUpdatePendingAction => {
    return {
        type: ACCOUNT.ACCOUNT_UPDATE_PENDING,
        payload: request
    };
};

export const accountUpdateSuccess = (response: AccountUpdateResponse): AccountUpdateSuccessAction => {
    return {
        type: ACCOUNT.ACCOUNT_UPDATE_SUCCESS,
        accountUpdate: response
    }
}

export const accountUpdateError = (err: any): AccountUpdateErrorAction => {
    return {
        type: ACCOUNT.ACCOUNT_UPDATE_ERROR,
        error: err
    }
}

export const patientReferPending = (request: PatientReferRequest): PatientReferPendingAction => {
    return {
        type: PATIENT_DATA.PATIENT_REFER_PENDING,
        payload: request
    };
};

export const patientReferSuccess = (response: PatientReferResponse): PatientReferSuccessAction => {
    return {
        type: PATIENT_DATA.PATIENT_REFER_SUCCESS,
        patientRefer: response
    }
}

export const patientReferError = (err: any): PatientReferErrorAction => {
    return {
        type: PATIENT_DATA.PATIENT_REFER_ERROR,
        error: err
    }
}
export const emailVerificationPendingAction = (request: EmailVerificationRequest): EmailVerificationPendingAction => {
    return {
        type: PATIENT_DATA.EMAIL_VERIFICATION_PENDING,
        payload: request
    }
}
export const emailVerificationSuccessAction = (response: EmailVerificationResponse): EmailVerificationSuccessAction => {
    return {
        type: PATIENT_DATA.EMAIL_VERIFICATION_SUCCESS,
        emailVerification: response
    }
}
export const emailVerificationErrorAction = (error: any): EmailVerificationErrorAction => {
    return {
        type: PATIENT_DATA.EMAIL_VERIFICATION_ERROR,
        error: error
    }
}
export const removeDefaultClinicPendingAction = (request: RemoveDefaultClinicRequest): RemoveDefaultClinicPendingAction => {
    return {
        type: PATIENT_DATA.REMOVE_DEFAULT_CLINIC,
        payload: request
    }
}
export const removeDefaultClinicErrorAction = (error: any): RemoveDefaultClinicErrorAction => {
    return {
        type: PATIENT_DATA.REMOVE_DEFAULT_CLINIC_ERROR,
        payload: error
    }
}
export const removeDefaultClinicSuccessAction = (response: RemoveDefaultClinicResponse): RemoveDefaultClinicSuccessAction => {
    return {
        type: PATIENT_DATA.REMOVE_DEFAULT_CLINIC_SUCCESS,
        payload: response
    }
}
export const addDefaultClinicPendingAction = (request: AddDefaultClinicRequest): AddDefaultClinicPendingAction => {
    return {
        type: PATIENT_DATA.ADD_DEFAULT_CLINIC,
        payload: request
    }
}
export const addDefaultClinicErrorAction = (error: any): AddDefaultClinicErrorAction => {
    return {
        type: PATIENT_DATA.ADD_DEFAULT_CLINIC_ERROR,
        payload: error
    }
}
export const addDefaultClinicSuccessAction = (response: AddDefaultClinicResponse): AddDefaultClinicSuccessAction => {
    return {
        type: PATIENT_DATA.ADD_DEFAULT_CLINIC_SUCCESS,
        payload: response
    }
}

/* Change appointment review response after the review api success */
export const changeWriteReviewAction = (body: ChangeWriteReview): ChangeWriteReviewAction => ({
    type: PATIENT_DATA.CHANGE_WRITE_REVIEW,
    payload: body
});