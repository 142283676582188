import React, { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
export const SharedOffCanvas = ({ placement = "end", addclass, isOpen, handleClose, handleSuccess, style, Children, ...props }: any) => {
    const [show, setShow] = useState(isOpen);
    useEffect(() => {
        if (isOpen) {
            setShow(true);
        }
    }, [isOpen]);

    const handleModalClose = () => {
        if (props.mode === "payment") {
            return;
        }
        setShow(false);
        handleClose();
    }
    const handleAddEditSuccess = () => {
        setShow(false);
        handleSuccess();
    }
    return (
        <Offcanvas placement={placement} className={addclass} show={show} onHide={handleModalClose} style={style}>
            <Children handleModalClose={handleModalClose} handleSuccess={handleAddEditSuccess} {...props} />
        </Offcanvas>
    )
}
