import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAppointment } from '../../services/appointment';
import { roundNumberWithRoundingFactorDecimalFix, useQuery } from '../../../utils/global';
import moment from 'moment';
import { Link, NavLink } from 'react-router-dom';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { WriteReviewModal } from './WriteReviewModal';
import LoadingIndicator from '../../pages/loadingIndicator/LoadingIndicator';
export const Completed = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [appointmentList, setAppointmentList] = useState([]);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const [currentSelectedAppointment, setCurrentSelectedAppointment]: any = useState('');
    const [writeReview, setWriteReview] = useState(false);
    const [currentClaimStatusOpen, setCurrentClaimStatusOpen] = useState('');
    const history = useHistory();
    const location: any = useLocation();
    const [isNoRecord, setIsNoRecord] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        fetchAppointment(patientData.uniqueId, 'completed').then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                setAppointmentList(success.appointments);
                if (success.appointments && success.appointments.length <= 0) {
                    setIsNoRecord(true);
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }, []);
    const hideWriteReview = () => {
        setWriteReview(false);
        setCurrentSelectedAppointment('');
    }
    return (
        <section className="px-md-3 appointment-sec">
            {
                isLoading &&
                <LoadingIndicator />
            }
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="appUpcoming appCompleted">
                            {(appointmentList && appointmentList.length > 0) ?
                                appointmentList.map((appointment: any, index: number) => {
                                    let endTime: any = '';
                                    if (appointment.time && appointment.duration) {
                                        endTime = moment(appointment.time, 'LT').add(appointment.duration, 'minutes');
                                    }
                                    let pFullname = '';
                                    if (appointment && appointment.dependant && appointment.dependant.firstName && appointment.dependant.lastName) {
                                        pFullname = appointment.dependant.firstName + " " + appointment.dependant.lastName;
                                    }
                                    return (
                                        <div key={index + Math.random() + 'appointment'} className="d-md-flex d-block appDataBox rounded p-3 justify-content-between my-4">
                                            <div className="leftSec d-flex" onClick={() => {
                                                history.push('/appointmentdetail/' + appointment.uniqueid)
                                            }}>
                                                <div className="dateBox orange-badge  py-3 px-md-3 px-2  me-md-3 me-2">
                                                    <div className="dayVal text-center pt-1">{appointment.date ? moment(appointment.date).format('DD') : '-'}</div>
                                                    <div className="monVal text-center text-muted">{appointment.date ? moment(appointment.date).format('MMM') : '-'}</div>
                                                </div>
                                                <div className="detailBox d-block">
                                                    <span className="badge rounded-pill bg-primary text-white">Completed</span>
                                                    {
                                                        !(appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.payment) &&
                                                        <span className="badge rounded-pill bg-danger text-white ms-2">Invoice not available</span>

                                                    }
                                                    {
                                                        (appointment.billingdata && appointment.billingdata.clinicToPatientBilling &&
                                                            appointment.billingdata.clinicToPatientBilling.status === "paid") &&
                                                        <span className="badge rounded-pill bg-success text-white ms-2">Paid -
                                                            {(appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus && appointment.billingdata.recurring.paid) ?
                                                                <>
                                                                    ${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.recurring.paid, 2)}
                                                                </>
                                                            :
                                                                <>
                                                                    ${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}
                                                                </>
                                                            }
                                                        
                                                        </span>

                                                    }
                                                    <div className="detailTxt my-1">{appointment.appointmentType ? appointment.appointmentType : '-'} with {appointment.doctor.fullname ? appointment.doctor.fullname : ''} - ({appointment.doctor.speciality})</div>
                                                    <div className="">
                                                        <div className="text-muted me-3">{appointment.time ? appointment.time : '-'} - {endTime ? moment(endTime).format('hh:mm A') : '-'}  {appointment.date ? "(" + moment(appointment.date).format('MMM DD, YYYY') + ")" : '-'}</div>
                                                        <div className="text-muted">Clinic: <span>{appointment.clinic.name ? appointment.clinic.name : '-'}</span></div>
                                                    </div>
                                                    <div className="text-muted">Apt. for - <span>{pFullname ? pFullname : '-'}</span></div>
                                                    {
                                                        appointment.billingdata && appointment.billingdata.clinicToPatientBilling && appointment.billingdata.clinicToPatientBilling.total &&
                                                        <div className="text-muted">Total - <span>${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</span>
                                                            {(appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus && appointment.billingdata.recurring.numberOfEmi && appointment.billingdata.clinicToPatientBilling.total !== appointment.billingdata.recurring.paid) &&
                                                                <> , { appointment.billingdata.recurring.paymentMode} Instalment - <span>${roundNumberWithRoundingFactorDecimalFix((appointment.billingdata.clinicToPatientBilling.total / Number(appointment.billingdata.recurring.numberOfEmi)), 2)}</span></>
                                                            }
                                                        </div>
                                                    }
                                                    {/* <div className="text-muted">Total - <span>{appointment.billingdata && appointment.billingdata.clinicToPatientBilling && appointment.billingdata.clinicToPatientBilling.total ?
                                                        appointment.billingdata.clinicToPatientBilling.total : '-'}
                                                        {appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus && appointment.billingdata.recurring.numberOfEmi ? appointment.billingdata.recurring.paymentMode:''} Emi -
                                                        {appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.numberOfEmi ? <> ${roundNumberWithRoundingFactorDecimalFix((appointment.billingdata.clinicToPatientBilling.total / Number(appointment.billingdata.recurring.numberOfEmi)), 2)}</> : ''}
                                                    </span></div> */}
                                                </div>
                                            </div>
                                            <div className="rightSec d-xl-flex align-self-center d-block">
                                                {/* {
                                                    !(appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.payment) &&
                                                    <div className="paystatus align-self-center me-xl-3 mb-xl-0 mb-md-3 mb-0">
                                                        <span className="badge rounded-pill bg-primary text-white">Invoice not available</span>
                                                    </div>
                                                } */}
                                                {
                                                    (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.payment &&
                                                        (appointment.billingdata && appointment.billingdata.clinicToPatientBilling &&
                                                            appointment.billingdata.clinicToPatientBilling.status === "pending") && (
                                                            appointment.clinic && appointment.clinic.paymentSubscription === "On"
                                                        )) &&
                                                    <NavLink to={{
                                                        pathname: "/appointment/payment/" + appointment.uniqueid + location.search,
                                                        state: {
                                                            appointment: appointment
                                                        }
                                                    }} className="btn btn-primary align-self-center me-xl-3 mb-xl-0 mb-md-3 mb-0"
                                                        onClick={(e: any) => {
                                                            setCurrentClaimStatusOpen('');
                                                        }}>
                                                        {
                                                            (appointment.billingdata && appointment.billingdata.recurring && appointment.billingdata.recurring.recurringStatus &&
                                                                appointment.billingdata.recurring.numberOfEmi) ?
                                                                <>Pay now - <span>${roundNumberWithRoundingFactorDecimalFix((appointment.billingdata.clinicToPatientBilling.total/Number(appointment.billingdata.recurring.numberOfEmi)), 2)}</span></>
                                                        :
                                                         <>Pay now - <span>${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</span></>   
                                                        }
                                                        {/* Pay now - <span>${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</span> */}

                                                    </NavLink>
                                                }
                                                {/* {
                                                    (appointment.billingdata && appointment.billingdata.clinicToPatientBilling &&
                                                        appointment.billingdata.clinicToPatientBilling.status === "paid") &&
                                                    <div className="align-self-center me-xl-3 text-center mb-xl-0 mb-md-3 mb-0">
                                                        <div className="text-muted small d-none">Payment Status</div>
                                                        <span className="badge rounded-pill bg-primary text-white">Paid - ${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</span>
                                                    </div>
                                                } */}
                                                {
                                                    appointment.id.toString() !== currentClaimStatusOpen &&
                                                    <>
                                                        {/* <div className={"status align-self-center completed"} style={{ textTransform: "capitalize" }}>{'completed'}</div> */}
                                                        {/*
                                                        (status === APPOINTMENT_STATUS.COMPLETE[0] && appointment.claimStatus) &&
                                                        <button className="btn completed claim_rw align-self-center"
                                                            onClick={(e) => {
                                                                this.setState({ currentClaimStatusOpen: appointment.id });
                                                            }}
                                                        ><img src={require('../../assets/images/ic_card_giftca-white.svg')} className="btn_ico" /> Claim status</button>
                                                    */}
                                                        {/*
                                                        (status === APPOINTMENT_STATUS.COMPLETE[0] && (appointment.claimStatus === null || appointment.claimStatus === "")) &&
                                                        <Link
                                                            to={"/claim-reward?appointment=" + appointment.uniqueid}
                                                            className="btn completed claim_rw align-self-center"><img src={require('../../assets/images/ic_card_giftca-white.svg')} className="btn_ico" /> Claim your reward</Link>
                                                    */}
                                                    </>
                                                }
                                                {
                                                    appointment.id.toString() === currentClaimStatusOpen &&
                                                    <div className="claim-status rwsw d-flex">
                                                        {
                                                            claimStatusTimeline(appointment.claimStatus)
                                                        }
                                                        <button className="btn btn-circle_ico"
                                                            onClick={(e) => {
                                                                setCurrentClaimStatusOpen('');
                                                            }}><img src={require("../../../assets/images/close_ic.svg")} /></button>
                                                    </div>
                                                }
                                                <div className="d-md-flex align-self-center py-1">
                                                    {
                                                        (appointment.clinic && appointment.clinic.metadata && appointment.clinic.metadata.googleReviewUrl && appointment.clinic.metadata.googleReviewUrl !== "") ?
                                                            <div className="w-100 align-self-center"><a className="w-100 btn btn-outline-primary" href={appointment.clinic.metadata.googleReviewUrl} target="_blank">Write a Review</a></div>
                                                            : <>
                                                                {
                                                                    (appointment.rating === null) &&
                                                                    <div className="w-100 align-self-center"><a className="w-100 btn btn-outline-primary" href="javascript:void(0)" onClick={(e) => {
                                                                        setWriteReview(true);
                                                                        setCurrentSelectedAppointment(appointment);
                                                                    }}>Write a Review</a></div>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : isNoRecord &&
                                <div className="d-flex appDataBox rounded p-3 justify-content-between my-4">
                                    No record found.
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                (writeReview) &&
                <WriteReviewModal
                    writeReview={writeReview}
                    hideWriteReview={hideWriteReview}
                    currentSelectedAppointment={currentSelectedAppointment}
                />
            }
        </section>
    )
}
function claimStatusTimeline(claimStatus: string) {
    let lastIndex = 1;
    if (claimStatus.toLowerCase() === "requested") {
        lastIndex = 2;
    } else if (claimStatus.toLowerCase() === "validated") {
        lastIndex = 3;
    } else if (claimStatus.toLowerCase() === "sent") {
        lastIndex = 4;
    }
    return (
        <ul className="step-wizard p-0">
            <li className={"step " + (lastIndex < 1 ? 'disable' : 'active')}>
                <span className="icon"><img src={require("../../../assets/images/ic_check_24px.svg")} /></span>
                <span className="name">Visited</span>
            </li>
            <li className={"step " + (lastIndex < 2 ? 'disable' : 'active')}>
                <span className="icon"><img src={require("../../../assets/images/ic_star_24px.svg")} /></span>
                <span className="name">Claming</span>
            </li>
            <li className={"step " + (lastIndex < 3 ? 'disable' : 'active')}>
                <span className="icon">3</span>
                <span className="name">Validated</span>
            </li>
            <li className={"step " + (lastIndex < 4 ? 'disable' : 'active')}>
                <span className="icon">4</span>
                <span className="name">Sent</span>
            </li>
        </ul>
    );
}
