import React, { useState } from "react";
import { Animated } from "react-animated-css";
import { Spinner } from "react-bootstrap";
import Helmet from "react-helmet";
import { useForm } from "react-hook-form";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPasswordApi } from "../../services/auth";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
export const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isViewPassword, setIsViewPassword] = useState(false);
  const [isConfirmViewPassword, setIsConfirmViewPassword] = useState(false);
  const {
    register,
    setError,
    handleSubmit,
    setValue,
    getValues,
    errors,
    trigger,
  } = useForm();
  const history = useHistory();
  const location: any = useLocation();
  const match: any = useRouteMatch();
  const resetPasswordClick = (data: any) => {
    if (match.params.id) {
      if (
        getValues("newpassword").trim() !== getValues("cnewpassword").trim()
      ) {
        setError("cnewpassword", {
          type: "custom",
          message: "Both password should match.",
        });
        return;
      }
      setIsLoading(true);
      resetPasswordApi({
        patientuniqueId: match.params.id,
        newpwd: getValues("newpassword").trim(),
        modelType: "resetpwd",
      })
        .then((success) => {
          setIsLoading(false);
          if (success && success.status && success.status.error === false) {
            const queryParam: any = new URLSearchParams(location.search);
            toast.success("Reset password success");
            if (
              queryParam &&
              queryParam.get("redirect_url") &&
              queryParam.get("redirect_url") !== null &&
              queryParam.get("redirect_url") !== ""
            ) {
              window.location.replace(queryParam.get("redirect_url"));
            } else {
              history.replace("/login");
            }
          } else {
            toast.error(success.status.msg);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.msg);
        });
    } else {
      toast.error("Invalid request");
    }
  };
  return (
    <ErrorBoundary>
      <div className="master_patientp">
        {/*   {
                    isLoading &&
                    <LoadingIndicator></LoadingIndicator>
                } */}
        <section className="login-page">
          <Helmet>
            <title>Reset password</title>
          </Helmet>
          <div className="container">
            <div className="row flex-center min-vh-100 py-5">
              <div className="col-sm-8 col-md-6 col-lg-6 col-xl-5 col-xxl-4 m-auto">
                <Animated
                  animationIn="fadeIn"
                  animationOut="zoomOut"
                  animationInDuration={1000}
                  isVisible={true}
                >
                  <div className="card shadow-sm">
                    <div className="card-header bg-light px-5 py-3">
                      <img
                        src={require("../../../assets/images/logo-black.svg")}
                        alt="logo"
                      />
                    </div>
                    <div className="card-body p-5">
                      <div className="mb-3">
                        <label className="form-label fw-medium">
                          New password
                        </label>
                        <div className="input-group">
                          <input
                            type={isViewPassword ? "text" : "password"}
                            disabled={isLoading}
                            className="form-control"
                            name={"newpassword"}
                            ref={register({
                              required: true,
                              pattern:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            })}
                          />
                          <span className="input-group-text bg-transparent">
                            <div
                              className="view-password-control opacity-50 fs-7"
                              onClick={() => {
                                setIsViewPassword(!isViewPassword);
                              }}
                            >
                              <i
                                className={
                                  isViewPassword
                                    ? "bi bi-eye active"
                                    : "bi bi-eye-slash active"
                                }
                              ></i>
                            </div>
                          </span>
                        </div>
                        {errors &&
                          errors.newpassword &&
                          errors.newpassword.type === "required" && (
                            <div className="text-danger error">
                              New password is required.
                            </div>
                          )}
                        {errors &&
                          errors.newpassword &&
                          errors.newpassword.type === "pattern" && (
                            <div className="text-danger error">
                              Password must contain 8 characters with at least 1
                              upper case, 1 lower case, 1 special and 1 numeric
                              character.
                            </div>
                          )}
                        {errors &&
                          errors.newpassword &&
                          errors.newpassword.type === "custom" && (
                            <div className="text-danger error">
                              {errors.newpassword.message}
                            </div>
                          )}
                      </div>
                      <div className="mb-3">
                        <label className="form-label fw-medium">
                          Confirm new password
                        </label>
                        <div className="input-group">
                          <input
                            type={isConfirmViewPassword ? "text" : "password"}
                            disabled={isLoading}
                            className="form-control"
                            name={"cnewpassword"}
                            ref={register({
                              required: true,
                              pattern:
                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            })}
                          />
                          <span className="input-group-text bg-transparent">
                            <div
                              className="view-password-control opacity-50 fs-7"
                              onClick={() => {
                                setIsConfirmViewPassword(
                                  !isConfirmViewPassword
                                );
                              }}
                            >
                              <i
                                className={
                                  isConfirmViewPassword
                                    ? "bi bi-eye active"
                                    : "bi bi-eye-slash active"
                                }
                              ></i>
                            </div>
                          </span>
                        </div>
                        {errors &&
                          errors.cnewpassword &&
                          errors.cnewpassword.type === "required" && (
                            <div className="text-danger error">
                              Confirm new password is required.
                            </div>
                          )}
                        {errors &&
                          errors.cnewpassword &&
                          errors.cnewpassword.type === "pattern" && (
                            <div className="text-danger error">
                              Password must contain 8 characters with at least 1
                              upper case, 1 lower case, 1 special and 1 numeric
                              character.
                            </div>
                          )}
                        {errors &&
                          errors.cnewpassword &&
                          errors.cnewpassword.type === "custom" && (
                            <div className="text-danger error">
                              {errors.cnewpassword.message}
                            </div>
                          )}
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={handleSubmit(resetPasswordClick)}
                      >
                        Reset password
                        <span className="aft_load right">
                          <>
                            {isLoading === true && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </>
                        </span>
                      </button>
                    </div>
                  </div>
                </Animated>
              </div>
              <div className="text-center fw-normal footer-srip">
                Copyright © {new Date().getFullYear()} Xcare Inc. All rights
                reserved.
              </div>
            </div>
          </div>
        </section>
      </div>
    </ErrorBoundary>
  );
};
