import React, { useState, useEffect } from 'react';
import { Alert, Modal, ModalBody, ListGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { TITLES_LABELS, APPOINTMENT_STATUS, CHAT } from '../../../constants';
import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { toast } from 'react-toastify';
import apiConfigs from '../../configs/apiConfigs';
import { roundNumberWithRoundingFactorDecimalFix, useQuery } from '../../../utils/global';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { showWaitingPresc, userLogout } from '../../../store/auth/actions';
export const PrescreeningModal = ({ completeScreeningModal, hadleShowAlert, handleHidePreScreeningModal,
    currentSelectedAppointment, handleSuccessPreScreening }: any) => {
    let db: any;
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const [showAlert, setShowAlert] = useState(false);
    const [prescreeningQuestions, setPrescreeningQuestions]: any = useState('');
    const [allNoCheckBox, setAllNoCheckBox] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const location: any = useLocation();
    const dispatch = useDispatch();
    const [medium, setMedium]: any = useState('');
    const [isPreScreeningDone, setIsPreScreeningDone]: any = useState(false);
    const [isWebView, setIsWebView]: any = useState('');

    useEffect(() => {
        let mediumTemp = new URLSearchParams(location.search).get('medium');
        setMedium(mediumTemp);
        let webView: any = new URLSearchParams(location.search).get('webview');
        setIsWebView(webView);
        // if (mediumTemp === 'email' || mediumTemp === 'sms') {
        //     dispatch(userLogout());
        // }
        // if (currentSelectedAppointment?.metadata?.appointment?.prescreening && currentSelectedAppointment?.metadata?.appointment?.prescreening?.attempt && currentSelectedAppointment?.metadata?.appointment?.prescreening?.verifiedOn) {
        //     setIsPreScreeningDone(true)
        // }
        if (currentSelectedAppointment?.metadata?.appointment?.prescreening && currentSelectedAppointment?.metadata?.appointment?.prescreening?.attempt && currentSelectedAppointment?.metadata?.appointment?.prescreening?.verifiedOn) {
            dispatch(userLogout());
        }
        if (currentSelectedAppointment.metadata.appointment.prescreening && currentSelectedAppointment.metadata.appointment.prescreening.attempt && !currentSelectedAppointment.metadata.appointment.prescreening.verifiedOn) {
            dispatch(userLogout());
        }
        if (!(currentSelectedAppointment.date && currentSelectedAppointment.time &&
            Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) >= 0 &&
            Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) <= 1440
         ) &&
            (mediumTemp === 'email' || mediumTemp === 'sms')
            ) {
            dispatch(userLogout());
        }
        db = firebase.firestore();
        setPrescreeingToState();
    }, []);
    const setPrescreeingToState = () => {
        if (patientData) {
            const findSchedule = patientData.appointments.filter((value: any, index: number) => {
                return value.currentStatus === APPOINTMENT_STATUS.SCHEDULE[0];
            }).find((value: any) => {
                return moment(value.date, 'YYYY-MM-DD').isAfter(moment(moment(), 'YYYY-MM-DD'));
            });
            console.log(findSchedule);
            if (findSchedule) {
                setShowAlert(true);
                hadleShowAlert();
            }
            const { prescreening } = patientData;
            if (prescreening) {
                /*  this.setState({
                     prescreeningQuestions: Object.keys(prescreening).map((value, index) => {
                         if (prescreening[value].options) {
                             return Object.assign(prescreening[value], { answer: [] });
                         }
                         return Object.assign(prescreening[value], { answer: false });
                     })
                 }); */
                setPrescreeningQuestions(
                    prescreening.map((value: any, index: number) => {
                        if (value.options) {
                            return Object.assign(value, { answer: [] });
                        }
                        return Object.assign(value, { answer: '' });
                    })
                );
            }
        }
    }
    const addRemoveOptions = (questionIndex: number, ques: any) => {
        setPrescreeningQuestions(prescreeningQuestions.map((value: any, index: number) => {
            if (questionIndex === index) {
                const flag = prescreeningQuestions[questionIndex].answer.findIndex((option: any, oIndex: number) => {
                    return ques === option;
                });
                if (flag !== -1) {
                    return {
                        ...value,
                        answer: value.answer.filter((qValue: any) => { return (qValue !== ques) })
                    }
                } else if (ques.toLowerCase() === "none") {
                    return {
                        ...value,
                        answer: [ques]
                    }
                }
                return {
                    ...value,
                    answer: [...value.answer, ques].filter((qValue: any) => { return qValue.toLowerCase() !== "none" })
                }
            }
            return value;
        })
        )
    }
    const yesNoClickPreScreening = (questionIndex: number, answer: any) => {
        if (questionIndex === prescreeningQuestions.length - 1 && answer === "yes") {
            setPrescreeningQuestions(prescreeningQuestions.map((ques: any, index: number) => {
                if (index === questionIndex) {
                    return {
                        ...ques,
                        answer: answer,
                        oldAnswer: answer,
                    }
                }
                return ques;
            })
            )
            return;
        }
        setAllNoCheckBox(false);
        setPrescreeningQuestions(prescreeningQuestions.map((ques: any, index: number) => {
            if (index === questionIndex) {
                return {
                    ...ques,
                    answer: answer,
                    oldAnswer: answer,
                }
            }
            return ques;
        })
        )
    }
    const onFinishClick = () => {
        console.log('currentSelectedAppointment', currentSelectedAppointment);
        console.log('patientData', patientData);
        
        if (currentSelectedAppointment && patientData) {
            let isWebView: any = new URLSearchParams(location.search).get('webview');
            setIsLoading(true);
            let allowed = true;
            for (let start = 0; start < prescreeningQuestions.length; start++) {
                const obj = prescreeningQuestions[start];
                if (obj.options && obj.answer.length === 0 && start !== prescreeningQuestions.length - 1) {
                    allowed = false;
                    toast.warn('Please answer all questions.');
                    setIsLoading(false);
                    return;
                } else if ((obj.answer === undefined || obj.answer === null || obj.answer === '') && start !== prescreeningQuestions.length - 1) {
                    allowed = false;
                    toast.warn('Please answer all questions.');
                    setIsLoading(false);
                    return;
                } else if (start === prescreeningQuestions.length - 1 && obj.answer === "no") {
                    allowed = false;
                    toast.warn("Please verify that your answeres are correct.");
                    setIsLoading(false);
                    return;
                }
            }
            console.log('allowed', allowed);
            
            if (allowed) {
                dispatch(fetchLoadingPending());
                let passPrescreenAuto = false;
                const prescreeningQuestionsNew = prescreeningQuestions.map((value: any) => {
                    if (value.options) {
                        return {
                            ...value,
                            answer: value.answer.toString()
                        }
                    }
                    return value;
                });
                passPrescreenAuto = prescreeningQuestionsNew.slice(0, prescreeningQuestionsNew.length - 1).find((item: any) => item.answer === "yes") === undefined;
                const obj = {
                    "appointmentId": Number(currentSelectedAppointment.uniqueid),
                    "senderId": Number(patientData.uniqueId),
                    "sender": CHAT.SENDER.PATIENT,
                    "action": CHAT.ACTION.PBP,
                    "actionType": CHAT.ACTION_TYPE.PRESCREENING,
                    "text": prescreeningQuestionsNew,
                    "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "sendToCId": currentSelectedAppointment.clinic.uniqueid,
                    "sendToDId": currentSelectedAppointment.doctor.uniqueid,
                    "metadata": {
                        "viewByClinicAt": null,
                        "viewByPatientAt": null,
                    },
                    "dependantId": Number(currentSelectedAppointment.dependant.uniqueid),
                    isMobile: isWebView !== null && isWebView !== "" ? true : false
                };
                const objPrescreen = {
                    "appointmentId": Number(currentSelectedAppointment.uniqueid),
                    "senderId": Number(patientData.uniqueId),
                    "sender": CHAT.SENDER.PATIENT,
                    "action": CHAT.ACTION.PVBP,
                    "actionType": CHAT.ACTION_TYPE.PRESCREENING_STATUS,
                    "text": 'Verified automatically from patient side.',
                    "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "sendToCId": currentSelectedAppointment.clinic.uniqueid,
                    "sendToDId": currentSelectedAppointment.doctor.uniqueid,
                    "dependantId": Number(currentSelectedAppointment.dependant.uniqueid),
                    isMobile: isWebView !== null && isWebView !== "" ? true : false,
                    dateTime: {
                        date: currentSelectedAppointment.date,
                        time: currentSelectedAppointment.time
                    }
                };
                console.log('obj', obj);
                console.log('objPrescreen', objPrescreen);
                
                firebase.firestore().collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
                    .then(async () => {
                        if (passPrescreenAuto && patientData) {
                            try {
                                await firebase.firestore().collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(objPrescreen);
                            } catch (err) {

                            }
                        }
                        let webView: any = new URLSearchParams(location.search).get('webview');
                        if (webView !== null && webView !== "") {
                            dispatch(fetchLoadingSuccess(false));
                            setIsLoading(false);
                            history.replace('/exitapp');
                        } else {
                            console.log("Document successfully written!");
                            if (medium === 'email' || medium === 'sms') {
                                // executeWhenMediumEmailSms();
                                setPrescreeingToState();
                                // // setAllNoCheckBox(false);
                                // setTimeout(() => {
                                //     handleSuccessPreScreening();
                                // }, 2000);
                                dispatch(fetchLoadingSuccess(false));
                                setIsPreScreeningDone(true);
                                dispatch(userLogout());
                            } else {
                                setPrescreeingToState();
                                // setAllNoCheckBox(false);
                                setTimeout(() => {
                                    handleSuccessPreScreening();
                                }, 2000);
                            }
                        }
                    })
                    .catch((error: any) => {
                        dispatch(userLogout());
                        history.push('/');
                        console.error("Error writing document: ", error);
                    });
            }
        }
    }

    const executeWhenMediumEmailSms = () => {
        // dispatch(userLogout());
        setPrescreeingToState();
        setAllNoCheckBox(false);
        handleHidePreScreeningModal();
        // window.close();
    }
    return (
        <Modal centered className="newcommon model_covid modal-background-blur" backdrop={false} show={completeScreeningModal} onHide={() => {
            if (medium === 'email' || medium === 'sms') {
                // executeWhenMediumEmailSms();
                setPrescreeingToState();
                setAllNoCheckBox(false);
                handleHidePreScreeningModal();
            } else {
                setPrescreeingToState();
                setAllNoCheckBox(false);
                handleHidePreScreeningModal();
            }
        }}>
            <Modal.Header>
                <div className="modal-title">
                    {(isPreScreeningDone) ? 'Pre-screening Submitted Successfully' : 'Pre-screening Attempted'}
                </div>
                {
                    (medium !== 'email' && medium !== 'sms' && !isWebView) &&
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                        if (medium === 'email' || medium === 'sms') {
                            // executeWhenMediumEmailSms();
                            setPrescreeingToState();
                            setAllNoCheckBox(false);
                            handleHidePreScreeningModal();
                        } else {
                            setPrescreeingToState();
                            setAllNoCheckBox(false);
                            handleHidePreScreeningModal();
                        }
                    }}>
                    </button>
                }
            </Modal.Header>
            {
                (isPreScreeningDone) ?
                    (medium === 'email' || medium === 'sms') &&
                    <Modal.Body>
                        <p>Thank you for completing pre-screening. We have sent your completed pre-screening form to the clinic admin for review. You do not need to take any further action unless advised by the clinic admin.</p>
                        <p className='text-danger'>Note: Please close this tab from the browser. </p>
                    </Modal.Body>
                    :

                    <Modal.Body>
                        {
                            (currentSelectedAppointment?.metadata?.appointment?.prescreening && currentSelectedAppointment?.metadata?.appointment?.prescreening?.attempt && currentSelectedAppointment?.metadata?.appointment?.prescreening?.verifiedOn) ?
                                <>
                                    <p>
                                        Oops !! looks like you are trying to complete pre-screening for an appointment which appears to be already verified, kindly get in touch with clinic admin for further assistance.
                                    </p>
                                    {(medium === 'email' || medium === 'sms') && <p className='text-danger'>Note: Please close this tab from the browser. </p>}
                                </>

                                :
                                (currentSelectedAppointment.metadata.appointment.prescreening && currentSelectedAppointment.metadata.appointment.prescreening.attempt && !currentSelectedAppointment.metadata.appointment.prescreening.verifiedOn) ?
                                    <>
                                        <p>
                                            Oops !! looks like you are trying to complete pre-screening for an appointment which appears to be in wating for approval at clinic admin, kindly get in touch with clinic admin for further assistance.
                                        </p>
                                        {(medium === 'email' || medium === 'sms') && <p className='text-danger'>Note: Please close this tab from the browser. </p>}
                                    </>
                                    :
                                    (
                                        !(currentSelectedAppointment.date
                                            && currentSelectedAppointment.time
                                            && Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) > 0 &&
                                            Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) <= 1440
                                        )
                                    ) &&
                                    <>
                                        <p>Oops! Looks like you are trying to complete pre-screening for an appointment which appears to be in the past or future. You can complete pre-screening 1 to 23 hours before your appointment. Get in touch with the clinic for further assistance.</p>
                                        {(medium === 'email' || medium === 'sms') && <p className='text-danger'>Note: Please close this tab from the browser. </p>}
                                    </>
                        }
                        {
                            !(currentSelectedAppointment?.metadata?.appointment?.prescreening && currentSelectedAppointment?.metadata?.appointment?.prescreening?.attempt && currentSelectedAppointment?.metadata?.appointment?.prescreening?.verifiedOn) &&
                            !(currentSelectedAppointment.metadata.appointment.prescreening && currentSelectedAppointment.metadata.appointment.prescreening.attempt && !currentSelectedAppointment.metadata.appointment.prescreening.verifiedOn) &&
                            (currentSelectedAppointment.date
                                && currentSelectedAppointment.time
                                && Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) >= 0 &&
                                Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) <= 1440
                                )
                            &&
                            (prescreeningQuestions && prescreeningQuestions.length > 0 &&
                                currentSelectedAppointment.date
                                && currentSelectedAppointment.time
                                && Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) >= 0 &&
                                Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) <= 1440
                                ) &&
                            prescreeningQuestions.map((question: any, index: number) => {
                                if (question.options) {
                                    return (
                                        <div className="covdque" key={index + Math.random()}>
                                            {index + 1}. {prescreeningQuestions[index].question}
                                            <div className="deslist">
                                                <ul className="list-group">
                                                    {
                                                        question.options.map((ques: any, qIndex: number) => {
                                                            const findOption = question.answer.findIndex((option: any, oIndex: number) => {
                                                                return ques === option;
                                                            });
                                                            return (
                                                                <li className="list-group-item" key={qIndex + Math.random()}>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" id={ques} name={ques} className="custom-control-input"
                                                                            value={ques}
                                                                            checked={findOption !== -1 ? true : false}
                                                                            onChange={(e) => {
                                                                                addRemoveOptions(index, e.target.value);
                                                                            }}
                                                                        />
                                                                        <label title="" htmlFor={ques} className="custom-control-label">{ques}</label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                }
                                if (prescreeningQuestions.length - 1 === index) {
                                    return (
                                        <>
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="allNoCheckBox" name="allNoCheckBox" checked={allNoCheckBox} value={allNoCheckBox ? "yes" : "no"} onChange={(e) => {
                                                    setAllNoCheckBox(!allNoCheckBox)
                                                    setPrescreeningQuestions(prescreeningQuestions.map((ques: any, index: number) => {
                                                        if (prescreeningQuestions.length - 1 === index) {
                                                            return {
                                                                ...ques,
                                                                answer: allNoCheckBox === true ? "no" : "no",
                                                            }
                                                        }
                                                        return {
                                                            ...ques,
                                                            answer: allNoCheckBox === false ? "no" : ques.oldAnswer,
                                                        }
                                                    })
                                                    )
                                                }}></input>
                                                <label className="form-check-label" htmlFor="allNoCheckBox"> &nbsp; Please check to select all as NO.</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="confirmCheckbox" name="confirmCheckbox" checked={question.answer === "yes" ? true : false}
                                                    value={question.answer} onChange={(e) => {
                                                        yesNoClickPreScreening(index, question.answer === "yes" ? "no" : "yes");
                                                    }}></input>
                                                <label htmlFor="confirmCheckbox" className="form-check-label"> &nbsp; {question.question}</label>
                                            </div>
                                        </>
                                    )
                                }
                                return (
                                    <div key={index + Math.random()}>
                                        <div className="covdque">
                                            {index + 1}. {question.question}
                                        </div>
                                        <div className="ysnrad">
                                            <div className="form-check-inline yes-no">
                                                <input type="radio" name={"has_received" + index} id={"received_yes" + index} value={question.answer}
                                                    checked={question.answer === 'yes'}
                                                    onChange={(e) => {
                                                        yesNoClickPreScreening(index, 'yes');
                                                    }}
                                                />
                                                <label className="l-yes" htmlFor={"received_yes" + index}><img src={require('../../../assets/images/yes-icon.svg')} /></label>
                                                <span>Yes</span>
                                            </div>
                                            <div className="form-check-inline yes-no">
                                                <input type="radio" name={"has_received" + index} id={"received_no" + index} value={question.answer}
                                                    checked={question.answer === 'no'}
                                                    onChange={(e) => {
                                                        yesNoClickPreScreening(index, 'no');
                                                    }} />
                                                <label className="l-no" htmlFor={"received_no" + index}><img src={require('../../../assets/images/no-icon.svg')} /></label>
                                                <span>No</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            !(currentSelectedAppointment?.metadata?.appointment?.prescreening && currentSelectedAppointment?.metadata?.appointment?.prescreening?.attempt && currentSelectedAppointment?.metadata?.appointment?.prescreening?.verifiedOn) &&
                            !(currentSelectedAppointment.metadata.appointment.prescreening && currentSelectedAppointment.metadata.appointment.prescreening.attempt && !currentSelectedAppointment.metadata.appointment.prescreening.verifiedOn) &&
                            (currentSelectedAppointment.date
                                && currentSelectedAppointment.time && Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) >= 0 &&
                                Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) <= 1440
                                )
                            &&
                            (prescreeningQuestions && prescreeningQuestions.length > 0 &&
                                currentSelectedAppointment.date
                                && currentSelectedAppointment.time && Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) >= 0 &&
                                Math.floor(moment.duration(moment(currentSelectedAppointment.date + " " + currentSelectedAppointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asMinutes()) <= 1440
                                ) &&
                            <div className="d-flex justify-content-end mt-3">
                                <button type="button" className="btn btn-dark px-3" disabled={isLoading}
                                    onClick={(e) => {
                                        onFinishClick();
                                    }}>Finish</button>
                            </div>
                        }
                        {
                            !(prescreeningQuestions && prescreeningQuestions.length > 0) &&
                            <label className="text text-danger">No questions avaiable</label>
                        }
                    </Modal.Body>
            }
        </Modal>
    )
}
