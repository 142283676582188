import React, { useEffect, useState } from "react";
import { SharedOffCanvas } from "../../v2/components/sharedOffCanvas/SharedOffCanvas";
import { ReferFriend } from "../../v2/components/dashboardNew/xcarepoints/ReferFriend";
import { Nav, Tab } from "react-bootstrap";
import { AvailableOffers } from "./AvailableOffers";
import { MyOffers } from "./MyOffer";
import { getXcareOffersFilters } from "../../v2/services/xcareOffers";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../store/loadingIndicator/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Offers = () => {
  const dispatch = useDispatch();
  const [isShowReferFriend, setIsShowReferFriend] = useState(false);
  const [eventKey, setEventKey] = useState("available");
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");

  const getXcareoffersData = () => {
    dispatch(fetchLoadingPending());
    getXcareOffersFilters()
      .then((success: any) => {
        dispatch(fetchLoadingSuccess(false));
        if (success.status.error === false) {
          setRegions(success.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err?.msg);
        dispatch(fetchLoadingSuccess(false));
      });
  };

  useEffect(() => {
    getXcareoffersData();
  }, []);

  useEffect(() => {
    console.log("regions", regions);
  }, [regions]);

  useEffect(() => {
    console.log("selectedRegion", selectedRegion);
  }, [selectedRegion]);
  return (
    <>
      <div
        className="main-middle-sec d-flex flex-column"
        style={{ minHeight: "90vh" }}
      >
        {/* <div className="container upcoming-box">
                    <div className="row justify-content-center h-100">
                        <div className="col-md-6 align-self-center">
                            <div className="text-center">
                                <div className="pin-letter">                                     
                                    <img src={require('../../assets/images/announce.png')} className="img-responsive letter-icon mb-3" style={{
                                        maxHeight: 400
                                    }}/>
                                    <h3>Stay tuned, we will rollout <b>Offers</b> soon.</h3>
                                    <h6>
                                        More you can do now is <button type="button" className="btn btn-link p-0 fw-normal fs-7 me-3" onClick={() => { setIsShowReferFriend(true) }}>Refer a Friend</button>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        <>
          <div className="row justify-content-end mx-0">
            <div className="col-md-3 my-2">
              <div className="row align-items-center justify-content-center">
              <div className="col-3 col-md-4 col-lg-3">
                <label
                  className="form-label"
                  style={{fontWeight: "bold" }}
                >
                  Region:
                </label>
              </div>
              <div className="col-6">
                <select
                  className="form-control"
                  name="region"
                  placeholder="region"
                  onChange={(e: any) => {
                    setSelectedRegion(e.target.value);
                  }}
                  value={selectedRegion}
                >
                  <option value="">Select</option>
                  {regions.map((item: any, index: any) => (
                    <option value={item.state} key={item.state + index}>
                      {item.state}
                    </option>
                  ))}
                </select>
                </div>
                </div>
            </div>
          </div>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="upcoming"
            activeKey={eventKey}
            onSelect={(e: any) => {
              console.log("on select", e);
              setEventKey(e);
            }}
          >
            <Nav className="cs_tab mb-3 border-bottom">
              <Nav.Item>
                <Nav.Link eventKey="available">Available</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="myoffer">My Offers</Nav.Link>
              </Nav.Item>
            </Nav>

            <div>
              <Tab.Content>
                <Tab.Pane eventKey="available">
                  <AvailableOffers selectedRegion={selectedRegion} />
                </Tab.Pane>
                <Tab.Pane eventKey="myoffer">
                  <MyOffers selectedRegion={selectedRegion} />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </>
      </div>
      <SharedOffCanvas
        isOpen={isShowReferFriend}
        handleClose={() => {
          setIsShowReferFriend(false);
        }}
        Children={ReferFriend}
        handleSuccess={() => {
          setIsShowReferFriend(false);
        }}
      />
    </>
  );
};
export default Offers;
