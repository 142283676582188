import React from 'react'

export const Footer = () => {
    let currentYear = new Date().getFullYear();
    return (
        <div className="container-fluid container-xl px-lg-4">
            <footer className="footer mt-auto py-3 border-top bg-white text-center text-lg-end">
                <div>Copyright © {currentYear} XCARE PRO. All rights reserved.</div>
            </footer>
        </div>
    )
}
