export const maskUSPhoneInput = (text: string) => {
    let x: any = text.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    text = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return text;
}
export const takeOnlyDigitAndPlus = (maskedText: string) => {
    return maskedText.replace(/[^0-9+]/g, "");
}
export const takeOnlyDigit = (maskedText: string) => {
    return maskedText.replace(/[^0-9]/g, "");
}
export const addressMaster: any = {
    countries: ["Canada", "United States of America"],
    states: {
        Canada: ["Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Northwest Territories", "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon"],
        "United States of America": ["Alabama", "Alaska", "American Samoa", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "District Of Columbia", "Florida", "Georgia", "Guam", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Northern Mariana Islands", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "United States Minor Outlying Islands", "Utah", "Vermont", "Virgin Islands", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"]
    },
    postalRegexByCountry: {
        Canada: /^[a-zA-Z]{1}\d{1}[a-zA-Z]{1} ?\d{1}[a-zA-Z]{1}\d{1}$/,
        "United States of America": /^[0-9]{5}(?:-[0-9]{4})?$/
    }
}
export const getImageExtension = (filename: string) => {
    return filename.split('.').pop();
}

export const checkImageExtension = (ext: string) => {
    if (ext === "jpeg") {
        return true;
    } else if (ext === "jpg") {
        return true;
    } else if (ext === "png") {
        return true;
    } else if (ext === "pdf") {
        return true;
    }
    return false;
}

export function useQuery(location: any): any {
    return new URLSearchParams(location.search);
}

export const timeConvert = (time: any) => {
    try {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
            if (time[0] < 10) {
                time[0] = "0" + time[0];
            }
        }
        return time.join(''); // return adjusted time or original string
    } catch (err) {
        return '';
    }
}
export const weekDay = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

/**
 * 
 * @param givenNumber - any number
 * @param roundingFactor must be positive integer
 */
export const roundNumberWithRoundingFactorDecimalFix = (givenNumber: any, roundingFactor: number) => {
    if (isNaN(givenNumber)) {
        return '';
    } else if (isNaN(roundingFactor)) {
        return '';
    }
    let roundBy: string = "1";
    for (let start = 0; start < Math.floor(roundingFactor); start++) {
        roundBy += "0";
    }
    return Number(Math.round((Number(givenNumber) + Number.EPSILON) * Number(roundBy)) / Number(roundBy)).toFixed(roundingFactor);
}