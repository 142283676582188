import { ProviderSearchRequest, ProviderSearchPendingAction, PROVIDER } from "../store/provider/types";
import { fetchLoadingPending, fetchLoadingSuccess } from "../store/loadingIndicator/actions";
import { put, takeEvery } from "redux-saga/effects";
import { searchProviderError, searchProviderSuccess } from "../store/provider/actions";
import Axios from "axios";
import configs from "../configs/apiConfigs";
import { attachHeader } from "../utils";

function execute(request: ProviderSearchRequest) {
    return Axios.get(configs.API_URL + "clinic/" + request.uniqueId + "/search/", {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* searchProviderPendingRun(action: ProviderSearchPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(searchProviderError(response.data));
            } else {
                yield put(searchProviderSuccess(response.data));
            }
        } else {
            yield put(searchProviderError(response));
        }
    }
    if (error) {
        yield put(searchProviderError(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export function* searchProviderPendingWatcher() {
    yield takeEvery(PROVIDER.SEARCH_PROVIDER_PENDING, searchProviderPendingRun);
}