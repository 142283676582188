import React from 'react';
import './MyCoverage.scss';
class MyCoverage extends React.Component {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <>



                <div className="main-middle-sec">
                    <div className="container upcoming-box">
                        <div className="row justify-content-center h-100">
                            <div className="col-sm-6 align-self-center">
                                <div className="text-center">
                                    <div className="pin-letter">
                                        <img src={require('../../assets/images/announce.png')} className="img-responsive letter-icon mb-3" />
                                        <h3>Stay tuned, we will rollout <b>My-coverage</b> soon.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/*  <section className="claim-reward pantient-view">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-10 col-lg-7">
                                <div className="form-group have_insurance">
                                    <div className="form-title-txt">Do you have insurance?</div>
                                    <div className="form-check-inline radio-box">
                                        <input type="radio" name="is_child" id="child_yes" />
                                        <label className="l-yes" htmlFor="child_yes">
                                            <span className="icon-value"><img src={require('../../assets/images/yes-icon.svg')} /></span>
                                            <span className="radio-value">Yes</span>
                                        </label>
                                    </div>
                                    <div className="form-check-inline radio-box">
                                        <input type="radio" name="is_child" id="child_no" />
                                        <label className="l-no" htmlFor="child_no">
                                            <span className="icon-value"><img src={require('../../assets/images/no-icon.svg')}/></span>
                                            <span className="radio-value">No</span>
                                        </label>

                                    </div>
                                </div>
                            </div>  
                            <div className="col-md-10 col-lg-7">
                                <div className="form-outer-box">
                                    <div className="form-title-txt">Insurance provider and plan</div>
                                    <div className="form-group">
                                        <label>Insurance provider</label>
                                        <select className="form-control">
                                            <option>Option 1</option>
                                            <option>Option 2</option>
                                            <option>Option 3</option>
                                        </select>
                                    </div>   
                                    <div className="form-group">
                                        <label>Insurance Plan</label>
                                        <select className="form-control">
                                            <option>Option 1</option>
                                            <option>Option 2</option>
                                            <option>Option 3</option>
                                        </select>
                                    </div> 
                                    <div className="form-group">
                                        <label>Other</label>
                                        <input type="text" className="form-control"/>
                                    </div> 
                                    <button className="btn btn-save btn-custom mr-3">Save</button>
                                    <button className="btn btn-cancel btn-custom">Back</button>
                                </div>
                            </div>
                            <div className="col-md-10 col-lg-8">
                                <div className="form-outer-box add-coverage">
                                    <div className="form-title-txt">Add your coverage details</div>
                                    <div className="form-value-box media">
                                        <div className="align-self-center">Dentist</div>
                                        <div className="media-body text-center mx-2">
                                            <div>$4500<span>Total</span></div>
                                            <div>$1500<span>Used</span></div>
                                        </div>
                                        <div className="align-self-center">
                                            <button className="varification-status verified">Edit</button>
                                            <button className="varification-status verify">Delete</button>
                                        </div>
                                    </div>
                                    <div className="form-value-box media">
                                        <div className="align-self-center">Dentist</div>
                                        <div className="media-body text-center mx-2">
                                            <div>$4500<span>Total</span></div>
                                            <div>$1500<span>Used</span></div>
                                        </div>
                                        <div className="align-self-center">
                                            <button className="varification-status verified">Edit</button>
                                            <button className="varification-status verify">Delete</button>
                                        </div>
                                    </div>
                                    <div className="form-row mt-4">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="fname">Select Specialty</label>
                                            <select className="form-control">
                                                <option>Dentist</option>
                                                <option>Dentist</option>
                                                <option>Dentist</option>                                
                                            </select>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="lname">Last Name</label>
                                            <input type="text" className="form-control" value="$1500" />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="fname">Email</label>
                                            <input type="email" className="form-control" value="$4500" />
                                        </div>                       
                                    </div>
                                    <button className="btn btn-save btn-custom mr-3">Add coverage</button>
                                    <button className="btn btn-cancel btn-custom">Back</button>
                                </div>
                            </div>
                            <div className="col-md-10 col-lg-7">
                                <div className="form-outer-box">
                                    <div className="form-title-txt">Add family member</div>
                                    <div className="form-value-box media">
                                        <div className="media-body mr-2">
                                            Rishabh desai
                                        </div>
                                        <div className="align-self-center">
                                            <button className="varification-status verified">Edit</button>
                                            <button className="varification-status verify">Delete</button>
                                        </div>
                                    </div>
                                    <div className="form-value-box media">
                                        <div className="media-body mr-2">
                                            Rishabh desai
                                        </div>
                                        <div className="align-self-center">
                                            <button className="varification-status verified">Edit</button>
                                            <button className="varification-status verify">Delete</button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="fname">First Name</label>
                                            <input type="text" className="form-control" value="Rishabh" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="lname">Last Name</label>
                                            <input type="text" className="form-control" value="Desai" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="fname">Email</label>
                                            <input type="email" className="form-control" value="rishabh@srm.com" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="lname">Phone Number</label>
                                            <input type="text" className="form-control" value="+1 647-123-4545" />
                                        </div>
                                    </div>
                                    <button  className="btn btn-save btn-custom mr-3" >Add to my family</button>
                                    <button  className="btn btn-cancel btn-custom" >Back</button>
                                    <button className="float-right ml-3 edit-btn align-self-center btn">Skip</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                */}
            </>
        )
    }
}
export default MyCoverage;