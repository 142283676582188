import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ReactInputMask from "react-input-mask";
import { communicationUpdate, profileUpdate } from "../../../services/profile";
import { getCookie } from "../../../utils/cookies";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { showWaitingPresc, userLogout } from "../../../../store/auth/actions";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { Spinner } from "react-bootstrap";
import { patientDataPending } from "../../../../store/patientData/actions";
export const SigninmobileEdit = ({
  handleModalClose,
  handleSuccess,
  phone,
  title,
  user,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector(
    (state: any) => state.patientData.patientDataResponse,
    shallowEqual
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    setError,
    handleSubmit,
    setValue,
    getValues,
    errors,
    trigger,
    control,
    watch,
  } = useForm({
    defaultValues: {
      phone: "",
      cphone: "",
      globalError: "",
    },
  });

  const handleUpdatePhone = (data: any) => {
    if (data.phone === phone) {
      setError("globalError", {
        type: "custom",
        message: "Old mobile and new mobile both are same.",
      });
      return;
    }
    if (data.phone !== data.cphone) {
      setError("globalError", {
        type: "custom",
        message: "Mobile and confirm mobile not match.",
      });
      return;
    }
    const login: any = getCookie();
    setIsLoading(true);
    if (title === "Sign in") {
      profileUpdate(
        {
          type: "phone",
          phone: data.phone,
        },
        login.xpr_user_id
      )
        .then((success) => {
          setIsLoading(false);
          if (success && success.status && success.status.error === false) {
            toast.success("Mobile number updated successfully");
            handleSuccess();
            dispatch(userLogout());
            history.push("/login");
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.success("Something went wrong.");
        });
    } else {
      communicationUpdate({
        type: "phone",
        phone: data.phone,
        dependantId: user.dependantId,
        guarantorId: user.guarantor,
      })
        .then((success) => {
          setIsLoading(false);
          if (success && success.status && success.status.error === false) {
            toast.success("Mobile number updated successfully");
            handleSuccess();
            dispatch(
              patientDataPending({
                uniqueId: userData.uniqueId,
              })
            );
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.success("Something went wrong.");
        });
    }
  };
  return (
    <>
      <div className="offcanvas-action d-flex">
        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7 ms-auto"
          // data-bs-dismiss="offcanvas" aria-label="Close"
          onClick={() => {
            handleModalClose();
          }}
        >
          Close
        </button>
      </div>

      <div className="offcanvas-header">
        <h5 className="offcanvas-title">{title} Mobile Number</h5>
      </div>

      <div className="offcanvas-body">
        <div className="current_data border-bottom pb-2 mb-2">
          <div>Current {title} Mobile Number</div>
          <span className="opacity-50 fs-7">+1 {phone ? phone : ""}</span>
        </div>
        <div className="mb-3">
          <label className="form-label">
            New {title === "Sign in" ? "sign in" : "communication"} mobile
            number
          </label>
          <Controller
            className="form-control"
            name="phone"
            control={control}
            mask="9999999999"
            rules={{ required: true, minLength: 10, maxLength: 10 }}
            as={ReactInputMask}
          ></Controller>
          {errors && errors.phone && errors.phone.type === "required" && (
            <div className="form-error">Mobile number is required</div>
          )}
          {errors &&
            errors.cphone &&
            (errors.phone?.type === "minLength" ||
              errors.phone?.type === "maxLength") && (
              <div className="form-error">Mobile number is invalid</div>
            )}
        </div>
        <div className="mb-3">
          <label className="form-label">
            Confirm new {title === "Sign in" ? "sign in" : "communication"}{" "}
            mobile number
          </label>
          <Controller
            className="form-control"
            name="cphone"
            control={control}
            mask="9999999999"
            rules={{ required: true, minLength: 10, maxLength: 10 }}
            as={ReactInputMask}
          ></Controller>
          {errors && errors.cphone && errors.cphone.type === "required" && (
            <div className="form-error">Confirm mobile number is required</div>
          )}
          {errors &&
            errors.cphone &&
            (errors.cphone.type === "minLength" ||
              errors.cphone.type === "maxLength") && (
              <div className="form-error">Confirm mobile number is invalid</div>
            )}
          <input type="hidden" name="globalError" ref={register} />
          {errors &&
            errors.globalError &&
            errors.globalError.type === "custom" && (
              <div className="text-danger error">
                {errors.globalError.message}
              </div>
            )}
        </div>
        {title === "Sign in" && (
          <div className="alert alert-danger text-center py-2">
            Note: For security reasons you will be asked to sign in again
          </div>
        )}
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-dark w-100 fw-normal"
            onClick={handleSubmit(handleUpdatePhone)}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </>
  );
};
