import { takeEvery } from 'redux-saga/effects';
import { appointmentDetailError, appointmentDetailSuccess, assignAppointmentError, assignAppointmentSuccess, changeAppointmentRemiderDetailError, changeAppointmentRemiderDetailSuccess, writeReviewError, writeReviewSuccess } from './../store/appointments/actions';
import { put } from 'redux-saga/effects';
import Axios from 'axios';
import { AppointmentDetailRequest, AppointmentDetailPendingAction, APPOINTMENT, ChangeAppointmentRemiderRequest, ChangeAppointmentRemiderPendingAction, WriteReviewRequest, WriteReviewPendingAction, AssignAppointmentPendingAction, AssignAppointmentRequest } from './../store/appointments/types';
import apiConfigs from '../configs/apiConfigs';
import { attachHeader } from '../utils';
import { fetchLoadingPending, fetchLoadingSuccess } from '../store/loadingIndicator/actions';
function execute(request: AppointmentDetailRequest) {
    return Axios.get(apiConfigs.API_URL + "patient/" + request.appointmentUniqueId + "/appointment/details/", {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* appointmentDetailPendingRun(action: AppointmentDetailPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(appointmentDetailError(response.data));
            } else {
                yield put(appointmentDetailSuccess(response.data));
            }
        } else {
            yield put(appointmentDetailError(response));
        }
    }
    if (error) {
        yield put(appointmentDetailError(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export function* appointmentDetailPendingWatcher() {
    yield takeEvery(APPOINTMENT.LOAD_PENDING, appointmentDetailPendingRun);
}

/* Change Appointment Reminder */
function executeChangeAppoitmentRemider(request: ChangeAppointmentRemiderRequest) {
    const reminder = request.reminderStatus === 1 ? "on" : "off";
    return Axios.get(
        apiConfigs.API_URL + "patient/" + request.appointmentUniqueId + "/appointment/reminder/" + reminder + "/", {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* changeAppointmentReminderPendingRun(action: ChangeAppointmentRemiderPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeChangeAppoitmentRemider(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(changeAppointmentRemiderDetailError(response.data));
            } else {
                yield put(changeAppointmentRemiderDetailSuccess({
                    ...response.data,
                    reminder: action.payload.reminderStatus
                }));
            }
        } else {
            yield put(changeAppointmentRemiderDetailError(response));
        }
    }
    if (error) {
        yield put(changeAppointmentRemiderDetailError(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export function* changeAppointmentReminderPendingWatcher() {
    yield takeEvery(APPOINTMENT.CHANGE_APPOINTMENT_REMINDER_PENDING, changeAppointmentReminderPendingRun);
}

/* Write review for appointemnt */
function executeWriteReview(request: WriteReviewRequest) {
    const formData = new FormData();
    formData.append('score', request.score.toString());
    formData.append('comment', request.comment);
    formData.append('appointment', request.appointment.toString());
    return Axios.post(
        apiConfigs.API_URL + "patient/" + request.patientUniqueId + "/post-review/", formData, {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* writeReviewPendingRun(action: WriteReviewPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeWriteReview(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(writeReviewError(response.data));
            } else {
                yield put(writeReviewSuccess(response.data));
            }
        } else {
            yield put(writeReviewError(response));
        }
    }
    if (error) {
        yield put(writeReviewError(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export function* writePendingWatcher() {
    yield takeEvery(APPOINTMENT.WRITE_REVIEW_PENDING, writeReviewPendingRun);
}

function executeAssignAppointment(request: AssignAppointmentRequest) {
    const body = {
        email: request.email
    }
    return Axios.post(
        apiConfigs.API_URL + "patient/" + request.appointmentUniqueId + "/assign/", body, {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* assignAppointmentPendingRun(action: AssignAppointmentPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeAssignAppointment(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(fetchLoadingSuccess(false));
                yield put(assignAppointmentError(response.data));
            } else {
                yield put(assignAppointmentSuccess({ ...response.data, email: action.payload.email }));
            }
        } else {
            yield put(fetchLoadingSuccess(false));
            yield put(assignAppointmentError(response));
        }
    }
    if (error) {
        yield put(fetchLoadingSuccess(false));
        yield put(assignAppointmentError(error));
    }
    // yield put(fetchLoadingSuccess(false));
}

export function* assignAppointmentPendingWatcher() {
    yield takeEvery(APPOINTMENT.ASSIGN_APPOINTMENT, assignAppointmentPendingRun);
}