import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import { KeyboardDatePicker } from "@material-ui/pickers";
import $ from "jquery";
import { addMember, editMember } from "../../../services/myFamily";
import { toast } from "react-toastify";
import { Button, Modal, Spinner } from "react-bootstrap";
export const AddEditMemberForm = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    setError,
    handleSubmit,
    setValue,
    getValues,
    errors,
    trigger,
    control,
    watch,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      gender: "Male",
      relation: "Spouse",
      dob: null,
      contactmethod: "",
      reminder: "",
      signin: "no",
    },
  });

  useEffect(() => {
    if (props.member) {
      setValue("firstName", props.member.firstName);
      setValue("lastName", props.member.lastName);
      setValue("email", props.member.email);
      setValue("phone", props.member.phone);
      setValue("gender", props.member.gender);
      setValue("dob", props.member.dob);
      setValue("contactmethod", props.member.contactMethod);
      setValue("relation", props.member.relation);
      // setValue('contactmethod', props.member.firstName);
    }
  }, [props.member]);

  const handleUpdate = (data: any) => {
    if (!getValues("dob")) {
      setError("dob", { type: "required", message: "dob is requried." });
    }
    let requestedData = {
      fname: getValues("firstName"),
      lname: getValues("lastName"),
      email: getValues("email"),
      phone: getValues("phone"),
      dob: moment(getValues("dob")).format("YYYY-MM-DD"),
      gender: getValues("gender"),
      contactMethod: getValues("contactmethod")
        ? getValues("contactmethod")
        : "",
      reminder: "",
      relation: getValues("relation"),
      signIn: getValues("signin"),
    };
    if (props.member) {
      setIsLoading(true);
      editMember(requestedData, props.member.id)
        .then((success) => {
          setIsLoading(false);
          if (success && success.status && success.status.error === false) {
            toast.success("Member updated successfully");
            props.handleSuccess();
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      addMember(requestedData)
        .then((success) => {
          setIsLoading(false);
          if (success && success.status && success.status.error === false) {
            toast.success("Member add successfully");
            props.handleSuccess();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.status && error.status.msg) {
            toast.error(error.status.msg);
            return;
          }
        });
    }
  };
  const handleModalClose = () => {
    props.handleModalClose();
  };
  const handleClearAll = () => {
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("email", "");
    setValue("phone", "");
    setValue("gender", "");
    setValue("dob", "");
    setValue("contactmethod", "");
    setValue("relation", "");
  };

  return (
    <div className="my_family_drawer d-flex flex-column overflow-hidden">
      <div className="offcanvas-action d-flex">
        {/* {isLoading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <button
            type="button"
            className="btn btn-link p-0 fw-normal fs-7"
            onClick={handleSubmit(handleUpdate)}
          >
            Save
          </button>
        )} */}

        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7 ms-auto"
          onClick={() => {
            handleModalClose();
          }}
        >
          Cancel
        </button>
      </div>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">
          {props.title === "Add" ? "Add new member" : "Edit Member"}
        </h5>
      </div>
      <div className="offcanvas-body">
        <div className="add_member_form">
          <div className="mb-3">
            <label className="form-label">First Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Patient first name"
              name="firstName"
              ref={register({ required: true })}
            />
            {errors &&
              errors.firstName &&
              errors.firstName.type === "required" && (
                <div className="form-error">First name is required</div>
              )}
          </div>
          <div className="mb-3">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              className="form-control"
              id=""
              placeholder="Patient last name"
              name="lastName"
              ref={register({ required: true })}
            />
            {errors &&
              errors.firstName &&
              errors.firstName.type === "required" && (
                <div className="form-error">Last name is required</div>
              )}
          </div>
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id=""
              placeholder="Enter email"
              name="email"
              ref={register({
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            {errors && errors.email && errors.email.type === "required" && (
              <div className="form-error">Email is required</div>
            )}
            {errors && errors.email && errors.email.type === "pattern" && (
              <div className="form-error">Email is invalid</div>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Mobile #</label>
            <Controller
              className="form-control"
              name="phone"
              control={control}
              mask="9999999999"
              rules={{ required: true, minLength: 10, maxLength: 10 }}
              as={ReactInputMask}
            ></Controller>
            {errors && errors.phone && errors.phone.type === "required" && (
              <div className="form-error">Mobile number is required</div>
            )}
            {errors && errors.phone && errors.phone.type === "minLength" && (
              <div className="form-error">Mobile number is invalid</div>
            )}
            {errors && errors.phone && errors.phone.type === "maxLength" && (
              <div className="form-error">Mobile number is invalid</div>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Gender</label>
            <select
              className="form-select"
              name="gender"
              ref={register({ required: true })}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            {errors && errors.gender && errors.gender.type === "required" && (
              <div className="form-error">Gender is required</div>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Relation</label>
            <select
              className="form-select"
              name="relation"
              ref={register({ required: true })}
            >
              <option value="Spouse">Spouse</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
            </select>
            {errors &&
              errors.relation &&
              errors.relation.type === "required" && (
                <div className="form-error">Relation is required</div>
              )}
          </div>
          <div className="mb-3 miuif_margin">
            <label className="form-label">Date of birth</label>
            <Controller
              name="dob"
              control={control}
              rules={{ required: "Date of birth is required" }}
              // defaultValue={moment().subtract(1, "day")}
              render={(props) => (
                <KeyboardDatePicker
                  id="date-picker-dialog"
                  format="MM/DD/YYYY"
                  name="dob"
                  className="form-control"
                  value={props.value}
                  maxDate={moment().subtract(1, "day")}
                  onChange={(date: any) => props.onChange(date)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              )}
            ></Controller>
            {errors && errors.dob && errors.dob.type === "required" && (
              <div className="form-error">Date of birth is required</div>
            )}
          </div>
          <div className="mb-3">
            <div className="d-block">
              <label className="form-label">Preferred contact method</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="contactmethod"
                id="preEmail"
                value="email"
                ref={register({ required: true })}
              />
              <label className="form-check-label" htmlFor="preEmail">
                Email
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="contactmethod"
                id="preText"
                value="text"
                ref={register({ required: true })}
              />
              <label className="form-check-label" htmlFor="preText">
                Text
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="contactmethod"
                id="preBoth"
                value="email,text"
                ref={register({ required: true })}
              />
              <label className="form-check-label" htmlFor="preBoth">
                Text & Email
              </label>
            </div>
            {errors &&
              errors.contactmethod &&
              errors.contactmethod.type === "required" && (
                <div className="form-error">Contact method is required.</div>
              )}
          </div>
          {((props.member && !props.member.loginId) ||
            props.title === "Add") && (
            <div className="mb-3">
              <div className="d-block">
                <label className="form-label">
                  Create sign in for new member
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="signin"
                  id="signinYes"
                  value="yes"
                  ref={register({ required: true })}
                />
                <label className="form-check-label" htmlFor="signinYes">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="signin"
                  id="signinNo"
                  value="no"
                  ref={register({ required: true })}
                />
                <label className="form-check-label" htmlFor="signinNo">
                  No
                </label>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="offcanvas-action d-flex">
        {isLoading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <button
            type="button"
            className="btn btn-link p-0 fw-normal fs-7"
            onClick={handleSubmit(handleUpdate)}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
};
