import firebase from 'firebase';
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import { getCookie } from '../../../utils';
import configs from '../../configs/apiConfigs';
import { CHAT } from '../../constants';
import { getChatAppointmentList } from '../../services/patientchat';
import * as _ from 'lodash';
import $ from 'jquery';

let unsubscribe: any;
let apptListener: any;
export const Messages = (props: any) => {
    const [messagelist, setMessageList] = useState([]);
    const [chatMessage, setChatMessage] = useState('');
    const [appoitmentList, setAppointmentList] = useState([]);
    const userData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const myMessageListRef = React.useRef(messagelist);
    const setMyMessageList = (data: any) => {
        myMessageListRef.current = data;
        setMessageList(data);
    }
    const getMessages = () => {
        unsubscribe && unsubscribe();
        unsubscribe = firebase.firestore().collection(configs.CONVERSATION_DEV)
            .where('dependentUniqueId', '==', Number(userData.dependant[0].uniqueId))
            .where('clinicId', '==', Number(props.clinic.uniqueid))
            .orderBy("createdAt", 'asc')
            .onSnapshot((querySnapshot: any) => {
                const messages = querySnapshot.docs.map((item: any) => ({
                    ...item.data(), id: item.id,
                    sortTime:
                        moment(moment.unix(item.data()?.createdAt?.seconds)).format("DD-MM-YYYY hh:mm:ss")
                }));
                setMyMessageList(messages);
                getAppointmentCollectionDoc(props.clinic.uniqueid, userData.dependant[0].uniqueId);
                scrollToBottom();
                updateViewingAt(props.clinic.uniqueid);
            });
    }

    const getAppointmentCollectionDoc = (clinicId: number, dependantId: number) => {
        if (apptListener) {
            apptListener();
        }
        if (clinicId && dependantId) {
            console.log(clinicId, dependantId);
            apptListener = firebase.firestore().collection(configs.CHAT_COLLECTION_NAME)
                .where('sendToCId', '==', clinicId)
                .where('dependantId', '==', Number(dependantId))
                .orderBy('createdAt', 'asc')
                .onSnapshot((snapshot) => {
                    console.log("snapshot", snapshot.docs);
                    let docs = snapshot.docs.map((item: any) => ({
                        ...item.data(), id: item.id, sortTime:
                            moment(moment.unix(item.data()?.createdAt?.seconds)).format("DD-MM-YYYY hh:mm:ss")
                    }));
                    docs = docs.filter((item: any) => item.actionType !== CHAT.ACTION_TYPE.MESSAGE);
                    setMyMessageList([...myMessageListRef.current, ...docs]);
                    scrollToBottom();
                });
        }
    }

    const onMessageSendClick = (e: any) => {
        e.preventDefault();
        const obj = {
            "clinicId": Number(props.clinic.uniqueid),
            "senderId": Number(getCookie().xpr_user_id),
            "dependentUniqueId": Number(userData.dependant[0].uniqueId),
            "sender": CHAT.SENDER.PATIENT,
            "message": chatMessage,
            "metadata": {
                viewByClinicAt: null,
                viewByPatientAt: firebase.firestore.FieldValue.serverTimestamp()
            },
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
        };
        try {
            firebase.firestore().collection(configs.CONVERSATION_DEV).doc().set(obj)
                .then(() => {
                    setChatMessage('');
                })
                .catch((error: any) => {
                    console.log('error while send message', error);
                });
        } catch (error) {
            console.log('error while send message', error);
        }

    }

    const getApptList = async () => {
        getChatAppointmentList(props.clinic.uniqueid).then((success) => {
            if (success && success.status && success.status.error === false) {
                setAppointmentList(success.appointments);
            }
            return new Promise((res, rej) => {
                res('');
            })
        }).catch((err) => {
            return new Promise((res, rej) => {
                rej('');
            })
        });
    }

    useEffect(() => {
        if (props.clinic && props.clinic.uniqueid) {
            setMyMessageList([]);
            setAppointmentList([]);
            setChatMessage('');
            getApptList().then((success) => {
                getMessages();
            });
        }

    }, [props.clinic]);

    const updateViewingAt = (clinicid: any) => {
        firebase.firestore().collection(configs.CONVERSATION_DEV)
            .where("clinicId", "==", Number(clinicid))
            .where("dependentUniqueId", "==", Number(userData.dependant[0].uniqueId))
            .where("metadata.viewByPatientAt", "==", null)
            .get().then((querySnapshot: any) => {
                querySnapshot.forEach((doc: any) => {
                    const docRef = firebase.firestore().collection(configs.CONVERSATION_DEV).doc(doc.id);
                    docRef.update({
                        "metadata.viewByPatientAt": firebase.firestore.FieldValue.serverTimestamp(),
                    });
                });
            }).catch((error: any) => {
                console.log('error while update view', error);
            });;
    }

    const scrollToBottom = () => {
        $("#chatBox").animate({ scrollTop: $('#chatBox').prop("scrollHeight") }, 50);
    }
    console.log(JSON.parse(JSON.stringify(messagelist)));
    let uniqueMessageById = _.uniqBy(messagelist, 'id');
    let sortedArray = _.sortBy(uniqueMessageById, function (value: any) { return value.sortTime });

    return (
        <div className="col-12 col-md-9 position-relative p-0">
            <div className="provider-chat">
                <div className="title-box">Conversation with {props.clinic.name}</div>
                <div className="chat-box" id="chatBox">
                    {sortedArray && sortedArray.length > 0 && sortedArray.map((value: any, index: number) => {
                        if (value.appointmentId) {
                            if (
                              value.actionType ===
                              CHAT.ACTION_TYPE.MESSAGE_CENTER_TEXT
                            ) {
                              let text = value.text;
                              const idx = text.indexOf("Sent On");
                              const newTxt = text.substr(
                                idx,
                                value.text.length
                              );
                              text = text.replace(newTxt, "");

                              const appointment: any = appoitmentList.find(
                                (appointment: any) =>
                                  Number(appointment.uniqueid) ===
                                  Number(value.appointmentId)
                              );

                              return (
                                <div
                                  className="alert alert-light border py-2 text-center"
                                  key={index + Math.random()}
                                >
                                  {appointment && (
                                    <div>
                                      {text} on{" "}
                                      {value && value.createdAt && (
                                        <>
                                          {" "}
                                          {moment(
                                            moment.unix(value.createdAt.seconds)
                                          ).format("MMM DD, YYYY - LT")}
                                          {value.senderName &&
                                            value.senderName !== "" && <></>}
                                        </>
                                      )}{" "}
                                      {appointment.appointmentType} with{" "}
                                      {appointment.doctor.fullname} for{" "}
                                      {appointment.patient.firstName +
                                        " " +
                                        appointment.patient.lastName}{" "}
                                      at {appointment.clinic.name} for the
                                      appointment on{" "}
                                      {moment(
                                        appointment.date +
                                          " " +
                                          appointment.time,
                                        "YYYY-MM-DD LT"
                                      ).format("MMM DD, YYYY - LT")}
                                      . XcarePro Appointment ID:{" "}
                                      {appointment.uniqueid}.
                                    </div>
                                  )}
                                </div>
                              );
                            } else if (
                              value.actionType === CHAT.ACTION_TYPE.VOICE_CALL
                            ) {
                              return (
                                <div
                                  className="alert alert-light border py-2 text-center"
                                  key={index + Math.random()}
                                >
                                  <span>{value.text}</span>
                                </div>
                              );
                            } else if (
                              value.actionType === CHAT.ACTION_TYPE.STATUS
                            ) {
                              const appointment: any = appoitmentList.find(
                                (appointment: any) =>
                                  Number(appointment.uniqueid) ===
                                  Number(value.appointmentId)
                              );
                              if (value.toStatus === CHAT.TO_STATUS.COMPLETED) {
                                return (
                                  <div
                                    className="alert alert-success py-2 text-center"
                                    key={index + Math.random()}
                                  >
                                    {appointment && (
                                      <div>
                                        Appointment Completed:{" "}
                                        {appointment.appointmentType} with{" "}
                                        {appointment.doctor.fullname} for{" "}
                                        {appointment.patient.firstName +
                                          " " +
                                          appointment.patient.lastName}{" "}
                                        at {appointment.clinic.name} for the
                                        appointment on{" "}
                                        {moment(
                                          appointment.date +
                                            " " +
                                            appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format("MMM DD, YYYY - LT")}
                                        . XcarePro Appointment ID:{" "}
                                        {appointment.uniqueid}.
                                        <div className="text-end alert-date-txt opacity-50">
                                          Completion request date:
                                          {value &&
                                            value.createdAt &&
                                            value.createdAt.seconds && (
                                              <>
                                                &nbsp;
                                                {moment(
                                                  moment.unix(
                                                    value.createdAt.seconds
                                                  )
                                                ).format("MMM DD, YYYY") +
                                                  " - " +
                                                  moment(
                                                    moment.unix(
                                                      value.createdAt.seconds
                                                    )
                                                  ).format("LT")}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              } else if (
                                value.toStatus === CHAT.TO_STATUS.CANCELLED
                              ) {
                                return (
                                  <div
                                    className="alert alert-danger py-2 text-center"
                                    key={index + Math.random()}
                                  >
                                    {appointment && (
                                      <div>
                                        Appointment Cancelled:{" "}
                                        {appointment.appointmentType} with{" "}
                                        {appointment.doctor.fullname} for{" "}
                                        {appointment.patient.firstName +
                                          " " +
                                          appointment.patient.lastName}{" "}
                                        at {appointment.clinic.name} for the
                                        appointment on{" "}
                                        {moment(
                                          appointment.date +
                                            " " +
                                            appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format("MMM DD, YYYY - LT")}
                                        . XcarePro Appointment ID:{" "}
                                        {appointment.uniqueid}.
                                        <div className="text-end alert-date-txt opacity-50">
                                          Cancellation request date:
                                          {value &&
                                            value.createdAt &&
                                            value.createdAt.seconds && (
                                              <>
                                                &nbsp;
                                                {moment(
                                                  moment.unix(
                                                    value.createdAt.seconds
                                                  )
                                                ).format("MMM DD, YYYY") +
                                                  " - " +
                                                  moment(
                                                    moment.unix(
                                                      value.createdAt.seconds
                                                    )
                                                  ).format("LT")}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              } else if (
                                value.fromStatus === CHAT.TO_STATUS.SCHEDULED &&
                                value.toStatus === CHAT.TO_STATUS.SCHEDULED
                              ) {
                                return (
                                  <div
                                    className="alert alert-info py-2 text-center"
                                    key={index + Math.random()}
                                  >
                                    {appointment && (
                                      <div>
                                        Appointment Re-schedule:{" "}
                                        {appointment.appointmentType} with{" "}
                                        {appointment.doctor.fullname} for{" "}
                                        {appointment.patient.firstName +
                                          " " +
                                          appointment.patient.lastName}{" "}
                                        at {appointment.clinic.name} is
                                        Re-scheduled to{" "}
                                        {moment(
                                          appointment.date +
                                            " " +
                                            appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format("MMM DD, YYYY - LT")}
                                        . XcarePro Appointment ID:{" "}
                                        {appointment.uniqueid}.
                                        <div className="text-end alert-date-txt opacity-50">
                                          Re-schedule request date:
                                          {value &&
                                            value.createdAt &&
                                            value.createdAt.seconds && (
                                              <>
                                                &nbsp;
                                                {moment(
                                                  moment.unix(
                                                    value.createdAt.seconds
                                                  )
                                                ).format("MMM DD, YYYY") +
                                                  " - " +
                                                  moment(
                                                    moment.unix(
                                                      value.createdAt.seconds
                                                    )
                                                  ).format("LT")}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              } else if (
                                value.toStatus === CHAT.TO_STATUS.SCHEDULED
                              ) {
                                return (
                                  <div
                                    className="alert alert-info py-2 text-center"
                                    key={index + Math.random()}
                                  >
                                    {appointment && (
                                      <div>
                                        Appointment Scheduled:{" "}
                                        {appointment.appointmentType} with{" "}
                                        {appointment.doctor.fullname} for{" "}
                                        {appointment.patient.firstName +
                                          " " +
                                          appointment.patient.lastName}{" "}
                                        at {appointment.clinic.name} for the
                                        appointment on{" "}
                                        {moment(
                                          appointment.date +
                                            " " +
                                            appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format("MMM DD, YYYY - LT")}
                                        . XcarePro Appointment ID:{" "}
                                        {appointment.uniqueid}.
                                        <div className="text-end alert-date-txt opacity-50">
                                          Schedule request date:
                                          {value &&
                                            value.createdAt &&
                                            value.createdAt.seconds && (
                                              <>
                                                &nbsp;
                                                {moment(
                                                  moment.unix(
                                                    value.createdAt.seconds
                                                  )
                                                ).format("MMM DD, YYYY") +
                                                  " - " +
                                                  moment(
                                                    moment.unix(
                                                      value.createdAt.seconds
                                                    )
                                                  ).format("LT")}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            } else if (
                              value.actionType === CHAT.ACTION_TYPE.PRESCREENING
                            ) {
                              const appointment: any = appoitmentList.find(
                                (appointment: any) =>
                                  Number(appointment.uniqueid) ===
                                  Number(value.appointmentId)
                              );
                              return (
                                <>
                                  <div
                                    className="alert alert-info py-2 text-center"
                                    key={index + Math.random()}
                                  >
                                    {appointment && (
                                      <div>
                                        Appointment Prescreening - Completed:{" "}
                                        {appointment.appointmentType} with{" "}
                                        {appointment.doctor.fullname} for{" "}
                                        {appointment.patient.firstName +
                                          " " +
                                          appointment.patient.lastName}{" "}
                                        at {appointment.clinic.name} for the
                                        appointment on{" "}
                                        {moment(
                                          appointment.date +
                                            " " +
                                            appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format("MMM DD, YYYY - LT")}
                                        . XcarePro Appointment ID:{" "}
                                        {appointment.uniqueid}.
                                        <div className="text-end alert-date-txt opacity-50">
                                          Prescreening complete date:
                                          {value &&
                                            value.createdAt &&
                                            value.createdAt.seconds && (
                                              <>
                                                &nbsp;
                                                {moment(
                                                  moment.unix(
                                                    value.createdAt.seconds
                                                  )
                                                ).format("MMM DD, YYYY") +
                                                  " - " +
                                                  moment(
                                                    moment.unix(
                                                      value.createdAt.seconds
                                                    )
                                                  ).format("LT")}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            } else if (
                              value.actionType ===
                              CHAT.ACTION_TYPE.PRESCREENING_STATUS
                            ) {
                              const appointment: any = appoitmentList.find(
                                (appointment: any) =>
                                  Number(appointment.uniqueid) ===
                                  Number(value.appointmentId)
                              );
                              return (
                                <div
                                  className="alert alert-success py-2 text-center"
                                  key={index + Math.random()}
                                >
                                  {appointment && (
                                    <div>
                                      Appointment Prescreening - Verified:{" "}
                                      {appointment.appointmentType} with{" "}
                                      {appointment.doctor.fullname} for{" "}
                                      {appointment.patient.firstName +
                                        " " +
                                        appointment.patient.lastName}{" "}
                                      at {appointment.clinic.name} for the
                                      appointment on{" "}
                                      {moment(
                                        appointment.date +
                                          " " +
                                          appointment.time,
                                        "YYYY-MM-DD LT"
                                      ).format("MMM DD, YYYY - LT")}
                                      . XcarePro Appointment ID:{" "}
                                      {appointment.uniqueid}.
                                      <div className="text-end alert-date-txt opacity-50">
                                        Prescreening verify date:
                                        {value &&
                                          value.createdAt &&
                                          value.createdAt.seconds && (
                                            <>
                                              &nbsp;
                                              {moment(
                                                moment.unix(
                                                  value.createdAt.seconds
                                                )
                                              ).format("MMM DD, YYYY") +
                                                " - " +
                                                moment(
                                                  moment.unix(
                                                    value.createdAt.seconds
                                                  )
                                                ).format("LT")}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            } else if (
                              value.actionType ===
                              CHAT.ACTION_TYPE.PRESCREENING_REQUEST
                            ) {
                              const appointment: any = appoitmentList.find(
                                (appointment: any) =>
                                  Number(appointment.uniqueid) ===
                                  Number(value.appointmentId)
                              );
                              return (
                                <div
                                  className="alert alert-warning py-2 text-center"
                                  key={index + Math.random()}
                                >
                                  {appointment && (
                                    <div>
                                      Appointment Prescreening - Request:{" "}
                                      {appointment.appointmentType} with{" "}
                                      {appointment.doctor.fullname} for{" "}
                                      {appointment.patient.firstName +
                                        " " +
                                        appointment.patient.lastName}{" "}
                                      at {appointment.clinic.name} for the
                                      appointment on{" "}
                                      {moment(
                                        appointment.date +
                                          " " +
                                          appointment.time,
                                        "YYYY-MM-DD LT"
                                      ).format("MMM DD, YYYY - LT")}
                                      . XcarePro Appointment ID:{" "}
                                      {appointment.uniqueid}.
                                      <div className="text-end alert-date-txt opacity-50">
                                        Prescreening request date:
                                        {value &&
                                          value.createdAt &&
                                          value.createdAt.seconds && (
                                            <>
                                              &nbsp;
                                              {moment(
                                                moment.unix(
                                                  value.createdAt.seconds
                                                )
                                              ).format("MMM DD, YYYY") +
                                                " - " +
                                                moment(
                                                  moment.unix(
                                                    value.createdAt.seconds
                                                  )
                                                ).format("LT")}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            } else if (
                              value.actionType ===
                              CHAT.ACTION_TYPE.APPONINTMENT_ASSIGN
                            ) {
                              const appointment: any = appoitmentList.find(
                                (appointment: any) =>
                                  Number(appointment.uniqueid) ===
                                  Number(value.appointmentId)
                              );
                              return (
                                <div
                                  className="alertparent"
                                  key={index + Math.random()}
                                >
                                  {appointment && (
                                    <div>
                                      Appointment Assign:{" "}
                                      {appointment.appointmentType} with{" "}
                                      {appointment.doctor.fullname} for{" "}
                                      {appointment.patient.firstName +
                                        " " +
                                        appointment.patient.lastName}{" "}
                                      at {appointment.clinic.name} for the
                                      appointment on{" "}
                                      {moment(
                                        appointment.date +
                                          " " +
                                          appointment.time,
                                        "YYYY-MM-DD LT"
                                      ).format("MMM DD, YYYY - LT")}{" "}
                                      assigned to email {value.assignToEmail}.
                                      XcarePro Appointment ID:{" "}
                                      {appointment.uniqueid}.
                                      <div className="text-end alert-date-txt opacity-50">
                                        Appointment assign request date:
                                        {value &&
                                          value.createdAt &&
                                          value.createdAt.seconds && (
                                            <>
                                              &nbsp;
                                              {moment(
                                                moment.unix(
                                                  value.createdAt.seconds
                                                )
                                              ).format("MMM DD, YYYY") +
                                                " - " +
                                                moment(
                                                  moment.unix(
                                                    value.createdAt.seconds
                                                  )
                                                ).format("LT")}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            } else if (
                              value.actionType === CHAT.ACTION_TYPE.CHECKIN
                            ) {
                              const appointment: any = appoitmentList.find(
                                (appointment: any) =>
                                  Number(appointment.uniqueid) ===
                                  Number(value.appointmentId)
                              );
                              return (
                                <>
                                  <div
                                    className="alert alert-warning py-2 text-center"
                                    key={index + Math.random()}
                                  >
                                    {appointment && (
                                      <div>
                                        Appointment CheckedIn - ({value.text}):{" "}
                                        {appointment.appointmentType} with{" "}
                                        {appointment.doctor.fullname} for{" "}
                                        {appointment.patient.firstName +
                                          " " +
                                          appointment.patient.lastName}{" "}
                                        at {appointment.clinic.name} for the
                                        appointment on{" "}
                                        {moment(
                                          appointment.date +
                                            " " +
                                            appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format("MMM DD, YYYY - LT")}{" "}
                                        assigned to email {value.assignToEmail}.
                                        XcarePro Appointment ID:{" "}
                                        {appointment.uniqueid}.
                                        <div className="text-end alert-date-txt opacity-50">
                                          Appointment CheckedIn date:
                                          {value &&
                                            value.createdAt &&
                                            value.createdAt.seconds && (
                                              <>
                                                &nbsp;
                                                {moment(
                                                  moment.unix(
                                                    value.createdAt.seconds
                                                  )
                                                ).format("MMM DD, YYYY") +
                                                  " - " +
                                                  moment(
                                                    moment.unix(
                                                      value.createdAt.seconds
                                                    )
                                                  ).format("LT")}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            } else if (
                              value.actionType ===
                              CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT
                            ) {
                              const appointment: any = appoitmentList.find(
                                (appointment: any) =>
                                  Number(appointment.uniqueid) ===
                                  Number(value.appointmentId)
                              );
                              return (
                                <>
                                  <div
                                    className="alert alert-warning py-2 text-center"
                                    key={index + Math.random()}
                                  >
                                    {appointment && (
                                      <div>
                                        Appointment Cancelled by Patient:{" "}
                                        {appointment.appointmentType} with{" "}
                                        {appointment.doctor.fullname} for{" "}
                                        {appointment.patient.firstName +
                                          " " +
                                          appointment.patient.lastName}{" "}
                                        at {appointment.clinic.name} is cancelld
                                        by patient for{" "}
                                        {moment(
                                          appointment.date +
                                            " " +
                                            appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format("MMM DD, YYYY - LT")}
                                        . XcarePro Appointment ID:{" "}
                                        {appointment.uniqueid}.
                                        <div className="text-end alert-date-txt opacity-50">
                                          Cancellation by Patient request date:
                                          {value &&
                                            value.createdAt &&
                                            value.createdAt.seconds && (
                                              <>
                                                &nbsp;
                                                {moment(
                                                  moment.unix(
                                                    value.createdAt.seconds
                                                  )
                                                ).format("MMM DD, YYYY") +
                                                  " - " +
                                                  moment(
                                                    moment.unix(
                                                      value.createdAt.seconds
                                                    )
                                                  ).format("LT")}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            } else if (
                              value.actionType ===
                              CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT
                            ) {
                              const appointment: any = appoitmentList.find(
                                (appointment: any) =>
                                  Number(appointment.uniqueid) ===
                                  Number(value.appointmentId)
                              );
                              return (
                                <>
                                  <div
                                    className="alert alert-warning py-2 text-center"
                                    key={index + Math.random()}
                                  >
                                    {appointment && (
                                      <div>
                                        Appointment Confirmed:{" "}
                                        {appointment.appointmentType} with{" "}
                                        {appointment.doctor.fullname} for{" "}
                                        {appointment.patient.firstName +
                                          " " +
                                          appointment.patient.lastName}{" "}
                                        at {appointment.clinic.name} is
                                        confirmed for{" "}
                                        {moment(
                                          appointment.date +
                                            " " +
                                            appointment.time,
                                          "YYYY-MM-DD LT"
                                        ).format("MMM DD, YYYY - LT")}
                                        . XcarePro Appointment ID:{" "}
                                        {appointment.uniqueid}.
                                        <div className="text-end alert-date-txt opacity-50">
                                          Confirmation request date:
                                          {value &&
                                            value.createdAt &&
                                            value.createdAt.seconds && (
                                              <>
                                                &nbsp;
                                                {moment(
                                                  moment.unix(
                                                    value.createdAt.seconds
                                                  )
                                                ).format("MMM DD, YYYY") +
                                                  " - " +
                                                  moment(
                                                    moment.unix(
                                                      value.createdAt.seconds
                                                    )
                                                  ).format("LT")}
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            }
                        } else if (value.sender === "patient") {
                            return (
                                <div className="chat user">
                                    <div className="float-end">
                                        <div className="chat-bubble">{value.message}</div>
                                    </div>
                                    <span className="msg_date">{
                                        value &&
                                        value.createdAt &&
                                        <> {moment(moment.unix(value.createdAt.seconds)).format("MMM DD, YYYY - LT")}
                                        </>
                                    }</span>
                                    <div className="clear"></div>
                                </div>
                            )
                        }
                        else if (value.sender === "clinic") {
                            return (
                                <div className="chat dr">
                                    <div className="float-start">
                                        <div className="chat-bubble">{value.message}</div>
                                    </div>
                                    <span className="msg_date">{value &&
                                        value.createdAt &&
                                        <> {moment(moment.unix(value.createdAt.seconds)).format("MMM DD, YYYY - LT")}
                                        </>}
                                        {
                                            (value.medium && value.medium === "SMS") &&
                                            <>- received via sms</>
                                        }
                                    </span>
                                    <div className="clear"></div>
                                </div>
                            )
                        }
                    })}
                </div>
                <div className="input-box"><textarea name="" id="" placeholder="Type a message..." value={chatMessage} onChange={(e) => {
                    setChatMessage(e.target.value);
                }}></textarea><button className="enter-btn btn-custom rounded btn text-center" onClick={(e) => {
                    onMessageSendClick(e);
                }}><img className="" src="/static/media/send.50043e21.svg" /></button></div>
            </div>
        </div>
    )
}
