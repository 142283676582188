import React, { useEffect, useRef, useState } from "react";
import { communicationUpdate } from "../../../services/profile";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

export const SigninAddressEdit = ({
  handleModalClose,
  handleSuccess,
  address,
  title,
  user,
}: any) => {
  let autcompleteRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation]: any = useState("");
  const [locationError, setLocationError]: any = useState("");
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    if (isScriptLoaded) {
      try {
        // Initialize Google Places API autocomplete
        //@ts-ignore
        const autocomplete = new window.google.maps.places.Autocomplete(
          autcompleteRef.current,
          {
            types: ["(regions)"],
          }
        );

        // When a place is selected, update the location
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          setLocation(place);
        });

        // Dynamically update the z-index of the autocomplete dropdown
        const interval = setInterval(() => {
          const pacContainer = document.querySelector(".pac-container");
          if (pacContainer) {
            pacContainer.setAttribute("style", "z-index: 9999 !important;");
            clearInterval(interval);
          }
        }, 100);
      } catch (err) {
        console.log(err);
      }
    }
  }, [isScriptLoaded]); // Ensure this effect runs after the script is loaded

  const onLoadGetPlace = () => {
    if (!document.getElementById("googlePlaceScript")) {
      const googleScript = document.createElement("script");
      googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8&libraries=places`;
      googleScript.id = "googlePlaceScript";
      window.document.body.appendChild(googleScript);
      googleScript.addEventListener("load", () => {
        setIsScriptLoaded(true); // Mark script as loaded
      });
    } else {
      setIsScriptLoaded(true); // If script is already loaded, set the flag
    }
  };

  useEffect(() => {
    onLoadGetPlace();
  }, []); // Only run on mount

  const handleUpdateAddress = () => {
    if (location === "") {
      setLocationError("Postal code required");
      return;
    }

    setIsLoading(true);
    communicationUpdate({
      type: "address",
      formatted_address: location?.formatted_address,
      address: location?.address_components,
      dependantId: user.dependantId,
      guarantorId: user.guarantor,
    })
      .then((success) => {
        setIsLoading(false);
        if (success && success.status && success.status.error === false) {
          toast.success("Address updated successfully");
          handleSuccess();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.success("Something went wrong.");
      });
  };

  return (
    <>
      <div className="offcanvas-action d-flex">
        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7 ms-auto"
          onClick={handleModalClose}
        >
          Close
        </button>
      </div>

      <div className="offcanvas-header">
        <h5 className="offcanvas-title">{title}</h5>
      </div>

      <div className="offcanvas-body">
        <div className="current_data border-bottom pb-2 mb-2">
          <div>Current {title}</div>
          <span className="opacity-50 fs-7">{address}</span>
        </div>
        <div>
          <div className="mb-3">
            <input
              type="text"
              id="autocomplete"
              value={location ? location.formatted_address : location}
              onChange={(e) => {
                setLocation(e.target.value);
                if (locationError !== "") {
                  setLocationError("");
                }
              }}
              ref={autcompleteRef}
              placeholder="postal code"
              className="form-control"
            />
            {locationError && locationError !== "" && (
              <div className="text-danger error">{locationError}</div>
            )}
          </div>
        </div>
        <div className="mb-3">
          <button
            type="button"
            className="btn btn-dark w-100 fw-normal"
            onClick={handleUpdateAddress}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </>
  );
};
