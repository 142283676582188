import React, { useState, useEffect } from 'react'
import { NoCard } from './NoCard';
import moment from 'moment';
import configs from '../../../configs/apiConfigs';
import Carousel from 'react-bootstrap/Carousel';
import { useHistory } from 'react-router-dom';
import { SharedOffCanvas } from '../../sharedOffCanvas/SharedOffCanvas';
import { SearchProviderModal } from '../../../../components/searchProvider/SearchProviderModal';
export const CancelledAppointments = ({ appointments, fromDashboard }: any) => {
    const history = useHistory();
    const [isShowSearchProviderModal, setIsShowSearchProviderModal] = useState(false);

    const wrapeCarousel = () => {
        if (fromDashboard) {
            return (
                <Carousel
                    className="myappointment_coro"
                    controls={true}
                    interval={null}
                    nextIcon={<i className="bi bi-arrow-right-circle"></i>}
                    prevIcon={<i className="bi bi-arrow-left-circle"></i>}
                >
                    {appointmentLoop()}
                </Carousel>
            )
        } else {
            return(appointmentLoop())
        }
    }

    const appointmentLoop = () => {
        return (
            (appointments && appointments.cancelled && appointments.cancelled.length > 0) ?
                appointments.cancelled.map((appointment: any) => {
                    let endTime: any = '';
                    if (appointment.time && appointment.duration) {
                        endTime = moment(appointment.time, 'LT').add(appointment.duration, 'minutes');
                    }
                    let pFullname = '';
                    if (appointment && appointment.dependant && appointment.dependant.firstName && appointment.dependant.lastName) {
                        pFullname = appointment.dependant.firstName + " " + appointment.dependant.lastName;
                    }
                    if (fromDashboard) {
                        return (
                            <Carousel.Item key={appointment.id}>
                                {singleAppointment(appointment,endTime,pFullname)}
                            </Carousel.Item>
                        )
                    } else {
                        return (
                            singleAppointment(appointment,endTime,pFullname)
                        )
                    }
                })
                :
                <NoCard name="Cancelled" />
        )
    }

    const singleAppointment = (appointment: any, endTime: any, pFullname: any) => {
        return (
            <div key={appointment.id} className="appo_card card p-4 p-lg-3 position-relative bg-light">
                <div className="app_status">
                    <span className="badge ms-2 rounded-pill bg-danger">By Clinic</span>
                </div>
                <div className="d-flex align-items-center appo_with mb-4">
                    <div className="avatar avatar-lg rounded-circle flex-shrink-0 me-2">
                        <img alt="Image placeholder" src={configs.IMAGE_URL + '/doctor/' + appointment.doctor.photo.name + '-200x200.' + appointment.doctor.photo.type} />
                    </div>
                    <div className="av-txt">
                        <div className="av_title">{appointment.doctor.fullname ? appointment.doctor.fullname : ''}</div>
                        <div className="av_subtitle opacity-50">{appointment.clinic.name ? appointment.clinic.name : '-'}</div>
                    </div>
                </div>
                <div className="d-flex align-items-center appo_for mb-4">
                    <div className="av-txt">
                        <div className="av_title fw-normal">{pFullname ? pFullname : '-'}</div>
                        <div className="av_subtitle">{appointment.date ? moment(appointment.date).format('MMM DD, YYYY') : '-'} ({appointment.time ? appointment.time : '-'} - {endTime ? moment(endTime).format('hh:mm A') : '-'})</div>
                    </div>
                </div>
                <div className="card_buttons">
                    <button type="button" className="btn btn-dark fw-normal fs-7 me-2 btn-sm px-3" onClick={() => {
                                                        setIsShowSearchProviderModal(true);
                                                    }}>Book Again</button>
                    {/* <button type="button" className="btn btn-dark fw-normal fs-7 me-2 btn-sm px-3" onClick={() => { history.push({ pathname: '/search-provider', state: { type: 'existing' } }) }}>Book Again</button> */}
                </div>
            </div>
        )
    }
    
    return (
        <>
            {
                (fromDashboard) ?
                    <Carousel
                        className="myappointment_coro"
                        controls={true}
                        interval={null}
                        nextIcon={<i className="bi bi-arrow-right-circle"></i>}
                        prevIcon={<i className="bi bi-arrow-left-circle"></i>}
                    >
                        {appointmentLoop()}
                    </Carousel>
                    : appointmentLoop()
            }
            <SharedOffCanvas
                placement={'top'}
                addclass={'new_and_ex'}
                isOpen={isShowSearchProviderModal}
                handleClose={() => { setIsShowSearchProviderModal(false) }}
                Children={SearchProviderModal}
                handleSuccess={() => {
                    setIsShowSearchProviderModal(false)
                }}
                handleSrcIframe={(type: any) => {
                    setIsShowSearchProviderModal(false)
                    history.push({ pathname: '/search-provider', state: { type: type } });
                }}
            />
        </>
    )
}