import axios from 'axios';
import apiConfigs from '../configs/apiConfigs';
export const prepareAddress = (addressGoogle: any) => {
    const addressObj = JSON.parse(JSON.stringify(addressGoogle));
    const addressComponent = addressObj.address_components;
    // return defined address.
    let returnObj = {};
    Object.assign(returnObj, {
        latitude: addressObj.geometry.location.lat,
        longitude: addressObj.geometry.location.lng
    });
    Object.assign(returnObj, { formatted_address: addressObj.formatted_address });
    const addressPartAllows = [
        "sublocality", "locality", "administrative_area_level_2",
        "administrative_area_level_1", "country", "sublocality_level_1"
    ];
    let address = {};
    for (let item of addressComponent) {
        const types = item.types;
        const index = addressPartAllows.findIndex((value: any) => {
            return value === types[0];
        });
        if (index !== -1) {
            if (addressPartAllows[index] === "administrative_area_level_1") {
                Object.assign(address, { administrative_area_level_1_short: item.short_name });
                Object.assign(address, { administrative_area_level_1: item.long_name });
            } else if (addressPartAllows[index] === "sublocality_level_1") {
                Object.assign(address, { political: item.long_name });
            } else if (addressPartAllows[index] === "sublocality") {
                Object.assign(address, { political: item.long_name });
            } else if (addressPartAllows[index] === "locality") {
                Object.assign(address, { locality: item.long_name });
            } else if (addressPartAllows[index] === "administrative_area_level_2") {
                Object.assign(address, { administrative_area_level_2: item.long_name });
            } else if (addressPartAllows[index] === "country") {
                Object.assign(address, { country: item.long_name });
            }
        }
    }
    Object.assign(returnObj, { address: address });
    return returnObj;
}
export const findDistanceBetweenTwolatLng = (coordOne: { lat: number, lng: number }, coordTwo: { lat: number, lng: number }) => {
    // let distance = '';
    // // const url = "https://maps.googleapis.com/maps/api/directions/json?origin=" + coordOne.lat + "," + coordOne.lng + "&destination=" + coordTwo.lat + "," + coordTwo.lng + "&key=" + apiConfigs.GOOGLE_API_KEY;
    // const url = "https://maps.googleapis.com/maps/api/distancematrix/json?origins=" + coordOne.lat + "," + coordOne.lng + "&destinations=" + coordTwo.lat + "," + coordTwo.lng + "&key=" + apiConfigs.GOOGLE_API_KEY;
    // return axios.get(url).then((success: any) => {
    //     if (success.status === 200) {
    //         console.log(success);
    //         /* if (success.data.status === "OK") {
    //             return success.data.routes[0].legs[0].distance.text;
    //         } */
    //     }
    // }, (error) => {
    //     return distance;
    // });
    return new Promise((res, rej) => {
        //@ts-ignore
        var origin = new google.maps.LatLng(coordOne.lat, coordOne.lng);
        //@ts-ignore
        var destination = new google.maps.LatLng(coordTwo.lat, coordTwo.lng);
        // @ts-ignore
        var service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
            {
                origins: [origin],
                destinations: [destination],
                //@ts-ignore
                travelMode: google.maps.DirectionsTravelMode.DRIVING
            }, (response: any, status: any) => {

                if (response && response.rows && response.rows[0] && response.rows[0].elements
                    && response.rows[0].elements[0] && response.rows[0].elements[0].distance && response.rows[0].elements[0].distance.text) {
                    res(response.rows[0].elements[0].distance.text);
                } else {
                    res('');
                }

            });
    })
}

export const loadMap = () => {
    return new Promise((res, rej) => {
        if (!document.getElementById('googlePlaceScript')) {
            const googleScript = document.createElement('script');
            googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8&libraries=places`
            googleScript.id = "googlePlaceScript";
            googleScript.async = true;
            window.document.body.appendChild(googleScript);
            googleScript.addEventListener('load', () => {
                res(true);
            });
        } else {
            res(true);
        }
    });
}
export const setGoogleMap = (latLng: { latitude: number, longitude: number }, mapId: any) => {

    return new Promise((res, rej) => {
        try {
            //@ts-ignore
            const map = new window.google.maps.Map(document.getElementById(mapId), {
                center: { lat: latLng.latitude, lng: latLng.longitude },
                streetViewControl: !1,
                zoom: 16,
                scrollwheel: !1,
                draggable: !1,
                disableDoubleClickZoom: !0,
                disableDefaultUI: !0,
                gestureHandling: 'cooperative',
                zoomControl: false,
                maxZoom: 16,
                styles: [{
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [{
                        visibility: "off"
                    }]
                }, {
                    featureType: "transit.station",
                    elementType: "labels",
                    stylers: [{
                        visibility: "off"
                    }]
                }],
                //@ts-ignore
                mapTypeId: window.google.maps.MapTypeId.ROADMAP
            });
            //@ts-ignore
            window.google.maps.event.addListenerOnce(map, 'idle', () => {
                // do something only the first time the map is loaded
                res(map);
            });
        } catch (err) {
            rej(err);
        }
    });
}

export const setMarkerToPostion = (map: any, position: { latitude: number, longitude: number }) => {
    try {
        //@ts-ignore
        var myLatlng = new window.google.maps.LatLng(position.latitude, position.longitude);
        //@ts-ignore
        var marker = new window.google.maps.Marker({
            position: myLatlng,
        });
        map.setCenter(myLatlng);
        marker.setMap(map);
        return marker;
    } catch (err) {
        console.log(err);
        return;
    }
}