import React, { useState, useEffect } from 'react';
import { Alert, Modal, ModalBody, ListGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { assignAppointmentPending, writeReviewPending } from '../../../store/appointments/actions';
import { writeReviewApi } from '../../services/appointment';
import { toast } from 'react-toastify';
import { changeWriteReviewAction, patientDataPending } from '../../../store/patientData/actions';
export const WriteReviewModal = ({ writeReview, hideWriteReview, currentSelectedAppointment, writeReviewSuccess }: any) => {

    const [writeReviewForm, setWriteReviewForm]: any = useState({ comment: '', score: -1 });
    const ratingsCounts: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [ReviewComment, setReviewComment]: any = useState('');
    const [ReviewScore, setReviewScore]: any = useState(-1);

    const handleWriteReview = () => {
        setIsLoading(true);
        let data = {
            appointmentId: currentSelectedAppointment.uniqueid,
            comment: ReviewComment,
            // patientUniqueId: patientData.uniqueId,
            rating: ReviewScore
        };
        writeReviewApi(data, currentSelectedAppointment.uniqueid).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                console.log('success', success);
                toast.success(success.status.msg);
                //Pending Chnage review Api call
                // dispatch(changeWriteReviewAction({
                //     appointmentUniqueId: currentSelectedAppointment.uniqueid,
                //     comment: writeReviewForm.comment,
                //     score: writeReviewForm.score.toString()
                // }));
                setWriteReviewForm({ comment: '', score: -1 });
                writeReviewSuccess();
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }

    return (
        <Modal centered className="write_review newcommon" show={writeReview} onHide={() => {
            setWriteReviewForm({ comment: '', score: -1 });
            hideWriteReview();
        }}>
            <Modal.Header>
                <div className="modal-title">Review on this appointment.</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                    hideWriteReview();
                }}></button>
            </Modal.Header>
            <ModalBody>
                {
                    (currentSelectedAppointment) &&
                    <>
                        <div className="form-group">
                            <label className="form-label">How likely are you to visit {currentSelectedAppointment.clinic.name} again?</label>
                            <div className="rate-buttons d-flex justify-content-between">
                                {
                                    ratingsCounts.map((value, index) => {
                                        return (
                                            <button className={(ReviewScore === value ? 'btn btn-dark' : 'btn btn-light')} key={index + Math.random()} onClick={(e) => {
                                                setReviewScore(value)
                                            }}>{value}</button>
                                        )
                                    })
                                }
                            </div>
                            <div className="d-flex justify-content-between mb-3">
                                <span className="opacity-50 fs-7">Not likely</span>
                                <span className="opacity-50 fs-7">Extremely likely</span>
                            </div>
                        </div>
                        {
                            ReviewScore > -1 &&
                            <div className="mb-3">
                                <label className="form-label">We'd love to learn more. Please tell us why you selected a score of <span>{ReviewScore}</span>.</label>
                                <textarea className="form-control" id="comment" placeholder="" value={ReviewComment} onChange={(e) => {
                                    setReviewComment(e.target.value);
                                }}></textarea>
                            </div>
                        }
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-dark" disabled={ReviewScore === -1 || ReviewComment === ''}
                                onClick={(e) => {
                                    if (currentSelectedAppointment && patientData) {
                                        handleWriteReview()
                                        // dispatch(writeReviewPending({
                                        //     appointment: currentSelectedAppointment.uniqueid,
                                        //     comment: writeReviewForm.comment,
                                        //     patientUniqueId: patientData.uniqueId,
                                        //     score: writeReviewForm.score
                                        // }));
                                    }
                                }}>Submit</button>
                        </div>
                    </>
                }
            </ModalBody>
        </Modal>
    )
}
