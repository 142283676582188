import React, { Fragment, useEffect, useRef, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AddCoverage } from "./AddCoverage";
import { EditCoverage } from "./EditCoverage";
import { AddSpent } from "./AddSpent";
import { ViewCoverage } from "./ViewCoverage";
import { SharedOffCanvas } from "../../sharedOffCanvas/SharedOffCanvas";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../../store/loadingIndicator/actions";
import {
  fetchCoverageDetails,
  getInsuranceDetail,
  getInsuranceProviderNew,
  sendUpdateInsuranceOTP,
  updateInsuranceByDependant,
} from "../../../services/insuranceCalculator";
import { iteratee } from "lodash";
import { Stack } from "react-bootstrap";
import { borderRadius } from "react-select/src/theme";
import { toast } from "react-toastify";
import { patientDataPending } from "../../../../store/patientData/actions";

export const InsuranceManage = (props: any) => {
  const [show, setShow] = useState(false);
  const otpInputRef: any = useRef();
  const handleClose = () => {
    setShow(false);
    props.handleClose();
    resetState();
  };

  const focusOtpInput = () => {
    if (otpInputRef) {
      otpInputRef.current.focus();
    }
  };

  useEffect(() => {
    if (props.isShow) {
      setShow(true);
      if (userData) {
        setSelectedDependant(userData.dependant[0]);
      }
    }
  }, [props.isShow]);

  const [isShowAddCoverage, setIsShowAddCoverage] = useState(false);
  const [isShowEditCoverage, setIsShowEditCoverage] = useState(false);
  const [isShowAddSpent, setIsShowAddSpent] = useState(false);
  const [isShowViewCoverage, setIsShowViewCoverage] = useState(false);
  const [isShowInsuranceManage, setIsShowInsuranceManage] = useState(false);
  const [isInsuranceUpdate, setIsInsuranceUpdate] = useState(false);
  const [issentOtp, setIsSentOtp] = useState(false);
  const [dependants, setDependants] = useState([]);
  const [selectedDependant, setSelectedDependant]: any = useState("");
  const [selectedFamilyMember, setSelectedFamilyMember]: any = useState({});
  const dispatch = useDispatch();
  const [coverageDetails, setCoverageDetails]: any = useState([]);
  const [selectedInsuranceProvider, setSelectedInsuranceProvider] =
    useState("");
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [selectedPolicyId, setSelectedPolicyId] = useState("");
  const [selectedNote, setSelectedNote] = useState("");
  const [selectedCoverageType, setSelectedCoverageType] = useState("");
  const [otp, setOtp] = useState("");
  const [familyList, setFamilyList]: any = useState([]);
  const [insuranceProviders, setInsuranceProviders]: any = useState([]);
  const [planError, setPlanError]: any = useState("");
  const offcanvasContentRef: any = useRef(null);
  const [coverageTypeList, setCoverageTypeList] = useState([
    {
      name: "Basic Plan",
      value: "basic_plans",
    },
    {
      name: "Comprehensive Plan",
      value: "comprehensive_plans",
    },
    {
      name: "Orthodontic Plan",
      value: "orthodontic_plans",
    },
    {
      name: "Group Benefits Plan",
      value: "group_benefits_plans",
    },
    {
      name: "Standalone Dental Insurance",
      value: "standalone_dental_insurance",
    },
  ]);
  const resetState = () => {
    setIsShowAddCoverage(false);
    setIsShowEditCoverage(false);
    setIsShowAddSpent(false);
    setIsShowViewCoverage(false);
    setIsShowInsuranceManage(false);
    setIsInsuranceUpdate(false);
    setIsSentOtp(false);
    setSelectedFamilyMember({});
    setCoverageDetails([]);
    setSelectedInsuranceProvider("");
    setSelectedPlanId("");
    setSelectedGroupId("");
    setSelectedPolicyId("");
    setSelectedNote("");
    setSelectedCoverageType("");
    setOtp("");
    setFamilyList([]);
    setInsuranceProviders([]);
    setPlanError("");
  };

  const userData = useSelector(
    (state: any) => state.patientData.patientDataResponse,
    shallowEqual
  );
  const [selectedCoverage, setSelectedCoverage]: any = useState("");

  useEffect(() => {
    if (userData && userData.dependant && userData.dependant.length > 0) {
      setDependants(userData.dependant);
      setSelectedDependant(userData.dependant[0]);
    }
  }, [userData]);

  useEffect(() => {
    if (selectedDependant) {
      fetchCoverageData();
    }
    console.log("selectedDependant", dependants);
  }, [dependants, show]);

  const scrollToBottom = () => {
    if (offcanvasContentRef.current) {
      offcanvasContentRef.current.scrollIntoView({
        behavior: "smooth", // Smooth scrolling
        block: "end", // Align to the bottom
      });
    }
  };

  const fetchCoverageData = () => {
    if (selectedDependant && selectedDependant.id) {
      dispatch(fetchLoadingPending());
      getInsuranceProviderNew().then((success: any) => {
        if (
          success &&
          success.response &&
          success.response.data &&
          success.response.status === 200
        ) {
          if (
            success.response.data &&
            success.response.data.status.error === false
          ) {
            console.log(
              "selected insurance provider",
              success.response.data.insurance
            );
            setInsuranceProviders([
              ...success.response.data.insurance,
              {
                id: "other",
                name: "Other",
                plan: [{ id: "other", name: "Other" }],
              },
            ]);
          }
        }
      });
      fetchCoverageDetails(selectedDependant.id)
        .then((success: any) => {
          dispatch(fetchLoadingSuccess(false));
          if (success && success.status && success.status.error === false) {
            setCoverageDetails(success.coverage);
          }
        })
        .catch((err: any) => {
          dispatch(fetchLoadingSuccess(false));
          if (err.error) {
            setCoverageDetails([]);
          }
          console.log(err);
        });

      getInsuranceDetail(selectedDependant.id)
        .then((success: any) => {
          console.log("getInsuranceDetail", success);
          setSelectedPlanId(success?.memberId);
          setSelectedGroupId(success?.groupId);
          setSelectedPolicyId(success?.policyId);
          setSelectedNote(success?.notes);
          setSelectedCoverageType(success?.type);
          setSelectedInsuranceProvider(success?.insuranceName);

          if (success?.groups && success?.groups?.length > 0) {
            setFamilyList(success?.groups);
          }
        })
        .catch((err: any) => {
          dispatch(fetchLoadingSuccess(false));

          console.log("err", err);
        });
    }
  };

  useEffect(() => {
    console.log("selectedFamilyMember", familyList);
  }, [familyList]);
  const onChnageDependant = (e: any) => {
    let tempDependant = userData.dependant.find(
      (dependant: any) => dependant.id === e.target.value
    );
    if (tempDependant) {
      setSelectedDependant(tempDependant);
    }
  };

  const onChnageFamilyMember = (e: any) => {
    let tempDependant = userData.dependant.find(
      (dependant: any) => dependant.id === e.target.value
    );
    if (tempDependant) {
      setSelectedFamilyMember(tempDependant);
    }
  };

  const onChangePolicyNumber = (value: any, index: any) => {
    setIsInsuranceUpdate(true);
    setFamilyList(
      familyList.map((mItem: any, mIndex: any) => {
        console.log("policyId", value);
        if (mIndex === index) {
          return {
            ...mItem,
            policyId: value,
          };
        }
        return mItem;
      })
    );
  };

  const onRemoveRow = (selectedIndex: any) => {
    setIsInsuranceUpdate(true);
    const familyMember = familyList[selectedIndex];

    if (familyMember.isInternal) {
      setFamilyList(
        familyList.filter((item: any, index: any) => index !== selectedIndex)
      );
    } else {
      setFamilyList(
        familyList.map((fItem: any, index: any) => {
          if (index === selectedIndex) {
            return {
              ...fItem,
              isDeleted: true,
            };
          }
          return fItem;
        })
      );
    }
  };

  const sendOTP = () => {
    if (!selectedPlanId) {
      setPlanError("plan id is requried");
      console.log("here1");
      return;
    }
    dispatch(fetchLoadingSuccess(true));
    sendUpdateInsuranceOTP(selectedDependant.id)
      .then((_success) => {
        toast.success("One-Time password sent");
        setIsSentOtp(true);
        dispatch(fetchLoadingSuccess(false));
        focusOtpInput();
        scrollToBottom();
      })
      .catch((err) => {
        console.log("err", err);
        setIsSentOtp(true);
        dispatch(fetchLoadingSuccess(false));
        toast.error(err?.msg);
      });
  };

  const updateInsuranceDetail = () => {
    const request = {
      code: otp,
      insurance_name: selectedInsuranceProvider,
      type: selectedCoverageType,
      memberId: selectedPlanId,
      groupId: selectedGroupId,
      policyId: selectedPolicyId,
      notes: selectedNote,
      family: familyList,
    };

    console.log("request", request);
    updateInsuranceByDependant(request, selectedDependant?.id)
      .then((_success) => {
        dispatch(fetchLoadingSuccess(false));
        dispatch(patientDataPending({ uniqueId: userData?.uniqueId }));
        console.log("success msg", _success?.status?.msg);
        // toast.info(_success?.status?.msg);
        handleClose();
      })
      .catch((err) => {
        console.log("err", err);
        setIsSentOtp(true);
        dispatch(fetchLoadingSuccess(false));
        toast.error(err?.msg);
      });
  };

  useEffect(() => {
    console.log("familyList", familyList);
  }, [familyList]);

  return (
    <>
      <Offcanvas
        className="insurance_manage"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <div className="offcanvas-action d-flex">
          <button
            type="button"
            className="btn btn-link p-0 fw-normal fs-7 ms-auto"
            onClick={() => {
              props.handleClose();
              setShow(false);
              resetState();
            }}
          >
            Cancel
          </button>
        </div>
        <div className="offcanvas-header mt-2">
          <h5 className="offcanvas-title">Manage my insurance</h5>
        </div>
        <div className="offcanvas-body" ref={offcanvasContentRef}>
          {!issentOtp ? (
            <>
              <div className="pt-1">
                {" "}
                {selectedDependant?.firstName} {selectedDependant?.lastName}{" "}
                {selectedDependant?.guarantorId === selectedDependant?.loginId
                  ? "(gurantor)"
                  : ""}{" "}
              </div>
              <div className="d-flex flex-column">
                <div
                  className="shadow-sm p-2 mt-1"
                  style={{ borderRadius: "2px" }}
                >
                  <div className="mt-1 d-flex flex-column">
                    <label style={{ fontWeight: "bold" }}>
                      Insurance carrier Name
                    </label>
                    <div className="form-group">
                      <select
                        className="form-control"
                        value={selectedInsuranceProvider}
                        onChange={(e) => {
                          setIsInsuranceUpdate(true);
                          setSelectedInsuranceProvider(e.target.value);
                        }}
                      >
                        <option>Select Insurance</option>
                        {insuranceProviders &&
                          insuranceProviders.length > 0 &&
                          insuranceProviders.map((item: any, index: number) => {
                            return (
                              <option
                                key={
                                  index + Math.random() + "Insurance_provider"
                                }
                                value={item.name}
                                style={{ width: "30%" }}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="mt-2 d-flex flex-column">
                    <label style={{ fontWeight: "bold" }}>Plan #</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="0000000"
                      id="plan"
                      value={selectedPlanId}
                      onChange={(e) => {
                        setIsInsuranceUpdate(true);
                        setSelectedPlanId(e.target.value);
                        setPlanError("");
                      }}
                    />
                    {planError && (
                      <div className="text-danger error">{planError}</div>
                    )}
                  </div>
                  <div className="mt-2 d-flex flex-column">
                    <label style={{ fontWeight: "bold" }}>Group #</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="0000000"
                      id="group"
                      value={selectedGroupId}
                      onChange={(e) => {
                        setIsInsuranceUpdate(true);
                        setSelectedGroupId(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mt-2 d-flex flex-column">
                    <label style={{ fontWeight: "bold" }}>Policy ID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="0000000"
                      id="policy_id"
                      value={selectedPolicyId}
                      onChange={(e) => {
                        setIsInsuranceUpdate(true);
                        setSelectedPolicyId(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mt-2 d-flex flex-column">
                    <label style={{ fontWeight: "bold" }}>Coverage Type</label>
                    <select
                      className="form-control"
                      id="exampleFormControlSelect4"
                      value={selectedCoverageType}
                      onChange={(e) => {
                        setIsInsuranceUpdate(true);
                        setSelectedCoverageType(e.target.value);
                      }}
                    >
                      <option>Select Plan</option>
                      {coverageTypeList &&
                        coverageTypeList.length > 0 &&
                        coverageTypeList.map((item: any, index: any) => {
                          return (
                            <option
                              key={index + Math.random() + "plan"}
                              value={item.value}
                            >
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="d-flex justify-content-center mt-2 flex-column">
                    <label style={{ fontWeight: "bold" }}> Notes </label>
                    <textarea
                      name=""
                      id="notes"
                      className="form-control"
                      placeholder="Type a message..."
                      value={selectedNote}
                      onChange={(e) => {
                        setIsInsuranceUpdate(true);
                        setSelectedNote(e.target.value);
                      }}
                    ></textarea>
                  </div>
                </div>

                <div className="mt-2 d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() => {
                      setIsShowInsuranceManage(true);
                    }}
                  >
                    Add Annual Insured $ Limits
                  </button>
                </div>
                <div
                  className="mt-2 d-flex flex-column shadow-sm p-2"
                  style={{ borderRadius: "2px" }}
                >
                  <label style={{ fontWeight: "bold" }}>
                    Apply to my Family Member
                  </label>
                  <div className="d-flex pt-1">
                    <select
                      className="form-select"
                      name="dependantid"
                      onChange={onChnageFamilyMember}
                    >
                      <option key="select">select</option>
                      {dependants &&
                        dependants.length > 0 &&
                        dependants
                          .filter(
                            (fItem: any) => fItem.id !== selectedDependant?.id
                          )
                          .map((dependant: any) => {
                            return (
                              <option key={dependant.id} value={dependant.id}>
                                {dependant.firstName + " " + dependant.lastName}
                              </option>
                            );
                          })}
                    </select>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => {
                        console.log(
                          "selectedFamilyMember",
                          selectedFamilyMember
                        );
                        if (selectedFamilyMember?.id) {
                          setIsInsuranceUpdate(true);
                          setFamilyList([
                            ...familyList,
                            { ...selectedFamilyMember, isInternal: true },
                          ]);
                        }
                      }}
                    >
                      Add
                    </button>
                  </div>
                  <div className="d-flex justify-content-between p-2 flex-column">
                    <div className="d-flex">
                      <label style={{ fontWeight: "bold" }}>
                        Family Member
                      </label>
                      <label style={{ fontWeight: "bold", marginLeft: "40px" }}>
                        Policy#
                      </label>
                    </div>
                    <div>
                      {familyList?.length > 0 &&
                        familyList
                          .filter((item: any) => item.isDeleted !== true)
                          .map((fItem: any, index: any) => {
                            return (
                              <Stack
                                direction="horizontal"
                                gap={3}
                                className="mb-2 align-items-center"
                                id={index}
                              >
                                <div
                                  className="d-flex"
                                  style={{ width: "100%" }}
                                >
                                  <label
                                    style={{
                                      flex: "0 0 130px",
                                      marginTop: "5px",
                                    }}
                                  >
                                    {fItem.firstName} {fItem.lastName}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="policy number"
                                    name="policy"
                                    id={`policy${index}`}
                                    value={fItem.policyId || ""}
                                    onChange={(e) => {
                                      console.log("Event object:", e); // Log the whole event
                                      console.log(
                                        "Input value:",
                                        e?.target?.value
                                      ); // Log the input value
                                      onChangePolicyNumber(
                                        e?.target?.value,
                                        index
                                      ); // Call your function
                                    }}
                                  />
                                </div>
                                <button
                                  className="btn p-0"
                                  onClick={() => {
                                    onRemoveRow(index);
                                  }}
                                >
                                  <i className="bi bi-x-circle-fill text-danger"></i>
                                </button>
                              </Stack>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex flex-column mt-2">
                <label style={{ fontWeight: "bold" }}> One-Time password</label>
                <input
                  type="text"
                  className="form-control pt-2"
                  placeholder="OTP"
                  name="otp"
                  maxLength={4}
                  pattern="\d{4}"
                  ref={otpInputRef}
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                />
              </div>
            </>
          )}
          {/* <div className="pt-1">
            {" "}
            {selectedDependant?.firstName} {selectedDependant?.lastName}{" "}
            {selectedDependant?.guarantorId === selectedDependant?.loginId
              ? "(gurantor)"
              : ""}{" "}
          </div>
          <div className="d-flex flex-column">
            <div className="shadow-sm p-2 mt-1" style={{ borderRadius: "2px" }}>
              <div className="mt-1 d-flex flex-column">
                <label style={{ fontWeight: "bold" }}>
                  Insurance carrier Name
                </label>
                <div className="form-group">
                  <select
                    className="form-control"
                    value={selectedInsuranceProvider}
                    onChange={(e) => {
                      setIsInsuranceUpdate(true);
                      setSelectedInsuranceProvider(e.target.value);
                    }}
                  >
                    <option>Select Insurance</option>
                    {insuranceProviders &&
                      insuranceProviders.length > 0 &&
                      insuranceProviders.map((item: any, index: number) => {
                        return (
                          <option
                            key={index + Math.random() + "Insurance_provider"}
                            value={item.name}
                            style={{ width: "30%" }}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="mt-2 d-flex flex-column">
                <label style={{ fontWeight: "bold" }}>Plan #</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="0000000"
                  id="plan"
                  value={selectedPlanId}
                  onChange={(e) => {
                    setIsInsuranceUpdate(true);
                    setSelectedPlanId(e.target.value);
                    setPlanError("");
                  }}
                />
                {planError && (
                  <div className="text-danger error">{planError}</div>
                )}
              </div>
              <div className="mt-2 d-flex flex-column">
                <label style={{ fontWeight: "bold" }}>Group #</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="0000000"
                  id="group"
                  value={selectedGroupId}
                  onChange={(e) => {
                    setIsInsuranceUpdate(true);
                    setSelectedGroupId(e.target.value);
                  }}
                />
              </div>
              <div className="mt-2 d-flex flex-column">
                <label style={{ fontWeight: "bold" }}>Policy ID</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="0000000"
                  id="policy_id"
                  value={selectedPolicyId}
                  onChange={(e) => {
                    setIsInsuranceUpdate(true);
                    setSelectedPolicyId(e.target.value);
                  }}
                />
              </div>
              <div className="mt-2 d-flex flex-column">
                <label style={{ fontWeight: "bold" }}>Coverage Type</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect4"
                  value={selectedCoverageType}
                  onChange={(e) => {
                    setIsInsuranceUpdate(true);
                    setSelectedCoverageType(e.target.value);
                  }}
                >
                  <option>Select Plan</option>
                  {coverageTypeList &&
                    coverageTypeList.length > 0 &&
                    coverageTypeList.map((item: any, index: any) => {
                      return (
                        <option
                          key={index + Math.random() + "plan"}
                          value={item.value}
                        >
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="d-flex justify-content-center mt-2 flex-column">
                <label style={{ fontWeight: "bold" }}> Notes </label>
                <textarea
                  name=""
                  id="notes"
                  className="form-control"
                  placeholder="Type a message..."
                  value={selectedNote}
                  onChange={(e) => {
                    setIsInsuranceUpdate(true);
                    setSelectedNote(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>

            <div className="mt-2 d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setIsShowInsuranceManage(true);
                }}
              >
                Add Annual Insured $ Limits
              </button>
            </div>
            <div
              className="mt-2 d-flex flex-column shadow-sm p-2"
              style={{ borderRadius: "2px" }}
            >
              <label style={{ fontWeight: "bold" }}>
                Apply to my Family Member
              </label>
              <div className="d-flex pt-1">
                <select
                  className="form-select"
                  name="dependantid"
                  onChange={onChnageFamilyMember}
                >
                  <option key="select">select</option>
                  {dependants &&
                    dependants.length > 0 &&
                    dependants
                      .filter(
                        (fItem: any) => fItem.id !== selectedDependant?.id
                      )
                      .map((dependant: any) => {
                        return (
                          <option key={dependant.id} value={dependant.id}>
                            {dependant.firstName + " " + dependant.lastName}
                          </option>
                        );
                      })}
                </select>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    console.log("selectedFamilyMember", selectedFamilyMember);
                    if (selectedFamilyMember?.id) {
                      setIsInsuranceUpdate(true);
                      setFamilyList([
                        ...familyList,
                        { ...selectedFamilyMember, isInternal: true },
                      ]);
                    }
                  }}
                >
                  Add
                </button>
              </div>
              <div className="d-flex justify-content-between p-2 flex-column">
                <div className="d-flex">
                  <label style={{ fontWeight: "bold" }}>Family Member</label>
                  <label style={{ fontWeight: "bold", marginLeft: "40px" }}>
                    Policy#
                  </label>
                </div>
                <div>
                  {familyList?.length > 0 &&
                    familyList
                      .filter((item: any) => item.isDeleted !== true)
                      .map((fItem: any, index: any) => {
                        return (
                          <Stack
                            direction="horizontal"
                            gap={3}
                            className="mb-2 align-items-center"
                            id={index}
                          >
                            <div className="d-flex" style={{ width: "100%" }}>
                              <label
                                style={{ flex: "0 0 130px", marginTop: "5px" }}
                              >
                                {fItem.firstName} {fItem.lastName}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="policy number"
                                name="policy"
                                id={`policy${index}`}
                                value={fItem.policyId || ""}
                                onChange={(e) => {
                                  console.log("Event object:", e); // Log the whole event
                                  console.log("Input value:", e?.target?.value); // Log the input value
                                  onChangePolicyNumber(e?.target?.value, index); // Call your function
                                }}
                              />
                            </div>
                            <button
                              className="btn p-0"
                              onClick={() => {
                                onRemoveRow(index);
                              }}
                            >
                              <i className="bi bi-x-circle-fill text-danger"></i>
                            </button>
                          </Stack>
                        );
                      })}
                </div>
              </div>
              {issentOtp && (
                <div className="d-flex flex-column mt-2">
                  <label style={{ fontWeight: "bold" }}>
                    {" "}
                    One-Time password
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="OTP"
                    name="otp"
                    maxLength={4}
                    pattern="\d{4}"
                    ref={otpInputRef}
                    value={otp}
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
          </div> */}
        </div>
        <div className="offcanvas-action d-flex">
          {!issentOtp ? (
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-link"
                disabled={!isInsuranceUpdate}
                onClick={() => {
                  sendOTP();
                }}
              >
                Update
              </button>
            </div>
          ) : (
            <div className="d-flex justify-content-end mt-2">
              <button
                type="button"
                className="btn btn-link"
                disabled={!isInsuranceUpdate}
                onClick={() => {
                  updateInsuranceDetail();
                }}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </Offcanvas>
      <Offcanvas
        className="insurance_manage"
        placement="end"
        show={isShowInsuranceManage}
        onHide={() => {
          setIsShowInsuranceManage(false);
        }}
      >
        <div className="offcanvas-action d-flex">
          <button
            type="button"
            className="btn btn-link p-0 fw-normal fs-7"
            onClick={() => {
              setIsShowAddCoverage(true);
            }}
          >
            Add Coverage
          </button>

          <button
            type="button"
            className="btn btn-link p-0 fw-normal fs-7 ms-auto"
            onClick={() => {
              setIsShowInsuranceManage(false);
            }}
          >
            Close
          </button>
        </div>

        <div className="offcanvas-header">
          <h5 className="offcanvas-title">Insurance Details</h5>
        </div>
        <div className="offcanvas-other">
          <div className="mb-3">
            <label className="form-label">Dependent</label>
            <select
              className="form-select"
              name="dependantid"
              onChange={onChnageDependant}
            >
              {dependants &&
                dependants.length > 0 &&
                dependants.map((dependant: any) => {
                  return (
                    <option key={dependant.id} value={dependant.id}>
                      {dependant.firstName + " " + dependant.lastName}
                    </option>
                  );
                })}
            </select>
          </div>

          {coverageDetails && coverageDetails.length > 0 && (
            <div className="d-flex mt-3">
              <button
                className="flex-fill me-2 btn btn-dark fw-normal fs-7"
                onClick={() => {
                  setIsShowViewCoverage(true);
                }}
              >
                View Expense
              </button>
              <button
                className="flex-fill btn btn-dark fw-normal fs-7"
                onClick={() => {
                  setIsShowAddSpent(true);
                }}
              >
                Add Expense
              </button>
            </div>
          )}
        </div>
        <div className="offcanvas-body">
          {coverageDetails && coverageDetails.length === 0 && (
            <div className="alert alert-warning mb-4 text-center" role="alert">
              You don't have any coverage details, please add coverage details.
            </div>
          )}
          <div className="coverages_list">
            {coverageDetails &&
              coverageDetails.length > 0 &&
              coverageDetails.map((coverage: any) => {
                return (
                  <>
                    <div
                      key={coverage.id}
                      className="d-flex align-items-start justify-content-between mb-2"
                    >
                      <div className="coverage_name">
                        {coverage.name} Coverage
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-link p-0 fw-normal fs-7"
                          onClick={() => {
                            setIsShowEditCoverage(true);
                            setSelectedCoverage(coverage);
                          }}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                    {coverage &&
                      coverage.details.length > 0 &&
                      coverage.details.map((item: any) => {
                        return (
                          <div
                            key={item.id + item.name}
                            className="coverage_item"
                          >
                            <div className="d-flex justify-content-end fs-5 fw-medium">
                              ${item.totalExpense ? item.totalExpense : 0}.00
                            </div>

                            <div
                              className="progress my-2"
                              style={{ height: "3px" }}
                            >
                              <div
                                className="progress-bar"
                                style={{
                                  width:
                                    (item.totalExpense / item.amount) * 100 +
                                    "%",
                                }}
                              ></div>
                            </div>

                            <div className="d-flex align-items-start justify-content-between">
                              <div className="fs-7 opacity-50">{item.name}</div>
                              <div className="fs-7 opacity-50">
                                ${item.amount}.00
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </>
                );
              })}
          </div>
        </div>
      </Offcanvas>
      {/* <AddCoverage isShow={isShowAddCoverage} handleClose={() => { setIsShowAddCoverage(false) }} /> */}
      {/* <EditCoverage isShow={isShowEditCoverage} handleClose={() => { setIsShowEditCoverage(false) }} /> */}
      {/* <AddSpent isShow={isShowAddSpent} handleClose={() => { setIsShowAddSpent(false) }} /> */}
      {/* <ViewCoverage isShow={isShowViewCoverage} handleClose={() => { setIsShowViewCoverage(false) }} /> */}
      <SharedOffCanvas
        isOpen={isShowAddCoverage}
        handleClose={() => {
          setIsShowAddCoverage(false);
        }}
        Children={AddCoverage}
        handleSuccess={() => {
          setIsShowAddCoverage(false);
          fetchCoverageData();
        }}
        dependant={selectedDependant}
      />
      <SharedOffCanvas
        isOpen={isShowAddSpent}
        handleClose={() => {
          setIsShowAddSpent(false);
        }}
        Children={AddSpent}
        handleSuccess={() => {
          setIsShowAddSpent(false);
          fetchCoverageData();
        }}
        dependant={selectedDependant}
      />
      <SharedOffCanvas
        isOpen={isShowViewCoverage}
        handleClose={() => {
          setIsShowViewCoverage(false);
        }}
        Children={ViewCoverage}
        handleSuccess={() => {
          setIsShowViewCoverage(false);
          fetchCoverageData();
        }}
        dependant={selectedDependant}
        // coverageDetails={coverageDetails}
        coverageData={coverageDetails}
        handleApiCall={() => {
          fetchCoverageData();
        }}
      />
      <SharedOffCanvas
        isOpen={isShowEditCoverage}
        handleClose={() => {
          setIsShowEditCoverage(false);
        }}
        Children={EditCoverage}
        handleSuccess={() => {
          setIsShowEditCoverage(false);
          fetchCoverageData();
        }}
        dependant={selectedDependant}
        coverageDetails={selectedCoverage}
      />
    </>
  );
};
