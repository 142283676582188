import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { IAppState } from "../../../store";
import { userLogout } from "../../../store/auth/actions";
import { Emergency } from "../../components/dashboardNew/emergency/Emergency";
import { DigitalForm } from "../dashboardNew/digitalform/DigitalForm";
import { Profile } from "../dashboardNew/profile/Profile";
import { SharedOffCanvas } from "../sharedOffCanvas/SharedOffCanvas";
import { SearchProviderModal } from "../../../components/searchProvider/SearchProviderModal";
import configs from "../../../configs/apiConfigs";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";

export const Header = () => {
  const patientData = useSelector(
    (state: IAppState) => state.patientData.patientDataResponse,
    shallowEqual
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const [isShowEmergency, setIsShowEmergency] = useState(false);
  const [isShowDigitalForm, setIsShowDigitalForm] = useState(false);
  const [isShowProfile, setIsShowProfile] = useState(false);
  const [user, setUser]: any = useState("");
  const [isShowSearchProviderModal, setIsShowSearchProviderModal] =
    useState(false);

  useEffect(() => {
    if (patientData) {
      let ID = patientData.id;
      setUser(
        patientData.dependant.find((item: any) => {
          return Number(item.loginId) === Number(ID);
        })
      );
    }
  }, [patientData]);
  return (
    <>
      <header className="main-header border-bottom">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <div>
              <div className="logo">
                <a href="/" className="d-block logo-img">
                  <img
                    style={{ width: "90px" }}
                    src={require("../../assets/images/logo.svg")}
                  />
                </a>
              </div>
            </div>

            {/* <div className="search">
                        <input type="text" className="form-control" id="location" value="1810 Ironstone Dr..." />
                        </div> */}
            {location && location.pathname !== "/search-provider" && (
              <div className="ex-new d-flex align-items-center ms-auto">
                {/* <span className="fw-medium">Book Appointment</span> */}
                {/* <button type="button" className="btn btn-dark btn-sm ms-2 me-2 rounded-pill fs-7 px-3" onClick={() => { setIsShowSearchProviderModal(true) }}>Book Appointment</button> */}
                <button
                  type="button"
                  className="btn btn-dark btn-sm ms-2 me-2 rounded-pill fs-7 px-3"
                  onClick={() => {
                    // history.push({ pathname: '/search-provider', state: { type: 'existing' } });
                    setIsShowSearchProviderModal(true);
                  }}
                >
                  Book Appointment
                </button>
                {/* <button type="button" className="btn btn-dark btn-sm rounded-pill fs-7 px-3">Existing Provider</button> */}
              </div>
            )}
            {location && location.pathname === "/search-provider" && (
              <div className="ex-new d-flex align-items-center ms-auto">
                <button
                  type="button"
                  className="btn btn-outline-dark btn-sm ms-2 me-2 fs-7 px-3"
                  onClick={() => {
                    history.push({ pathname: "board" });
                  }}
                >
                  <i className="bi bi-arrow-left-short"></i> My Account
                </button>
              </div>
            )}
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-link d-none d-sm-block"
                onClick={() => {
                  setIsShowEmergency(true);
                }}
              >
                Triage/Emergency
              </button>

              <button
                type="button"
                className="btn btn-link d-none d-sm-block"
                onClick={() => {
                  setIsShowDigitalForm(true);
                }}
              >
                Digital Forms
              </button>
              <div className=" m-2" style={{ cursor: "pointer" }}>
                <HomeRoundedIcon
                  onClick={() => {
                    history.push("/");
                  }}
                />
              </div>
              <button
                className="avatar flex-shrink-0 img-circle ms-2 p-0 border-0"
                onClick={() => {
                  setIsShowProfile(true);
                }}
              >
                {/* {user.firstName ? user.firstName.charAt(0) : ''}{user.lastName ? user.lastName.charAt(0) : ''} */}
                {patientData &&
                patientData.profilephoto &&
                patientData.profilephoto.name &&
                patientData.profilephoto.type ? (
                  <img
                    src={
                      configs.IMAGE_URL +
                      "patient/" +
                      patientData.profilephoto.name +
                      "-100x100." +
                      patientData.profilephoto.type
                    }
                  />
                ) : patientData && patientData.firstName ? (
                  patientData.firstName.charAt(0)
                ) : (
                  ""
                )}
                {patientData && patientData.lastName
                  ? patientData.lastName.charAt(0)
                  : ""}
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* <div className="d-block d-sm-none">
                <div className="d-flex">
                    <button type="button" className="btn btn-link"
                        onClick={() => { setIsShowEmergency(true) }}
                    >
                        Triage/Emergency
                    </button>

                    <button type="button" className="btn btn-link"
                        onClick={() => { setIsShowDigitalForm(true) }}
                    >
                        Digital Forms
                    </button>
                </div>
            </div> */}

      <SharedOffCanvas
        isOpen={isShowEmergency}
        handleClose={() => {
          setIsShowEmergency(false);
        }}
        Children={Emergency}
        handleSuccess={() => {
          setIsShowEmergency(false);
        }}
      />
      <SharedOffCanvas
        isOpen={isShowDigitalForm}
        handleClose={() => {
          setIsShowDigitalForm(false);
        }}
        Children={DigitalForm}
        handleSuccess={() => {
          setIsShowDigitalForm(false);
        }}
      />
      <SharedOffCanvas
        isOpen={isShowProfile}
        handleClose={() => {
          setIsShowProfile(false);
        }}
        Children={Profile}
        handleSuccess={() => {
          setIsShowProfile(false);
        }}
      />
      <SharedOffCanvas
        placement={"top"}
        addclass={"new_and_ex"}
        isOpen={isShowSearchProviderModal}
        handleClose={() => {
          setIsShowSearchProviderModal(false);
        }}
        Children={SearchProviderModal}
        handleSuccess={() => {
          setIsShowSearchProviderModal(false);
        }}
        handleSrcIframe={(type: any) => {
          setIsShowSearchProviderModal(false);
          history.push({ pathname: "/search-provider", state: { type: type } });
        }}
      />
    </>
  );
};
