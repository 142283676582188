import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
export const Map = () => {
    let googleMapsPromise: any;
    let googleMap: any;
    let maps: any;
    let currentDirectionRenderer: any;
    const [userLocation, setUserLocation] = useState({ latitude: '', longitude: '' });
    const location: any = useLocation();

    useEffect(() => {
        console.log('Location', location);
        if (!maps) {
            getGoogleMaps().then(async (google: any) => {
                const center = { lat: 43.6205, lng: -79.5132 };
                const map = new google.maps.Map(document.getElementById('gmap_canvas'), {
                    zoom: 10,
                    center: center,
                    streetViewControl: !1,
                    scrollwheel: 1,
                    draggable: 1,
                    disableDoubleClickZoom: !0,
                    disableDefaultUI: !0,
                    gestureHandling: 'cooperative',
                    zoomControl: true,
                    maxZoom: 15,
                    minZoom: 3,
                    name: 'xcare',
                    styles: [{
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [{
                            visibility: "off"
                        }]
                    }, {
                        featureType: "transit.station",
                        elementType: "labels",
                        stylers: [{
                            visibility: "off"
                        }]
                    }],
                    //@ts-ignore
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                });
                console.log('map', map);
            
                googleMap = map;
                maps = await google;
                google.maps.event.addListenerOnce(map, 'idle', () => {
                    drawRouteOnHover(map);
                })
            });
        } else {
            if (maps) {
                 drawRouteOnHover(maps);
            }
        }
    }, []);
    const drawRouteOnHover = (mapss: any) => {
        let { userLoc, clinic } = location.state;
        if (userLoc && userLoc.latitude && userLoc.longitude && clinic && clinic.latitude && clinic.longitude) {
            //@ts-ignore
            const directionsService = new google.maps.DirectionsService();
            //@ts-ignore
            var directionsDisplay = new google.maps.DirectionsRenderer();
            directionsDisplay.setMap(mapss);
            //@ts-ignore
            var originLatLng = 43.328674 + ',' + -79.817734;
            // var originLatLng = userLoc.latitude + ',' + userLoc.longitude;
            //@ts-ignore
            var destinationLatLng = clinic.latitude + ',' + clinic.longitude;

            var request = {
                origin: originLatLng,
                destination: destinationLatLng,
                //@ts-ignore
                travelMode: google.maps.DirectionsTravelMode.DRIVING
            };
            directionsService.route(request, function (response: any, status: any) {
                //@ts-ignore
                if (status === google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                }
            });
        }
    }
    
    const getGoogleMaps=async()=> {
        // If we haven't already defined the promise, define it
        if (!googleMapsPromise) {
             googleMapsPromise = await new Promise((resolve) => {
                // Add a global handler for when the API finishes loading
                //@ts-ignore
                window.resolveGoogleMapsPromise = () => {
                    // Resolve the promise
                    //@ts-ignore
                    resolve(google);

                    // Tidy up
                    //@ts-ignore
                    delete window.resolveGoogleMapsPromise;
                };

                // Load the Google Maps API
                const script = document.createElement("script");
                const API = 'AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8';
                script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
                script.async = true;
                document.body.appendChild(script);
            });
        }

        // Return a promise for the Google Maps API
        return googleMapsPromise;
    }
    return (
        <div className="map-right-sidebar">
            <div style={{height:'785px',width:'1595px'}} id="gmap_canvas"></div>
        </div>
    )
}
