import React from 'react'

export const NoCard = ({ name = "" }: any) => {
    return (
        <div className="appo_card card p-4 position-relative alert-warning">
            <div className="nocard text-center pt-4 mt-2">
                <div><i className="bi bi-exclamation-triangle fs-4"></i></div>
                You don't have any {name} <br />appointments
            </div>
        </div>
    )
}
