import axios from "axios"
import configs from "../configs/apiConfigs";
import {BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandlers";
import { getCookie } from "../utils/cookies";

export const fetchClinicWiseAppointments = (clinicId:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "traigeappointment/"+clinicId+"/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};
// export const submitEmergencyRequest = (data: any) => {
//     const request: CommonApiOptions = {
//         url: configs.API_URL + "emergencyrequest/",
//         apiOptions: {
//             headers: attachHeader(true),
//             withCredentials: true
//         },
//         method: METHOD_TYPE.POST,
//         body: {
//             type: BODY_TYPE.FORM_DATA,
//             data: data
//         }
//     };
//     return commonApi(request);
// }

export const submitEmergencyRequest = (data: any) => {
    const formData = new FormData();
    formData.append('clinicId', data.clinicId);
    formData.append('appointmentId', data.appointmentId);
    formData.append('subject', data.subject);
    formData.append('description', data.description);
    formData.append('video', data.video);
    for (let i = 0; i < data.photos.length; i++){
        formData.append('photos[' + i + ']', data.photos[i]);
    }
    return axios.post(
        configs.API_URL + "emergencyrequest/", formData, {
        headers: attachHeader(true),
        withCredentials: true
    }).then((response) => ({ response }), error => ({ error }));
    
}