import axios from "axios";
import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import PinInput from "react-pin-input";
import { toast } from "react-toastify";
import configs from "../../configs/apiConfigs";
import { verifyCode } from "../../services/auth";
import TextsmsIcon from "@material-ui/icons/Textsms";
import EmailIcon from "@material-ui/icons/Email";

export const OTPModal = ({
  hideModal,
  sentPhone,
  isLoading,
  setIsLoading,
  patientId,
  codeVerifySuccessLogin,
  sentEmail,
}: any) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeEmail, setVerificationCodeEmail] = useState("");
  const [verificationCodeFalse, setVerificationCodeFalse] = useState("");
  const verifyCodeFunc = () => {
    if (patientId) {
      setIsLoading(true);
      const obj = {};
      if (verificationCode === "" && verificationCodeEmail === "") {
        setVerificationCodeFalse("Please enter code for atleat one.");
        return;
      }
      if (sentPhone) {
        Object.assign(obj, { code: verificationCode });
      }
      if (sentEmail) {
        Object.assign(obj, { ecode: verificationCodeEmail });
      }
      verifyCode(obj, patientId)
        .then((success) => {
          setIsLoading(false);
          if (success && success.status && success.status.error === false) {
            codeVerifySuccessLogin(success);
            hideModal();
            return;
          }
          setVerificationCodeFalse("Something went wrong");
        })
        .catch((err) => {
          setIsLoading(false);
          setVerificationCodeFalse(err.msg);
          console.log(err);
        });
    }
  };
  const sendVerificationCodeEmail = () => {
    if (patientId) {
      setIsLoading(true);
      axios
        .post(configs.API_URL + "register/" + patientId + "/resend/email/", {
          email: sentEmail,
        })
        .then(
          (success) => {
            setIsLoading(false);
            if (success.status === 200) {
              if (success.data.status.error === false) {
                toast.success(success.data.status.msg);
                setVerificationCodeFalse(success.data.status.msg);
              } else {
                toast.error(success.data.status.msg);
              }
            }
          },
          (error) => {
            setIsLoading(false);
            toast.error("Something went wrong");
          }
        );
    }
  };
  const sendVerificationCode = () => {
    if (patientId) {
      setIsLoading(true);
      axios
        .post(configs.API_URL + "register/" + patientId + "/resend/phone/", {
          phone: sentPhone,
        })
        .then(
          (success) => {
            setIsLoading(false);
            if (success.status === 200) {
              if (success.data.status.error === false) {
                toast.success(success.data.status.msg);
                setVerificationCodeFalse(success.data.status.msg);
              } else {
                toast.error(success.data.status.msg);
              }
            }
          },
          (error) => {
            setIsLoading(false);
            toast.error("Something went wrong");
          }
        );
    }
  };
  return (
    <Modal
      centered
      className="newcommon otp_popup"
      show={true}
      onHide={() => {
        hideModal();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Sign in Verification</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="alert alert-danger py-2 text-center">
          {sentPhone && sentEmail && (
            <>
              We have sent a One-Time password, please check your mobile and
              email.
            </>
          )}
          {sentPhone && !sentEmail && (
            <>We have sent a One-Time password, please check your mobile.</>
          )}
          {sentEmail && !sentPhone && (
            <>We have sent a One-Time password, please check your email.</>
          )}
        </div>

        {sentPhone && (
          <>
            <label className="form-label text-center d-block">
              Enter an One-Time password here for mobile number verification
            </label>
            <div className="code_input text-center">
              <div className="d-flex justify-content-center">
                <PinInput
                  length={4}
                  initialValue={""}
                  onChange={(value, index) => {
                    setVerificationCode(value);
                  }}
                  type="numeric"
                  style={{ padding: "10px" }}
                  inputStyle={{}}
                  inputFocusStyle={{ borderColor: "#000000" }}
                  onComplete={(value, index) => {
                    setVerificationCode(value);
                  }}
                />
                <div className="mt-3">
                  <TextsmsIcon />
                </div>
              </div>

              {
                <button
                  className="btn btn-link fs-7"
                  onClick={(e) => {
                    sendVerificationCode();
                  }}
                >
                  Resend Code
                  {isLoading && (
                    <span className="aft_load right">
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </button>
              }
            </div>
          </>
        )}
        {sentEmail && (
          <>
            <label className="form-label text-center d-block">
              Enter an One-Time password here for email verification
            </label>
            <div className="code_input text-center">
              <div className="d-flex justify-content-center">
                <PinInput
                  length={4}
                  initialValue={""}
                  onChange={(value, index) => {
                    setVerificationCodeEmail(value);
                  }}
                  type="numeric"
                  style={{ padding: "10px" }}
                  inputStyle={{}}
                  inputFocusStyle={{ borderColor: "#000000" }}
                  onComplete={(value, index) => {
                    setVerificationCodeEmail(value);
                  }}
                />
                <div className="mt-3">
                  <EmailIcon />
                </div>
              </div>
              {
                <button
                  className="btn btn-link fs-7"
                  onClick={(e) => {
                    sendVerificationCodeEmail();
                  }}
                >
                  Resend Code
                  {isLoading && (
                    <span className="aft_load right">
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </button>
              }
            </div>
          </>
        )}
        {verificationCodeFalse !== "" && (
          <div className="text-danger error">{verificationCodeFalse}</div>
        )}
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <button
          className="btn btn-dark"
          onClick={(e) => {
            verifyCodeFunc();
          }}
          disabled={
            (sentEmail && verificationCodeEmail.length !== 4) ||
            (sentPhone && verificationCode.length !== 4) ||
            isLoading
          }
        >
          {/* <i className="bi bi-arrow-right"></i> */}
          Verify
        </button>
      </Modal.Footer>
    </Modal>
  );
};
