import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandlers";

export const getXcareOffers = (myoffer?: boolean, selectedRegion?: string) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + `offers/?myoffer=${myoffer ? 'true' : 'false'}&filter=${selectedRegion} `,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};

export const getXcareOffersFilters = () => {
    const request: CommonApiOptions = {
        url: configs.API_URL + `offersfilters/`,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};