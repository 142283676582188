import React, { useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import configs from '../../configs/apiConfigs';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { getPatientClinicList } from '../../services/patientchat';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
export const ClinicList = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [clinics, setClinics]: any = useState([]);
    const userData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const [isNoRecord, setIsNoRecord] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        getPatientClinicList().then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                console.log('success', success);
                setClinics(success.clinics);
                if (success.clinics && success.clinics.length <= 0) {
                    setIsNoRecord(true);
                }
                else {
                    // setSelectedClinic(success.clinics[0]);
                    props.handleSelectedClinic(success.clinics[0]);
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }, []);

    const changeclinicposition = (clinic: any) => {
        console.log('clinic', clinics);

        setClinics((clinics: any) => {
            var tempclinics = clinics;
            var clinicindex = tempclinics.findIndex((sclinic: any) => {
                return sclinic.uniqueid === clinic.uniqueid
            })
            tempclinics.splice(clinicindex, 1);
            console.log('tempclinics', tempclinics);
            return [clinic, ...tempclinics]
        })

    }
    return (
        <>
            {
                isLoading &&
                <LoadingIndicator />
            }
            <div className="clinic-list d-none d-md-block">
                {(clinics && clinics.length > 0) ? clinics.map((clinic: any) => {
                    return (
                        <React.Fragment key={clinic.uniqueid}>
                            <ClinicListItem clinic={clinic} dependantId={userData.dependant[0].uniqueId}
                                selectedClinic={props.selectedClinic}
                                handleSelectedClinic={props.handleSelectedClinic}
                                changeclinicposition={(clinic: any) => { changeclinicposition(clinic) }}
                            ></ClinicListItem>
                        </React.Fragment>


                    )
                }) : isNoRecord && <div className="flex-grow-1">
                    <div>No Clinic Found</div>
                </div>}
            </div>
            <div className="clinic-dropdown d-block d-md-none">
                <select className="form-select" value={props.selectedClinic.uniqueid} onChange={(e) => {
                    const clinic = clinics.find((item: any) => item.uniqueid == e.target.value);
                    props.handleSelectedClinic(clinic);
                }}>
                    {
                        clinics.map((clinic: any) => {
                            return (
                                <option key={clinic.uniqueid} value={clinic.uniqueid}>{clinic.name}</option>
                            );
                        })
                    }
                </select>
            </div>
        </>
    )
}


export const ClinicListItem = (props: any) => {
    const [unReadCount, setUnReadCount] = useState(0);
    const [lastMessage, setLastMessage] = useState();

    useEffect(() => {
        getUnReadCount();
        getLastMessage();
    }, []);
    const getUnReadCount = () => {
        try {
            firebase.firestore().collection(configs.CONVERSATION_DEV)
                .where('clinicId', '==', Number(props.clinic.uniqueid))
                .where('dependentUniqueId', '==', Number(props.dependantId))
                .where('metadata.viewByPatientAt', '==', null)
                .onSnapshot((querySnapshot: any) => {
                    console.log('docs', querySnapshot.docs[0]);
                    setUnReadCount(querySnapshot.docs.length);
                    if (querySnapshot.docs.length > 0) {
                        props.changeclinicposition(props.clinic);
                    }
                });
        } catch (error) {
            console.log('error while get UnReadCount', error);
        }
    }

    const getLastMessage = () => {
        try {
            console.log('clinicid', props.clinic.uniqueid, 'dependantid', props.dependantId);
            firebase.firestore().collection(configs.CONVERSATION_DEV)
                .where('clinicId', '==', Number(props.clinic.uniqueid))
                .where('dependentUniqueId', '==', Number(props.dependantId))
                .orderBy('createdAt', 'asc')
                .onSnapshot((querySnapshot: any) => {
                    if (querySnapshot.docs.reverse()[0]) {
                        const pLastMessage = querySnapshot.docs.reverse()[0].data();
                        setLastMessage(pLastMessage.message);
                    }

                });
        } catch (error) {
        }
    }

    const updateViewingAt = (clinicid: any) => {
        firebase.firestore().collection(configs.CONVERSATION_DEV)
            .where("clinicId", "==", Number(clinicid))
            .where("dependentUniqueId", "==", Number(props.dependantId))
            .where("metadata.viewByPatientAt", "==", null)
            .get().then((querySnapshot: any) => {
                querySnapshot.forEach((doc: any) => {
                    const docRef = firebase.firestore().collection(configs.CONVERSATION_DEV).doc(doc.id);
                    docRef.update({
                        "metadata.viewByPatientAt": firebase.firestore.FieldValue.serverTimestamp(),
                    });
                    setUnReadCount(0);
                });
            }).catch((error: any) => {
                console.log('error while update view', error);
            });;
    }

    return (
        <li className="border-bottom">
            <div className={props.selectedClinic.id === props.clinic.id ? "list-group-item list-group-item-action border-0 active" : "list-group-item list-group-item-action border-0"} onClick={() => {
                // props.setSelectedClinic(props.clinic);
                props.handleSelectedClinic(props.clinic);
                updateViewingAt(props.clinic.uniqueid);
            }}>
                <div className="d-flex align-items-center position-relative">
                    <div className="avatar avatar-48 img-circle me-2 text-uppercase">{props.clinic.name.charAt(0)}</div>
                    <div className="flex-grow-1">
                        <div className="d-flex justify-content-between">
                            {props.clinic.name}
                            {unReadCount !== 0 && <span className="badge rounded-pill bg-primary  text-center ms-2 small unread-count">{unReadCount}</span>}
                        </div>
                        <span>{lastMessage}</span>
                    </div>
                </div>
            </div>
        </li>
    )
}