import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandlers";

export const fetchAppointment = (pUniqueId: any,appointmentStatus:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "patient/" + pUniqueId + "/appointment/"+appointmentStatus+"/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};

export const assignAppointment = (data: any, appointmentId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "patient/" + appointmentId + "/assign/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        }
    };
    return commonApi(request);
}

export const writeReviewApi = (data: any,patientUniqueId:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "writereview/" ,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.FORM_DATA,
            data: data
        }
    };
    return commonApi(request);
}

export const fetchAllAppointment = (pUniqueId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "fetchappointments/" + pUniqueId + "/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};