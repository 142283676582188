import React, { useState, useEffect } from 'react';
import { AccountTab } from '../account/AccountTab';
import { Route, Switch } from 'react-router';
import UserInformation from '../../components/account/UserInformation';
import { Notifications } from '../../components/account/Notifications';
export const AccountV2 = () => {
    return (
        <>
            <div className="main-middle-sec" id="main-middle">
                <AccountTab />
                <Switch>
                    <Route path="/user/information" component={UserInformation}></Route>
                    <Route path="/user/notification" component={Notifications}></Route>
                </Switch>
            </div>
        </>
    )
}
