import Cookie from 'js-cookie';
import apiConfigs from '../configs/apiConfigs';
export const getCookie = () => {
    // const str: any = localStorage.getItem('login:Patient');
    // return JSON.parse(str);
    return Cookie.getJSON('patient_cookie');
}

export const isLogin = () => {
    try {
        // const str: any = localStorage.getItem('login:Patient');
        const str: any = Cookie.getJSON('patient_cookie');
        console.log(str);
        if (str === undefined || str === null || str === '') {
            return false;
        }
        // if (JSON.parse(str).uniqueId) {
        if (str.xpr_user_id) {
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
}

export const prepareCookie = () => {
    const json = Cookie.getJSON('patient_cookie');
    let cookieString: string = '';
    for (let key in json) {
        if (json.hasOwnProperty(key)) {
            let val = json[key];
            cookieString = cookieString + key + '=' + val + ';';
        }
    }
    return cookieString;
}

export const removeCookie = () => {
    Cookie.remove('clinic_cookie');
    Cookie.remove("clinic_cookie", { domain: apiConfigs.COOKIE_DOMAIN, path: '/' });
}