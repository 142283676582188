import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
export const AccountTab = () => {

    const location = useLocation();
    const history = useHistory();
    return (
        <section className="middle-navbar px-3">
            <div className="container-fluid">
                <div className="d-flex justify-content-between">
                    <div className="w-100">
                        <div className="nav-title-txt">Account setting</div>
                        <ul className="nav nav-tabs" id="commonTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className={(location.pathname === '/user/information') ? "active nav-link" : 'nav-link'} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                    aria-selected={(location.pathname === '/user/information') ? "true" : "false"}
                                    onClick={() => { history.push('/user/information'); }}
                                >User Information</a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a className={(location.pathname === '/user/notification') ? "active nav-link" : 'nav-link'} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                    aria-selected={(location.pathname === '/user/notification') ? "true" : "false"}
                                    onClick={() => { history.push('/user/notification'); }}

                                >Notifications</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

