import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Stack from "react-bootstrap/Stack";
import { useDispatch } from "react-redux";
import {
  allowAlphabetsAndSpace,
  allowOnlyDigit,
} from "../../../../utils/validators";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../../store/loadingIndicator/actions";
import {
  addCoverage,
  deleteCoverage,
  editCoverage,
} from "../../../services/insuranceCalculator";

export const EditCoverage = ({
  handleModalClose,
  handleSuccess,
  dependant,
  coverageDetails,
}: any) => {
  const dispatch = useDispatch();
  const [coverageType, setCoverageType]: any = useState("");
  const [coverageList, setCoverageList]: any = useState([]);
  const [sIndex, setSIndex]: any = useState("");
  const [sType, setSType]: any = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount]: any = useState("");
  const [coverageIds, setCoverageIds]: any = useState([]);

  useEffect(() => {
    if (
      coverageDetails &&
      coverageDetails.details &&
      coverageDetails.details.length > 0
    ) {
      setCoverageType(coverageDetails.name);
      setCoverageList(coverageDetails.details);
    }
  }, []);

  const onChangeName = (e: any, selectedIndex: any) => {
    if (allowAlphabetsAndSpace(e.target.value)) {
      let data = coverageList.map((item: any, index: any) => {
        if (index === selectedIndex) {
          return {
            ...item,
            name: e.target.value,
          };
        } else {
          return item;
        }
      });
      setCoverageList(data);
    }
    setSIndex(selectedIndex);
    setSType("name");
  };
  const onChangeAmount = (e: any, selectedIndex: any) => {
    if (allowOnlyDigit(e.target.value) === true || e.target.value === "") {
      let data = coverageList.map((item: any, index: any) => {
        if (index === selectedIndex) {
          return {
            ...item,
            amount: e.target.value,
          };
        } else {
          return item;
        }
      });
      setCoverageList(data);
    }
    setSIndex(selectedIndex);
    setSType("amount");
  };
  const onRemove = (selectedIndex: any, coverageId: any) => {
    // if (coverageId) {
    //     dispatch(fetchLoadingPending());
    //     deleteCoverage(coverageId).then((success: any) => {
    //         dispatch(fetchLoadingSuccess(false));
    //         if (success && success.status && success.status.error === false) {
    //             console.log('successCoverageDelete', success);
    //             setCoverageList(coverageList.filter((item:any,index:any)=>index !== selectedIndex));
    //         }
    //     }).catch((err: any) => {
    //         dispatch(fetchLoadingSuccess(false));
    //         console.log(err);
    //     })
    // } else {
    //     setCoverageList(coverageList.filter((item:any,index:any)=>index !== selectedIndex));
    // }
    if (coverageId) {
      setCoverageIds([...coverageIds, coverageId]);
      setCoverageList(
        coverageList.filter((item: any, index: any) => index !== selectedIndex)
      );
    } else {
      setCoverageList(
        coverageList.filter((item: any, index: any) => index !== selectedIndex)
      );
    }
  };
  const isDisabled = () => {
    let findIndex = coverageList.findIndex(
      (item: any) => item.name === "" || item.amount === ""
    );
    if (findIndex) {
      return false;
    } else {
      return true;
    }
  };
  const handleType = (e: any) => {
    if (allowAlphabetsAndSpace(e.target.value)) {
      setType(e.target.value);
    }
  };
  const handleAmount = (e: any) => {
    if (allowOnlyDigit(e.target.value) || e.target.value === "") {
      setAmount(e.target.value);
    }
  };
  const onAdd = () => {
    let tempCoverageList = coverageList;
    tempCoverageList.push({ id: "", name: type, amount: amount });
    setType("");
    setAmount("");
  };
  const onSubmit = () => {
    if (coverageIds && coverageIds.length > 0) {
      dispatch(fetchLoadingPending());
      deleteCoverage(coverageIds.toString())
        .then((success: any) => {
          dispatch(fetchLoadingSuccess(false));
          if (success && success.status && success.status.error === false) {
            handleEditCoverage();
          }
        })
        .catch((err: any) => {
          dispatch(fetchLoadingSuccess(false));
          console.log(err);
        });
    } else {
      handleEditCoverage();
    }
  };
  const handleEditCoverage = () => {
    const request = {
      name: coverageType,
      for: dependant.id,
      coverageDetails: coverageList,
      coverageId: coverageDetails.id,
    };
    dispatch(fetchLoadingPending());
    editCoverage(request)
      .then((success: any) => {
        dispatch(fetchLoadingSuccess(false));
        console.log("success", success);
        if (
          success &&
          success.response &&
          success.response.data &&
          success.response.data.status &&
          success.response.data.status.error === false
        ) {
          toast.success(success.response.data.status.msg);
          handleSuccess();
        }
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
        toast.error("Something went wrong.");
        console.log(err);
      });
  };
  return (
    <>
      <div className="offcanvas-action d-flex">
        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7 ms-auto"
          onClick={() => {
            handleModalClose();
          }}
        >
          Close
        </button>
      </div>
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Edit Dental Coverage</h5>
      </div>
      <div className="offcanvas-body">
        <div className="add_covform">
          <div className="mb-3">
            <label className="form-label">Coverage type</label>
            <input
              type="text"
              className="form-control"
              id=""
              value={coverageType}
              onChange={(e: any) => {
                setCoverageType(e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Coverage details</label>
            {coverageList &&
              coverageList.length > 0 &&
              coverageList.map((coverage: any, index: any) => {
                return (
                  <Stack
                    key={coverage.name}
                    direction="horizontal"
                    gap={3}
                    className="mb-2"
                  >
                    <div>
                      <input
                        autoFocus={index === sIndex && sType === "name"}
                        type="text"
                        className="form-control"
                        id=""
                        value={coverage.name}
                        onChange={(e: any) => {
                          onChangeName(e, index);
                        }}
                      />
                    </div>
                    <div>
                      <input
                        autoFocus={index === sIndex && sType === "amount"}
                        type="text"
                        className="form-control"
                        id=""
                        value={coverage.amount}
                        onChange={(e: any) => {
                          onChangeAmount(e, index);
                        }}
                      />
                    </div>
                    <button
                      className="btn p-0"
                      onClick={() => {
                        onRemove(index, coverage.id);
                      }}
                    >
                      <i className="bi bi-x-circle-fill text-danger"></i>
                    </button>
                  </Stack>
                );
              })}
            <Stack direction="horizontal" gap={3} className="mb-2">
              <div>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Add"
                  value={type}
                  onChange={(e: any) => {
                    handleType(e);
                  }}
                />
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Amount"
                  value={amount}
                  onChange={(e: any) => {
                    handleAmount(e);
                  }}
                />
              </div>
              <button
                className="btn p-0"
                disabled={type === "" || amount === ""}
                onClick={() => {
                  onAdd();
                }}
              >
                <i className="bi bi-plus-circle-fill text-dark"></i>
              </button>
            </Stack>
          </div>
          <div className="mb-3">
            <button
              disabled={isDisabled()}
              type="button"
              className="btn btn-dark w-100 fw-normal"
              onClick={() => {
                onSubmit();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
