import React, { useEffect, useRef, useState } from "react";
import { SigninEmailEdit } from "./SigninEmailEdit";
import { SigninmobileEdit } from "./SigninmobileEdit";
import { ChangePassword } from "./ChangePassword";
import { CommPreferenceEdit } from "./CommPreferenceEdit";
import { PaymentAddCard } from "./PaymentAddCard";
import { userLogout } from "../../../../store/auth/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SharedOffCanvas } from "../../sharedOffCanvas/SharedOffCanvas";
import { getMasterInsuranceList } from "../../../../services/auth";
import { fetchCards, removeStripeCard } from "../../../services/card";
import { toast } from "react-toastify";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../../store/loadingIndicator/actions";
import { fetchProfile, uploadProfilePicture } from "../../../services/profile";
import { ConfirmationModal } from "../../confirmationModal/ConfirmationModal";
import { ReferFriend } from "../xcarepoints/ReferFriend";
import configs from "../../../configs/apiConfigs";
import { patientDataPending } from "../../../../store/patientData/actions";
import { SigninAddressEdit } from "./SigninAddressEdit";

export const Profile = (props: any) => {
  // let uploadRef: any;
  const [isShowSigninEmailEdit, setIsShowSigninEmailEdit] = useState(false);
  const [isShowSigninmobileEdit, setIsShowSigninmobileEdit] = useState(false);
  const [isShowChangePassword, setIsShowChangePassword] = useState(false);
  const [isShowCommEmailEdit, setIsShowCommEmailEdit] = useState(false);
  const [isShowCommMobileEdit, setIsShowCommMobileEdit] = useState(false);
  const [isShowCommPreferenceEdit, setIsShowCommPreferenceEdit] =
    useState(false);
  const [isShowPaymentAddCard, setIsShowPaymentAddCard] = useState(false);
  const [isShowPaymentEditCard, setIsShowPaymentEditCard] = useState(false);
  const [isShowAddressEdit, setIsShowAddressEdit] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector(
    (state: any) => state.patientData.patientDataResponse,
    shallowEqual
  );
  const [user, setUser]: any = useState("");
  const [guarantor, setGuarantor]: any = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [insuranceName, setInsuranceName] = useState("");
  const [insurancePlan, setInsurancePlan] = useState("");
  const [title, setTitle] = useState("");
  const [cardList, setCardList]: any = useState([]);
  const [profileInfo, setProfileInfo]: any = useState("");
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [selectedCardId, setSelectedCardId]: any = useState("");
  const [isShowReferFriend, setIsShowReferFriend] = useState(false);
  const uploadRef: any = useRef();
  const [selectedFile, setSelectedFile]: any = useState("");

  useEffect(() => {
    if (userData) {
      let ID = userData.id;
      setUser(
        userData.dependant.find((item: any) => {
          return Number(item.loginId) === Number(ID);
        })
      );
      fetchProfileData();
      if (userData.insuranceId) {
        getInsurance();
        getCards();
      }
    }
    if (userData && userData.guarantorInfo) {
      setGuarantor(userData.guarantorInfo);
    }
  }, [userData]);
  const fetchProfileData = () => {
    dispatch(fetchLoadingPending());
    fetchProfile()
      .then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.status && success.status.error === false) {
          setProfileInfo(success);
        }
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
        console.log(err);
      });
  };
  useEffect(() => {
    if (profileInfo && profileInfo.insuranceId) {
      getInsurance();
    }
    if (profileInfo) {
      getCards();
    }
  }, [profileInfo]);
  const getCards = () => {
    setIsLoading(true);
    fetchCards()
      .then((success) => {
        setIsLoading(false);
        if (success && success.status && success.status.error === false) {
          setCardList(success);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setCardList([]);
        console.log(err);
      });
  };

  const getInsurance = () => {
    console.log("profileInfo", profileInfo);

    setIsLoading(true);
    getMasterInsuranceList()
      .then((success) => {
        setIsLoading(false);
        if (success && success.status && success.status.error === false) {
          let tempInsurance: any = "",
            tempInsurancePlan: any = "";
          tempInsurance = success.insurance.find((insurance: any) => {
            return Number(insurance.id) === Number(profileInfo.insuranceId);
          });
          if (tempInsurance) {
            tempInsurancePlan = tempInsurance.plan.find((plan: any) => {
              return Number(plan.id) === Number(profileInfo.insurancePlan);
            });
            setInsuranceName(tempInsurance.name);
            if (tempInsurancePlan) {
              setInsurancePlan(tempInsurancePlan.name);
            }
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const removeCard = () => {
    dispatch(fetchLoadingPending());
    removeStripeCard(selectedCardId)
      .then((success) => {
        dispatch(fetchLoadingSuccess(false));
        if (success && success.status && success.status.error === false) {
          toast.success("Card remove successfully");
          getCards();
        }
      })
      .catch((err) => {
        dispatch(fetchLoadingSuccess(false));
        console.log(err);
      });
  };
  const hideConfirmationModal = () => {
    setIsConfirmationModal(false);
  };

  const handleDelete = (Id: any) => {
    setIsConfirmationModal(true);
    setSelectedCardId(Id);
  };
  const handleOnChangeFile = (files: any) => {
    if (files && files[0] && files[0].size && files[0].size > 1048576 * 5) {
      toast.error("Max file size 5 MB.");
      return;
    }
    console.log("files", files[0]);

    if (
      files[0].type === "image/png" ||
      files[0].type === "image/jpg" ||
      files[0].type === "image/jpeg"
    ) {
      setSelectedFile(files[0]);
      dispatch(fetchLoadingPending());
      uploadProfilePicture(
        { uphoto: files[0], type: "upload" },
        userData.uniqueId
      )
        .then((success) => {
          console.log("successProfile", success);
          dispatch(fetchLoadingSuccess(false));
          dispatch(patientDataPending({ uniqueId: userData.uniqueId }));
          fetchProfileData();
          toast.success("Profile uploaded successfully.");
        })
        .catch((error) => {
          dispatch(fetchLoadingSuccess(false));
          console.log("profile upload error", error);
        });
    } else {
      toast.error("File extension is not valid, allowed .png , .jpg and .jpeg");
      return;
    }
  };
  return profileInfo ? (
    <>
      <div className="offcanvas-action d-flex">
        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7"
          onClick={() => {
            dispatch(userLogout());
            history.replace("/login");
          }}
        >
          Sign out
        </button>
        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7 ms-auto"
          onClick={() => {
            props.handleModalClose();
          }}
        >
          Close
        </button>
      </div>
      <div className="offcanvas-profile">
        <div className="avatar avatar-xxl img-circle mb-2">
          {profileInfo &&
          profileInfo.profilephoto &&
          profileInfo.profilephoto.name &&
          profileInfo.profilephoto.type ? (
            <img
              src={
                configs.IMAGE_URL +
                "patient/" +
                profileInfo.profilephoto.name +
                "-100x100." +
                profileInfo.profilephoto.type
              }
            />
          ) : profileInfo.firstName ? (
            profileInfo.firstName.charAt(0)
          ) : (
            ""
          )}
          {profileInfo.lastName ? profileInfo.lastName.charAt(0) : ""}
        </div>
        <div>
          <button
            className="btn p-1 px-3 btn-outline-primary"
            onClick={() => {
              uploadRef.current.click();
            }}
          >
            Edit
          </button>
          <input
            type="file"
            className="btn btn-upload"
            placeholder="Upload Completed Form"
            ref={uploadRef}
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e: any) => {
              handleOnChangeFile(e.target.files);
            }}
          />
        </div>

        <div className="fs-5">
          {profileInfo.firstName + " " + profileInfo.lastName}
        </div>
        {/* <div className="fs-7 opacity-50">{profileInfo.uniqueId}</div> */}
      </div>
      <div className="offcanvas-body">
        <div className="prof_block">
          <div className="profile_title">Profile</div>
          <div className="d-flex align-items-start justify-content-between border-bottom pb-2 mb-2">
            <div className="">
              <div>Email</div>
              <span className="opacity-50 fs-7">
                {profileInfo.email ? profileInfo.email : "Not available."}
              </span>
            </div>
            <button
              type="button"
              className="btn btn-link p-0 fw-normal fs-7"
              onClick={() => {
                setIsShowSigninEmailEdit(true);
                setTitle("Sign in");
              }}
            >
              Edit
            </button>
          </div>
          <div className="d-flex align-items-start justify-content-between border-bottom pb-2 mb-2">
            <div className="">
              <div>Mobile number</div>
              <span className="opacity-50 fs-7">
                {profileInfo.phone
                  ? "+1 " + profileInfo.phone
                  : "Not available."}
              </span>
            </div>
            <button
              type="button"
              className="btn btn-link p-0 fw-normal fs-7"
              onClick={() => {
                setIsShowSigninmobileEdit(true);
                setTitle("Sign in");
              }}
            >
              Edit
            </button>
          </div>
          <div className="d-flex align-items-start justify-content-between border-bottom pb-2 mb-2">
            <div className="">
              <div>Password</div>
              <span className="opacity-50 fs-7">********</span>
            </div>
            <div className="to-right-box">
              <button
                type="button"
                className="btn btn-link p-0 fw-normal fs-7"
                onClick={() => {
                  setIsShowChangePassword(true);
                }}
              >
                Change Password
              </button>
              {/* <div className="view-password-control opacity-50 fs-7">
                                    <i className="bi bi-eye active"></i>
                                    <i className="bi bi-eye-slash"></i>
                                </div> */}
            </div>
          </div>
        </div>
        <div className="prof_block">
          <div className="profile_title">Communication</div>
          <div className="d-flex align-items-start justify-content-between border-bottom pb-2 mb-2">
            <div className="">
              <div>Email</div>
              <span className="opacity-50 fs-7">
                {profileInfo.communication.email
                  ? profileInfo.communication.email
                  : "Not available."}
              </span>
            </div>
            <button
              type="button"
              className="btn btn-link p-0 fw-normal fs-7"
              onClick={() => {
                setIsShowSigninEmailEdit(true);
                setTitle("Communication");
              }}
            >
              Edit
            </button>
          </div>
          <div className="d-flex align-items-start justify-content-between border-bottom pb-2 mb-2">
            <div className="">
              <div>Mobile number</div>
              <span className="opacity-50 fs-7">
                {profileInfo.communication.phone
                  ? "+1 " + profileInfo.communication.phone
                  : "Not available."}
              </span>
            </div>
            <button
              type="button"
              className="btn btn-link p-0 fw-normal fs-7"
              onClick={() => {
                setIsShowSigninmobileEdit(true);
                setTitle("Communication");
              }}
            >
              Edit
            </button>
          </div>
          <div className="d-flex align-items-start justify-content-between border-bottom pb-2 mb-2">
            <div className="">
              <div>Preference</div>
              <span className="opacity-50 fs-7">
                {profileInfo.communication.peference}
              </span>
            </div>
            <button
              type="button"
              className="btn btn-link p-0 fw-normal fs-7"
              onClick={() => {
                setIsShowCommPreferenceEdit(true);
              }}
            >
              Edit
            </button>
          </div>
          <div className="d-flex align-items-start justify-content-between border-bottom pb-2 mb-2">
            <div className="">
              <div>Address</div>
              <span className="opacity-50 fs-7">
                {profileInfo.communication.formatted_address
                  ? profileInfo.communication.formatted_address
                  : "Not available."}
              </span>
            </div>
            <button
              type="button"
              className="btn btn-link p-0 fw-normal fs-7"
              onClick={() => {
                setIsShowAddressEdit(true);
                setTitle("Address");
              }}
            >
              Edit
            </button>
          </div>
        </div>
        <div className="prof_block">
          <div
            className={
              cardList &&
              cardList.stripeCards &&
              cardList.stripeCards.length > 0
                ? ""
                : "border-bottom mb-2 pb-2"
            }
          >
            Payment info
          </div>
          <div className="d-flex align-items-start justify-content-between border-bottom pb-2 mb-2">
            <div className="">
              {/* <div>Payment info</div> */}
              {cardList && !cardList.stripeCards && (
                <span className="opacity-50 fs-7">Payment info not added.</span>
              )}
            </div>

            {cardList && !cardList.stripeCards && (
              <div>
                <button
                  type="button"
                  className="btn btn-link p-0 fw-normal fs-7"
                  onClick={() => {
                    setIsShowPaymentAddCard(true);
                  }}
                >
                  Add new
                </button>
              </div>
            )}
          </div>
          {cardList &&
            cardList.stripeCards &&
            cardList.stripeCards.length > 0 &&
            cardList.stripeCards.map((card: any) => {
              return (
                <div
                  key={card.cardId}
                  className="d-flex align-items-start justify-content-between border-bottom pb-2 mb-2"
                >
                  <div className="">
                    <div>{card.name}</div>
                    <span className="opacity-50 fs-7">
                      <i className="bi bi-credit-card-2-back-fill"></i>{" "}
                      {card.brand} **** **** **** {card.last4}
                    </span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-link p-0 fw-normal fs-7"
                      onClick={() => {
                        handleDelete(card.cardId);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="prof_block d-none">
          <div className="profile_title">Loyalty and Reward</div>
          <div className="d-flex align-items-start justify-content-between border-bottom pb-2 mb-2">
            <div className="">
              <div>My Rewards</div>
              <span className="opacity-50 fs-7">
                {profileInfo.reward} Points (Value $25 CAD)
              </span>
            </div>
            <button type="button" className="btn btn-link p-0 fw-normal fs-7">
              Redeem
            </button>
          </div>
          <div className="d-flex align-items-start justify-content-between border-bottom pb-2 mb-2">
            <div className="">
              <div>My Loyalty Points</div>
              <span className="opacity-50 fs-7">
                {profileInfo.loyalty} Points (Value $25 CAD)
              </span>
            </div>
            <button type="button" className="btn btn-link p-0 fw-normal fs-7">
              Redeem
            </button>
          </div>
        </div>
        {insuranceName && (
          <div className="prof_block">
            {/* <div className="profile_title">
                                Insurance
                            </div> */}
            <div className="border-bottom mb-2 pb-2">Insurance Info</div>
            <div className="d-flex align-items-start border-bottom pb-2 mb-2">
              <div className="">
                <div className="opacity-50 fs-7">
                  {insuranceName ? insuranceName : "-"}
                </div>
                <div className="opacity-50 fs-7">
                  Plan: {insurancePlan ? insurancePlan : "-"}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="prof_block">
          {/* <div className="profile_title">
                            Refer a Friend
                        </div> */}
          <div className="border-bottom mb-2 pb-2">Refer & Earn</div>
          <div className="d-flex align-items-start justify-content-between pb-2 mb-2">
            <div className="">
              {/* <div>Refer & Earn</div> */}
              <span className="opacity-50 fs-7">
                {profileInfo.referral.sent} out of {profileInfo.referral.limit}{" "}
                referral
              </span>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-link p-0 fw-normal fs-7 me-2"
                onClick={() => {
                  setIsShowReferFriend(true);
                }}
              >
                Refer a friend
              </button>
              <button
                type="button"
                className="btn btn-link p-0 fw-normal fs-7 d-none"
                onClick={() => {
                  setIsShowReferFriend(true);
                }}
              >
                Share link
              </button>
            </div>
          </div>
        </div>
      </div>
      <SharedOffCanvas
        isOpen={isShowSigninEmailEdit}
        handleClose={() => {
          setIsShowSigninEmailEdit(false);
        }}
        Children={SigninEmailEdit}
        handleSuccess={() => {
          setIsShowSigninEmailEdit(false);
        }}
        title={title}
        email={
          title === "Sign in"
            ? profileInfo.email
            : profileInfo.communication.email
        }
        user={profileInfo}
      />
      <SharedOffCanvas
        isOpen={isShowAddressEdit}
        handleClose={() => {
          setIsShowAddressEdit(false);
        }}
        Children={SigninAddressEdit}
        handleSuccess={() => {
          setIsShowAddressEdit(false);
        }}
        title={title}
        address={profileInfo?.communication?.formatted_address}
        user={profileInfo}
      />
      <SharedOffCanvas
        isOpen={isShowSigninmobileEdit}
        handleClose={() => {
          setIsShowSigninmobileEdit(false);
        }}
        Children={SigninmobileEdit}
        handleSuccess={() => {
          setIsShowSigninmobileEdit(false);
        }}
        title={title}
        phone={
          title === "Sign in"
            ? profileInfo.phone
            : profileInfo.communication.phone
        }
        user={profileInfo}
      />
      <SharedOffCanvas
        isOpen={isShowChangePassword}
        handleClose={() => {
          setIsShowChangePassword(false);
        }}
        Children={ChangePassword}
        handleSuccess={() => {
          setIsShowChangePassword(false);
        }}
      />
      <SharedOffCanvas
        isOpen={isShowCommPreferenceEdit}
        handleClose={() => {
          setIsShowCommPreferenceEdit(false);
        }}
        Children={CommPreferenceEdit}
        handleSuccess={() => {
          setIsShowCommPreferenceEdit(false);
        }}
        user={profileInfo}
      />
      <SharedOffCanvas
        isOpen={isShowPaymentAddCard}
        handleClose={() => {
          setIsShowPaymentAddCard(false);
        }}
        Children={PaymentAddCard}
        handleSuccess={() => {
          setIsShowPaymentAddCard(false);
          getCards();
        }}
      />
      <SharedOffCanvas
        isOpen={isShowReferFriend}
        handleClose={() => {
          setIsShowReferFriend(false);
        }}
        Children={ReferFriend}
        handleSuccess={() => {
          setIsShowReferFriend(false);
        }}
      />
      {isConfirmationModal && (
        <ConfirmationModal
          isConfirm={isConfirmationModal}
          hideConfirmationModal={hideConfirmationModal}
          onClickDelete={() => {
            hideConfirmationModal();
            removeCard();
          }}
        />
      )}
      {/* <SigninEmailEdit isShow={isShowSigninEmailEdit} handleClose={() => { setIsShowSigninEmailEdit(false) }} /> */}
      {/* <SigninmobileEdit isShow={isShowSigninmobileEdit} handleClose={() => { setIsShowSigninmobileEdit(false) }} /> */}
      {/* <ChangePassword isShow={isShowChangePassword} handleClose={() => { setIsShowChangePassword(false) }} /> */}
      {/* <CommEmailEdit isShow={isShowCommEmailEdit} handleClose={() => { setIsShowCommEmailEdit(false) }} />
                <CommMobileEdit isShow={isShowCommMobileEdit} handleClose={() => { setIsShowCommMobileEdit(false) }} /> */}
      {/* <CommPreferenceEdit isShow={isShowCommPreferenceEdit} handleClose={() => { setIsShowCommPreferenceEdit(false) }} /> */}
      {/* <PaymentAddCard isShow={isShowPaymentAddCard} handleClose={() => { setIsShowPaymentAddCard(false) }} /> */}
      {/* <PaymentEditCard isShow={isShowPaymentEditCard} handleClose={() => { setIsShowPaymentEditCard(false) }} /> */}
    </>
  ) : (
    <></>
  );
};
