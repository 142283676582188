import {
    AUTH, AuthState, AuthActionTypes
} from './types';

export const initialState: AuthState = {
    pending: false,
    error: null,
    showWaitingPresc: false
};

export const authReducer = (state = initialState, action: AuthActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case AUTH.LOGIN_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case AUTH.LOGIN_PENDING:
            return {
                ...state,
                pending: true,
                loginRequest: action.payload
            }
        case AUTH.LOGIN_SUCCESS:
            return {
                ...state,
                pending: false,
                loginResponse: action.login
            }
        case AUTH.REGISTER_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case AUTH.REGISTER:
            return {
                ...state,
                pending: true,
                registerRequest: action.payload
            }
        case AUTH.REGISTER_SUCCESS:
            return {
                ...state,
                pending: false,
                registerResponse: action.register
            }
        case AUTH.SHOW_WAITING_PRESC:
            return {
                ...state,
                showWaitingPresc: action.payload
            }
        case AUTH.SHOW_WAITING_PAYMENT:
            return {
                ...state,
                showWaitingPayment: action.payload
            }
        default:
            return NewState;

    }
};