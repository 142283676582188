import {
    AppointmentDetailPendingAction, AppointmentDetailRequest, APPOINTMENT, AppointmentDetailErrorAction,
    AppointmentDetailResponse, AppointmentDetailSuccessAction, ChangeAppointmentRemiderRequest, ChangeAppointmentRemiderPendingAction, ChangeAppointmentRemiderErrorAction, ChangeAppointmentRemiderSuccessAction, ChangeAppointmentRemiderResponse, WriteReviewRequest, WriteReviewPendingAction, WriteReviewErrorAction, WriteReviewResponse, WriteReviewSuccessAction, AssignAppointmentRequest, AssignAppointmentPendingAction, AssignAppointmentErrorAction, AssignAppointmentResponse, AssignAppointmentSuccessAction
} from './types';
export const appointmentDetailPending = (request: AppointmentDetailRequest): AppointmentDetailPendingAction => {
    return {
        type: APPOINTMENT.LOAD_PENDING,
        payload: request
    };
};
export const appointmentDetailError = (error: any): AppointmentDetailErrorAction => {
    return {
        type: APPOINTMENT.LOAD_ERROR,
        error: error
    };
};
export const appointmentDetailSuccess = (response: AppointmentDetailResponse): AppointmentDetailSuccessAction => {
    return {
        type: APPOINTMENT.LOAD_SUCCESS,
        appointmentDetail: response
    };
};

/* Change Appointment Reminder */
export const changeAppointmentRemiderPending = (request: ChangeAppointmentRemiderRequest): ChangeAppointmentRemiderPendingAction => {
    return {
        type: APPOINTMENT.CHANGE_APPOINTMENT_REMINDER_PENDING,
        payload: request
    };
};
export const changeAppointmentRemiderDetailError = (error: any): ChangeAppointmentRemiderErrorAction => {
    return {
        type: APPOINTMENT.CHANGE_APPOINTMENT_REMIDER_ERROR,
        error: error
    };
};
export const changeAppointmentRemiderDetailSuccess = (response: ChangeAppointmentRemiderResponse): ChangeAppointmentRemiderSuccessAction => {
    return {
        type: APPOINTMENT.CHANGE_APPOINTMENT_REMINDER_SUCCESS,
        changeAppointmentRemider: response
    };
};

/* Write review and rating */
export const writeReviewPending = (request: WriteReviewRequest): WriteReviewPendingAction => ({
    type: APPOINTMENT.WRITE_REVIEW_PENDING,
    payload: request
});
export const writeReviewError = (error: any): WriteReviewErrorAction => ({
    type: APPOINTMENT.WRITE_REVIEW_ERROR,
    payload: error
});
export const writeReviewSuccess = (success: WriteReviewResponse): WriteReviewSuccessAction => ({
    type: APPOINTMENT.WRITE_REVIEW_SUCCESS,
    payload: success
});
export const assignAppointmentPending = (request: AssignAppointmentRequest): AssignAppointmentPendingAction => ({
    type: APPOINTMENT.ASSIGN_APPOINTMENT,
    payload: request
});
export const assignAppointmentError = (error: any): AssignAppointmentErrorAction => ({
    type: APPOINTMENT.ASSIGN_APPOINTMENT_ERROR,
    payload: error
});
export const assignAppointmentSuccess = (response: AssignAppointmentResponse): AssignAppointmentSuccessAction => ({
    type: APPOINTMENT.ASSIGN_APPOINTMENT_SUCCESS,
    payload: response
});