export enum PAYMENT {
    PAYMENT_CLIENT_SECRET = 'PAYMENT_CLIENT_SECRET',
    PAYMNT_CLIENT_SECRET_ERROR = 'PAYMENT_CLIENT_SECRET_ERROR',
    PAYMENT_CLIENT_SECRET_SUCCESS = 'PAYMENT_CLIENT_SECRET_SUCCESS'
}
export interface PaymentState {
    pending: boolean;
    error: any;
    paymentClientSecretRequest?: PaymentClientSecretRequest;
    paymentClientSecretResponse?: PaymentClientSecretResponse;
}
export interface PaymentClientSecretRequest {
    appointmentId: string;
}
export interface PaymentClientSecretResponse {
    client_secret: string;
}
export interface PaymentClientSecretPendingAction {
    type: PAYMENT.PAYMENT_CLIENT_SECRET,
    payload: PaymentClientSecretRequest
}
export interface PaymentClientScretSuccessAction {
    type: PAYMENT.PAYMENT_CLIENT_SECRET_SUCCESS,
    payload: PaymentClientSecretResponse
}
export interface PaymentClientSecretErrorAction {
    type: PAYMENT.PAYMNT_CLIENT_SECRET_ERROR,
    payload: any;
}
export type PaymentActionType = PaymentClientScretSuccessAction | PaymentClientSecretErrorAction | PaymentClientSecretPendingAction;