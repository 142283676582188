import firebase from 'firebase';
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import { getCookie } from '../../../utils';
import configs from '../../configs/apiConfigs';
import { CHAT } from '../../constants';
import * as _ from 'lodash';
import $ from 'jquery';
import { Avatar } from '@material-ui/core';
import nl2br from 'react-nl2br';
import { chatNotificationProvider } from '../../services/groupPin';

let unsubscribe: any;
export const Messages = (props: any) => {
    const [messagelist, setMessageList] = useState([]);
    const [message, setMessage] = useState('');
    const myMessageListRef = React.useRef(messagelist);
    const [groupDoc, setGroupDoc]: any = useState(null);
    const patientData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);

    const setMyMessageList = (data: any) => {
        myMessageListRef.current = data;
        setMessageList(data);
    }
    const getMessages = () => {
        unsubscribe && unsubscribe();
        unsubscribe = firebase.firestore().collection(configs.MESSAGE_COLLECTION).doc(`${props.group.groupId}`).onSnapshot((snapshot) => {
            const data = snapshot.data();
            console.log(data);
            if (data && data.messages && Array.isArray(data.messages)) {
                setMyMessageList(data?.messages || []);
                scrollToBottom();
                updateViewBy();
            }
        });
    }
    const updateViewBy = async () => {
        let patientDoc = await firebase.firestore().collection(configs.USER_COLLECTION).where('uniqueId', '==', patientData.uniqueId).get();
        let patientFirebaseId = patientDoc.docs.map((doc) => ({ ...doc.data, id: doc.id }))[0].id;
        if (patientFirebaseId) {
            const docRef = await firebase.firestore().collection(configs.MESSAGE_COLLECTION).doc(props.group.groupId);
            const snapshot = await docRef.get();
            const data = snapshot.data();
            let messages = [];
            if (data && Array.isArray(data.messages)) {
                messages = data.messages.map((item) => {
                    if (!item.readBy.includes(patientFirebaseId)) {
                        return {
                            ...item,
                            readBy: [...item.readBy, patientFirebaseId]
                        }
                    } else {
                        return item;
                    }
                })
            }
            docRef.update('messages', messages);
        }
    }

    const onMessageSendClick = async (e: any) => {
        if (message.trim() !== '') {
            const docRef = firebase.firestore().collection(configs.MESSAGE_COLLECTION).doc(`${props.group.groupId}`);
            let patientDoc = await firebase.firestore().collection(configs.USER_COLLECTION).where('uniqueId', '==', patientData.uniqueId).get();
            let patientFirebaseId = patientDoc.docs.map((doc) => ({ ...doc.data, id: doc.id }))[0].id;
            docRef.update('messages', firebase.firestore.FieldValue.arrayUnion({
                messageText: message,
                senderType: CHAT.USER_TYPE.PATIENT,
                sentBy: patientFirebaseId,
                sentAt: new Date(),
                readBy: [],
                isInfo: false
            }));
            if (!(props.group && props.group.requestAcceptedByProvider && props.group.requestAcceptedByProvider.accepted === false)) {
                chatNotificationProvider(props?.group?.doctor?.uniqueid, {
                    title: "New message from " + props?.group?.dependant?.fname + " " + props?.group?.dependant?.lname,
                    body: message,
                    notifications: {
                        appointmentmentUniqueId: props?.group?.appointmentUniqueId,
                        groupDocId: props?.group?.groupId
                    }
                }).then((success) => { }).catch((err) => { });
            }
            setMessage('');
        }
    }

    useEffect(() => {
        if (props.group && props.group.uniqueid) {
            setMyMessageList([]);
            setMessage('');
            getMessages();
            getGroup();
        }

    }, [props.group]);

    const getGroup = () => {
        console.log('---GroupId---', props.group.groupId);
        firebase.firestore().collection(configs.GROUP_COLLECTION).doc(props.group.groupId).onSnapshot((snapshot) => {
            const data = snapshot.data();
            setGroupDoc(data);
        })
    }

    const scrollToBottom = () => {
        $("#chatBox").animate({ scrollTop: $('#chatBox').prop("scrollHeight") }, 50);
    }
    console.log(JSON.parse(JSON.stringify(messagelist)));
    let uniqueMessageById = _.uniqBy(messagelist, 'id');
    let sortedArray = _.sortBy(uniqueMessageById, function (value: any) { return value.sortTime });
    console.log(groupDoc);
    return (
        <div className="col-12 col-md-9 position-relative p-0">
            <div className="provider-chat">
                <div className="title-box">Conversation with {props.group.name}</div>
                <div className="chat-box" id="chatBox">
                    <div className="alert alert-light border py-2 text-center">
                        <div>
                            {
                                moment(props.group.date, 'YYYY-MM-DD').format('MMM DD, YYYY') + " (" + props.group.time + " - " + props.group.endTime + ")"
                            }
                        </div>
                        <div>
                            {
                                props.group && props.group.dependant && props.group.dependant.fname + " " + `${props.group && props.group.dependant && props.group.dependant.lname + " - " + props.group.appointmentType} with ${props.group.doctor.fullname}`
                            }
                        </div>
                    </div>
                    <div className="alert alert-danger border py-2 text-center">
                        <label className="group-chat-label">Three way chat activated, Clinic and provider can see chat now.</label>
                    </div>
                    {
                        messagelist.map((message: any, index) => {
                            if (message.isInfo === true) return null;
                            let clinicAvatar = '';
                            let providerAvatar = '';
                            if (props.group.doctor && props.group.doctor.fullname) {
                                let doctorName: any = props.group.doctor.fullname;
                                doctorName = doctorName.trim();
                                doctorName = doctorName.split(" ");
                                providerAvatar = doctorName[0] ? doctorName[0].charAt(0) : '' + doctorName[1] ? doctorName[1].charAt(0) : '';
                            }
                            if (props.group.clinic && props.group.clinic.name) {
                                let clinicName: any = props.group.clinic.name;
                                clinicName = clinicName.trim();
                                clinicName = clinicName.split(" ");
                                clinicAvatar = clinicName[0] ? clinicName[0].charAt(0) : '' + clinicName[1] ? clinicName[1].charAt(0) : '';
                            }

                            return (
                                <div className={"chat " + (message.senderType === CHAT.USER_TYPE.PATIENT ? ' user' : 'dr')} key={index + props.group.appointmentUniqueId}>
                                    <div className={(message.senderType === CHAT.USER_TYPE.PATIENT ? ' float-end d-flex' : 'float-start d-flex')}>
                                        {
                                            (clinicAvatar && message.senderType === CHAT.USER_TYPE.CLINIC) &&
                                            <div className="avatar avatar-icon avatar-md newAvtr me-2">
                                                <Avatar>{clinicAvatar}</Avatar>
                                            </div>
                                        }
                                        {
                                            (providerAvatar && message.senderType === CHAT.USER_TYPE.PROVIDER) &&
                                            <div className="avatar avatar-icon avatar-md newAvtr me-2">
                                                <Avatar>{providerAvatar}</Avatar>
                                            </div>
                                        }
                                        <div className="chat-bubble">
                                            {nl2br(message.messageText)}
                                        </div>
                                    </div>
                                    <span className="msg_date">
                                        {
                                            message &&
                                            message.sentAt &&
                                            <> {moment(moment.unix(message.sentAt.seconds)).format("MMM DD, YYYY - LT")}
                                                {
                                                    (message.senderType === CHAT.USER_TYPE.CLINIC) &&
                                                    <><br />sent by {props.group.clinic && props.group.clinic.name}</>
                                                }
                                                {
                                                    (message.senderType === CHAT.USER_TYPE.PROVIDER) &&
                                                    <><br />sent by {props.group.doctor.fullname}</>
                                                }
                                            </>
                                        }
                                    </span>
                                </div>
                            )
                        })
                    }
                    {
                        groupDoc && groupDoc.requestAcceptedByProvider && groupDoc.requestAcceptedByProvider.accepted === false &&
                        <div className="alert alert-info py-2 text-center">
                            Provider is not available - <span>{moment(moment.unix(groupDoc.requestAcceptedByProvider.updatedAt.seconds)).format("MMM DD, YYYY - LT")}</span>
                        </div>
                    }
                </div>
                <div className="input-box"><textarea name="" id="" placeholder="Type a message..." value={message} onChange={(e) => {
                    setMessage(e.target.value);
                }}></textarea><button className="enter-btn btn-custom rounded btn text-center" onClick={(e) => {
                    onMessageSendClick(e);
                }}><img className="" src="/static/media/send.50043e21.svg" /></button></div>
            </div>
        </div>
    )
}
