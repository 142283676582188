import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../../store';
import { getMasterInsuranceList } from '../../../services/auth';
import { guarantorEdit } from '../../../services/account';
import ReactInputMask from 'react-input-mask';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Spinner } from 'react-bootstrap';
import { patientDataPending } from '../../../store/patientData/actions';
import { collapseTextChangeRangesAcrossMultipleVersions, couldStartTrivia } from 'typescript';
import { TITLES_LABELS } from '../../constants';
export const GuarantorInfo = () => {
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [insuranceList, setInsuranceList]: any = useState([]);
    const [insurancePlanList, setInsurancePlanList]: any = useState([]);
    const [selectedInsurane, setSelectedInsurance] = useState('');
    const [selectedInsurancePlan, setSelectedInsurancePlan] = useState('');
    const userData = useSelector((state: any) => state.patientData.patientDataResponse, shallowEqual);
    const { register, setValue, control, errors, getValues, handleSubmit, watch } = useForm({
        defaultValues: {
            gFirstName: userData?.guarantorInfo?.firstName,
            gLastName: userData?.guarantorInfo?.lastName,
            gGender: userData?.guarantorInfo?.gender,
            gEmail: userData?.guarantorInfo?.email,
            gPhone: userData?.guarantorInfo?.phone,
            gDob: moment(userData?.guarantorInfo?.dob).toDate(),
            gInsurance: userData?.guarantorInfo?.insuranceId,
            gInsurancePlanName: userData?.guarantorInfo?.insurancePlan,
            gOtherInsurance: '',
            gOtherInsurancePlanName: ''

        }
    });

    useEffect(() => {
        console.log('call')
        if (userData && userData.guarantorInfo) {
            setValue('gFirstName', userData.guarantorInfo.firstName);
        }
    }, [userData]);

    useEffect(() => {
        if (getValues('gInsurance') && getValues('gInsurance') !== "") {
            let insuranceId = getValues('gInsurance');
            let insurance: any = insuranceList.find((item: any) => item.id.toString() === insuranceId.toString());
            if (insurance) {
                if (insuranceId === 'other') {
                    insurance = {
                        ...insurance,
                        plan: [{ id: 'other', name: 'Other' }]
                    }
                } else {
                    insurance = {
                        ...insurance,
                        plan: [...insurance.plan, { id: 'other', name: 'Other' }]
                    }
                }
                setInsurancePlanList(insurance.plan)
                if (insurance.plan[0]) {
                    setValue('gInsurancePlanName', insurance.plan[0].id);
                }
            }
        }
    }, [watch('gInsurance')]);

    const handleEdit = (data: any) => {
        let insurance: any = insuranceList.find((item: any) => item.id === getValues('gInsurance'));
        let insurancePlan = insurancePlanList.find((item: any) => item.id == getValues('gInsurancePlanName'));
        let request = {
            guarantor: {
                id: userData?.guarantorInfo.id,
                fname: getValues('gFirstName'),
                lname: getValues('gLastName'),
                dob: moment(getValues('gDob')).format('YYYY-MM-DD'),
                gender: getValues('gGender'),
                insurance: {
                    id: insurance ? insurance.id : '',
                    name: insurance ? insurance.name === 'Other' ? getValues('gOtherInsurance') : insurance.name : '',
                    plan: insurancePlan ? insurancePlan.id : '',
                    planName: insurancePlan ? insurancePlan.name === 'Other' ? getValues('gOtherInsurancePlanName') : insurancePlan.name : '',
                },
            }
        }
        setIsLoading(true);
        let paitentUniqueId = userData.uniqueId;
        guarantorEdit(request, paitentUniqueId).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                setIsEdit(false);
                dispatch(patientDataPending({
                    uniqueId: userData.uniqueId
                }));
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }
    const clickEditBtn = () => {
        if (isEdit) {
            setIsEdit(false);
        } else {
            if (userData.guarantorInfo.insuranceId) {
                let insurance = insuranceList.find((item: any) => item.id === userData.guarantorInfo.insuranceId);
                setValue('insurancename', insurance.id);
            }
            if (userData.guarantorInfo.insuranceId && userData.guarantorInfo.insurancePlan) {
                let insurance = insuranceList.find((item: any) => item.id === userData.guarantorInfo.insuranceId);
                let insurancePlan = insurance.plan.find((item: any) => item.id === userData.guarantorInfo.insurancePlan);
                setValue('gInsurancePlanName', insurancePlan.id);
            }
            setIsEdit(true);
        }
    }
    useEffect(() => {
        setIsLoading(true);
        getMasterInsuranceList().then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false && userData && userData.guarantorInfo) {
                setInsuranceList([...success.insurance, { id: 'other', name: 'Other', plan: [{ id: 'other', name: 'Other' }] }]);
                setValue('gInsurance', userData.guarantorInfo.insuranceId);
                if (userData && userData.guarantorInfo && userData.guarantorInfo.insuranceId) {
                    let insurance = success.insurance.find((item: any) => item.id === userData.guarantorInfo.insuranceId);
                    if (insurance) {
                        if (userData.guarantorInfo.insuranceId === 'other') {
                            insurance = {
                                ...insurance,
                                plan: [{ id: 'other', name: 'Other' }]
                            }
                        } else {
                            insurance = {
                                ...insurance,
                                plan: [...insurance.plan, { id: 'other', name: 'Other' }]
                            }
                        }
                        setInsurancePlanList(insurance.plan);
                        if (userData.guarantorInfo.insuranceId) {
                            let tempInsuranceList: any = [...success.insurance, { id: 'other', name: 'Other', plan: [{ id: 'other', name: 'Other' }] }];
                            let selectedinsurance: any = tempInsuranceList.find((item: any) => item.id === userData.guarantorInfo.insuranceId);
                            let selectedinsurancePlan = selectedinsurance.plan.find((item: any) => item.id === userData.guarantorInfo.insurancePlan);
                            setSelectedInsurance(selectedinsurance.name);
                            setSelectedInsurancePlan(selectedinsurancePlan.name);
                        }
                    }
                }
                setValue('gDob', moment(userData?.guarantorInfo?.dob).toDate());
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })

    }, [userData]);

    try {
        const { firstName, lastName, gender, email, phone, dob, insuranceId, insurancePlan, id } = userData?.guarantorInfo;
        return (
            <>
                <div className="mt-5">
                    <div className="d-flex align-items-right mb-4 align-items-center">
                        <div className="flex-grow-1">
                            <div className="fs-5 fw-medium">Guarantor info</div>
                        </div>
                        <button type="button" className="edit-btn border-0 me-2" onClick={() => {
                            clickEditBtn()
                        }}>{isEdit ? 'Cancel' : 'Edit'}</button>
                    </div>

                </div>

                <div className="card mb-3">
                    <div className="card-body px-4 guarantor-data">
                        {
                            (!isEdit) &&

                            <div className={"d-md-flex flex-wrap justify-content-between" + (isEdit ? 'd-none' : '')}>
                                <div className="mr-1 my-2">
                                    <div className="text-muted">Name</div>
                                    <div className="fw-medium">{firstName} {lastName}</div>
                                </div>

                                <div className="mr-1 my-2">
                                    <div className="text-muted">Communication email</div>
                                    <div className="fw-medium">{email}</div>
                                </div>
                                <div className="mr-1 my-2">
                                    <div className="text-muted">Mobile Number</div>
                                    <div className="fw-medium">{phone ? TITLES_LABELS.PLUS_ONE + phone : '-'}</div>
                                </div>

                                <div className="mr-1 my-2">
                                    <div className="text-muted">Gender</div>
                                    <div className="fw-medium">{gender}</div>
                                </div>

                            </div>
                        }

                        {/* show this div on edit */}
                        {(isEdit) &&
                            <div className={"editGuarantorInfo" + (isEdit ? '' : 'd-none')}>

                                <div className="fw-medium">Edit Guarantor information</div>
                                <hr />
                                <div className="row g-3">
                                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 my-2">
                                        <label className="form-label">First name</label>
                                        <input type="text" name="gFirstName" className="form-control" placeholder="First name" ref={register({ required: true })} />
                                        {
                                            (errors && errors.gFirstName && errors.gFirstName.type === 'required') &&
                                            <div className="is-invalid">First name is required</div>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 my-2">
                                        <label className="form-label">Last name</label>
                                        <input type="text" name="gLastName" className="form-control" placeholder="Last Name" ref={register({ required: true })} />
                                        {
                                            (errors && errors.gLastName && errors.gLastName.type === 'required') &&
                                            <div className="is-invalid">Last name is required</div>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 my-2">
                                        <label className="form-label">Mobile Number</label>
                                        {/* <input name="gPhone" className="form-control" placeholder="1234567890" ref={register({ required: true })} /> */}
                                        <Controller
                                            as={ReactInputMask}
                                            control={control}
                                            mask="9999999999"
                                            name="gPhone"
                                            className="form-control"
                                            placeholder="mobile number #"
                                            disabled={true}
                                            rules={{ required: true, minLength: { value: 10, message: 'Mobile Number is invalid' } }}
                                        />
                                        {
                                            (errors && errors.gPhone && errors.gPhone.type === 'required') &&
                                            <div className="is-invalid">Mobile Number is required</div>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-3 my-2">
                                        <label className="form-label">Communication email</label>
                                        <input type="email" name="gEmail" className="form-control" placeholder="Email" ref={register({ required: true })} />
                                        {
                                            (errors && errors.gEmail && errors.gEmail.type === 'required') &&
                                            <div className="is-invalid">Email is required</div>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-3 col-xl-3 my-2 miuif_margin">
                                        <label className="form-label">Date of birth</label>
                                        {/* <input type="text" name="gdob" className="form-control" placeholder="Date of birth" ref={register({ required: true })} /> */}
                                        <Controller
                                            name="gDob"
                                            control={control}
                                            render={(props) => {
                                                return (
                                                    <KeyboardDatePicker
                                                        margin="none"
                                                        id={"date-picker-dialog-dob"}
                                                        name={"date-picker-dialog-dob"}
                                                        format="MM/DD/YYYY"
                                                        maxDate={moment().subtract(1, 'days')}
                                                        value={props.value}
                                                        onChange={(date: any) => { props.onChange(date) }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                        }}
                                                    />
                                                )
                                            }}
                                        ></Controller>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-3 col-xl-3 my-2">
                                        <label className="form-label">Gender</label>
                                        <select className="form-select " name="gGender" ref={register({ required: true })}>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                        {
                                            (errors && errors.gGender && errors.gGender.type === 'required') &&
                                            <div className="is-invalid">Gender is required</div>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-3 my-2">
                                        <label className="form-label">Insurance name</label>
                                        <select className="form-select " name="gInsurance" ref={register({ required: true })}>
                                            {
                                                insuranceList.map((item: any) => {
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {
                                            (watch('gInsurance') === 'other') &&
                                            <input type="text" name="gOtherInsurance" className="form-control" placeholder="Enter insurance name" ref={register({ required: true })} />
                                        }
                                        {
                                            (errors && errors.gEmail && errors.gEmail.type === 'required') &&
                                            <div className="is-invalid">Insurance name is required</div>
                                        }
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-3 my-2">
                                        <label className="form-label">Insurance plan</label>
                                        <select className="form-select " name="gInsurancePlanName" ref={register({ required: true })}>
                                            {
                                                insurancePlanList.map((item: any) => {
                                                    return (
                                                        <option value={item.id} key={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        {
                                            (watch('gInsurancePlanName') === 'other') &&
                                            <input type="text" name="gOtherInsurancePlanName" className="mt-3 form-control" placeholder="Enter insurance plan name" ref={register({ required: true })} />
                                        }
                                        {
                                            (errors && errors.gEmail && errors.gEmail.type === 'required') &&
                                            <div className="is-invalid">Insurance plan name is required</div>
                                        }
                                        {/* <input type="text" className="form-control" placeholder="Insurance plan" name="gInsurancePlanName" ref={register({ required: true })} /> */}
                                    </div>


                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-outline-primary me-2" onClick={() => {
                                            clickEditBtn()
                                        }}>Cancel</button>

                                        <button type="button" className="btn btn-primary" onClick={handleSubmit(handleEdit)}>
                                            {
                                                isLoading === true &&
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            }
                                            Save</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    } catch (err) {
        return null
    }
}