import { takeEvery, put } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { attachHeader } from '../utils';
import { AccountUpdateRequest, AccountUpdatePendingAction, ACCOUNT } from "../store/patientData/types";
import { accountUpdateError, accountUpdateSuccess } from "../store/patientData/actions";
import { fetchLoadingPending, fetchLoadingSuccess } from "../store/loadingIndicator/actions";

function execute(request: AccountUpdateRequest) {
    /* const headers = {
        'Content-Type': 'application/json',
    }; */
    const body = {};
    if (request.updateType === 'name') {
        Object.assign(body, { type: 'name', firstName: request.firstName, lastName: request.lastName });
    } else if (request.updateType === 'phone') {
        Object.assign(body, { type: 'phone', phone: request.phone });
    } else if (request.updateType === 'email') {
        Object.assign(body, { type: 'email', email: request.email });
    } else if (request.updateType === 'password' && request.password) {
        Object.assign(body, {
            type: 'password', cpassword: request.password.cpassword, rpassword: request.password.rpassword,
            npassword: request.password.nPassword
        });
    } else if (request.updateType === 'dob') {
        Object.assign(body, {
            type: 'dob', dob: request.dob
        });
    } else if (request.updateType === 'gender') {
        Object.assign(body, {
            type: 'gender', gender: request.gender
        });
    } else if (request.updateType === 'address' && request.address) {
        Object.assign(body, {
            type: 'address', addressline1: request.address.addressline1, addressline2: request.address.addressline2,
            country: request.address.country, state: request.address.state, city: request.address.city,
            postalCode: request.address.postalCode
        });
    }
    return axios.post(CONFIG.API_URL + "patient/" + request.uniqueId + "/account/", body, {
        headers: attachHeader(),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* accountUpdatePendingRun(action: AccountUpdatePendingAction) {
    //    yield delay(2000);
    // yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    console.log(response, error);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(accountUpdateError(response.data));
            } else {
                if (action.payload.updateType === 'name') {
                    yield put(accountUpdateSuccess({
                        ...response.data,
                        updateType: 'name',
                        firstName: action.payload.firstName, lastName: action.payload.lastName
                    }));
                } else if (action.payload.updateType === 'phone') {
                    yield put(accountUpdateSuccess({
                        ...response.data,
                        updateType: 'phone',
                        phone: action.payload.phone
                    }));
                } else if (action.payload.updateType === 'email') {
                    yield put(accountUpdateSuccess({
                        ...response.data,
                        updateType: 'email',
                        email: action.payload.email
                    }));
                } else if (action.payload.updateType === 'dob') {
                    yield put(accountUpdateSuccess({
                        ...response.data,
                        updateType: 'dob',
                        dob: action.payload.dob
                    }));
                } else if (action.payload.updateType === 'gender') {
                    yield put(accountUpdateSuccess({
                        ...response.data,
                        updateType: 'gender',
                        gender: action.payload.gender
                    }));
                } else if (action.payload.updateType === 'address') {
                    yield put(accountUpdateSuccess({
                        ...response.data,
                        updateType: 'address',
                        address: action.payload.address
                    }));
                } else {
                    yield put(accountUpdateSuccess({
                        ...response.data
                    }));
                }
            }
        } else {
            yield put(accountUpdateError(response));
        }
    }
    if (error) {
        yield put(accountUpdateError(error));
    }
    // yield put(fetchLoadingSuccess(false));
}

export function* accountUpdatePendingWatcher() {
    yield takeEvery(ACCOUNT.ACCOUNT_UPDATE_PENDING, accountUpdatePendingRun);
}