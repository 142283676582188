import React from 'react';
import { Modal } from 'react-bootstrap';
import { showAddress } from '../../components/renderAddress';
import configs from '../../configs/apiConfigs';
interface Props {
  onHide: () => void;
  data: any[];
}
interface State {
  data: any[];
}
export default class ClinicSearch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.data
    };
  }
  onHide = () => {
    this.props.onHide();
  }
  render() {
    return (
      <Modal centered show onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Providers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table>
            {
              this.props.data.map((item: any) => {
                return (
                  <tr key={item.id}>
                    {
                      (item.logo && item.logo.name && item.logo.type) ?
                        <img alt="clinicimage" src={configs.IMAGE_URL + 'clinic/' + item.logo.name + '-200x200.' + item.logo.type} height={50} width={50}></img>
                        :
                        <img alt="clinicimage" src={configs.DEFAULT_IMAGE_URL_CLINIC} height={50} width={50}></img>
                    }
                    <p>Clinic name: {item.name}</p>
                    <p>Address {showAddress(item.address)}</p>
                    <p>Provider Id: {item.uniqueId}</p>
                  </tr>
                )
              })
            }
          </table>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={(e) => { this.onHide(); }}>Close</button>
        </Modal.Footer>
      </Modal>
    )
  }
}