export const LABELS = {
    EMAIL: 'Email address',
    PASSWORD: 'Password',
    FORGOT_PASSWORD_DESC: 'Enter the email address or mobile number associated with your account.',
    REGISTER: 'Register New Account',
    BUTTON: {
        LOGIN: 'Login',
        SEND_RESET_LINK: 'Reset password'
    },
    EMAILORPHONE: 'Email or mobile number',
    PHONE: 'Mobile number'
}