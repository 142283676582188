import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandlers";

export const addStripeCard = (data: any) => {
  const request: CommonApiOptions = {
        url: configs.API_URL + "addcard/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.FORM_DATA,
            data: data
        }
    };
    return commonApi(request);
};

export const fetchCards = () => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "cardlist/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};
export const removeStripeCard = (Id:any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + "removecard/"+Id+"/",
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(request);
};