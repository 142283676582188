import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { SharedOffCanvas } from "../../sharedOffCanvas/SharedOffCanvas";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ReferFriendList } from "../xcarepoints/ReferFriendList";
import { ReferByEmailSms } from "./ReferByEmailSms";
import { toast } from "react-toastify";

export const ReferFriend = ({
  handleModalClose,
  handleSuccess,
  setIsShowPoints,
}: any) => {
  const [isShowReferFriendList, setIsShowReferFriendList] = useState(false);
  const [isShowReferByEmailSms, setIsShowReferByEmailSms] = useState(false);
  const userData = useSelector(
    (state: any) => state.patientData.patientDataResponse,
    shallowEqual
  );

  const handleCopyText = () => {
    toast.success("Referral link copied successfully");
    navigator.clipboard.writeText(userData.referralLink);
  };

  return (
    <>
      <div className="offcanvas-action d-flex">
        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7 ms-auto"
          // data-bs-dismiss="offcanvas" aria-label="Close"
          onClick={() => {
            handleModalClose();
          }}
        >
          Close
        </button>
      </div>

      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Refer a Friend</h5>
      </div>

      <div className="offcanvas-body">
        <div className="lootbox_top">
          <div className="lootbox_img">
            <img
              src={require("../../../assets/images/lootbox.png")}
              alt=""
              style={{ maxWidth: "130px" }}
            />
          </div>
          <div className="xcode">
            <div className="fs-7 opacity-50">Your code</div>
            <div className="xcode_copy rounded-3 d-flex flex-row justify-content-between">
              <div>
                {userData && userData.referralCode
                  ? userData.referralCode
                  : "-"}
              </div>
              {userData && userData.referralLink && (
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    handleCopyText();
                  }}
                >
                  <i className="bi bi-clipboard"></i>
                </button>
              )}
            </div>
          </div>
          <div>
            <p>
              Simply copy and share the referral link with your friends. When
              they complete their first appointment, you both get rewarded.
            </p>
          </div>
        </div>

        {/* <div className="sh_btn d-flex justify-content-between py-3 mb-2">
                    <div className="sh_btn_item">
                        <button className="btn btn-trans" type="button">
                            <span className="icon rounded-3 text-white bg-dark">
                                <i className="bi bi-whatsapp"></i>
                            </span>
                            WhatsApp
                        </button>
                    </div>
                    <div className="sh_btn_item">
                        <button className="btn btn-trans" type="button">
                            <span className="icon rounded-3 text-white bg-dark">
                                <i className="bi bi-chat-fill"></i>
                            </span>
                            Messages
                        </button>
                    </div>
                    <div className="sh_btn_item">
                        <button className="btn btn-trans" type="button">
                            <span className="icon rounded-3 text-white bg-dark">
                                <i className="bi bi-three-dots"></i>
                            </span>
                            More
                        </button>
                    </div>
                </div> */}

        <div className="text-center">
          <button
            className="btn btn-link me-3"
            type="button"
            onClick={() => {
              setIsShowReferFriendList(true);
            }}
          >
            My invite list
          </button>
          <button
            className="btn btn-link"
            type="button"
            onClick={() => {
              setIsShowReferByEmailSms(true);
            }}
          >
            Refer by Email or SMS
          </button>
        </div>
      </div>
      {/* <ReferFriendList isShow={isShowReferFriendList} handleClose={() => { setIsShowReferFriendList(false) }} /> */}
      <SharedOffCanvas
        isOpen={isShowReferFriendList}
        handleClose={() => {
          setIsShowReferFriendList(false);
        }}
        Children={ReferFriendList}
        handleSuccess={() => {
          setIsShowReferFriendList(false);
        }}
        setIsShowPoints={setIsShowPoints}
      />
      <SharedOffCanvas
        isOpen={isShowReferByEmailSms}
        handleClose={() => {
          setIsShowReferByEmailSms(false);
        }}
        Children={ReferByEmailSms}
        handleSuccess={() => {
          setIsShowReferByEmailSms(false);
        }}
      />
    </>
  );
};
