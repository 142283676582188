import { ClaimSubmitRequest, ClaimSubmitPendingAction, CLAIM_SUBMIT, ClaimSubmitResponse, ClaimSubmitSuccessAction, ClaimSubmitErrorAction } from "./types";

/* Action Creators */
export const claimSubmitPending = (request: ClaimSubmitRequest): ClaimSubmitPendingAction => {
    return {
        type: CLAIM_SUBMIT.CLAIM_SUBMIT_PENDING,
        payload: request
    };
};

export const claimSubmitSuccess = (response: ClaimSubmitResponse): ClaimSubmitSuccessAction => {
    return {
        type: CLAIM_SUBMIT.CLAIM_SUBMIT_SUCCESS,
        claimSubmit: response
    }
}

export const claimSubmitError = (err: any): ClaimSubmitErrorAction => {
    return {
        type: CLAIM_SUBMIT.CLAIM_SUBMIT_ERROR,
        error: err
    }
}