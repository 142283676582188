import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Helmet from 'react-helmet';
import { TITLES_LABELS, APPOINTMENT_STATUS, CHAT } from '../../constants';
import { Animated } from "react-animated-css";
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { PatientDataState, Appointment, ChangeWriteReview, PatientDataRequest } from '../../store/patientData/types';
import apiConfigs from '../../configs/apiConfigs';
import moment from 'moment';
import { Avatar } from '@material-ui/core';
import { Alert, Modal, ModalBody, ListGroup } from 'react-bootstrap';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { toast } from 'react-toastify';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../store/loadingIndicator/actions';
import { showWaitingPresc, userLogout } from '../../store/auth/actions';
import { roundNumberWithRoundingFactorDecimalFix, useQuery } from '../../utils/global';
import { assignAppointmentPending, writeReviewPending } from '../../store/appointments/actions';
import { WriteReviewRequest, AppointmentDetailState, AssignAppointmentRequest, AssignAppointmentResponse } from '../../store/appointments/types';
import { changeWriteReviewAction, patientDataPending } from '../../store/patientData/actions';
import AppointmentAssignModal from './AppointmentAssignModal';
import * as _ from 'lodash';
import { getCookie } from '../../utils';
interface Props {
    patientDataState: PatientDataState;
    doLoading: typeof fetchLoadingPending;
    loadingSuccess: typeof fetchLoadingSuccess;
    logout: typeof userLogout;
    history: any;
    location: any;
    writeReview: typeof writeReviewPending;
    appointmentState: AppointmentDetailState;
    changeWriteReview: typeof changeWriteReviewAction;
    assignAppointment: typeof assignAppointmentPending;
    patientData: typeof patientDataPending;
    showWaitingPresc: typeof showWaitingPresc;
}
interface State {
    currentClaimStatusOpen: string;
    completeScreeningModal: boolean;
    checkInModal: boolean;
    writeReview: boolean;
    prescreeningQuestions?: any;
    currentQuestion: number;
    currentSelectedAppointment?: any;
    showAlert: boolean;
    checkInBox: number;
    writeReviewForm: {
        score: number,
        comment: string;
    },
    assignAppointmentModal: boolean;
    allNoCheckBox: boolean;
    currentAppointmentType: any;
    cancelled: any;
}
const ratingsCounts: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
class Appointments extends React.Component<Props, State> {
    db: any;
    constructor(props: any) {
        super(props);
        this.state = {
            currentClaimStatusOpen: '',
            completeScreeningModal: false,
            checkInModal: false,
            writeReview: false,
            currentQuestion: 0,
            showAlert: false,
            checkInBox: -1,
            writeReviewForm: {
                score: -1,
                comment: ''
            },
            assignAppointmentModal: false,
            allNoCheckBox: false,
            currentAppointmentType: 'requested', cancelled: ''
        };
        this.yesNoClickPreScreening = this.yesNoClickPreScreening.bind(this);
        this.setPrescreeingToState = this.setPrescreeingToState.bind(this);
        this.addRemoveOptions = this.addRemoveOptions.bind(this);
        this.onFinishClick = this.onFinishClick.bind(this);
        this.onCheckInClick = this.onCheckInClick.bind(this);
    }
    componentDidMount() {
        this.db = firebase.firestore();
        this.setPrescreeingToState();
        console.log(useQuery(this.props.location).get('id'), this.props.patientDataState.patientDataResponse);
        if (useQuery(this.props.location).get('id') && useQuery(this.props.location).get('id') !== ""
            && this.props.patientDataState.patientDataResponse) {
            const appointment = this.props.patientDataState.patientDataResponse.appointments.find((item) => item.uniqueid == useQuery(this.props.location).get('id'));
            if (appointment && appointment.metadata && appointment.metadata.appointment &&
                !(appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt)) {
                this.props.showWaitingPresc(false);
                this.setState({
                    completeScreeningModal: true,
                    currentSelectedAppointment: this.props.patientDataState.patientDataResponse.appointments.find((item) => item.uniqueid === useQuery(this.props.location).get('id'))
                });
            }
        } else {
            this.props.showWaitingPresc(false);
        }
        if (localStorage.getItem('appointmentType')) {
            let data: any;
            data = localStorage.getItem('appointmentType');
            data = JSON.parse(data);
            if (data.type === 'history') {
                this.setState({ currentAppointmentType: APPOINTMENT_STATUS.COMPLETE[0], cancelled: 'cancelled' });
                localStorage.removeItem('appointmentType');
            } else {
                this.setState({ currentAppointmentType: data.type });
                localStorage.removeItem('appointmentType');
            }
        }
    }
    setPrescreeingToState() {
        if (this.props.patientDataState && this.props.patientDataState.patientDataResponse) {
            const findSchedule = this.props.patientDataState.patientDataResponse.appointments.filter((value, index) => {
                return value.currentStatus === APPOINTMENT_STATUS.SCHEDULE[0];
            }).find((value) => {
                return moment(value.date, 'YYYY-MM-DD').isAfter(moment(moment(), 'YYYY-MM-DD'));
            });
            console.log(findSchedule);
            if (findSchedule) {
                this.setState({ showAlert: true });
            }
            const { prescreening } = this.props.patientDataState.patientDataResponse;
            if (prescreening) {
                /*  this.setState({
                     prescreeningQuestions: Object.keys(prescreening).map((value, index) => {
                         if (prescreening[value].options) {
                             return Object.assign(prescreening[value], { answer: [] });
                         }
                         return Object.assign(prescreening[value], { answer: false });
                     })
                 }); */
                this.setState({
                    prescreeningQuestions: prescreening.map((value: any, index: number) => {
                        if (value.options) {
                            return Object.assign(value, { answer: [] });
                        }
                        return Object.assign(value, { answer: '' });
                    })
                });
            }
        }
    }
    yesNoClickPreScreening(questionIndex: number, answer: any) {
        console.log(questionIndex);
        if (questionIndex === this.state.prescreeningQuestions.length - 1 && answer === "yes") {
            this.setState({
                prescreeningQuestions: this.state.prescreeningQuestions.map((ques: any, index: number) => {
                    if (index === questionIndex) {
                        return {
                            ...ques,
                            answer: answer,
                            oldAnswer: answer,
                        }
                    }
                    return ques;
                })
            }, () => {
                console.log(this.state.prescreeningQuestions);
            });
            return;
        }
        this.setState({
            allNoCheckBox: false,
            prescreeningQuestions: this.state.prescreeningQuestions.map((ques: any, index: number) => {
                if (index === questionIndex) {
                    return {
                        ...ques,
                        answer: answer,
                        oldAnswer: answer,
                    }
                }
                return ques;
            })
        }, () => {
            console.log(this.state.prescreeningQuestions);
        });
    }
    addRemoveOptions(questionIndex: number, ques: any) {
        this.setState({
            prescreeningQuestions: this.state.prescreeningQuestions.map((value: any, index: number) => {
                if (questionIndex === index) {
                    const flag = this.state.prescreeningQuestions[questionIndex].answer.findIndex((option: any, oIndex: number) => {
                        return ques === option;
                    });
                    if (flag !== -1) {
                        return {
                            ...value,
                            answer: value.answer.filter((qValue: any) => { return (qValue !== ques) })
                        }
                    } else if (ques.toLowerCase() === "none") {
                        return {
                            ...value,
                            answer: [ques]
                        }
                    }
                    return {
                        ...value,
                        answer: [...value.answer, ques].filter((qValue: any) => { return qValue.toLowerCase() !== "none" })
                    }
                }
                return value;
            })
        });
    }
    onFinishClick() {
        if (this.state.currentSelectedAppointment && this.props.patientDataState && this.props.patientDataState.patientDataResponse) {
            let allowed = true;
            for (let start = 0; start < this.state.prescreeningQuestions.length; start++) {
                const obj = this.state.prescreeningQuestions[start];
                if (obj.options && obj.answer.length === 0 && start !== this.state.prescreeningQuestions.length - 1) {
                    allowed = false;
                    toast.warn('Please answer all questions.');
                    return;
                } else if ((obj.answer === undefined || obj.answer === null || obj.answer === '') && start !== this.state.prescreeningQuestions.length - 1) {
                    allowed = false;
                    toast.warn('Please answer all questions.');
                    return;
                } else if (start === this.state.prescreeningQuestions.length - 1 && obj.answer === "no") {
                    allowed = false;
                    toast.warn("Please verify that your answeres are correct.");
                    return;
                }
            }
            if (allowed) {
                this.props.doLoading();
                let passPrescreenAuto = false;
                const prescreeningQuestions = this.state.prescreeningQuestions.map((value: any) => {
                    if (value.options) {
                        return {
                            ...value,
                            answer: value.answer.toString()
                        }
                    }
                    return value;
                });
                passPrescreenAuto = prescreeningQuestions.slice(0, prescreeningQuestions.length - 1).find((item: any) => item.answer === "yes") === undefined;
                const obj = {
                    "appointmentId": Number(this.state.currentSelectedAppointment.uniqueid),
                    "senderId": Number(this.props.patientDataState.patientDataResponse.uniqueId),
                    "sender": CHAT.SENDER.PATIENT,
                    "action": CHAT.ACTION.PBP,
                    "actionType": CHAT.ACTION_TYPE.PRESCREENING,
                    "text": prescreeningQuestions,
                    "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "sendToCId": this.state.currentSelectedAppointment.clinic.uniqueid,
                    "sendToDId": this.state.currentSelectedAppointment.doctor.uniqueid,
                    "metadata": {
                        "viewByClinicAt": null,
                        "viewByPatientAt": null,
                    },
                    "dependantId": Number(this.state.currentSelectedAppointment.dependant.uniqueid)
                };
                const objPrescreen = {
                    "appointmentId": Number(this.state.currentSelectedAppointment.uniqueid),
                    "senderId": Number(this.props.patientDataState.patientDataResponse.uniqueId),
                    "sender": CHAT.SENDER.PATIENT,
                    "action": CHAT.ACTION.PVBP,
                    "actionType": CHAT.ACTION_TYPE.PRESCREENING_STATUS,
                    "text": 'Verified automatically from patient side.',
                    "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                    "sendToCId": this.state.currentSelectedAppointment.clinic.uniqueid,
                    "sendToDId": this.state.currentSelectedAppointment.doctor.uniqueid,
                    "dependantId": Number(this.state.currentSelectedAppointment.dependant.uniqueid),
                    dateTime: {
                        date: this.state.currentSelectedAppointment.date,
                        time: this.state.currentSelectedAppointment.time
                    }
                };
                this.db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
                    .then(async () => {
                        if (passPrescreenAuto && this.props.patientDataState && this.props.patientDataState.patientDataResponse) {
                            try {
                                await this.db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(objPrescreen);
                            } catch (err) {

                            }
                        }
                        if (useQuery(this.props.location).get('webview') !== null && useQuery(this.props.location).get('webview') !== "") {
                            this.props.loadingSuccess(false);
                            this.props.history.replace('/exitapp');
                        } else {
                            console.log("Document successfully written!");
                            this.setPrescreeingToState();
                            this.setState({ completeScreeningModal: false, currentQuestion: 0, allNoCheckBox: false });
                            const login: any = getCookie();
                            setTimeout(() => {
                                this.props.patientData({ uniqueId: login.xpr_user_id });
                            }, 2000);
                        }
                    })
                    .catch((error: any) => {
                        this.props.logout();
                        this.props.history.push('/');
                        console.error("Error writing document: ", error);
                    });
            }
        }
    }
    onCheckInClick(text: string) {
        if (this.state.currentSelectedAppointment && this.props.patientDataState && this.props.patientDataState.patientDataResponse) {
            const obj = {
                "appointmentId": Number(this.state.currentSelectedAppointment.uniqueid),
                "senderId": Number(this.props.patientDataState.patientDataResponse.uniqueId),
                "sender": CHAT.SENDER.PATIENT,
                "action": CHAT.ACTION.CBP,
                "actionType": CHAT.ACTION_TYPE.CHECKIN,
                "text": text,
                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "sendToCId": this.state.currentSelectedAppointment.clinic.uniqueid,
                "sendToDId": this.state.currentSelectedAppointment.doctor.uniqueid,
                "metadata": {
                    "viewByClinicAt": null,
                    "viewByPatientAt": null,
                },
                "dependantId": Number(this.state.currentSelectedAppointment.dependant.uniqueid),
                dateTime: {
                    date: this.state.currentSelectedAppointment.date,
                    time: this.state.currentSelectedAppointment.time
                }
            };
            this.props.doLoading();
            console.log(obj);
            this.db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
                .then(() => {
                    this.props.loadingSuccess(false);
                    console.log("Document successfully written!");
                    this.setState({ checkInModal: false });
                    window.location.href = '/';
                })
                .catch((error: any) => {
                    this.props.logout();
                    this.props.history.push('/');
                    console.error("Error writing document: ", error);
                });
        }
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.appointmentState !== prevProps.appointmentState) {
            if (this.props.appointmentState.error !== null) {
                if (this.props.appointmentState.error.status && this.props.appointmentState.error.status.error) {
                    toast.error(this.props.appointmentState.error.status.msg);
                    return;
                }
                toast.error('Something went wrong');
            } else if (this.props.appointmentState.writeReviewResponse && this.props.appointmentState.writeReviewResponse !== prevProps.appointmentState.writeReviewResponse &&
                this.props.appointmentState.pending === false) {
                this.props.changeWriteReview({
                    appointmentUniqueId: this.state.currentSelectedAppointment.uniqueid,
                    comment: this.state.writeReviewForm.comment,
                    score: this.state.writeReviewForm.score.toString()
                });
                this.setState({
                    writeReview: false, currentSelectedAppointment: undefined, writeReviewForm: {
                        comment: '', score: -1
                    }
                });
                toast.success(this.props.appointmentState.writeReviewResponse.status.msg);
            } else if (this.props.appointmentState.assignAppointmentResponse &&
                this.props.appointmentState.assignAppointmentResponse !== prevProps.appointmentState.assignAppointmentResponse &&
                this.props.appointmentState.pending === false) {
                toast.success(this.props.appointmentState.assignAppointmentResponse.status.msg);
                this.assignAppointmentAddObjectFirebase(this.props.appointmentState.assignAppointmentResponse);
            }
        }
    }
    changeAppointmentAssignModal = (status: boolean) => {
        this.setState({
            assignAppointmentModal: status
        });
    }
    assignAppointmentAddObjectFirebase = (assignAppointmentResponse: AssignAppointmentResponse) => {
        if (this.state.currentSelectedAppointment && this.props.patientDataState && this.props.patientDataState.patientDataResponse) {
            const obj = {
                "appointmentId": Number(this.state.currentSelectedAppointment.uniqueid),
                "senderId": Number(this.props.patientDataState.patientDataResponse.uniqueId),
                "sender": CHAT.SENDER.PATIENT,
                "senderName": this.props.patientDataState.patientDataResponse.firstName + " " + this.props.patientDataState.patientDataResponse.lastName,
                "assignToEmail": assignAppointmentResponse.email,
                "action": "Appointment is assigned to another user",
                "actionType": CHAT.ACTION_TYPE.APPONINTMENT_ASSIGN,
                "text": "Assign Appointment Book",
                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "sendToCId": this.state.currentSelectedAppointment.clinic.uniqueid,
                "sendToDId": this.state.currentSelectedAppointment.doctor.uniqueid,
                "dependantId": Number(this.state.currentSelectedAppointment.dependant.uniqueid),
                dateTime: {
                    date: this.state.currentSelectedAppointment.date,
                    time: this.state.currentSelectedAppointment.time
                }
            };
            this.db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
                .then(() => {
                    this.props.loadingSuccess(false);
                    console.log("Document successfully written!");
                    this.setState({
                        assignAppointmentModal: false,
                        currentSelectedAppointment: ''
                    });
                })
                .catch((error: any) => {
                    this.props.loadingSuccess(false);
                    this.props.logout();
                    this.props.history.push('/');
                    console.error("Error writing document: ", error);
                });
        }
    }
    changeAppointmentData = (type: string) => {
        if (type === 'history') {
            this.setState({ currentAppointmentType: APPOINTMENT_STATUS.COMPLETE[0], cancelled: 'cancelled' })
        } else {
            this.setState({ currentAppointmentType: type, cancelled: '' })
        }

    }
    onClickAppointment = (Id: any) => {
        if (Id) {
            if (this.state.currentAppointmentType === APPOINTMENT_STATUS.COMPLETE[0] && this.state.cancelled === "cancelled") {
                localStorage.setItem('appointmentType', JSON.stringify({ type: 'history' }));
            } else {
                localStorage.setItem('appointmentType', JSON.stringify({ type: this.state.currentAppointmentType }));
            }
            // this.props.history.push("/appointment/" + Id + this.props.location.search)
        }

    }
    render() {
        return (
            <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDuration={1000} isVisible={true}>
                <section className="pantient-view">
                    <Helmet><title>{TITLES_LABELS.META.APPOINTMENT}</title></Helmet>
                    <div className="container">
                        <div className="row justify-content-center">
                            {/* <h4 className="page-title">List of appointments</h4> */}
                            <div className="col-lg-12">
                                <div className="in-nav mb-3">
                                    <button className={"btn btn-outline-secondary btn-sm " + (this.state.currentAppointmentType === 'requested' ? 'active' : '')} onClick={() => { this.changeAppointmentData('requested') }}>Requested</button> &nbsp;
                                    <button className={"btn btn-outline-secondary btn-sm " + (this.state.currentAppointmentType === APPOINTMENT_STATUS.SCHEDULE[0] ? 'active' : '')} onClick={() => { this.changeAppointmentData(APPOINTMENT_STATUS.SCHEDULE[0]) }}>Scheduled</button> &nbsp;
                                    <button className={"btn btn-outline-secondary btn-sm " + ((this.state.currentAppointmentType === APPOINTMENT_STATUS.COMPLETE[0] && this.state.cancelled === '') ? 'active' : '')} onClick={() => { this.changeAppointmentData(APPOINTMENT_STATUS.COMPLETE[0]) }}>Completed</button> &nbsp;
                                    <button className={"btn btn-outline-secondary btn-sm " + ((this.state.cancelled === 'cancelled') ? 'active' : '')} onClick={() => { this.changeAppointmentData('history') }}>History</button>
                                </div>
                            </div>
                            <div className="col-12">
                                {
                                    this.state.showAlert === true &&
                                    <Alert className="noti_covid" variant="danger" dismissible>
                                        <p>Complete the Pre-Screening process to activate the Check-in feature, without completing the Pre-screening and notifying your arrival with the check-in button you cannot enter the clinic</p>
                                    </Alert>
                                }

                                <ul className="appointment-list">
                                    {
                                        (this.props.patientDataState.patientDataResponse && this.props.patientDataState.patientDataResponse.appointments
                                            && this.props.patientDataState.patientDataResponse.appointments.length > 0) &&
                                        <>
                                            {
                                                this.props.patientDataState.patientDataResponse.appointments.map((appointment: Appointment, index: number) => {
                                                    if (appointment.currentStatus === this.state.currentAppointmentType || appointment.currentStatus === this.state.cancelled) {
                                                        let status = '';
                                                        let classStatus = '';
                                                        if (appointment.currentStatus === APPOINTMENT_STATUS.REQUEST[0]) {
                                                            status = APPOINTMENT_STATUS.REQUEST[0];
                                                            classStatus = APPOINTMENT_STATUS.REQUEST[1];
                                                        } else if (appointment.currentStatus === APPOINTMENT_STATUS.SCHEDULE[0]) {
                                                            status = APPOINTMENT_STATUS.SCHEDULE[0];
                                                            classStatus = APPOINTMENT_STATUS.SCHEDULE[1];
                                                        } else if (appointment.currentStatus === APPOINTMENT_STATUS.CANCEL[0]) {
                                                            status = APPOINTMENT_STATUS.CANCEL[0];
                                                            classStatus = APPOINTMENT_STATUS.CANCEL[1];
                                                        } else if (appointment.currentStatus === APPOINTMENT_STATUS.COMPLETE[0]) {
                                                            status = APPOINTMENT_STATUS.COMPLETE[0];
                                                            classStatus = APPOINTMENT_STATUS.COMPLETE[1];
                                                        }
                                                        let url = '';
                                                        if (appointment.doctor.photo && appointment.doctor.photo.name && appointment.doctor.photo.type) {
                                                            url = apiConfigs.IMAGE_URL + 'doctor/' + appointment.doctor.photo.name + '-200x200.' + appointment.doctor.photo.type;
                                                        }
                                                        let doctorName: any;
                                                        doctorName = appointment.doctor.fullname.replace("Dr.", "");
                                                        doctorName = doctorName.trim();
                                                        doctorName = doctorName.split(" ");
                                                        const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
                                                        const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
                                                        return (
                                                            <li className="apl d-flex" key={index + Math.random()}>
                                                                <NavLink className="media flex-grow-1" to={"/appointment/" + appointment.uniqueid + this.props.location.search} onClick={() => { this.onClickAppointment(appointment.uniqueid) }} >
                                                                    <div className="media-img mr-3">
                                                                        {
                                                                            url !== '' ?
                                                                                <img src={url} className="img-fluid" /> :
                                                                                <Avatar>{letterOne}{letterTwo}</Avatar>
                                                                        }
                                                                    </div>
                                                                    <div className="media-body align-self-center">
                                                                        <div className="dr-name">{appointment.doctor.fullname}</div>
                                                                        <div className="clinic-name">{appointment.clinic.name}</div>
                                                                        <div className="clinic-name">
                                                                            {/*  {
                                                                            appointment.requests.appointmentFor === "other" ?
                                                                                appointment.requests.dependantName + " - " + appointment.requests.dependantRelation :
                                                                                appointment.dependant.firstName + " " + appointment.dependant.lastName
                                                                        } */}
                                                                            {
                                                                                appointment.dependant &&
                                                                                appointment.dependant.firstName + " " + appointment.dependant.lastName
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </NavLink>
                                                                {
                                                                    appointment.currentStatus === APPOINTMENT_STATUS.SCHEDULE[0] &&
                                                                    <div className={"apt_prescrt" + ((appointment.currentStatus === APPOINTMENT_STATUS.SCHEDULE[0] && appointment.date
                                                                        && appointment.time && moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() <= 48
                                                                        && moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() >= 0 &&
                                                                        appointment?.metadata && appointment?.metadata?.appointment &&
                                                                        (!appointment?.metadata?.appointment?.prescreening || (!(appointment?.metadata?.appointment?.prescreening && appointment?.metadata?.appointment?.prescreening?.attempt)))) ? '' : ' disabled') +
                                                                        ((appointment?.metadata?.appointment?.prescreening && appointment?.metadata?.appointment?.prescreening?.attempt && appointment?.metadata?.appointment?.prescreening?.verifiedOn) ? ' verified' : '') +
                                                                        ((!(appointment?.metadata?.appointment?.prescreening && appointment?.metadata?.appointment?.prescreening?.attempt)) ? ' pending' : '') +
                                                                        ((appointment?.metadata?.appointment?.prescreening && appointment?.metadata?.appointment?.prescreening?.attempt && !appointment?.metadata?.appointment?.prescreening?.verifiedOn) ? ' completed' : '')
                                                                    }>
                                                                        <a href="javascript:void(0)" className="media" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            if (appointment.currentStatus === APPOINTMENT_STATUS.SCHEDULE[0] && appointment.date
                                                                                && appointment.time && moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() <= 48
                                                                                && moment.duration(moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').diff(moment(moment(), 'YYYY-MM-DD LT'))).asHours() >= 0 &&
                                                                                appointment.metadata && appointment.metadata.appointment &&
                                                                                (!appointment.metadata.appointment.prescreening || (!(appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt)))) {
                                                                                this.setState({
                                                                                    completeScreeningModal: true,
                                                                                    currentSelectedAppointment: appointment
                                                                                });
                                                                            }
                                                                        }}>

                                                                            <div className="media-img mr-3">
                                                                                <img src={require('../../assets/images/covid-icon.svg')} className="" />
                                                                            </div>

                                                                            <div className="media-body">
                                                                                Pre-Screening
                                                                                {
                                                                                    (appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt && appointment.metadata.appointment.prescreening.verifiedOn) &&
                                                                                    <span>Verified</span>
                                                                                }
                                                                                {
                                                                                    (!(appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt)) &&
                                                                                    <span>Pending</span>
                                                                                }
                                                                                {
                                                                                    (appointment.metadata.appointment.prescreening && appointment.metadata.appointment.prescreening.attempt && !appointment.metadata.appointment.prescreening.verifiedOn) &&
                                                                                    <span>Completed</span>
                                                                                }
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                }
                                                                {
                                                                    appointment.currentStatus === APPOINTMENT_STATUS.SCHEDULE[0] &&
                                                                    <div className={"apt_checkin" + ((appointment.currentStatus === APPOINTMENT_STATUS.SCHEDULE[0] && appointment?.metadata
                                                                        && appointment?.metadata?.appointment
                                                                        && appointment?.metadata?.appointment?.prescreening &&
                                                                        appointment?.metadata?.appointment?.prescreening?.attempt &&
                                                                        appointment?.metadata?.appointment?.prescreening?.verifiedOn &&
                                                                        (!appointment?.metadata?.appointment?.checkin || (!(appointment?.metadata?.appointment?.checkin && appointment?.metadata?.appointment?.checkin?.attempt)))) ? '' : ' disabled')}>
                                                                        <a href="javascript:void(0)" className="media" onClick={(e) => {
                                                                            e.preventDefault();
                                                                            if (appointment.currentStatus === APPOINTMENT_STATUS.SCHEDULE[0] && appointment.metadata
                                                                                && appointment.metadata.appointment
                                                                                && appointment.metadata.appointment.prescreening &&
                                                                                appointment.metadata.appointment.prescreening.attempt &&
                                                                                appointment.metadata.appointment.prescreening.verifiedOn &&
                                                                                (!appointment.metadata.appointment.checkin || (!(appointment.metadata.appointment.checkin && appointment.metadata.appointment.checkin.attempt)))) {
                                                                                this.setState({ checkInModal: true, currentSelectedAppointment: appointment });
                                                                            }
                                                                        }}>
                                                                            <div className="media-img mr-3">
                                                                                <img src={require('../../assets/images/checkin_icon.svg')} className="" />
                                                                            </div>
                                                                            <div className="media-body">
                                                                                Check-In
                                                                                {
                                                                                    (appointment.metadata && appointment.metadata.appointment && appointment.metadata.appointment.checkin &&
                                                                                        appointment.metadata.appointment.checkin.attempt) ?
                                                                                        <span>{appointment.metadata.appointment.checkin.data}</span> :
                                                                                        <span>Status</span>
                                                                                }
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                }

                                                                {
                                                                    (appointment?.date && appointment?.time && appointment?.currentStatus.toLowerCase() === APPOINTMENT_STATUS.SCHEDULE[0].toLowerCase()) &&
                                                                    <>
                                                                        <div className="aps_date">
                                                                            <span>Appointment scheduled on</span>
                                                                            {
                                                                                moment(appointment.date + " " + appointment.time, 'YYYY-MM-DD LT').format("DD MMMM, YYYY LT")
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }

                                                                {
                                                                    (appointment?.metadata && appointment?.metadata?.appointment && appointment?.metadata?.appointment?.payment &&
                                                                        !(appointment?.billingdata && appointment?.billingdata?.clinicToPatientBilling &&
                                                                            appointment?.billingdata?.clinicToPatientBilling?.status === "paid")) &&
                                                                    <NavLink to={{
                                                                        pathname: "/appointment/payment/" + appointment.uniqueid + this.props.location.search,
                                                                        state: {
                                                                            appointment: appointment
                                                                        }
                                                                    }} className="paystatus"
                                                                        onClick={(e: any) => {
                                                                            this.setState({ currentClaimStatusOpen: '' });
                                                                        }}>
                                                                        Pay now
                                                                        <span>${roundNumberWithRoundingFactorDecimalFix(appointment.billingdata.clinicToPatientBilling.total, 2)}</span>

                                                                    </NavLink>
                                                                }
                                                                {
                                                                    (appointment?.billingdata && appointment?.billingdata?.clinicToPatientBilling &&
                                                                        appointment?.billingdata?.clinicToPatientBilling?.status === "paid") &&
                                                                    <div className="paystatus">
                                                                        Payment Status
                                                                        <span>Paid - ${roundNumberWithRoundingFactorDecimalFix(appointment?.billingdata?.clinicToPatientBilling?.total, 2)}</span>
                                                                    </div>
                                                                }

                                                                {
                                                                    (appointment?.clinic && appointment?.clinic?.metadata && appointment?.clinic?.metadata?.googleReviewUrl && appointment?.clinic?.metadata?.googleReviewUrl !== "" &&
                                                                        appointment?.currentStatus === APPOINTMENT_STATUS.COMPLETE[0]) ?
                                                                        <div className="rw-link align-self-center"><a className="btn" href={appointment?.clinic?.metadata?.googleReviewUrl} target="_blank">Write Review</a></div>
                                                                        : <>
                                                                            {
                                                                                (appointment?.currentStatus === APPOINTMENT_STATUS.COMPLETE[0] && appointment?.rating === null) &&
                                                                                <div className="rw-link align-self-center"><a className="btn" href="javascript:void(0)" onClick={(e) => {
                                                                                    this.setState({
                                                                                        writeReview: true,
                                                                                        currentSelectedAppointment: appointment
                                                                                    });
                                                                                }}>Write Review</a></div>
                                                                            }
                                                                        </>
                                                                }

                                                                {
                                                                    appointment.id.toString() !== this.state.currentClaimStatusOpen &&
                                                                    <>
                                                                        <div className={"status align-self-center " + classStatus} style={{ textTransform: "capitalize" }}>{status}</div>
                                                                        {/*
                                                                        (status === APPOINTMENT_STATUS.COMPLETE[0] && appointment.claimStatus) &&
                                                                        <button className="btn completed claim_rw align-self-center"
                                                                            onClick={(e) => {
                                                                                this.setState({ currentClaimStatusOpen: appointment.id });
                                                                            }}
                                                                        ><img src={require('../../assets/images/ic_card_giftca-white.svg')} className="btn_ico" /> Claim status</button>
                                                                    */}
                                                                        {/*
                                                                        (status === APPOINTMENT_STATUS.COMPLETE[0] && (appointment.claimStatus === null || appointment.claimStatus === "")) &&
                                                                        <Link
                                                                            to={"/claim-reward?appointment=" + appointment.uniqueid}
                                                                            className="btn completed claim_rw align-self-center"><img src={require('../../assets/images/ic_card_giftca-white.svg')} className="btn_ico" /> Claim your Reward</Link>
                                                                    */}
                                                                    </>
                                                                }
                                                                {
                                                                    appointment.id.toString() === this.state.currentClaimStatusOpen &&
                                                                    <div className="claim-status rwsw d-flex">
                                                                        {
                                                                            claimStatusTimeline(appointment.claimStatus)
                                                                        }
                                                                        <button className="btn btn-circle_ico"
                                                                            onClick={(e) => {
                                                                                this.setState({ currentClaimStatusOpen: '' });
                                                                            }}><img src={require("../../assets/images/close_ic.svg")} /></button>
                                                                    </div>
                                                                }
                                                                {
                                                                    ((status === APPOINTMENT_STATUS.REQUEST[0] || status === APPOINTMENT_STATUS.SCHEDULE[0]) && appointment.owership) &&
                                                                    <button className="assignBtn align-self-center" onClick={(e) => {
                                                                        this.changeAppointmentAssignModal(true);
                                                                        this.setState({ currentSelectedAppointment: appointment });
                                                                    }}>Assign</button>
                                                                }
                                                            </li>
                                                        );
                                                    }
                                                })
                                            }
                                        </>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal centered className="model_covid" show={this.state.completeScreeningModal} onHide={() => {
                    this.setPrescreeingToState();
                    this.setState({ completeScreeningModal: false, currentQuestion: 0, allNoCheckBox: false });
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Prescreening Questions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            (this.state.prescreeningQuestions && this.state.prescreeningQuestions.length > 0) &&
                            this.state.prescreeningQuestions.map((question: any, index: number) => {
                                if (question.options) {
                                    return (
                                        <div className="covdque" key={index + Math.random()}>
                                            {index + 1}. {this.state.prescreeningQuestions[index].question}
                                            <div className="deslist">
                                                <ul className="list-group">
                                                    {
                                                        question.options.map((ques: any, qIndex: number) => {
                                                            const findOption = question.answer.findIndex((option: any, oIndex: number) => {
                                                                return ques === option;
                                                            });
                                                            return (
                                                                <li className="list-group-item" key={qIndex + Math.random()}>
                                                                    <div className="custom-control custom-checkbox">
                                                                        <input type="checkbox" id={ques} name={ques} className="custom-control-input"
                                                                            value={ques}
                                                                            checked={findOption !== -1 ? true : false}
                                                                            onChange={(e) => {
                                                                                this.addRemoveOptions(index, e.target.value);
                                                                            }}
                                                                        />
                                                                        <label title="" htmlFor={ques} className="custom-control-label">{ques}</label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                }
                                if (this.state.prescreeningQuestions.length - 1 === index) {
                                    return (
                                        <>
                                            <div>
                                                <input type="checkbox" id="allNoCheckBox" name="allNoCheckBox" checked={this.state.allNoCheckBox} value={this.state.allNoCheckBox ? "yes" : "no"} onChange={(e) => {
                                                    this.setState({
                                                        allNoCheckBox: !this.state.allNoCheckBox,
                                                        prescreeningQuestions: this.state.prescreeningQuestions.map((ques: any, index: number) => {
                                                            if (this.state.prescreeningQuestions.length - 1 === index) {
                                                                return {
                                                                    ...ques,
                                                                    answer: this.state.allNoCheckBox === true ? "no" : "no",
                                                                }
                                                            }
                                                            return {
                                                                ...ques,
                                                                answer: this.state.allNoCheckBox === false ? "no" : ques.oldAnswer,
                                                            }
                                                        })
                                                    });
                                                }}></input>
                                                <label htmlFor="allNoCheckBox"> &nbsp; Please check to select all as NO.</label>
                                            </div>
                                            <div>
                                                <input type="checkbox" id="confirmCheckbox" name="confirmCheckbox" checked={question.answer === "yes" ? true : false}
                                                    value={question.answer} onChange={(e) => {
                                                        this.yesNoClickPreScreening(index, question.answer === "yes" ? "no" : "yes");
                                                    }}></input>
                                                <label htmlFor="confirmCheckbox"> &nbsp; {question.question}</label>
                                            </div>
                                        </>
                                    )
                                }
                                return (
                                    <div key={index + Math.random()}>
                                        <div className="covdque">
                                            {index + 1}. {question.question}
                                        </div>
                                        <div className="ysnrad">
                                            <div className="form-check-inline yes-no">
                                                <input type="radio" name={"has_received" + index} id={"received_yes" + index} value={question.answer}
                                                    checked={question.answer === 'yes'}
                                                    onChange={(e) => {
                                                        this.yesNoClickPreScreening(index, 'yes');
                                                    }}
                                                />
                                                <label className="l-yes" htmlFor={"received_yes" + index}><img src={require('../../assets/images/yes-icon.svg')} /></label>
                                                <span>Yes</span>
                                            </div>
                                            <div className="form-check-inline yes-no">
                                                <input type="radio" name={"has_received" + index} id={"received_no" + index} value={question.answer}
                                                    checked={question.answer === 'no'}
                                                    onChange={(e) => {
                                                        this.yesNoClickPreScreening(index, 'no');
                                                    }} />
                                                <label className="l-no" htmlFor={"received_no" + index}><img src={require('../../assets/images/no-icon.svg')} /></label>
                                                <span>No</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="andpbtn">
                            <button type="button" className="btn btn-cancel btn-custom"
                                onClick={(e) => {
                                    this.onFinishClick();
                                }}>Finish</button>
                        </div>
                        {
                            !(this.state.prescreeningQuestions && this.state.prescreeningQuestions.length > 0) &&
                            <label className="text text-danger">No questions avaiable</label>
                        }
                    </Modal.Body>
                </Modal>
                {/* Prescreening Modal - Commented By Himanshu 17/08/2020 - Start */}
                {/* <Modal centered className="model_covid" show={this.state.completeScreeningModal} onHide={() => {
                    this.setPrescreeingToState();
                    this.setState({ completeScreeningModal: false, currentQuestion: 0 });
                }}>
                    {
                        (this.state.prescreeningQuestions && Object.keys(this.state.prescreeningQuestions).length > 0) &&
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.currentQuestion + 1}/{Object.keys(this.state.prescreeningQuestions).length}</Modal.Title>
                        </Modal.Header>
                    }

                    <ModalBody>

                        {
                            (this.state.prescreeningQuestions && this.state.prescreeningQuestions.length > 0) &&
                            <>
                                {

                                    (this.state.prescreeningQuestions[this.state.currentQuestion].options) ?
                                        <>
                                            <div className="covdque">
                                                {this.state.prescreeningQuestions[this.state.currentQuestion].question}
                                                <div className="deslist">
                                                    <ul className="list-group">
                                                        {
                                                            this.state.prescreeningQuestions[this.state.currentQuestion].options.map((ques: any, qIndex: number) => {
                                                                const findOption = this.state.prescreeningQuestions[this.state.currentQuestion].answer.findIndex((option: any, oIndex: number) => {
                                                                    return ques === option;
                                                                });
                                                                return (
                                                                    <li className="list-group-item" key={qIndex + Math.random()}>
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input type="checkbox" id={ques} name={ques} className="custom-control-input"
                                                                                value={ques}
                                                                                checked={findOption !== -1 ? true : false}
                                                                                onChange={(e) => {
                                                                                    this.addRemoveOptions(this.state.currentQuestion, e.target.value);
                                                                                }}
                                                                            />
                                                                            <label title="" htmlFor={ques} className="custom-control-label">{ques}</label>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                        :

                                        <>
                                            <div className="covdque">
                                                {this.state.prescreeningQuestions[this.state.currentQuestion].question}
                                            </div>
                                            <div className="ysnrad">
                                                <div className="form-check-inline yes-no">
                                                    <input type="radio" name="has_received" id="received_yes" value={this.state.prescreeningQuestions[this.state.currentQuestion].answer}
                                                        checked={this.state.prescreeningQuestions[this.state.currentQuestion].answer === 'yes'}
                                                        onChange={(e) => {
                                                            this.yesNoClickPreScreening(this.state.currentQuestion, 'yes');
                                                        }}
                                                    />
                                                    <label className="l-yes" htmlFor="received_yes"><img src={require('../../assets/images/yes-icon.svg')} /></label>
                                                    <span>Yes</span>
                                                </div>
                                                <div className="form-check-inline yes-no">
                                                    <input type="radio" name="has_received" id="received_no" value={this.state.prescreeningQuestions[this.state.currentQuestion].answer}
                                                        checked={this.state.prescreeningQuestions[this.state.currentQuestion].answer === 'no'}
                                                        onChange={(e) => {
                                                            this.yesNoClickPreScreening(this.state.currentQuestion, 'no');
                                                        }} />
                                                    <label className="l-no" htmlFor="received_no"><img src={require('../../assets/images/no-icon.svg')} /></label>
                                                    <span>No</span>
                                                </div>
                                            </div>
                                        </>

                                }
                            </>
                        }
                        <div className="andpbtn">
                            {
                                (this.state.prescreeningQuestions && (this.state.currentQuestion > 0)) &&
                                <button type="button" className="btn btn-save btn-custom mr-3" onClick={(e) => {
                                    this.setState({ currentQuestion: this.state.currentQuestion - 1 });
                                }}>Back</button>
                            }
                            {
                                (this.state.prescreeningQuestions && (this.state.currentQuestion === this.state.prescreeningQuestions.length - 1)) ?
                                    <button type="button" className="btn btn-cancel btn-custom" disabled={!(this.state.prescreeningQuestions &&
                                        this.state.prescreeningQuestions[this.state.currentQuestion].answer && this.state.prescreeningQuestions[this.state.currentQuestion].answer.length !== 0)}
                                        onClick={(e) => {
                                            this.onFinishClick();
                                        }}>Finish</button>
                                    :
                                    <button type="button" className="btn btn-cancel btn-custom" disabled={!(this.state.prescreeningQuestions && this.state.prescreeningQuestions[this.state.currentQuestion].answer !== '')}
                                        onClick={(e) => {
                                            this.setState({ currentQuestion: this.state.currentQuestion + 1 });
                                        }}>Next</button>
                            }

                        </div>
                    </ModalBody>
                </Modal> */}
                {/* Prescreening Modal - Commented By Himanshu 17/08/2020 - End */}
                <Modal centered className="chk_in" show={this.state.checkInModal} onHide={() => {
                    this.setState({ checkInModal: false });
                }}>

                    <Modal.Header closeButton>
                        <Modal.Title>Check-in</Modal.Title>
                    </Modal.Header>

                    <ModalBody>

                        <ul className="checkinlist">
                            <li className="list-group-item">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id="checkin1" value={"Check-in Now"} className="custom-control-input"
                                        checked={this.state.checkInBox === 0}
                                        onChange={(e: any) => {
                                            this.onCheckInClick(e.target.value);
                                            this.setState({ checkInBox: 0 });
                                        }}
                                    />
                                    <label title="" htmlFor="checkin1" className="custom-control-label">Check-in Now</label>
                                </div>
                            </li>

                            <li className="list-group-item">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id="checkin2" value={"Running a bit late (5-10 mins)"}
                                        className="custom-control-input"
                                        checked={this.state.checkInBox === 1}
                                        onChange={(e: any) => {
                                            this.onCheckInClick(e.target.value);
                                            this.setState({ checkInBox: 1 });
                                        }}
                                    />
                                    <label title="" htmlFor="checkin2" className="custom-control-label">Running a bit late <span>(5-10 mins)</span></label>
                                </div>
                            </li>

                            <li className="list-group-item">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" id="checkin3" value={"Stuck in traffic (15-30 mins)"}
                                        className="custom-control-input"
                                        checked={this.state.checkInBox === 2}
                                        onChange={(e: any) => {
                                            this.onCheckInClick(e.target.value);
                                            this.setState({ checkInBox: 2 });
                                        }}
                                    />
                                    <label title="" htmlFor="checkin3" className="custom-control-label">Stuck in traffic <span>(15-30 mins)</span></label>
                                </div>
                            </li>
                        </ul>

                    </ModalBody>
                </Modal>
                <Modal centered className="write_review" show={this.state.writeReview} onHide={() => {
                    this.setState({
                        writeReview: false, currentSelectedAppointment: undefined, writeReviewForm: {
                            comment: '', score: -1
                        }
                    });
                }}>

                    <Modal.Header closeButton>
                        <Modal.Title>Review On this appointment.</Modal.Title>
                    </Modal.Header>
                    <ModalBody>

                        {
                            (this.state.currentSelectedAppointment) &&
                            <>
                                <div className="form-group">
                                    <label>How likely are you to visit {this.state.currentSelectedAppointment.clinic.name} again?</label>
                                    <div className="rate-range d-flex justify-content-between">
                                        {
                                            ratingsCounts.map((value, index) => {
                                                return (
                                                    <a key={index + Math.random()} href="javascript:void(0);"
                                                        onClick={(e) => {
                                                            this.setState({
                                                                writeReviewForm: {
                                                                    ...this.state.writeReviewForm,
                                                                    score: value
                                                                }
                                                            });
                                                        }
                                                        }
                                                        className={(this.state.writeReviewForm.score === value ? 'active' : '')}>{value}</a>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="ratting-desc d-flex justify-content-between">
                                        <span>Not likely</span>
                                        <span>Extremely likely</span>
                                    </div>
                                </div>
                                {
                                    this.state.writeReviewForm.score > -1 &&
                                    <div className="form-group">
                                        <label>We'd love to learn more. Please tell us why you selected a score of <span>{this.state.writeReviewForm.score}</span>.</label>
                                        <textarea className="form-control" id="comment" placeholder="" value={this.state.writeReviewForm.comment} onChange={(e) => {
                                            this.setState({
                                                writeReviewForm: {
                                                    ...this.state.writeReviewForm,
                                                    comment: e.target.value
                                                }
                                            });
                                        }}></textarea>
                                    </div>
                                }
                                <div className="form-group pop_btnsec">
                                    <button className="btn btn-custom w-100" disabled={this.state.writeReviewForm.score === -1 || this.state.writeReviewForm.comment === ''}
                                        onClick={(e) => {
                                            if (this.state.currentSelectedAppointment && this.props.patientDataState.patientDataResponse) {
                                                this.props.writeReview({
                                                    appointment: this.state.currentSelectedAppointment.uniqueid,
                                                    comment: this.state.writeReviewForm.comment,
                                                    patientUniqueId: this.props.patientDataState.patientDataResponse.uniqueId,
                                                    score: this.state.writeReviewForm.score
                                                });
                                            }
                                        }}>Submit</button>
                                </div>
                            </>
                        }
                    </ModalBody>
                </Modal>
                {
                    this.state.assignAppointmentModal &&
                    <AppointmentAssignModal closeModal={() => {
                        this.changeAppointmentAssignModal(false);
                    }} submitAssign={(email: string) => {
                        if (this.state.currentSelectedAppointment)
                            this.props.assignAppointment({
                                appointmentUniqueId: this.state.currentSelectedAppointment.uniqueid,
                                email: email
                            });
                    }}></AppointmentAssignModal>
                }
            </Animated>
        )
    }
}
function claimStatusTimeline(claimStatus: string) {
    let lastIndex = 1;
    if (claimStatus.toLowerCase() === "requested") {
        lastIndex = 2;
    } else if (claimStatus.toLowerCase() === "validated") {
        lastIndex = 3;
    } else if (claimStatus.toLowerCase() === "sent") {
        lastIndex = 4;
    }
    return (
        <ul className="step-wizard p-0">
            <li className={"step " + (lastIndex < 1 ? 'disable' : 'active')}>
                <span className="icon"><img src={require("../../assets/images/ic_check_24px.svg")} /></span>
                <span className="name">Visited</span>
            </li>
            <li className={"step " + (lastIndex < 2 ? 'disable' : 'active')}>
                <span className="icon"><img src={require("../../assets/images/ic_star_24px.svg")} /></span>
                <span className="name">Claming</span>
            </li>
            <li className={"step " + (lastIndex < 3 ? 'disable' : 'active')}>
                <span className="icon">3</span>
                <span className="name">Validated</span>
            </li>
            <li className={"step " + (lastIndex < 4 ? 'disable' : 'active')}>
                <span className="icon">4</span>
                <span className="name">Sent</span>
            </li>
        </ul>
    );
}
const mapDispatchToProps = (dispatch: any) => ({
    doLoading: () => { dispatch(fetchLoadingPending()); },
    loadingSuccess: (status: boolean) => { dispatch(fetchLoadingSuccess(status)); },
    logout: () => {
        dispatch(userLogout())
    },
    writeReview: (request: WriteReviewRequest) => {
        dispatch(writeReviewPending(request));
    },
    changeWriteReview: (body: ChangeWriteReview) => {
        dispatch(changeWriteReviewAction(body));
    },
    assignAppointment: (request: AssignAppointmentRequest) => {
        dispatch(assignAppointmentPending(request));
    },
    patientData: (request: PatientDataRequest) => {
        dispatch(patientDataPending(request));
    },
    showWaitingPresc: (status: boolean) => {
        dispatch(showWaitingPresc(status));
    }
});
const mapStateToProps = (state: IAppState) => ({
    patientDataState: state.patientData,
    appointmentState: state.appointmentDetail
});
export default connect(mapStateToProps, mapDispatchToProps)(Appointments);