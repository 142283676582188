import { KeyboardDatePicker } from "@material-ui/pickers";
import Cookies from "js-cookie";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Animated } from "react-animated-css";
import { Spinner } from "react-bootstrap";
import Helmet from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { NavLink, useHistory } from "react-router-dom";
import configs from "../../configs/apiConfigs";
import { LABELS, TITLES_LABELS } from "../../constants";
import { getMasterInsuranceList, registerUser } from "../../services/auth";
import { signInWithCustomToken } from "../../utils/firebaseUtils";
import { GuarantorInfoModal } from "../../components/guarantorInfo/GuarantorInfoModal";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import { OTPModal } from "./otp";
import { getAge } from "../../utils/global";
export const Register = () => {
  let autcompleteRef: any = useRef();
  let autocomplete: any;
  const [isLoading, setIsLoading] = useState(false);
  const [isViewPassword, setIsViewPassword] = useState(false);
  const [isRetypeViewPassword, setIsRetypeViewPassword] = useState(false);
  const {
    register,
    setError,
    handleSubmit,
    setValue,
    getValues,
    errors,
    trigger,
    control,
    watch,
  } = useForm({
    defaultValues: {
      patienttype: "individual",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dob: null,
      gender: "Male",
      password: "",
      cpassword: "",
      automatedreminder: "yes",
      pcontactmethod: "email,text",
      paythroughinsurance: "no",
      insurancename: "",
      insuranceplanname: "",
      selfguarantor: "yes",
      primaryguarantor: "primary",
      secondaryguarantor: "",
      globalError: "",
      otherInsurance: "",
      otherInsuranceplanName: "",
    },
  });
  const [isShowOTPModal, setIsShowOTPModal] = useState(false);
  const [patientId, setPatientId] = useState();
  const [phone, setPhone]: any = useState("");
  const [email, setEmail]: any = useState("");
  const [showGurantorInfoModal, setShowGuarantorInfoModal] = useState(false);
  const [masterInsuranceList, setMasterInsuranceList]: any = useState([]);
  const [guarantorList, setGuarantorList]: any = useState([]);
  const history = useHistory();
  const [insurancePlanList, setInsurancePlanList] = useState([]);
  const [location, setLocation]: any = useState("");
  const [locationError, setLocationError]: any = useState("");

  useEffect(() => {
    setIsLoading(true);
    onLoadGetPlace();
    getMasterInsuranceList()
      .then((success) => {
        setIsLoading(false);
        if (success && success.status && success.status.error === false) {
          setMasterInsuranceList([
            ...success.insurance,
            {
              id: "other",
              name: "Other",
              plan: [{ id: "other", name: "Other" }],
            },
          ]);
          setValue("insurancename", success.insurance[0].id);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log("location", location);
  }, [location]);
  useEffect(() => {
    if (getValues("insurancename") && getValues("insurancename") !== "") {
      let selectedInsurance: any = getValues("insurancename");
      let insurance = masterInsuranceList.find(
        (item: any) => item.id === selectedInsurance
      );
      if (insurance) {
        if (selectedInsurance === "other") {
          insurance = {
            ...insurance,
            plan: [{ id: "other", name: "Other" }],
          };
        } else {
          insurance = {
            ...insurance,
            plan: [...insurance.plan, { id: "other", name: "Other" }],
          };
        }
        setInsurancePlanList(insurance.plan);
        if (insurance.plan[0]) {
          setValue("insuranceplanname", insurance.plan[0].id);
        }
      }
    }
  }, [watch("insurancename")]);
  const onRegisterClick = (data: any) => {
    console.log(
      getValues("primaryguarantor"),
      getValues("secondaryguarantor"),
      data
    );
    console.log(getAge(moment(getValues("dob")).format("YYYY-MM-DD")));
    if (getValues("email") === "" && getValues("phone") === "") {
      setError("globalError", {
        type: "custom",
        message: "Either email or phone is required.",
      });
      return;
    } else if (
      getValues("dob") &&
      getAge(moment(getValues("dob")).format("YYYY-MM-DD")) < 18
    ) {
      setError("globalError", {
        type: "custom",
        message: "You need to be 18 years and older to sign up.",
      });
      return;
    } else if (
      getValues("paythroughinsurance") === "yes" &&
      getValues("patienttype") === "individual" &&
      getValues("secondaryguarantor")[0] !== undefined
    ) {
      if (guarantorList.length === 0) {
        setError("globalError", {
          type: "custom",
          message: "Please add secondary guarantor.",
        });
        return;
      }
    } else if (
      getValues("paythroughinsurance") === "yes" &&
      getValues("patienttype") === "dependent" &&
      getValues("secondaryguarantor")[0] !== undefined
    ) {
      if (guarantorList.length === 0) {
        setError("globalError", {
          type: "custom",
          message: "Please add primary and secondary guarantor.",
        });
        return;
      } else if (guarantorList.length === 1) {
        setError("globalError", {
          type: "custom",
          message: "Please add secondary guarantor.",
        });
        return;
      }
    } else if (
      getValues("paythroughinsurance") === "yes" &&
      getValues("patienttype") === "dependent" &&
      getValues("primaryguarantor") !== undefined
    ) {
      if (guarantorList.length === 0) {
        setError("globalError", {
          type: "custom",
          message: "Please add primary guarantor.",
        });
        return;
      }
    } else if (getValues("password") !== getValues("cpassword")) {
      setError("globalError", {
        type: "custom",
        message: "Password and confirm password should be same.",
      });
      return;
    } else if (location === "") {
      setLocationError("postal code requried");
      return;
    }
    setError("globalError", {});
    let requestedData = {
      fname: getValues("firstName"),
      lname: getValues("lastName"),
      email: getValues("email"),
      phone: getValues("phone"),
      dob: moment(getValues("dob")).format("YYYY-MM-DD"),
      registeras: "normal",
      password: getValues("password"),
      gender: getValues("gender"),
      contactMethod: getValues("pcontactmethod")
        ? getValues("pcontactmethod")
        : "",
      reminder: getValues("automatedreminder"),
      formatted_address: location?.formatted_address,
      address: location?.address_components,
    };
    try {
      if (getValues("paythroughinsurance") === "no") {
        if (getValues("patienttype") === "individual") {
          Object.assign(requestedData, {
            isIndividual: true,
            request: {
              paymode: "cash,credit,card,cheque",
            },
            guarantor: {
              fname: getValues("firstName"),
              lname: getValues("lastName"),
              email: getValues("email"),
              phone: getValues("phone"),
              dob: moment(getValues("dob")).format("YYYY-MM-DD"),
              registeras: "normal",
              password: getValues("password"),
              gender: getValues("gender"),
            },
          });
        } else if (getValues("patienttype") === "dependent") {
          Object.assign(requestedData, {
            isIndividual: false,
            request: {
              paymode: "cash,credit,card,cheque",
            },
            guarantor: {
              fname: getValues("firstName"),
              lname: getValues("lastName"),
              email: getValues("email"),
              phone: getValues("phone"),
              dob: moment(getValues("dob")).format("YYYY-MM-DD"),
              registeras: "normal",
              password: getValues("password"),
              gender: getValues("gender"),
            },
          });
        }
      } else {
        let insurance = masterInsuranceList.find(
          (item: any) => item.id === getValues("insurancename")
        );
        let insurancePlan: any = insurancePlanList.find(
          (item: any) => item.id === getValues("insuranceplanname")
        );
        if (getValues("patienttype") === "individual") {
          if (getValues("secondaryguarantor")) {
            let secondaryguarantor: any = guarantorList[0];
            Object.assign(requestedData, {
              isIndividual: true,
              insurance: {
                id: insurance.id,
                name:
                  insurance && insurance.id === "other"
                    ? getValues("otherInsurance")
                    : insurance.name,
                plan: insurancePlan && insurancePlan.id ? insurancePlan.id : "",
                planName:
                  insurancePlan && insurancePlan.id === "other"
                    ? getValues("otherInsuranceplanName")
                    : insurancePlan.name,
              },
              guarantor: {
                fname: getValues("firstName"),
                lname: getValues("lastName"),
                email: getValues("email"),
                phone: getValues("phone"),
                dob: moment(getValues("dob")).format("YYYY-MM-DD"),
                registeras: "normal",
                password: getValues("password"),
                gender: getValues("gender"),
                insurance: {
                  id: insurance.id,
                  name:
                    insurance && insurance.id === "other"
                      ? getValues("otherInsurance")
                      : insurance.name,
                  plan:
                    insurancePlan && insurancePlan.id ? insurancePlan.id : "",
                  planName:
                    insurancePlan && insurancePlan.id === "other"
                      ? getValues("otherInsuranceplanName")
                      : insurancePlan.name,
                },
              },
              xguarantor: {
                fname: secondaryguarantor.fname,
                lname: secondaryguarantor.lname,
                email: secondaryguarantor.email,
                phone: secondaryguarantor.phone,
                dob: secondaryguarantor.dob,
                gender: secondaryguarantor.gender,
                registeras: "normal",
                insurance: secondaryguarantor.insurance,
              },
            });
          } else if (!getValues("secondaryguarantor")) {
            Object.assign(requestedData, {
              isIndividual: true,
              insurance: {
                id: insurance.id,
                name:
                  insurance && insurance.id === "other"
                    ? getValues("otherInsurance")
                    : insurance.name,
                plan: insurancePlan && insurancePlan.id ? insurancePlan.id : "",
                planName:
                  insurancePlan && insurancePlan.id === "other"
                    ? getValues("otherInsuranceplanName")
                    : insurancePlan.name,
              },
              guarantor: {
                fname: getValues("firstName"),
                lname: getValues("lastName"),
                email: getValues("email"),
                phone: getValues("phone"),
                dob: moment(getValues("dob")).format("YYYY-MM-DD"),
                registeras: "normal",
                password: getValues("password"),
                gender: getValues("gender"),
                insurance: {
                  id: insurance.id,
                  name:
                    insurance && insurance.id === "other"
                      ? getValues("otherInsurance")
                      : insurance.name,
                  plan:
                    insurancePlan && insurancePlan.id ? insurancePlan.id : "",
                  planName:
                    insurancePlan && insurancePlan.id === "other"
                      ? getValues("otherInsuranceplanName")
                      : insurancePlan.name,
                },
              },
            });
          }
        } else if (getValues("patienttype") === "dependent") {
          if (getValues("secondaryguarantor")[0] !== undefined) {
            const primaryguarantor: any = guarantorList[0];
            if (
              getValues("email") === primaryguarantor.email &&
              getValues("phone") === primaryguarantor.phone
            ) {
              setError("globalError", {
                type: "custom",
                message:
                  "Dependant email,mobile# and Primary guarantor email,mobile# can't be same.",
              });
              return;
            }
            setError("globalError", {});
            const secondaryguarantor: any = guarantorList[1];
            Object.assign(requestedData, {
              isIndividual: false,
              insurance: {
                id: insurance.id,
                name:
                  insurance && insurance.id === "other"
                    ? getValues("otherInsurance")
                    : insurance.name,
                plan: insurancePlan && insurancePlan.id ? insurancePlan.id : "",
                planName:
                  insurancePlan && insurancePlan.id === "other"
                    ? getValues("otherInsuranceplanName")
                    : insurancePlan.name,
              },
              guarantor: {
                fname: primaryguarantor.fname,
                lname: primaryguarantor.lname,
                email: primaryguarantor.email,
                phone: primaryguarantor.phone,
                dob: primaryguarantor.dob,
                registeras: "normal",
                gender: primaryguarantor.gender,
                insurance: primaryguarantor.insurance,
              },
              xguarantor: {
                fname: secondaryguarantor.fname,
                lname: secondaryguarantor.lname,
                email: secondaryguarantor.email,
                phone: secondaryguarantor.phone,
                dob: secondaryguarantor.dob,
                gender: secondaryguarantor.gender,
                registeras: "normal",
                insurance: secondaryguarantor.insurance,
              },
            });
          } else if (getValues("primaryguarantor") !== undefined) {
            const primaryguarantor: any = guarantorList[0];
            if (
              getValues("email") === primaryguarantor.email &&
              getValues("phone") === primaryguarantor.phone
            ) {
              setError("globalError", {
                type: "custom",
                message:
                  "Dependant email,mobile# and Primary guarantor email,mobile# can't be same.",
              });
              return;
            }
            setError("globalError", {});
            Object.assign(requestedData, {
              isIndividual: false,
              insurance: {
                id: insurance.id,
                name:
                  insurance && insurance.id === "other"
                    ? getValues("otherInsurance")
                    : insurance.name,
                plan: insurancePlan && insurancePlan.id ? insurancePlan.id : "",
                planName:
                  insurancePlan && insurancePlan.id === "other"
                    ? getValues("otherInsuranceplanName")
                    : insurancePlan.name,
              },
              guarantor: {
                fname: primaryguarantor.fname,
                lname: primaryguarantor.lname,
                email: primaryguarantor.email,
                phone: primaryguarantor.phone,
                dob: primaryguarantor.dob,
                registeras: "normal",
                gender: primaryguarantor.gender,
                insurance: primaryguarantor.insurance,
              },
            });
          }
        }
      }
      setIsLoading(true);
      registerUser(requestedData)
        .then((success) => {
          setIsLoading(false);
          if (success && success.status && success.status.error === false) {
            setPhone(getValues("phone"));
            setEmail(getValues("email"));
            setPatientId(success.id);
            setIsShowOTPModal(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError("globalError", {
            type: "custom",
            message: err.msg ? err.msg : "Theres something wrong.",
          });
        });
    } catch (err) {
      setIsLoading(false);
      setError("globalError", {
        type: "custom",
        message: err.msg ? err.msg : "Theres something wrong.",
      });
    }
  };
  const postLoginAction = (data: any) => {
    setIsLoading(true);
    signInWithCustomToken(data.jwtToken)
      .then((response) => {
        setIsLoading(false);
        if (response) {
          const key1 = "xprUser.patient-" + data.id;
          const key2 = "xpr_user_id";
          const key3 = "xprAuthUser";
          const key4 = "xprUserName";
          const key5 = "jwtToken";
          const key6 = "xprIsGuarantor";
          var json: any = {};
          json[key1] = true;
          json[key2] = data.uniqueId;
          json[key3] = data.authParam;
          json[key4] = data.firstName + " " + data.lastName;
          json[key5] = data.jwtToken;
          json[key6] = data.isGuarantor;
          Cookies.remove("patient_cookie");
          Cookies.remove("patient_cookie", { domain: configs.COOKIE_DOMAIN });
          Cookies.set("patient_cookie", json, {
            expires: 30,
            domain: configs.COOKIE_DOMAIN,
          });
          Cookies.set("patient_cookie", json, { expires: 30 });
          history.replace("/");
        } else {
          setIsLoading(false);
          setError("globalError", {
            type: "custom",
            message: "Theres something wrong.",
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError("globalError", {
          type: "custom",
          message: "Theres something wrong.",
        });
      });
  };
  useEffect(() => {
    if (watch("paythroughinsurance") === "no") {
      setValue("patienttype", "individual");
    }
  }, [watch("paythroughinsurance")]);

  const onLoadGetPlace = () => {
    if (!document.getElementById("googlePlaceScript")) {
      const googleScript = document.createElement("script");
      googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8&libraries=places`;
      googleScript.id = "googlePlaceScript";
      window.document.body.appendChild(googleScript);
      googleScript.addEventListener("load", () => {
        try {
          //@ts-ignore
          autocomplete = new window.google.maps.places.Autocomplete(
            autcompleteRef.current,
            {
              types: ["(regions)"],
            }
          );
          autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            setLocation(place);
          });
        } catch (err) {
          console.log(err);
        }
      });
    } else {
      try {
        //@ts-ignore
        autocomplete = new window.google.maps.places.Autocomplete(
          autcompleteRef.current,
          {
            types: ["(regions)"],
          }
        );
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          setLocation(place);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
  console.log(watch("secondaryguarantor"));
  return (
    <div className="master_patientp">
      {/* {
                isLoading &&
                <LoadingIndicator></LoadingIndicator>
            } */}
      {/* <header className="inner-header">
                <div className="container">
                    <NavLink className="navbar-brand" to="/login">
                        <img src={require('../../../assets/images/logo-black.svg')} alt="logo" />
                    </NavLink>
                </div>
            </header> */}
      <section className="login-page">
        <Helmet>
          <title>{TITLES_LABELS.META.LOGIN}</title>
        </Helmet>
        <div className="container">
          <div className="row flex-center min-vh-100 py-5">
            <div className="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-8 m-auto">
              <Animated
                animationIn="fadeIn"
                animationOut="zoomOut"
                animationInDuration={1000}
                isVisible={true}
              >
                <form onSubmit={handleSubmit(onRegisterClick)}>
                  <div className="card shadow-sm">
                    <div className="card-header bg-light px-5 py-3">
                      <div className="row text-left justify-content-between align-items-center">
                        <div className="col-auto">
                          <img
                            src={require("../../../assets/images/logo-black.svg")}
                            alt="logo"
                          />
                        </div>
                        <div className="col-auto">
                          <h4>{TITLES_LABELS.LOGIN.REGISTER}</h4>
                        </div>
                      </div>
                    </div>

                    {/* <div className="login-title">
                                            {TITLES_LABELS.LOGIN.REGISTER}
                                        </div> */}

                    <div className="card-body p-4">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label className="form-label fw-medium">
                              First name
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              className="form-control"
                              ref={register({ required: true })}
                            />
                            {errors &&
                              errors.firstName &&
                              errors.firstName.type === "required" && (
                                <div className="text-danger error">
                                  First name is required
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label className="form-label fw-medium">
                              Last name
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              className="form-control"
                              ref={register({ required: true })}
                            />
                            {errors &&
                              errors.lastName &&
                              errors.lastName.type === "required" && (
                                <div className="text-danger error">
                                  Last name is required
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label className="form-label fw-medium">
                              Email
                            </label>
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              ref={register({
                                required: true,
                                pattern:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              })}
                            />
                            {errors &&
                              errors.email &&
                              errors.email.type === "required" && (
                                <div className="text-danger error">
                                  Email is required
                                </div>
                              )}
                            {errors &&
                              errors.email &&
                              errors.email.type === "pattern" && (
                                <div className="text-danger error">
                                  Email is invalid
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label className="form-label fw-medium">
                              Mobile number
                            </label>
                            <Controller
                              className="form-control"
                              name="phone"
                              control={control}
                              mask="9999999999"
                              rules={{
                                required: true,
                                minLength: 10,
                                maxLength: 10,
                              }}
                              as={ReactInputMask}
                            ></Controller>
                            {errors &&
                              errors.phone &&
                              errors.phone.type === "required" && (
                                <div className="text-danger error">
                                  Mobile number is required
                                </div>
                              )}
                            {errors &&
                              errors.phone &&
                              errors.phone.type === "minLength" && (
                                <div className="text-danger error">
                                  Mobile number is invalid
                                </div>
                              )}
                            {errors &&
                              errors.phone &&
                              errors.phone.type === "maxLength" && (
                                <div className="text-danger error">
                                  Mobile number is invalid
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-sm-6 miuif_margin">
                          <div className="mb-3">
                            <label className="form-label fw-medium">
                              Date of birth
                            </label>
                            <Controller
                              name="dob"
                              control={control}
                              render={(props) => (
                                <KeyboardDatePicker
                                  id="date-picker-dialog"
                                  format="YYYY/MM/DD"
                                  name="dob"
                                  className="form-control"
                                  value={props.value}
                                  maxDate={moment().subtract(1, "day")}
                                  onChange={(date: any) => props.onChange(date)}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              )}
                            ></Controller>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label className="form-label fw-medium">
                              Gender
                            </label>
                            <select
                              className="form-select"
                              name="gender"
                              ref={register({ required: true })}
                            >
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                            {errors &&
                              errors.gender &&
                              errors.gender.type === "required" && (
                                <div className="text-danger error">
                                  Gender is required
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label className="form-label fw-medium">
                              Password
                            </label>
                            <div className="input-group">
                              <input
                                type={isViewPassword ? "text" : "password"}
                                name="password"
                                className="form-control"
                                ref={register({
                                  required: true,
                                  pattern:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                })}
                              />
                              <span className="input-group-text bg-transparent">
                                <div
                                  className="view-password-control opacity-50 fs-7"
                                  onClick={() => {
                                    setIsViewPassword(!isViewPassword);
                                  }}
                                >
                                  <i
                                    className={
                                      isViewPassword
                                        ? "bi bi-eye active"
                                        : "bi bi-eye-slash active"
                                    }
                                  ></i>
                                </div>
                              </span>
                            </div>
                            {errors &&
                              errors.password &&
                              errors.password.type === "required" && (
                                <div className="text-danger error">
                                  Password is required
                                </div>
                              )}
                            {errors &&
                              errors.password &&
                              errors.password.type === "pattern" && (
                                <div className="text-danger error">
                                  Password must contain 8 characters with at
                                  least 1 upper case, 1 lower case, 1 special
                                  and 1 numeric character.
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label className="form-label fw-medium">
                              Re-type password
                            </label>
                            <div className="input-group">
                              <input
                                type={
                                  isRetypeViewPassword ? "text" : "password"
                                }
                                name="cpassword"
                                ref={register({
                                  required: true,
                                  pattern:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                                })}
                                className="form-control"
                              />
                              <span className="input-group-text bg-transparent">
                                <div
                                  className="view-password-control opacity-50 fs-7"
                                  onClick={() => {
                                    setIsRetypeViewPassword(
                                      !isRetypeViewPassword
                                    );
                                  }}
                                >
                                  <i
                                    className={
                                      isRetypeViewPassword
                                        ? "bi bi-eye active"
                                        : "bi bi-eye-slash active"
                                    }
                                  ></i>
                                </div>
                              </span>
                            </div>
                            {errors &&
                              errors.cpassword &&
                              errors.cpassword.type === "required" && (
                                <div className="text-danger error">
                                  Confirm Password is required
                                </div>
                              )}
                            {errors &&
                              errors.cpassword &&
                              errors.cpassword.type === "pattern" && (
                                <div className="text-danger error">
                                  Password must contain 8 characters with at
                                  least 1 upper case, 1 lower case, 1 special
                                  and 1 numeric character.
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-3">
                            <label className="form-label fw-medium">
                              Postal Code
                            </label>
                            <input
                              type="text"
                              id="autocomplete"
                              value={
                                location ? location.formatted_address : location
                              }
                              onChange={(e) => {
                                setLocation(e.target.value);
                                if (locationError !== "") {
                                  setLocationError("");
                                }
                              }}
                              ref={autcompleteRef}
                              placeholder="postal code"
                              className="form-control"
                            />
                            {locationError && locationError !== "" && (
                              <div className="text-danger error">
                                {locationError}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6 pb-3 pb-md-3 d-none">
                          <label className="form-label fw-medium">
                            Payment through insurance ?
                          </label>
                          <div className="d-flex align-items-center">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="paythroughinsurance"
                                id="payinsurance-yes"
                                value="yes"
                                ref={register({ required: true })}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="payinsurance-yes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="paythroughinsurance"
                                id="payinsurance-no"
                                value="no"
                                ref={register({ required: true })}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="payinsurance-no"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          {errors &&
                            errors.paythroughinsurance &&
                            errors.paythroughinsurance.type === "required" && (
                              <div className="text-danger error">
                                Pay through insurance is required.
                              </div>
                            )}
                          {watch("paythroughinsurance") === "no" && (
                            <label className="text-muted fw-normal small">
                              Payment through cash/credit/debit.
                            </label>
                          )}
                        </div>
                        <div
                          className={`col-md-6 ${
                            watch("paythroughinsurance") === "no" && "d-none"
                          }`}
                        >
                          <label className="form-label fw-medium">
                            Are you Dependent or Individual ?
                          </label>
                          <div className="d-flex align-items-center">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="patienttype"
                                id="individual"
                                value="individual"
                                ref={register({ required: true })}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="individual"
                              >
                                Individual
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="patienttype"
                                id="dependent"
                                value="dependent"
                                ref={register({ required: true })}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="dependent"
                              >
                                Dependent
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 d-none">
                          <label className="form-label">
                            Automated Reminder?
                          </label>
                          <div className="d-flex align-items-center">
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="automatedreminder"
                                id="a-yes"
                                value="yes"
                                ref={register({ required: true })}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="a-yes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="automatedreminder"
                                id="a-no"
                                value="no"
                                ref={register({ required: true })}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="a-no"
                              >
                                No
                              </label>
                            </div>
                            {errors &&
                              errors.automatedreminder &&
                              errors.automatedreminder.type === "required" && (
                                <div className="text-danger error">
                                  Automated reminder is required.
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-12">
                          {watch("automatedreminder") === "yes" && (
                            <div className="mt-3 d-none">
                              <label className="form-label">
                                Preferred contact method?
                              </label>
                              <div className="d-flex align-items-center">
                                <div className="form-check me-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="pcontactmethod"
                                    id="email-only"
                                    value={"email"}
                                    ref={register({ required: true })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="email-only"
                                  >
                                    Email Only
                                  </label>
                                </div>
                                <div className="form-check me-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="pcontactmethod"
                                    id="text-only"
                                    value="text"
                                    ref={register({ required: true })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="text-only"
                                  >
                                    Text Only
                                  </label>
                                </div>

                                <div className="form-check me-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="pcontactmethod"
                                    id="et-both"
                                    value="email,text"
                                    ref={register({ required: true })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="et-both"
                                  >
                                    Email & Text
                                  </label>
                                </div>
                              </div>
                              {errors &&
                                errors.pcontactmethod &&
                                errors.pcontactmethod.type === "required" && (
                                  <div className="text-danger error">
                                    Contact method is required.
                                  </div>
                                )}
                            </div>
                          )}
                          {watch("automatedreminder") === "no" && (
                            <div className="text-danger error">
                              This patient will not receive notification.
                            </div>
                          )}
                        </div>

                        {watch("paythroughinsurance") === "yes" && (
                          <>
                            <div className="col-md-6 mt-3">
                              <label className="form-label fw-medium">
                                Insurance name:
                              </label>
                              <select
                                className="form-select"
                                name="insurancename"
                                ref={register({ required: true })}
                                placeholder="Insurance name"
                              >
                                {masterInsuranceList.map((item: any) => (
                                  <option
                                    value={item.id}
                                    key={item.id + item.name}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              {errors &&
                                errors.insurancename &&
                                errors.insurancename.type === "required" && (
                                  <div className="text-danger error">
                                    Insurance name is required.
                                  </div>
                                )}
                              {watch("insurancename") === "other" && (
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter insurance plan name"
                                  name="otherInsurance"
                                  ref={register({ required: true })}
                                />
                              )}
                              {errors &&
                                errors.otherInsurance &&
                                errors.otherInsurance.type === "required" && (
                                  <div className="text-danger error">
                                    Insurance plan name is required.
                                  </div>
                                )}
                            </div>
                            <div className="col-md-6 mt-3">
                              <label className="form-label fw-medium">
                                Insurance plan name:
                              </label>
                              <select
                                className="form-select"
                                name="insuranceplanname"
                                ref={register({ required: true })}
                                placeholder="Insurance name"
                              >
                                {insurancePlanList.map((item: any) => (
                                  <option
                                    value={item.id}
                                    key={item.id + item.name}
                                  >
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                              {errors &&
                                errors.insuranceplanname &&
                                errors.insuranceplanname.type ===
                                  "required" && (
                                  <div className="text-danger error">
                                    Insurance plan name is required.
                                  </div>
                                )}
                              {watch("insuranceplanname") === "other" && (
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter insurance plan name"
                                  name="otherInsuranceplanName"
                                  ref={register({ required: true })}
                                />
                              )}
                              {errors &&
                                errors.otherInsuranceplanName &&
                                errors.otherInsuranceplanName.type ===
                                  "required" && (
                                  <div className="text-danger error">
                                    Insurance plan name is required.
                                  </div>
                                )}
                              {/* <input type="text" className="form-control" placeholder="Enter insurance plan name" name="insuranceplanname" ref={register({ required: true })} />
                                                            {
                                                                (errors && errors.insuranceplanname && errors.insuranceplanname.type === 'required') &&
                                                                <div className="text-danger error">Insurance plan name is required.</div>
                                                            } */}
                            </div>
                          </>
                        )}
                        {watch("paythroughinsurance") === "yes" && (
                          <div className="col-12 mt-3 mb-3">
                            <label className="form-label fw-medium">
                              Guarantor info
                            </label>
                            {watch("patienttype") === "individual" && (
                              <div className="d-flex flex-wrap align-items-center">
                                <div className="form-check my-2 me-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="selfguarantor"
                                    id="selfguarantor"
                                    value="self"
                                    disabled
                                    ref={register({ required: true })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="selfguarantor"
                                  >
                                    Self
                                  </label>
                                </div>
                                <div className="form-check my-2 me-3">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="secondaryguarantor"
                                    id="secondaryguarantor"
                                    disabled={!watch("selfguarantor")}
                                    value="secondary"
                                    ref={register}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="secondaryguarantor"
                                  >
                                    Secondary
                                  </label>
                                </div>
                                <div className="formLinks">
                                  {
                                    //@ts-ignore
                                    (watch("secondaryguarantor") ===
                                      "secondary" ||
                                      (watch("secondaryguarantor")[0] &&
                                        watch("secondaryguarantor")[0] ==
                                          "secondary")) &&
                                      watch("selfguarantor") && (
                                        <button
                                          className="btn btn-link"
                                          onClick={() => {
                                            setShowGuarantorInfoModal(true);
                                          }}
                                        >
                                          Click to add guarantor info
                                        </button>
                                      )
                                  }
                                </div>
                              </div>
                            )}
                            {watch("patienttype") === "dependent" && (
                              <>
                                <div className="d-flex flex-wrap align-items-center">
                                  <div className="form-check my-2 me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="primaryguarantor"
                                      id="primaryguarantor"
                                      value="primary"
                                      disabled
                                      ref={register({ required: true })}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="primaryguarantor"
                                    >
                                      Primary Guarantor
                                    </label>
                                  </div>
                                  <div className="form-check my-2 me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="secondaryguarantor"
                                      id="secondaryguarantor"
                                      value="secondary"
                                      ref={register}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="secondaryguarantor"
                                    >
                                      Secondary Guarantor
                                    </label>
                                  </div>
                                  <div className="formLinks">
                                    {(watch("primaryguarantor") ||
                                      watch("secondaryguarantor")) && (
                                      <button
                                        className="btn btn-link"
                                        onClick={() => {
                                          setShowGuarantorInfoModal(true);
                                        }}
                                      >
                                        Click to add guarantor info
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}

                        <>
                          {watch("patienttype") === "individual" &&
                            guarantorList.length === 1 && (
                              <div className="alert alert-success alert-dismissible">
                                Secondary Guarantor :{" "}
                                {guarantorList[0].fname +
                                  " " +
                                  guarantorList[0].lname +
                                  " (" +
                                  guarantorList[0].email +
                                  ")"}
                              </div>
                            )}

                          {watch("patienttype") === "dependent" &&
                            guarantorList.length > 0 && (
                              <>
                                {watch("primaryguarantor") &&
                                  guarantorList[0] && (
                                    <div className="alert alert-success alert-dismissible">
                                      Primary Guarantor :{" "}
                                      {guarantorList[0].fname +
                                        " " +
                                        guarantorList[0].lname +
                                        " (" +
                                        guarantorList[0].email +
                                        ")"}
                                    </div>
                                  )}
                                {watch("secondaryguarantor") &&
                                  guarantorList[1] && (
                                    <div className="alert alert-success alert-dismissible">
                                      Secondary Guarantor :{" "}
                                      {guarantorList[1].fname +
                                        " " +
                                        guarantorList[1].lname +
                                        " (" +
                                        guarantorList[1].email +
                                        ")"}
                                    </div>
                                  )}
                              </>
                            )}
                        </>
                        <input
                          type="hidden"
                          name="globalError"
                          ref={register}
                        />
                        {errors &&
                          errors.globalError &&
                          errors.globalError.type === "custom" && (
                            <div className="text-danger error">
                              {errors.globalError.message}
                            </div>
                          )}
                        <div className="d-flex">
                          <label>
                            <b>Note:</b> Option to add minor/spouse or any
                            dependent family member will be available after the
                            sign up step.
                          </label>
                        </div>
                        <div className="col-sm-12">
                          <div className="d-flex justify-content-end mt-3">
                            <button
                              className="btn btn-outline-secondary me-3"
                              type="button"
                              onClick={(e) => {
                                history.push("/login");
                              }}
                            >
                              Back to sign in
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isLoading}
                            >
                              Register
                              <span>
                                <>
                                  {isLoading && (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      className="ms-2"
                                    />
                                  )}
                                  {/* {
                                                                        isLoading === false &&
                                                                        <img src={require('../../../assets/images/ic_arrow_upward_24px.svg')} alt="" />
                                                                    } */}
                                </>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Animated>
              {showGurantorInfoModal && (
                <GuarantorInfoModal
                  hideModal={() => {
                    setShowGuarantorInfoModal(false);
                  }}
                  patientType={watch("patienttype")}
                  selfguarantor={watch("selfguarantor")}
                  secondaryguarantor={watch("secondaryguarantor")}
                  primaryguarantor={watch("primaryguarantor")}
                  submit={(data: any) => {
                    if (getValues("patienttype") === "dependent") {
                      setGuarantorList(data);
                    } else if (getValues("patienttype") === "individual") {
                      setGuarantorList(data);
                    }
                    setShowGuarantorInfoModal(false);
                  }}
                  masterInsuranceList={masterInsuranceList}
                  selectedGuarantorList={guarantorList}
                ></GuarantorInfoModal>
              )}
              {isShowOTPModal && (
                <OTPModal
                  hideModal={() => {
                    setIsShowOTPModal(false);
                  }}
                  sentPhone={phone}
                  sentEmail={email}
                  patientId={patientId}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  codeVerifySuccessLogin={postLoginAction}
                ></OTPModal>
              )}
            </div>

            <div className="text-center fw-normal footer-srip">
              Copyright © {new Date().getFullYear()} Xcare Inc. All rights
              reserved.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
