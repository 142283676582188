import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { PatientDataState, PatientDataResponse, AccountUpdateRequest, EmailVerificationRequest, PatientDataRequest } from '../../store/patientData/types';
import { maskUSPhoneInput, addressMaster, takeOnlyDigitAndPlus, takeOnlyDigit } from '../../utils/global';
import { getCookie, attachHeader } from '../../utils';
import { toast } from 'react-toastify';
import { accountUpdatePending, emailVerificationPendingAction } from '../../store/patientData/actions';
import { emailValidator } from '../../utils/validators';
import Helmet from 'react-helmet';
import { TITLES_LABELS, CONTACTMETHODS } from '../../constants';
import { Animated } from "react-animated-css";
import PinInput from 'react-pin-input';
import Axios from 'axios';
import apiConfigs from '../../configs/apiConfigs';
import { Button, Modal, Spinner } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import axios from 'axios';
import CONFIG from '../../configs/apiConfigs';
import { patientDataPending } from '../../store/patientData/actions';
import { GuarantorInfo } from './gurantorinfo';

interface Props {
    patientDataState: PatientDataState;
    accountUpdate: typeof accountUpdatePending;
    emailVerification: typeof emailVerificationPendingAction;
    patientData: typeof patientDataPending;
}
interface State {
    patientData?: PatientDataResponse;
    currentActiveCard: number;
    originalPatientData?: PatientDataResponse;
    newEmailAddress: string;
    confirmNewEmailAddress: string;
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    cardZeroValidation: { valid: boolean, field: number, msg: string },
    cardOneValidation: { valid: boolean, msg: string },
    cardTwoValidation: { valid: boolean, field: number, msg: string },
    cardThreeValidation: { valid: boolean, field: number, msg: string },
    cardFourValidation: { valid: boolean, msg: string },
    cardFiveValiation: { valid: boolean, msg: string },
    cardSixValidaiton: { valid: boolean, msg: string },
    address: string;
    country: string;
    province: string;
    city: string;
    postalcode: string;
    provinceArray: [];
    phoneVerifyModal: boolean;
    verificationCode: string;
    intervalTime: number;
    verificationCodeFalse: boolean;
    loadingVerification: boolean;
    emailVerificationLoader: boolean;
    prefrences: any;
    isEdit: boolean;
    email: any;
    contactMethod: any;
    phone: any;
    reminder: any;
    preferenceUpdateLoading: boolean;
}
class Account extends React.Component<Props, State> {
    monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    interval: any;
    constructor(props: any) {
        super(props);
        this.state = {
            currentActiveCard: -1,
            newEmailAddress: '', confirmNewEmailAddress: '',
            currentPassword: '', newPassword: '', confirmNewPassword: '',
            cardZeroValidation: { valid: true, field: -1, msg: '' },
            cardOneValidation: { valid: true, msg: '' },
            cardTwoValidation: { valid: true, field: -1, msg: '' },
            cardThreeValidation: { valid: true, field: -1, msg: '' },
            cardFourValidation: { valid: true, msg: '' },
            cardFiveValiation: { valid: true, msg: '' },
            cardSixValidaiton: { valid: true, msg: '' },
            address: '', city: '', country: '', postalcode: '', province: '',
            provinceArray: [], phoneVerifyModal: false, verificationCode: '',
            intervalTime: 30,
            verificationCodeFalse: false,
            loadingVerification: false,
            emailVerificationLoader: false,
            prefrences: '',
            isEdit: false,
            email: '', phone: '', contactMethod: CONTACTMETHODS.EMAIL_TEXT, reminder: '', preferenceUpdateLoading: false
        }
    }
    componentDidMount() {
        if (this.props.patientDataState && this.props.patientDataState.error === null &&
            this.props.patientDataState.patientDataResponse) {
            this.setState({
                originalPatientData: JSON.parse(JSON.stringify(this.props.patientDataState.patientDataResponse))
            });
            let maskedText = '';
            let phone = this.props.patientDataState.patientDataResponse.phone;
            console.log(phone);
            if (phone && phone !== "") {
                if (phone[0] === "+") {
                    phone = phone.substr(2, phone.length);
                }
                maskedText = maskUSPhoneInput(phone);
            }
            this.setState({
                patientData: {
                    ...this.props.patientDataState.patientDataResponse,
                    phone: maskedText,
                },
                address: this.props.patientDataState.patientDataResponse.addressline1 + " " + this.props.patientDataState.patientDataResponse.addressline2,
                country: this.props.patientDataState.patientDataResponse.country,
                city: this.props.patientDataState.patientDataResponse.city,
                province: this.props.patientDataState.patientDataResponse.state,
                postalcode: this.props.patientDataState.patientDataResponse.postalCode,
            });
            if (addressMaster.states[this.props.patientDataState.patientDataResponse.country]) {
                this.setState({ provinceArray: addressMaster.states[this.props.patientDataState.patientDataResponse.country] });
            } else {
                this.setState({ provinceArray: [] });
            }
            const ID = this.props.patientDataState.patientDataResponse.id
            this.setState({ prefrences: this.props.patientDataState.patientDataResponse.dependant.find((item: any) => { return Number(item.loginId) === Number(ID) }) })
        }
    }
    resetAllFormData() {
        if (this.state.patientData) {
            console.log('PatientData', this.state.originalPatientData ? this.state.originalPatientData.state : '');
            if (this.state.originalPatientData && this.state.originalPatientData.country && addressMaster.states[this.state.originalPatientData.country]) {
                this.setState({ provinceArray: addressMaster.states[this.state.originalPatientData.country] });
            } else {
                this.setState({ provinceArray: [] });
            }
            this.setState({
                address: (this.state.originalPatientData && this.state.originalPatientData.addressline1) ? this.state.originalPatientData.addressline1 + " " + this.state.originalPatientData.addressline2 : '',
                country: (this.state.originalPatientData && this.state.originalPatientData.country) ? this.state.originalPatientData.country : '',
                city: (this.state.originalPatientData && this.state.originalPatientData.city) ? this.state.originalPatientData.city : '',
                province: (this.state.originalPatientData && this.state.originalPatientData.state) ? this.state.originalPatientData.state : '',
                postalcode: (this.state.originalPatientData && this.state.originalPatientData.postalCode) ? this.state.originalPatientData.postalCode : '',
                patientData: {
                    ...this.state.patientData,
                    firstName: (this.state.originalPatientData && this.state.originalPatientData.firstName) ? this.state.originalPatientData.firstName : '',
                    lastName: (this.state.originalPatientData && this.state.originalPatientData.lastName) ? this.state.originalPatientData.lastName : '',
                    addressline1: (this.state.originalPatientData && this.state.originalPatientData.addressline1) ? this.state.originalPatientData.addressline1 : '',
                    addressline2: (this.state.originalPatientData && this.state.originalPatientData.addressline2) ? this.state.originalPatientData.addressline2 : '',
                    country: (this.state.originalPatientData && this.state.originalPatientData.country) ? this.state.originalPatientData.country : '',
                    postalCode: (this.state.originalPatientData && this.state.originalPatientData.postalCode) ? this.state.originalPatientData.postalCode : '',
                    state: (this.state.originalPatientData && this.state.originalPatientData.state) ? this.state.originalPatientData.state : '',
                    gender: (this.state.originalPatientData && this.state.originalPatientData.gender) ? this.state.originalPatientData.gender : 'Male',
                    dob: (this.state.originalPatientData && this.state.originalPatientData.dob) ? this.state.originalPatientData.dob : ''
                }
            })
        }
    }
    resetAllFormValidation() {
        this.setState({
            cardZeroValidation: { valid: true, field: -1, msg: '' },
            cardOneValidation: { valid: true, msg: '', },
            cardTwoValidation: { valid: true, field: -1, msg: '' },
            cardThreeValidation: { valid: true, field: -1, msg: '' },
            cardFourValidation: { valid: true, msg: '' },
            cardFiveValiation: { valid: true, msg: '' },
            cardSixValidaiton: { valid: true, msg: '' }
        })
    }
    handleFormClick(currentCard: number) {
        const login: any = getCookie();
        if (currentCard === 0) {
            if (this.state.patientData) {
                if (!this.state.patientData.firstName || this.state.patientData.firstName === "") {
                    this.setState({ cardZeroValidation: { valid: false, field: 0, msg: 'First Name Required' } })
                    return;
                } else if (!this.state.patientData.lastName || this.state.patientData.lastName === "") {
                    this.setState({ cardZeroValidation: { valid: false, field: 1, msg: 'Last Name Required' } })
                    return;
                }
                this.props.accountUpdate({
                    // userId: login.id,
                    // uniqueId: login.uniqueId,
                    uniqueId: login.xpr_user_id,
                    updateType: 'name',
                    firstName: this.state.patientData.firstName,
                    lastName: this.state.patientData.lastName
                });
                this.resetAllFormValidation();
            }
        } else if (currentCard === 1) {
            if (this.state.patientData) {
                if (!this.state.patientData.phone || this.state.patientData.phone === "") {
                    this.setState({ cardOneValidation: { valid: false, msg: 'Mobile number is required' } });
                    return;
                }
                let phone: string = this.state.patientData.phone;
                if (phone.length > 10 || phone.length < 10) {
                    this.setState({ cardOneValidation: { valid: false, msg: 'Invalid mobile number' } });
                    return;
                }
                this.props.accountUpdate({
                    // userId: login.id,
                    // uniqueId: login.uniqueId,
                    uniqueId: login.xpr_user_id,
                    updateType: 'phone',
                    phone: phone
                });
                this.resetAllFormValidation();
            }
        } else if (currentCard === 2) {
            if (this.state.patientData) {
                if (this.state.newEmailAddress === "") {
                    this.setState({
                        cardTwoValidation: { valid: false, field: 0, msg: 'New Email is requried' }
                    });
                    return;
                } else if (this.state.confirmNewEmailAddress === "") {
                    this.setState({
                        cardTwoValidation: { valid: false, field: 1, msg: 'Confirm New Email is requried' }
                    });
                    return;
                } else if (!emailValidator(this.state.newEmailAddress)) {
                    this.setState({
                        cardTwoValidation: { valid: false, field: 0, msg: 'New Email is invalid' }
                    });
                    return;
                } else if (!emailValidator(this.state.confirmNewEmailAddress)) {
                    this.setState({
                        cardTwoValidation: { valid: false, field: 1, msg: 'Confirm New Email is invalid' }
                    });
                    return;
                } else if (this.state.confirmNewEmailAddress.trim() !== this.state.newEmailAddress.trim()) {
                    this.setState({
                        cardTwoValidation: { valid: false, field: 1, msg: 'Confirm New Email and New email should match' }
                    });
                    return;
                }
                this.props.accountUpdate({
                    // userId: login.id,
                    // uniqueId: login.uniqueId,
                    uniqueId: login.xpr_user_id,
                    updateType: 'email',
                    email: this.state.newEmailAddress
                });
                this.resetAllFormValidation();
            }
        } else if (currentCard === 3) {
            if (this.state.currentPassword === "") {
                this.setState({
                    cardThreeValidation: {
                        valid: false, field: -1, msg: 'Current Password Required'
                    }
                });
                return;
            } else if (this.state.newPassword === "") {
                this.setState({
                    cardThreeValidation: {
                        valid: false, field: -1, msg: 'New Password Required'
                    }
                });
                return;
            } else if (this.state.confirmNewPassword === "") {
                this.setState({
                    cardThreeValidation: {
                        valid: false, field: -1, msg: 'Confirm New Password Required'
                    }
                });
                return;
            } else if (this.state.newPassword !== this.state.confirmNewPassword) {
                this.setState({
                    cardThreeValidation: {
                        valid: false, field: -1, msg: 'New password and confirm password both should be same'
                    }
                });
                return;
            }
            this.props.accountUpdate({
                // userId: login.id,
                // uniqueId: login.uniqueId,
                uniqueId: login.xpr_user_id,
                updateType: 'password',
                password: {
                    cpassword: this.state.currentPassword,
                    nPassword: this.state.newPassword,
                    rpassword: this.state.confirmNewPassword
                }
            });
            this.resetAllFormValidation();
        } else if (currentCard === 4) {
            if (this.state.patientData) {
                if (this.state.patientData.dob === "") {
                    this.setState({
                        cardFourValidation: {
                            valid: false, msg: 'Date of birth is required'
                        }
                    });
                    return;
                }
                this.props.accountUpdate({
                    // userId: login.id,
                    // uniqueId: login.uniqueId,
                    uniqueId: login.xpr_user_id,
                    updateType: 'dob',
                    dob: this.state.patientData.dob
                });
                this.resetAllFormValidation();
            }
        } else if (currentCard === 5) {
            if (this.state.patientData) {
                if (this.state.patientData.gender === "") {
                    // no possible condition.
                }
                this.props.accountUpdate({
                    // userId: login.id,
                    // uniqueId: login.uniqueId,
                    uniqueId: login.xpr_user_id,
                    updateType: 'gender',
                    gender: this.state.patientData.gender
                });
                this.resetAllFormValidation();
            }
            this.resetAllFormValidation();
        } else if (currentCard === 6) {
            if (this.state.address.trim() === "") {
                this.setState({ cardSixValidaiton: { valid: false, msg: 'Address required' } });
                return;
            } else if (this.state.country.trim() === "") {
                this.setState({ cardSixValidaiton: { valid: false, msg: 'Country Required' } });
                return;
            } else if (this.state.province.trim() === "") {
                if (this.state.country === 'Canada') {
                    this.setState({ cardSixValidaiton: { valid: false, msg: 'Province required' } });
                } else {
                    this.setState({ cardSixValidaiton: { valid: false, msg: 'State required' } });
                }
                return;
            } else if (this.state.city.trim() === "") {
                this.setState({ cardSixValidaiton: { valid: false, msg: 'City required' } });
                return;
            } else if (this.state.postalcode.trim() === "") {
                this.setState({ cardSixValidaiton: { valid: false, msg: 'Postal code required' } });
                return;
            } else if (this.state.country.trim() !== "" && !addressMaster.postalRegexByCountry[this.state.country].test(this.state.postalcode)) {
                this.setState({ cardSixValidaiton: { valid: false, msg: 'Invalid postal code.' } });
                return;
            }
            this.props.accountUpdate({
                // userId: login.id,
                // uniqueId: login.uniqueId,
                uniqueId: login.xpr_user_id,
                updateType: 'address',
                address: {
                    addressline1: this.state.address.trim(),
                    addressline2: '', country: this.state.country.trim(),
                    city: this.state.city.trim(),
                    state: this.state.province.trim(), postalCode: this.state.postalcode.trim()
                }
            });
            this.resetAllFormValidation();
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (this.props !== nextProps) {
            if (nextProps.patientDataState.error !== null) {
                if (nextProps.patientDataState.error.status && nextProps.patientDataState.error.status.error === true) {
                    toast.error(nextProps.patientDataState.error.status.msg);
                } else {
                    toast.error('Error Occured');
                }
            } else {
                if (nextProps.patientDataState.pending === false) {
                    if (nextProps.patientDataState.patientDataResponse && nextProps.patientDataState.patientDataResponse !== this.props.patientDataState.patientDataResponse) {
                        this.setState({
                            originalPatientData: JSON.parse(JSON.stringify(nextProps.patientDataState.patientDataResponse))
                        });
                        let maskedText = '';
                        let phone: any = nextProps.patientDataState.patientDataResponse.phone;
                        /* if (phone && phone !== "") {
                            if (phone[0] === "+") {
                                phone = phone.substr(2, phone);
                            }
                            maskedText = maskUSPhoneInput(phone);
                        } */
                        this.setState({
                            patientData: {
                                ...nextProps.patientDataState.patientDataResponse,
                                phone: phone
                            },
                            address: nextProps.patientDataState.patientDataResponse.addressline1 + " " + nextProps.patientDataState.patientDataResponse.addressline2,
                            country: nextProps.patientDataState.patientDataResponse.country,
                            city: nextProps.patientDataState.patientDataResponse.city,
                            province: nextProps.patientDataState.patientDataResponse.state,
                            postalcode: nextProps.patientDataState.patientDataResponse.postalCode,
                        });
                        if (addressMaster.states[nextProps.patientDataState.patientDataResponse.country]) {
                            this.setState({ provinceArray: addressMaster.states[nextProps.patientDataState.patientDataResponse.country] });
                        } else {
                            this.setState({ provinceArray: [] });
                        }
                        const ID = nextProps.patientDataState.patientDataResponse.id
                        this.setState({ prefrences: nextProps.patientDataState.patientDataResponse.dependant.find((item: any) => { return item.loginId === ID }) })
                    }
                    if (nextProps.patientDataState.accountUpdateResponse &&
                        nextProps.patientDataState.accountUpdateResponse.status && nextProps.patientDataState.accountUpdateResponse.status.error === false) {
                        toast.success(nextProps.patientDataState.accountUpdateResponse.status.msg);
                        this.setState({
                            currentActiveCard: -1, newEmailAddress: '', confirmNewEmailAddress: '',
                            currentPassword: '', newPassword: '', confirmNewPassword: '',
                        });
                        nextProps.patientDataState.accountUpdateResponse = undefined;
                    }
                    if (nextProps.patientDataState.emailVerificationResponse
                        && nextProps.patientDataState.emailVerificationResponse.status.error === false) {
                        this.setState({ emailVerificationLoader: false });
                        toast.success(nextProps.patientDataState.emailVerificationResponse.status.msg);
                    } else {
                        this.setState({ emailVerificationLoader: false });
                    }
                }
            }
        }
        return true;
    }
    componentDidUpdate() {
        this.props.patientDataState.error = null;
        if (this.props.patientDataState.emailVerificationResponse) {
            this.props.patientDataState.emailVerificationResponse.status.error = true;
        }
    }
    handleEmailVerifyClick() {
        if (this.state.originalPatientData) {
            this.setState({ emailVerificationLoader: true });
            this.props.emailVerification({
                modelType: 'patient', modelUniqueid: this.state.originalPatientData.uniqueId,
                email: this.state.originalPatientData.email
            });
        }
    }
    sendVerificationCode() {
        if (this.state.originalPatientData) {
            this.setState({ loadingVerification: true });
            Axios.post(apiConfigs.API_URL + 'phoneverify/' + 'patient/' + this.state.originalPatientData.uniqueId + '/', {
                phone: this.state.originalPatientData.phone
            }, {
                headers: attachHeader(),
                withCredentials: true
            }).then((success) => {
                this.setState({ loadingVerification: false });
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast.success(success.data.status.msg);
                        this.setState({ phoneVerifyModal: true, verificationCodeFalse: false });
                        this.interval = setInterval(() => {
                            if (this.state.intervalTime === 0) {
                                clearInterval(this.interval);
                            } else {
                                this.setState({ intervalTime: this.state.intervalTime - 1 });
                            }
                        }, 1000)
                    } else {
                        toast.error(success.data.status.msg);
                    }
                }
            }, (error) => {
                this.setState({ loadingVerification: false });
                toast.error('Something went wrong');
            });
        }
    }
    verifyCode() {
        if (this.state.originalPatientData) {
            this.setState({ loadingVerification: true });
            Axios.post(apiConfigs.API_URL + 'verification/' + 'patient/' + this.state.originalPatientData.uniqueId + '/', {
                phone: this.state.originalPatientData.phone,
                code: this.state.verificationCode
            }, {
                headers: attachHeader(),
                withCredentials: true
            }).then((success) => {
                this.setState({ loadingVerification: false });
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast(success.data.status.msg);
                        this.setState({ phoneVerifyModal: false, verificationCodeFalse: false });
                        if (this.props.patientDataState.patientDataResponse) {
                            this.props.patientDataState.patientDataResponse.phoneVerified = new Date().toString();
                            if (this.state.originalPatientData && this.state.patientData) {
                                this.setState({
                                    originalPatientData: {
                                        ...this.state.originalPatientData,
                                        phoneVerified: new Date().toString()
                                    },
                                    patientData: {
                                        ...this.state.patientData,
                                        phoneVerified: new Date().toString()
                                    }
                                })
                            }
                        }
                    } else {
                        this.setState({ verificationCodeFalse: true });
                    }
                }
            }, (error) => {
                this.setState({ loadingVerification: false });
                toast.error('Something went wrong');
            });
        }
    }
    handleIsEdit = () => {
        this.setState({
            isEdit: true, email: this.state.prefrences.email,
            phone: this.state.prefrences.phone,
            contactMethod: this.state.prefrences.contactMethod,
            reminder: this.state.prefrences.reminder
        })
    }
    handleEditPrefrences = () => {
        if (this.state.email === '') {
            toast.warn('Please enter Email.');
            return;
        }
        if (this.state.phone === '') {
            toast.warn('Please enter Mobile No.');
            return;
        }
        if (!emailValidator(this.state.email)) {
            toast.warn('Please enter valid email.');
            return
        }
        if (this.state.phone.length !== 10) {
            toast.warn('Please enter valid mobile.');
            return
        }
        const body = {
            id: this.state.prefrences.id,
            fname: this.state.prefrences.firstName,
            lname: this.state.prefrences.lastName,
            relation: this.state.prefrences.relation,
            email: this.state.email,
            phone: this.state.phone,
            gender: this.state.prefrences.gender,
            dob: this.state.prefrences.dob,
            contactMethod: this.state.contactMethod,
            reminder: this.state.reminder,
        }
        this.setState({ preferenceUpdateLoading: true });
        const url = CONFIG.API_URL + 'patient' + '/' + this.props.patientDataState.patientDataResponse?.uniqueId + '/dependant/edit/';
        axios.post(url, body, { headers: attachHeader(), withCredentials: true }).then((response) => {
            if (response.status !== 200) {
                throw response;
            } else if (response.data && response.data.status && response.data.status.error === true) {
                throw response.data;
            }
            return response.data;
        }).then((success: any) => {
            this.setState({ preferenceUpdateLoading: false });
            const login: any = getCookie();
            this.props.patientData({ uniqueId: login.xpr_user_id });
            this.setState({ isEdit: false });
        }).catch((error: any) => {
            this.setState({ preferenceUpdateLoading: false });
            console.log(error)
        });
    }
    render() {
        let today: any = new Date();
        let dd: any = today.getDate();
        let mm: any = today.getMonth() + 1; //January is 0!
        let yyyy: any = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        return (
            <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDuration={1000} isVisible={true}>
                <section className="account-settings pantient-view">
                    <Helmet><title>{TITLES_LABELS.META.ACCOUNT}</title></Helmet>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <h4 className="page-title">Account Settings</h4>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="account-fields">
                                    {
                                        this.state.currentActiveCard === 0 ?
                                            null :
                                            <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDelay={100} isVisible={true}>
                                                <div className="ac-field-value media">
                                                    <div className="media-body">
                                                        <div className="ac-title">Name</div>
                                                        <div className="ac-value">
                                                            {
                                                                (this.state.originalPatientData && this.state.originalPatientData.firstName && this.state.originalPatientData.lastName) ? (this.state.originalPatientData.firstName + " " + this.state.originalPatientData.lastName) : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <a href="javascript:void(0);" className="ml-3 edit-btn align-self-center" onClick={(e) => {
                                                        this.setState({ currentActiveCard: 0 });
                                                        this.resetAllFormData();
                                                        this.resetAllFormValidation();
                                                    }}>Edit</a>
                                                </div>
                                            </Animated>
                                    }
                                    {
                                        this.state.currentActiveCard === 0 &&

                                        <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                            <div className="ac-form">
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="fname">First Name</label>
                                                        <input type="text" className="form-control" placeholder="First name" id="fname"
                                                            onInput={(e: any) => {
                                                                if (this.state.patientData) {
                                                                    this.setState({
                                                                        patientData: {
                                                                            ...this.state.patientData,
                                                                            firstName: e.target.value
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if (this.state.patientData) {
                                                                    this.setState({
                                                                        patientData: {
                                                                            ...this.state.patientData,
                                                                            firstName: e.target.value
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                            value={(this.state.patientData && this.state.patientData.firstName) ? this.state.patientData.firstName : ''} />
                                                        {
                                                            (this.state.cardZeroValidation.valid === false && this.state.cardZeroValidation.field === 0) &&
                                                            <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                                                <div className="text-danger error">{this.state.cardZeroValidation.msg}</div>
                                                            </Animated>
                                                        }
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="lname">Last Name</label>
                                                        <input type="text" className="form-control" placeholder="Last name" id="lname"
                                                            onInput={(e: any) => {
                                                                if (this.state.patientData) {
                                                                    this.setState({
                                                                        patientData: {
                                                                            ...this.state.patientData,
                                                                            lastName: e.target.value
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                if (this.state.patientData) {
                                                                    this.setState({
                                                                        patientData: {
                                                                            ...this.state.patientData,
                                                                            lastName: e.target.value
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                            value={(this.state.patientData && this.state.patientData.lastName) ? this.state.patientData.lastName : ''} />
                                                        {
                                                            (this.state.cardZeroValidation.valid === false && this.state.cardZeroValidation.field === 1) &&
                                                            <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                                                <div className="text-danger error">{this.state.cardZeroValidation.msg}</div>
                                                            </Animated>
                                                        }
                                                    </div>
                                                </div>

                                                <button type="button" className="btn btn-save btn-custom mr-3"
                                                    disabled={this.props.patientDataState.pending}
                                                    onClick={(e) => { this.handleFormClick(this.state.currentActiveCard) }}>
                                                    {TITLES_LABELS.ACCOUNT.SAVE_BUTTON}
                                                    {
                                                        this.props.patientDataState.pending &&
                                                        <span className="aft_load right">
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    }
                                                </button>
                                                <button type="button" className="btn btn-cancel btn-custom"
                                                    disabled={this.props.patientDataState.pending}
                                                    onClick={(e) => {
                                                        this.setState({
                                                            currentActiveCard: -1,
                                                        });
                                                        this.resetAllFormValidation();
                                                        this.resetAllFormData();
                                                        if (this.state.patientData) {
                                                            this.setState({
                                                                patientData: {
                                                                    ...this.state.patientData,
                                                                    firstName: (this.state.originalPatientData && this.state.originalPatientData.firstName) ? this.state.originalPatientData.firstName : '',
                                                                    lastName: (this.state.originalPatientData && this.state.originalPatientData.lastName) ? this.state.originalPatientData.lastName : ''
                                                                }
                                                            });
                                                        }
                                                    }
                                                    }>{TITLES_LABELS.ACCOUNT.CANCEL_BUTTON}</button>

                                            </div>
                                        </Animated>
                                    }
                                </div>
                                <div className="account-fields">
                                    {
                                        this.state.currentActiveCard === 1 ?
                                            null :
                                            <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDelay={100} isVisible={true}>
                                                <div className="ac-field-value media">
                                                    <div className="media-body">
                                                        <div className="ac-title">Mobile number</div>
                                                        <div className="ac-value">{/* +1 647-123-4545  */}{(this.state.originalPatientData && this.state.originalPatientData.phone) ? this.state.originalPatientData.phone : ''}
                                                            {
                                                                <>
                                                                    {
                                                                        (this.state.originalPatientData && this.state.originalPatientData.phone !== null) &&
                                                                        <>
                                                                            {
                                                                                (this.state.originalPatientData && this.state.originalPatientData.phoneVerified && this.state.originalPatientData.phoneVerified !== "" &&
                                                                                    this.state.originalPatientData.phoneVerified !== null) ?
                                                                                    <label className="varification-status verified">Verified</label>
                                                                                    :
                                                                                    <>
                                                                                        <label className="varification-status unverified">Unverified</label>
                                                                                        <label className="varification-status verify cs_point" onClick={(e) => { this.sendVerificationCode() }}>
                                                                                            Verify
                                                                            {
                                                                                                (this.state.loadingVerification && !this.state.phoneVerifyModal) &&
                                                                                                <span className="aft_load right">
                                                                                                    <Spinner
                                                                                                        as="span"
                                                                                                        animation="border"
                                                                                                        size="sm"
                                                                                                        role="status"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                </span>
                                                                                            }
                                                                                        </label>
                                                                                    </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </div>

                                                    </div>
                                                    <a className="ml-3 edit-btn align-self-center" href="javascript:void(0);" onClick={(e) => {
                                                        this.setState({ currentActiveCard: 1 });
                                                        this.resetAllFormData();
                                                        this.resetAllFormValidation();
                                                    }}>Edit</a>
                                                </div>
                                            </Animated>
                                    }
                                    {
                                        this.state.currentActiveCard === 1 &&
                                        <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                            <div className="ac-form">
                                                <div className="form-group">
                                                    <label htmlFor="fname">Mobile number</label>
                                                    <input type="tel" className="form-control"
                                                        onInput={(e: any) => {
                                                            if (this.state.patientData) {
                                                                /* if (e.target.value[0] === "+") {
                                                                    e.target.value = e.target.value.substr(2, e.target.value.length);
                                                                }
                                                                const maskedText = maskUSPhoneInput(e.target.value); */
                                                                if (e.target.value.length > 10) return;
                                                                this.setState({
                                                                    patientData: {
                                                                        ...this.state.patientData,
                                                                        phone: e.target.value
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            if (this.state.patientData) {
                                                                /* if (e.target.value[0] === "+") {
                                                                    e.target.value = e.target.value.substr(2, e.target.value.length);
                                                                }
                                                                const maskedText = maskUSPhoneInput(e.target.value); */
                                                                if (e.target.value.length > 10) return;
                                                                this.setState({
                                                                    patientData: {
                                                                        ...this.state.patientData,
                                                                        phone: e.target.value
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                        value={
                                                            (this.state.patientData && this.state.patientData.phone) ?
                                                                this.state.patientData.phone
                                                                : ''} />
                                                    {
                                                        (this.state.cardOneValidation.valid === false) &&
                                                        <div className="text-danger error">{this.state.cardOneValidation.msg}</div>
                                                    }
                                                </div>
                                                <button type="button" className="btn btn-save btn-custom mr-3"
                                                    disabled={this.props.patientDataState.pending}
                                                    onClick={(e) => { this.handleFormClick(1) }}>
                                                    {TITLES_LABELS.ACCOUNT.SAVE_BUTTON}
                                                    {
                                                        this.props.patientDataState.pending &&
                                                        <span className="aft_load right">
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    }

                                                </button>
                                                <button type="button" className="btn btn-cancel btn-custom"
                                                    disabled={this.props.patientDataState.pending}
                                                    onClick={(e) => {
                                                        this.setState({ currentActiveCard: -1 });
                                                        this.resetAllFormValidation();
                                                        this.resetAllFormData();
                                                        if (this.state.patientData) {
                                                            this.setState({
                                                                patientData: {
                                                                    ...this.state.patientData,
                                                                    phone: (this.state.originalPatientData && this.state.originalPatientData.phone) ? this.state.originalPatientData.phone : '',
                                                                }
                                                            });
                                                        }
                                                    }}>{TITLES_LABELS.ACCOUNT.CANCEL_BUTTON}</button>
                                            </div>
                                        </Animated>
                                    }
                                </div>
                                <div className="account-fields">
                                    {
                                        this.state.currentActiveCard === 2 ?
                                            null :
                                            <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDelay={100} isVisible={true}>
                                                <div className="ac-field-value media">
                                                    <div className="media-body">
                                                        <div className="ac-title">Login ID</div>
                                                        <div className="ac-value">{(this.state.originalPatientData && this.state.originalPatientData.email) ? this.state.originalPatientData.email : ''}
                                                            {/*   {
                                                                <>
                                                                    {
                                                                        (this.state.originalPatientData && this.state.originalPatientData.email) &&
                                                                        <>
                                                                            {(this.state.originalPatientData && this.state.originalPatientData.emailVerified && this.state.originalPatientData.emailVerified !== "" &&
                                                                                this.state.originalPatientData.emailVerified !== null) ?
                                                                                <label className="varification-status verified">Verified</label> :
                                                                                <>
                                                                                    <label className="varification-status unverified">Unverified</label>
                                                                                    <label className="varification-status verify cs_point" onClick={(e) => {
                                                                                        this.handleEmailVerifyClick();
                                                                                    }}>Resend
                                                                            {
                                                                                            this.state.emailVerificationLoader &&
                                                                                            <span className="aft_load right">
                                                                                                <Spinner
                                                                                                    as="span"
                                                                                                    animation="border"
                                                                                                    size="sm"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </span>
                                                                                        }
                                                                                    </label>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            } */}
                                                        </div>

                                                    </div>
                                                    <a className="ml-3 edit-btn align-self-center" href="javascript:void(0);" onClick={(e) => {
                                                        this.setState({ currentActiveCard: 2 });
                                                        this.resetAllFormData();
                                                        this.resetAllFormValidation();
                                                    }}>Edit</a>
                                                </div>
                                            </Animated>
                                    }
                                    {
                                        this.state.currentActiveCard === 2 &&
                                        <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                            <div className="ac-form">
                                                <div className="form-group">
                                                    <label>New Email</label>
                                                    <input type="email" className="form-control"
                                                        onInput={(e: any) => {
                                                            this.setState({ newEmailAddress: e.target.value });
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ newEmailAddress: e.target.value });
                                                        }}
                                                        value={this.state.newEmailAddress} />
                                                    {
                                                        (this.state.cardTwoValidation.valid === false && this.state.cardTwoValidation.field === 0) &&
                                                        <div className="text-danger error">{this.state.cardTwoValidation.msg}</div>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm New Email</label>
                                                    <input type="email" className="form-control"
                                                        onInput={(e: any) => {
                                                            this.setState({ confirmNewEmailAddress: e.target.value });
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ confirmNewEmailAddress: e.target.value });
                                                        }}
                                                        value={this.state.confirmNewEmailAddress} />
                                                    {
                                                        (this.state.cardTwoValidation.valid === false && this.state.cardTwoValidation.field === 1) &&
                                                        <div className="text-danger error">{this.state.cardTwoValidation.msg}</div>
                                                    }
                                                </div>
                                                <button type="button" className="btn btn-save btn-custom mr-3"
                                                    disabled={this.props.patientDataState.pending}
                                                    onClick={(e) => { this.handleFormClick(2) }}>
                                                    {TITLES_LABELS.ACCOUNT.SAVE_BUTTON}
                                                    {
                                                        this.props.patientDataState.pending &&
                                                        <span className="aft_load right">
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    }
                                                </button>
                                                <button type="button" className="btn btn-cancel btn-custom"
                                                    disabled={this.props.patientDataState.pending}
                                                    onClick={(e) => {
                                                        this.resetAllFormValidation();
                                                        this.resetAllFormData();
                                                        this.setState({
                                                            currentActiveCard: -1,
                                                            newEmailAddress: '', confirmNewEmailAddress: ''
                                                        });
                                                    }}>{TITLES_LABELS.ACCOUNT.CANCEL_BUTTON}</button>
                                            </div>
                                        </Animated>
                                    }
                                </div>
                                <div className="account-fields">
                                    {
                                        this.state.currentActiveCard === 3 ?
                                            null :
                                            <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDelay={100} isVisible={true}>
                                                <div className="ac-field-value media">
                                                    <div className="media-body">
                                                        <div className="ac-title">Password</div>
                                                        <div className="ac-value">**********</div>
                                                    </div>
                                                    <a className="ml-3 edit-btn align-self-center" href="javascript:void(0);" onClick={(e) => {
                                                        this.setState({ currentActiveCard: 3 });
                                                        this.resetAllFormData();
                                                        this.resetAllFormValidation();
                                                    }}>Edit</a>
                                                </div>
                                            </Animated>
                                    }
                                    {
                                        this.state.currentActiveCard === 3 &&
                                        <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                            <div className="ac-form">
                                                <div className="form-group">
                                                    <label>Current Password</label>
                                                    <input type="password" className="form-control"
                                                        onInput={(e: any) => {
                                                            this.setState({ currentPassword: e.target.value })
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ currentPassword: e.target.value })
                                                        }}
                                                        value={this.state.currentPassword} />
                                                </div>
                                                <div className="form-group">
                                                    <label>New Password</label>
                                                    <input type="password" className="form-control"
                                                        onInput={(e: any) => {
                                                            this.setState({ newPassword: e.target.value })
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ newPassword: e.target.value })
                                                        }} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm New Password</label>
                                                    <input type="password" className="form-control"
                                                        onInput={(e: any) => {
                                                            this.setState({ confirmNewPassword: e.target.value })
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ confirmNewPassword: e.target.value })
                                                        }} />
                                                    {
                                                        (this.state.cardThreeValidation.valid === false) &&
                                                        <div className="text-danger error">{this.state.cardThreeValidation.msg}</div>
                                                    }
                                                </div>
                                                <button type="button" className="btn btn-save btn-custom mr-3"
                                                    disabled={this.props.patientDataState.pending}
                                                    onClick={(e) => { this.handleFormClick(3) }}>
                                                    {TITLES_LABELS.ACCOUNT.SAVE_BUTTON}
                                                    {
                                                        this.props.patientDataState.pending &&
                                                        <span className="aft_load right">
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    }
                                                </button>
                                                <button type="button" className="btn btn-cancel btn-custom"
                                                    disabled={this.props.patientDataState.pending}
                                                    onClick={(e) => {
                                                        this.resetAllFormData();
                                                        this.resetAllFormValidation();
                                                        this.setState({
                                                            currentActiveCard: -1,
                                                            newPassword: '', confirmNewPassword: '', currentPassword: ''
                                                        });
                                                    }}>{TITLES_LABELS.ACCOUNT.CANCEL_BUTTON}</button>
                                            </div>
                                        </Animated>
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="account-fields">
                                    {
                                        this.state.currentActiveCard === 4 ?
                                            null :
                                            <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDelay={100} isVisible={true}>
                                                <div className="ac-field-value media">
                                                    <div className="media-body">
                                                        <div className="ac-title">Date of Birth</div>
                                                        <div className="ac-value">
                                                            {(this.state.originalPatientData && this.state.originalPatientData.dob) ?
                                                                this.monthNames[new Date(this.state.originalPatientData.dob).getMonth()] + "-" +
                                                                new Date(this.state.originalPatientData.dob).getDate() + "-" +
                                                                new Date(this.state.originalPatientData.dob).getFullYear()
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    <a className="ml-3 edit-btn align-self-center" href="javascript:void(0);" onClick={(e) => {
                                                        this.setState({ currentActiveCard: 4 });
                                                        this.resetAllFormData();
                                                        this.resetAllFormValidation();
                                                    }}>Edit</a>
                                                </div>
                                            </Animated>
                                    }
                                    {
                                        this.state.currentActiveCard === 4 &&
                                        <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                            <div className="ac-form">
                                                <div className="form-group">
                                                    <label>Select date</label>
                                                    <div className="form-group">
                                                        <input type="date" className="form-control"
                                                            max={today}
                                                            value={(this.state.patientData && this.state.patientData.dob) ? this.state.patientData.dob : ''}
                                                            onChange={(e: any) => {
                                                                const dobSelected: string = e.target.value;
                                                                if (this.state.patientData) {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        patientData: {
                                                                            ...this.state.patientData,
                                                                            dob: dobSelected
                                                                        }
                                                                    })
                                                                }
                                                            }} />
                                                        {
                                                            this.state.cardFourValidation.valid === false &&
                                                            <div className="text-danger error">{this.state.cardFourValidation.msg}</div>
                                                        }
                                                    </div>
                                                </div>
                                                <button type="button" className="btn btn-save btn-custom mr-3"
                                                    disabled={this.props.patientDataState.pending}
                                                    onClick={(e) => { this.handleFormClick(4) }}>
                                                    {TITLES_LABELS.ACCOUNT.SAVE_BUTTON}
                                                    {
                                                        this.props.patientDataState.pending &&
                                                        <span className="aft_load right">
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    }
                                                </button>
                                                <button type="button" className="btn btn-cancel btn-custom"
                                                    disabled={this.props.patientDataState.pending}
                                                    onClick={(e) => { this.resetAllFormValidation(); this.resetAllFormData(); this.setState({ currentActiveCard: -1 }); }}>{TITLES_LABELS.ACCOUNT.CANCEL_BUTTON}</button>
                                            </div>
                                        </Animated>
                                    }
                                </div>
                                <div className="account-fields">
                                    {
                                        this.state.currentActiveCard === 5 ?
                                            null :
                                            <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDelay={100} isVisible={true}>
                                                <div className="ac-field-value media">
                                                    <div className="media-body">
                                                        <div className="ac-title">Gender</div>
                                                        <div className="ac-value">{
                                                            (this.state.originalPatientData && this.state.originalPatientData.gender) ?
                                                                this.state.originalPatientData.gender : ''
                                                        }</div>
                                                    </div>
                                                    <a className="ml-3 edit-btn align-self-center" href="javascript:void(0);" onClick={(e) => {
                                                        this.setState({ currentActiveCard: 5 });
                                                        this.resetAllFormData();
                                                        this.resetAllFormValidation();
                                                    }}>Edit</a>
                                                </div>
                                            </Animated>
                                    }
                                    {
                                        this.state.currentActiveCard === 5 &&

                                        <div className="ac-form">
                                            <div className="form-group">
                                                <label>Gender</label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <select className="form-control"
                                                            onChange={(e) => {
                                                                const genderSelected: string = e.target.value;
                                                                if (this.state.patientData) {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        patientData: {
                                                                            ...this.state.patientData,
                                                                            gender: genderSelected
                                                                        }
                                                                    })
                                                                }
                                                            }}
                                                            value={(this.state.patientData && this.state.patientData.gender) ? this.state.patientData.gender : ''}>
                                                            <option value='Male'>Male</option>
                                                            <option value='Female'>Female</option>
                                                            {/* <option value="">Prefer not to say</option> */}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" className="btn btn-save btn-custom mr-3"
                                                disabled={this.props.patientDataState.pending}
                                                onClick={(e) => { this.handleFormClick(5) }}>
                                                {TITLES_LABELS.ACCOUNT.SAVE_BUTTON}
                                                {
                                                    this.props.patientDataState.pending &&
                                                    <span className="aft_load right">
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                }
                                            </button>
                                            <button type="button" className="btn btn-cancel btn-custom"
                                                disabled={this.props.patientDataState.pending}
                                                onClick={(e) => { this.resetAllFormValidation(); this.resetAllFormData(); this.setState({ currentActiveCard: -1 }); }}>{TITLES_LABELS.ACCOUNT.CANCEL_BUTTON}</button>
                                        </div>
                                    }
                                </div>

                                <div className="account-fields">
                                    {
                                        this.state.currentActiveCard === 6 ?
                                            null :
                                            <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDelay={100} isVisible={true}>
                                                <div className="ac-field-value media">
                                                    <div className="media-body">
                                                        <div className="ac-title">Address</div>
                                                        <div className="ac-value w-50">
                                                            {
                                                                (this.state.originalPatientData) ?
                                                                    <>
                                                                        <label>
                                                                            {
                                                                                this.state.originalPatientData.addressline1 !== "" ? <>{this.state.originalPatientData.addressline1 + ","}&nbsp;</> : ' '
                                                                            }
                                                                        </label>
                                                                        <label>
                                                                            {
                                                                                this.state.originalPatientData.addressline2 !== "" ? <>{this.state.originalPatientData.addressline2 + ","}&nbsp;</> : ' '
                                                                            }
                                                                        </label>
                                                                        <label>
                                                                            {this.state.originalPatientData.city !== "" ? <>{this.state.originalPatientData.city + ","}&nbsp;</> : ' '}
                                                                        </label>
                                                                        <label>{this.state.originalPatientData.state !== "" ? <>{this.state.originalPatientData.state + ","}&nbsp;</> : ' '}</label>
                                                                        <label>{this.state.originalPatientData.country !== "" ? <>{this.state.originalPatientData.country + ","}&nbsp;</> : ' '}</label>
                                                                        <label>{this.state.originalPatientData.postalCode !== "" ? this.state.originalPatientData.postalCode : ' '}</label>
                                                                    </>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <a className="ml-3 edit-btn align-self-center" href="javascript:void(0);" onClick={(e) => {
                                                        this.setState({ currentActiveCard: 6 });
                                                        this.resetAllFormData();
                                                        this.resetAllFormValidation();
                                                    }}>Edit</a>
                                                </div>
                                            </Animated>
                                    }
                                    {
                                        this.state.currentActiveCard === 6 &&
                                        <div className="ac-form">
                                            <div className="form-group">
                                                <label>Address</label>
                                                <input type="text" className="form-control"
                                                    placeholder="Apartment, suite, unit, floor, etc... (Optional)" value={this.state.address}
                                                    onInput={(e: any) => {
                                                        this.setState({ address: e.target.value })
                                                    }}
                                                    onChange={(e) => {
                                                        this.setState({ address: e.target.value })
                                                    }} />
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="fname">Country</label>
                                                    <select className="form-control" value={this.state.country} onChange={
                                                        (e) => {
                                                            this.setState({ country: e.target.value });
                                                            if (addressMaster.states[e.target.value]) {
                                                                this.setState({ provinceArray: addressMaster.states[e.target.value] });
                                                            } else {
                                                                this.setState({ provinceArray: [] });
                                                            }
                                                        }
                                                    }>
                                                        <option value="">Select Country</option>
                                                        {
                                                            addressMaster.countries.map((value: any, index: any) => {
                                                                return (
                                                                    <option value={value} key={index}>{value}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="lname">{
                                                        (this.state.country === 'Canada') ?
                                                            <>Province</> : <>State</>
                                                    }</label>
                                                    <select className="form-control" value={this.state.province} onChange={
                                                        (e) => {
                                                            this.setState({ province: e.target.value });
                                                        }
                                                    }>
                                                        {
                                                            (this.state.country === 'Canada') ?
                                                                <option value="">Select Province</option> : <option value="">Select State</option>
                                                        }
                                                        {
                                                            this.state.provinceArray.map((value, index) => {
                                                                return (
                                                                    <option value={value} key={index}>{value}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label>City</label>
                                                    <input type="text" className="form-control"
                                                        onInput={(e: any) => {
                                                            this.setState({ city: e.target.value });
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ city: e.target.value });
                                                        }}
                                                        value={this.state.city} />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label>{
                                                        (this.state.country === 'Canada') ?
                                                            <>Postal Code</> : <>Zip Code</>
                                                    }</label>
                                                    <input type="text" className="form-control"
                                                        onInput={(e: any) => {
                                                            this.setState({ postalcode: e.target.value });
                                                        }}
                                                        onChange={(e) => {
                                                            this.setState({ postalcode: e.target.value });
                                                        }}
                                                        placeholder={(this.state.country === 'Canada' ? 'Postal Code' : 'Zip Code')} value={this.state.postalcode} />
                                                </div>
                                            </div>
                                            {
                                                (this.state.cardSixValidaiton.valid === false) &&
                                                <div className="text-danger error">{this.state.cardSixValidaiton.msg}</div>
                                            }
                                            <button type="button" className="btn btn-save btn-custom mr-3"
                                                disabled={this.props.patientDataState.pending}
                                                onClick={(e) => { this.handleFormClick(6) }}>
                                                {TITLES_LABELS.ACCOUNT.SAVE_BUTTON}
                                                {
                                                    this.props.patientDataState.pending &&
                                                    <span className="aft_load right">
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                }
                                            </button>
                                            <button type="button" className="btn btn-cancel btn-custom"
                                                disabled={this.props.patientDataState.pending}
                                                onClick={(e) => {
                                                    this.setState({ currentActiveCard: -1 });
                                                    this.resetAllFormData();
                                                    this.resetAllFormValidation();
                                                }}>{TITLES_LABELS.ACCOUNT.CANCEL_BUTTON}</button>
                                        </div>
                                    }
                                </div>
                                {this.state.prefrences &&
                                    <div className="account-fields">
                                        <Animated animationIn="fadeIn" animationOut="fadeIn" animationInDelay={100} isVisible={true}>
                                            <div className="ac-field-value media">
                                                <div className="media-body">
                                                    <div className="ac-title">Notification Preference</div>
                                                    <div className="ac-value w-50">
                                                        <label>Email: <strong>{this.state.prefrences.email}</strong></label>
                                                        <label>Mobile number: <strong>{this.state.prefrences.phone}</strong> </label>
                                                        <label className="text-capitalize">Contact method: <strong>{this.state.prefrences.contactMethod}</strong> </label>
                                                        {/* <label>Auto reminder: <strong>{this.state.prefrences.reminder}</strong> </label> */}
                                                    </div>
                                                </div>
                                                <a className="ml-3 edit-btn align-self-center" href="javascript:void(0);" onClick={() => { this.handleIsEdit() }}>Edit</a>
                                            </div>
                                        </Animated>


                                        {this.state.isEdit &&
                                            <div className="ac-form">
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input type="email" className="form-control" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }} />
                                                </div>

                                                <div className="form-group">
                                                    <label>Mobile number</label>
                                                    <InputMask mask="9999999999" value={this.state.phone}
                                                        onChange={(e) => {
                                                            this.setState({ phone: e.target.value });
                                                        }}>
                                                        {(inputProps: any) => <input {...inputProps} className="form-control"></input>}
                                                    </InputMask>
                                                </div>

                                                <div className="form-group">
                                                    <label>Contact method</label>

                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="cs1" checked={this.state.contactMethod === CONTACTMETHODS.EMAIL} onChange={() => { this.setState({ contactMethod: CONTACTMETHODS.EMAIL, reminder: '' }) }} />
                                                        <label className="form-check-label" htmlFor="cs1">
                                                            Email Only
                                                </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="cs2" checked={this.state.contactMethod === CONTACTMETHODS.TEXT} onChange={() => { this.setState({ contactMethod: CONTACTMETHODS.TEXT, reminder: '' }) }} />
                                                        <label className="form-check-label" htmlFor="cs2">
                                                            Text Only
                                                </label>
                                                    </div>

                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="cs3" checked={this.state.contactMethod === CONTACTMETHODS.EMAIL_TEXT} onChange={() => { this.setState({ contactMethod: CONTACTMETHODS.EMAIL_TEXT, reminder: '' }) }} />
                                                        <label className="form-check-label" htmlFor="cs3">
                                                            Email & Text
                                                </label>
                                                    </div>

                                                </div>

                                                {/* <div className="form-group">
                                            <label>Auto reminder</label>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault1" id="yes" checked={this.state.reminder === 'yes'} onChange={()=>{this.setState({reminder:'yes'})}}/>
                                                <label className="form-check-label" htmlFor="yes">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault1" id="no" checked={this.state.reminder === 'no'} onChange={()=>{this.setState({reminder:'no'})}} />
                                                <label className="form-check-label" htmlFor="no">
                                                    No
                                                </label>
                                            </div>
                                        </div> */}
                                                <button disabled={this.state.preferenceUpdateLoading} type="button" className="btn btn-save btn-custom mr-3" onClick={() => { this.handleEditPrefrences() }}>
                                                    {
                                                        this.state.preferenceUpdateLoading &&
                                                        <span className="aft_load right">
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    }
                                                    Save Changes
                                                </button>
                                                <button type="button" className="btn btn-cancel btn-custom" onClick={() => { this.setState({ isEdit: false }) }} >Cancel</button>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <GuarantorInfo></GuarantorInfo>
                    </div>
                    {
                        this.state.phoneVerifyModal &&
                        <Modal centered className="phoneverify" show={this.state.phoneVerifyModal} onHide={() => {
                            this.setState({ phoneVerifyModal: false, verificationCodeFalse: false, intervalTime: 30 });
                            clearInterval(this.interval);
                        }}>
                            <div className="popup-sec">
                                <div className="popup-title text-center">
                                    Verify your number
                            </div>
                                <div className="popup-form text-center">
                                    <p>We have sent a verification code to <span>{this.state.originalPatientData ? this.state.originalPatientData.phone : ''}</span></p>
                                    <div className="form-txt">Enter code here</div>
                                    <div className="form-group">
                                        <PinInput
                                            length={4}
                                            initialValue={""}
                                            onChange={(value, index) => {
                                                this.setState({ verificationCode: value });
                                            }}
                                            type="numeric"
                                            style={{ padding: '10px' }}
                                            inputStyle={{}}
                                            inputFocusStyle={{ borderColor: 'blue' }}
                                            onComplete={(value, index) => {
                                                this.setState({ verificationCode: value });
                                            }}
                                        />
                                        {
                                            this.state.verificationCodeFalse &&
                                            <div className="text-danger error">Verification code entered is incorrect.</div>
                                        }
                                    </div>
                                    <button className="btn btn-custom send-btn"
                                        onClick={(e) => { this.verifyCode() }}
                                        disabled={this.state.verificationCode.length !== 4 || this.state.loadingVerification}>
                                        <img src={require('../../assets/images/ic_arrow_upward_24px.svg')} />
                                    </button>
                                    {
                                        this.state.intervalTime !== 0 &&
                                        <div className="resend-txt">Didn't received code? Resend after {this.state.intervalTime} seconds.</div>
                                    }
                                    {
                                        this.state.intervalTime === 0 &&
                                        <button className="btn resend-btn" onClick={(e) => { this.sendVerificationCode() }}>
                                            Resend Code
                                            {
                                                this.state.loadingVerification &&
                                                <span className="aft_load right">
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            }
                                        </button>
                                    }
                                </div>
                            </div>
                        </Modal>
                    }
                </section>
            </Animated>
        )
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    accountUpdate: (request: AccountUpdateRequest) => {
        dispatch(accountUpdatePending(request));
    },
    emailVerification: (request: EmailVerificationRequest) => {
        dispatch(emailVerificationPendingAction(request));
    },
    patientData: (request: PatientDataRequest) => {
        dispatch(patientDataPending(request));
    }
});
const mapStateToProps = (state: IAppState) => ({
    patientDataState: state.patientData,
});
export default connect(mapStateToProps, mapDispatchToProps)(Account);