import { getgroups } from 'process';
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Pin } from './Pin';
import { getCookie } from '../../utils/cookies';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { toast } from 'react-toastify';
import { TITLES_LABELS, APPOINTMENT_STATUS, CHAT } from '../../../constants';
import './Pin.css';
import configs from '../../../configs/apiConfigs';
export const Pins = () => {
    const pins = useSelector((state: any) => state.pins.pins,shallowEqual);

    useEffect(() => {
        console.log('pins', pins);
    }, [pins]);

    return (
        pins && pins.length > 0 ?
            pins.map((pin: any) => {
                return (
                    <React.Fragment key={pin.appointmentId}>
                        <Pin appointment={pin} pins={pins} />
                    </React.Fragment>
                )
            })
            :
            <></>
    )
}
