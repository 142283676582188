import React, { useEffect, useState } from 'react';

export const SearchProviderModal = ({ handleModalClose, handleSuccess, handleSrcIframe }: any) => {
    return (
        <div className="offcanvas-body">
            <div className="d-flex align-items-center justify-content-center">
                <div className="h6 me-3 mb-0">Are you looking for a new Provider or your Existing? </div>
                <div>
                    <button className="btn btn-dark fw-normal me-2" onClick={(e) => {
                        // this.handleExistingClick()
                        handleSrcIframe("new");
                        handleModalClose();
                    }}>New Provider</button>
                    <button className="btn btn-dark fw-normal " onClick={(e) => {
                        // this.handleExistingClick()
                        handleSrcIframe("existing");
                        handleModalClose();
                    }}>Existing Provider</button>
                </div>
            </div>
        </div>
    )
}