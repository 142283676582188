import { AUTH, LoginErrorAction, LoginPendingAction, LoginSuccessAction, LoginRequest, LoginResponse, RegisterRequest, RegisterPendingAction, RegisterResponse, RegisterSuccessAction, RegisterErrorAction } from './types';
/* Action Creators */
export const loginPending = (request: LoginRequest): LoginPendingAction => {
    return {
        type: AUTH.LOGIN_PENDING,
        payload: request
    };
};

export const loginSuccess = (response: LoginResponse): LoginSuccessAction => {
    return {
        type: AUTH.LOGIN_SUCCESS,
        login: response
    }
}

export const loginError = (err: any): LoginErrorAction => {
    return {
        type: AUTH.LOGIN_ERROR,
        error: err
    }
}

export const userLogout = () => {
    return {
        type: AUTH.LOGGED_OUT
    }
}

export const registerPending = (request: RegisterRequest): RegisterPendingAction => {
    return {
        type: AUTH.REGISTER,
        payload: request
    };
};

export const registerSuccess = (response: RegisterResponse): RegisterSuccessAction => {
    return {
        type: AUTH.REGISTER_SUCCESS,
        register: response
    }
}

export const registerError = (err: any): RegisterErrorAction => {
    return {
        type: AUTH.REGISTER_ERROR,
        error: err
    }
}

export const showWaitingPresc = (status: boolean) => {
    return {
        type: AUTH.SHOW_WAITING_PRESC,
        payload: status
    }
}

export const showWaitingPayment = (status: boolean) => {
    return {
        type: AUTH.SHOW_WAITING_PAYMENT,
        payload: status
    }
}