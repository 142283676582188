import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { profileUpdate } from "../../../services/profile";
import { getCookie } from "../../../utils/cookies";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { userLogout } from "../../../../store/auth/actions";
export const ChangePassword = ({ handleModalClose }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentViewPassword, setIsCurrentViewPassword] = useState(false);
  const [isNewViewPassword, setIsNewViewPassword] = useState(false);
  const [isConfirmViewPassword, setIsConfirmViewPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    setError,
    handleSubmit,
    setValue,
    getValues,
    errors,
    trigger,
    control,
    watch,
  } = useForm({
    defaultValues: {
      cpassword: "",
      npassword: "",
      rpassword: "",
    },
  });

  const handleUpdatePassword = (data: any) => {
    const login: any = getCookie();
    setIsLoading(true);
    profileUpdate(
      {
        type: "password",
        cpassword: data.cpassword,
        npassword: data.npassword,
        rpassword: data.rpassword,
      },
      login.xpr_user_id
    )
      .then((success) => {
        setIsLoading(false);
        if (success && success.status && success.status.error === false) {
          toast.success("Password updated successfully");
          handleModalClose();
          dispatch(userLogout());
          history.push("/login");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.success("Something went wrong.");
      });
  };
  return (
    <>
      <div className="offcanvas-action d-flex">
        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7 ms-auto"
          // data-bs-dismiss="offcanvas" aria-label="Close"
          onClick={() => {
            handleModalClose();
          }}
        >
          Close
        </button>
      </div>

      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Change Password</h5>
      </div>

      <div className="offcanvas-body">
        <div className="mb-3">
          <label className="form-label">Current Password</label>
          <div className="input-group">
            <input
              type={isCurrentViewPassword ? "text" : "password"}
              className="form-control"
              name="cpassword"
              id=""
              placeholder="Current password"
              ref={register({ required: true })}
            />
            <span className="input-group-text bg-transparent">
              <div
                className="view-password-control opacity-50 fs-7"
                onClick={() => {
                  setIsCurrentViewPassword(!isCurrentViewPassword);
                }}
              >
                <i
                  className={
                    isCurrentViewPassword
                      ? "bi bi-eye active"
                      : "bi bi-eye-slash active"
                  }
                ></i>
              </div>
            </span>
          </div>
          {errors &&
            errors.cpassword &&
            errors.cpassword.type === "required" && (
              <div className="text-danger error">
                Current password is required
              </div>
            )}
        </div>

        <div className="mb-3">
          <label className="form-label">New Password</label>
          <div className="input-group">
            <input
              type={isNewViewPassword ? "text" : "password"}
              className="form-control"
              name="npassword"
              id=""
              placeholder="Enter new password"
              ref={register({
                required: true,
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              })}
            />
            <span className="input-group-text bg-transparent">
              <div
                className="view-password-control opacity-50 fs-7"
                onClick={() => {
                  setIsNewViewPassword(!isNewViewPassword);
                }}
              >
                <i
                  className={
                    isNewViewPassword
                      ? "bi bi-eye active"
                      : "bi bi-eye-slash active"
                  }
                ></i>
              </div>
            </span>
          </div>

          {errors &&
            errors.npassword &&
            errors.npassword.type === "required" && (
              <div className="text-danger error">Password is required</div>
            )}
          {errors &&
            errors.npassword &&
            errors.npassword.type === "pattern" && (
              <div className="text-danger error">
                Password must contain 8 characters with at least 1 upper case, 1
                lower case, 1 special and 1 numeric character.
              </div>
            )}
        </div>
        <div className="mb-3">
          <label className="form-label">Confirm New Password</label>
          <div className="input-group">
            <input
              type={isConfirmViewPassword ? "text" : "password"}
              className="form-control"
              name="rpassword"
              id=""
              placeholder="Confirm new password"
              ref={register({ required: true })}
            />
            <span className="input-group-text bg-transparent">
              <div
                className="view-password-control opacity-50 fs-7"
                onClick={() => {
                  setIsConfirmViewPassword(!isConfirmViewPassword);
                }}
              >
                <i
                  className={
                    isConfirmViewPassword
                      ? "bi bi-eye active"
                      : "bi bi-eye-slash active"
                  }
                ></i>
              </div>
            </span>
          </div>
          {errors &&
            errors.rpassword &&
            errors.rpassword.type === "required" && (
              <div className="text-danger error">
                Confirm password is required
              </div>
            )}
        </div>

        <div className="alert alert-danger text-center py-2">
          Note: For security reasons you will be asked to sign in again
        </div>

        <div className="mb-3">
          <button
            type="button"
            className="btn btn-dark w-100 fw-normal"
            onClick={handleSubmit(handleUpdatePassword)}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};
